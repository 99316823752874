import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.75rem;
  padding: 0 0.25rem;
  border-radius: 0.125rem;
  background-color: var(--pink);
  margin-left: 0.25rem;
`
const Text = styled.div`
  font-family: inter;
  font-size: 0.5625rem;
  font-weight: 800;
  color: var(--white-color);
`
interface AutoBadgeProps {
  percentage: number | null
}
export const AutoBadge = ({ percentage }: AutoBadgeProps) => {
  return (
    <>
      {percentage !== null && (
        <Wrapper data-testid="totalInsulinAutoBadge">
          <Text>{`${formatNumber({
            value: percentage,
            decimals: 0
          })}%`}</Text>
        </Wrapper>
      )}
    </>
  )
}
