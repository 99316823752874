export enum OperatingSystem {
  MacOS = 'MacOS',
  iOS = 'iOS',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux'
}

export const getOperatingSystem = () => {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystem.MacOS
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystem.iOS
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystem.Windows
  } else if (/Android/.test(userAgent)) {
    os = OperatingSystem.Android
  } else if (!os && /Linux/.test(platform)) {
    os = OperatingSystem.Linux
  }

  return os
}
