import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'
import { AboutConfig } from '../About/About'
import { Mail, MailType } from '../Mail/Mail'
import { Dot, FooterItem } from '../PortalFooter'

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
`
interface MailSectionProps {
  config: AboutConfig
}
export const MailSection = ({ config }: MailSectionProps) => {
  return (
    <Buttons data-testid="MailSupportSection">
      <FooterItem style={{ padding: '0' }}>
        <Mail
          config={{
            type: MailType.support,
            frontendVersion: config.frontendVersion,
            backendVersion: config.backendVersion,
            deploymentName: `${config.deploymentName}`,
            productUDI: `${config.productUDI}`
          }}
        ></Mail>
      </FooterItem>
      <Dot icon={faCircleSmall} />
      <FooterItem style={{ padding: '0' }}>
        <Mail
          config={{
            type: MailType.feedback,
            frontendVersion: config.frontendVersion,
            backendVersion: config.backendVersion,
            deploymentName: `${config.deploymentName}`,
            productUDI: `${config.productUDI}`
          }}
        ></Mail>
      </FooterItem>
    </Buttons>
  )
}
