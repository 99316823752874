import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DeploymentType } from '../../../core/entities/siteSettings.entity'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  align-items: flex-start;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  gap: 0.5rem;
`
const InfoIcon = styled(FontAwesomeIcon)`
  color: var(--blue);
  font-size: 0.875rem;
  margin-top: 0.125rem;
`
const Text = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
`
export const getDeploymentTypeShortText = (
  deploymentType:
    | DeploymentType.Trial
    | DeploymentType.Dev
    | DeploymentType.Demo
) => {
  switch (deploymentType) {
    case DeploymentType.Trial:
      return 'TRIAL'
    case DeploymentType.Dev:
      return 'DEVELOPMENT'
    case DeploymentType.Demo:
      return 'DEMO'
  }
}
export const IfuWarningComponent = ({
  deploymentType
}: {
  deploymentType:
    | DeploymentType.Trial
    | DeploymentType.Dev
    | DeploymentType.Demo
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <InfoIcon icon={faInfoCircle}></InfoIcon>
      <Text data-testid="IfuWarningComponentText">
        {t('IfuWarningText', {
          deploymentName: getDeploymentTypeShortText(deploymentType)
        })}
      </Text>
    </Wrapper>
  )
}
