import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OpenUploader } from '../DownloadUploader/OpenUploader/OpenUploader'
import { useRedirectOnUrlChange, useUploaderAuthSchemeUrl } from './hooks'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
  width: 37.5rem;
`

const Title = styled.span`
  font-family: inter;
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const Text = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-medium);
`
/**
 * This component tries to redirect the browser to the scheme url to open the
 * application using a deep link. However, this might not work in some
 * situations.
 */
export const OpenUploaderPage = () => {
  const { t } = useTranslation()
  const url = useUploaderAuthSchemeUrl()

  useRedirectOnUrlChange(url)

  return (
    <Wrapper>
      <PageContainer>
        <TextWrapper>
          <Title>{t('Opening app...')}</Title>

          <Text>{t('You can close this page when the app is launched.')}</Text>
          <Text>
            {t(
              'If the application does not launch automatically please click below:'
            )}
          </Text>
        </TextWrapper>
        <OpenUploader />
      </PageContainer>
    </Wrapper>
  )
}
