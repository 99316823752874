import { TFunction } from 'react-i18next'
import { CgmEnum } from '../CGMWidget/CGMWidget'
import { SingleWidgetTypeEnum } from '../SingleWidget/SingleWidget'

export enum TooltipType {
  totalCarbs,
  penInjections,
  basalInjections,
  bolusInjections
}
export const getInformationTooltipParagraph = (
  type: CgmEnum | SingleWidgetTypeEnum | TooltipType,
  t: TFunction<'translation', undefined>
) => {
  switch (type) {
    case TooltipType.totalCarbs:
      return [
        {
          title: t('Total carbohydrates'),
          body: t('Total carbohydrates for the period.')
        }
      ]
    case CgmEnum.avg:
      return [
        {
          title: t('Avg. Glucose (CGM)'),
          body: t(
            'All CGM glucose values added together, divided by the number of readings.'
          )
        }
      ]
    case CgmEnum.std:
      return [
        {
          title: t('SD (Standard Deviation)'),
          body: t('How far values are from the average.')
        }
      ]
    case CgmEnum.cv:
      return [
        {
          title: t('CV (Coefficient of Variation)'),
          body: t(
            'How far apart (wide) glucose values are. Research suggests a target of 36% or lower.'
          )
        }
      ]
    case SingleWidgetTypeEnum.AvgCarbs:
      return [
        {
          title: t('Avg. Daily Carbs'),
          body: t(
            'All carb entries added together, then divided by the number of days in this view. Note, these entries come from either bolus wizard events.'
          )
        }
      ]
    case SingleWidgetTypeEnum.SensorUsage:
      return [
        {
          title: t('Sensor Usage'),
          body: t('Share of the period which has sensor data.')
        }
      ]

    case SingleWidgetTypeEnum.GMI:
      return [
        {
          title: t('GMI:'),
          body: t('MANGLER TEKST HER')
        }
      ]
    case SingleWidgetTypeEnum.DailySteps:
      return [{ title: t('Daily Steps'), body: t('Daily Steps') }]
    case TooltipType.penInjections:
      return [{ title: t('Pen Injections'), body: t('Pen Injections') }]
    case TooltipType.basalInjections:
      return [{ title: t('Basal Injections'), body: t('Basal Injections') }]
    case TooltipType.bolusInjections:
      return [{ title: t('Bolus Injections'), body: t('Bolus Injections') }]
  }
}
