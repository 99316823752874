import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { CSSProperties } from 'styled-components'
import { DeviceEventConfig } from '../Interfaces'

const DeviceEventIconHtml = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
`
export const DeviceEventIcon = ({
  config,
  isHovered
}: {
  config: DeviceEventConfig | null
  isHovered: boolean
}) => {
  return (
    config && (
      <DeviceEventIconHtml
        icon={config.icon}
        style={
          typeof config.colors === 'object'
            ? ({
                '--fa-primary-color': `${
                  isHovered ? 'var(--white-color)' : config.colors.primaryColor
                }`,
                '--fa-secondary-color': `${
                  isHovered
                    ? 'var(--white-color)'
                    : config.colors.secondaryColor
                }`,
                '--fa-secondary-opacity': '1',
                '--fa-primary-opacity': '1'
              } as CSSProperties)
            : { color: isHovered ? 'var(--white-color)' : config.colors }
        }
        width="1rem"
        height="1rem"
        x="0.125rem"
        y="0.125rem"
      />
    )
  )
}
