import { AxiosRequest } from './axios-request'
import config from '../config'
import { Clinic } from '../entities/clinic.entity'

export async function getCurrentClinic(options: object = {}): Promise<Clinic> {
  const response = await AxiosRequest.create().get(
    config.API_URL + '/clinics',
    options
  )
  return response.data as Clinic
}
