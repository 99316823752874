import config from '../config'
import { AxiosRequest } from './axios-request'

export async function removePatientFromPopulation(
  populationId: string | undefined,
  patientId: string
): Promise<void> {
  const response = await AxiosRequest.create().delete(
    `${config.API_URL}/population/${populationId}?patientId=${patientId}`
  )
  return response.data
}
