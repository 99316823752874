import axios from 'axios'
import config from '../config'
import { SiteSettings } from '../entities/siteSettings.entity'

export async function getSiteSettings(
  options: object = {}
): Promise<SiteSettings> {
  const response = await axios
    .create()
    .get(config.API_URL + '/deployment/settings', options)
  return response.data as SiteSettings
}
