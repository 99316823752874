export enum MarkDownStylingModels {
  table = 'table',
  math = 'math'
}

export enum MarkDownTypes {
  link,
  phone,
  email,
  dataController
}
export enum MarkDownIDs {
  hcpEmail = 'hcp-email',
  hcpPhone = 'hcp-phone',
  hcpLink = 'hcp-link',
  hcpDataController = 'hcp-data-controller',
  patientEmail = 'patient-email',
  patientPhone = 'patient-phone',
  patientLink = 'patient-link',
  patientDataController = 'patient-data-controller'
}
