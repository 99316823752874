import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { MarkDownComponent } from '../../../../components/MarkDownComponent/MarkDownComponent'
import { MarkDownStylingModels } from '../../../../components/MarkDownComponent/interfaces/interfaces'
import { ModalComponent } from '../../../../components/Modal/Modal'
import { formatNumber } from '../../../../helpers/helpers'
import { AlgorithmInfo } from '../../interfaces'

const DeleteModalComponent = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  padding: 2rem;
  width: 42.25rem;
`
const Entry = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const Text = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  color: var(--text-primary);
  font-weight: 600;
`
const LeftAlign = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 21rem;
`
interface EventCalculationsModalProps {
  closefn: () => void
  algorithmInfo: AlgorithmInfo
  probability: number
}

export const EventCalculationsModal = ({
  closefn,
  algorithmInfo,
  probability
}: EventCalculationsModalProps) => {
  const { t } = useTranslation()

  const getText = (key: string) => {
    switch (key) {
      case 'minValDay':
        return t('(Minimum CGM value recorded during the day)')
      case 'minValNight':
        return t('(Minimum CGM value recorded during the night)')
      case 'gvpDiffAfternoon':
        return t('(Coefficient of vairation)')
    }
  }
  return (
    <ModalComponent
      closeFun={closefn}
      closetestid={'closeEventCalculationsModal'}
    >
      <DeleteModalComponent data-testid="EventCalculationsModalWrapper">
        {Object.entries(algorithmInfo).map(
          ([key, value], i, arr) =>
            i !== arr.length - 1 && (
              <Entry key={key}>
                <Wrapper>
                  <Text data-testid={key}>{`${key}:`}</Text>
                  <Text>
                    {formatNumber({
                      value,
                      decimals: 2
                    })}
                  </Text>
                </Wrapper>
                <LeftAlign>
                  <Text>{getText(key)}</Text>
                </LeftAlign>
              </Entry>
            )
        )}
        <Wrapper>
          <Text>{t('probability')} =</Text>
          <MarkDownComponent
            text={algorithmInfo.probability}
            wrapperStyle={{}}
            stylingModel={MarkDownStylingModels.math}
          />
          <Text>= {formatNumber({ value: probability, decimals: 2 })}</Text>
        </Wrapper>
      </DeleteModalComponent>
    </ModalComponent>
  )
}
