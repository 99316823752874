import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TText, Td, Tr } from './ScrollTableComponent'

const CenterDiv = styled(Td)`
  text-align: center;
`
const Text = styled(TText)`
  margin: 0 auto;
  font-family: inter;
  font-weight: 600;
  color: var(--text-lighter);
`
export const NoDataRow = () => {
  const { t } = useTranslation()
  return (
    <Tr data-testid="noDataRow">
      <CenterDiv colSpan={100}>
        <Text>{t('No data')} </Text>
      </CenterDiv>
    </Tr>
  )
}
