import {
  faCheck,
  faDownToLine,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties, keyframes } from 'styled-components'
import { uploadData } from '../../core/api/upload-data-api'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePeriodContext } from '../../core/contexts/period-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { UserType } from '../../core/entities/user.entity'
export enum UploaderType {
  medtronic = 'medtronic',
  dexcom = 'dexcom',
  abbott = 'abbott'
}

enum UploadError {
  wrongfiletype = 'Upload Error'
}
enum UploadSuccess {
  fileuploaded = 'Uploaded'
}
const GetUploadTypeString = (type: UploaderType) => {
  switch (type) {
    case UploaderType.medtronic:
      return 'medtroniccarelink'
    case UploaderType.dexcom:
      return 'dexcomclarity'
    case UploaderType.abbott:
      return 'abbottlibreview'
    default:
      return ''
  }
}
const GetUploadTypeTitle = (type: UploaderType) => {
  switch (type) {
    case UploaderType.medtronic:
      return 'Medtronic Carelink'
    case UploaderType.dexcom:
      return 'Dexcom Clarity'
    case UploaderType.abbott:
      return 'Abbott Libreview'
    default:
      return ''
  }
}
const UploadComponentWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`
const UploadDataWrapper = styled.form`
  height: 12.5rem;
  width: 18.75rem;
  max-width: 100%;
  text-align: center;
  position: relative;
`
const FileInput = styled.input`
  display: none;
`

interface FileInputLabelProps {
  active: boolean
  status: UploadError | UploadSuccess | null
}
interface FileInputTitleProps {
  active: boolean
}

const loadAnimation = () => keyframes`
0% { left: 0%;}
100% { left: calc(100% - 2rem)}
`
const LoadWrapper = styled.div`
  width: 100%;
  height: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  background-color: var(--white-color);
  position: relative;
  overflow: hidden;
`
const LoadBar = styled.div`
  position: absolute;
  width: 2rem;
  height: 100%;
  border-radius: 0.5rem;
  border: none;
  background-color: var(--inRange-color);
  animation-name: ${loadAnimation};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
`
const LoadComponent = () => {
  return (
    <LoadWrapper>
      <LoadBar></LoadBar>
    </LoadWrapper>
  )
}
const FileInputLabel = styled.label<FileInputLabelProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  border-radius: 1rem;
  border: ${(props) =>
    Object.values(UploadError).includes(props.status as UploadError)
      ? '0.125rem solid var(--veryLow-darker-color)'
      : Object.values(UploadSuccess).includes(props.status as UploadSuccess)
      ? '0.125rem solid var(--inRange-darker-color)'
      : props.active
      ? '0.125rem solid var(--brand-primary-12-color)'
      : '0.125rem solid var(--text-lightest)'};
  background-color: ${(props) =>
    props.active ? 'var(--brand-primary-4-color)' : 'var(--element-bg)'};
  color: ${(props) =>
    props.active ? 'var(--brand-primary-color)' : 'var(--text-primary)'};
  &:hover {
    background-color: var(--brand-primary-4-color);
    border: 0.125rem solid var(--brand-primary-12-color);
    color: var(--brand-primary-color);
  }
`
const FileInputTitle = styled.div<FileInputTitleProps>`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 600;
  color: inherit;
`
const DragInfoWrapper = styled.div`
  height: 5.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`
const DragInfoTitle = styled.div<FileInputTitleProps>`
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  color: inherit;
`
const DragInfoSubText = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  color: var(--text-lighter);
`
const BrowseButton = styled.button`
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  border: 0.125rem solid var(--brand-primary-color);
  border-radius: 0.5rem;
  background-color: var(--white-color);
  color: var(--brand-primary-color);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
`
const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

const ErrorComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  color: var(--veryLow-darker-color);
  margin-top: 1rem;
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
`
const SuccessComponent = styled(ErrorComponent)`
  color: var(--inRange-darker-color);
`
const StatusLogo = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  font-weight: 900;
`
const DownloadComponent = styled(FontAwesomeIcon)`
  font-size: 2rem;
  font-weight: 900;
  color: var(--inRange-color);
`
interface UploadDataComponentProps {
  uploadertype: UploaderType
  patientId?: string
  strict?: boolean
  style?: CSSProperties
}

export const UploadDataComponent = ({
  uploadertype,
  patientId,
  strict,
  style
}: UploadDataComponentProps) => {
  const { t } = useTranslation()
  const { patient, refreshPatient, setPatient } = usePatientContext()
  const { setPeriod } = usePeriodContext()
  const { user } = useSessionContext()
  // drag state
  const [dragActive, setDragActive] = useState(false)
  const [uploadError, setUploadError] = useState<UploadError | null>(null)
  const [uploadSuccess, setUploadSuccess] = useState<UploadSuccess | null>(null)
  const [currentFile, setCurrentFile] = useState<string | null>(null)

  // ref
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (uploadSuccess !== null) {
      const t = setTimeout(() => {
        setCurrentFile(null)
        setUploadSuccess(null)
      }, 3600)
      return () => clearTimeout(t)
    }
  }, [uploadSuccess])

  useEffect(() => {
    if (uploadError !== null) {
      const t = setTimeout(() => {
        setCurrentFile(null)
        setUploadError(null)
      }, 3600)
      return () => clearTimeout(t)
    }
  }, [uploadError])

  const handleFile = (file: FileList, type: UploaderType) => {
    const formData = new FormData()
    if (patient || patientId) {
      setCurrentFile(file[0].name)
      formData.append('file', file[0])
      uploadData(
        patient?.patientId ?? patientId ?? '',
        GetUploadTypeString(type),
        formData,
        strict
      )
        .then(() => {
          setUploadSuccess(UploadSuccess.fileuploaded)
          if (user?.type === UserType.Patient && patient) {
            const patientCopy = { ...patient }
            setPeriod(null)
            setPatient(null)
            setPatient(patientCopy)
          } else {
            refreshPatient && refreshPatient()
          }
        })
        .catch((error: Error) => {
          // TODO update to more specific error types/messages
          console.log(error.message)
          setUploadError(UploadError.wrongfiletype)
        })
    }
  }
  const handleDrag = function (e: any) {
    setUploadError(null)
    setUploadSuccess(null)
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else {
      setDragActive(false)
    }
  }

  const handleDrop = function (e: any, type: UploaderType) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files, type)
    }
  }

  const handleChange = function (e: any, type: UploaderType) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files, type)
    }
  }

  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click()
  }
  return (
    <UploadDataWrapper
      style={style}
      data-testid={'uploadwrapper' + uploadertype}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <FileInput
        ref={inputRef}
        type="file"
        multiple={false}
        onClick={() => {
          setUploadError(null)
          setUploadSuccess(null)
        }}
        onChange={(event) => handleChange(event, uploadertype)}
        data-testid={'fileinput' + uploadertype}
      />
      <FileInputLabel
        id="label-file-upload"
        htmlFor="input-file-upload"
        active={dragActive}
        status={uploadError || uploadSuccess || null}
      >
        <FileInputTitle active={dragActive}>
          {t(GetUploadTypeTitle(uploadertype))}
        </FileInputTitle>
        <DragInfoWrapper>
          <DragInfoTitle active={dragActive}>
            {currentFile || t('Drag and drop file here')}
          </DragInfoTitle>
          {!dragActive && !uploadError && !uploadSuccess && (
            <DragInfoSubText>{t('or')}</DragInfoSubText>
          )}
          {!dragActive && !uploadError && !uploadSuccess && currentFile ? (
            <LoadComponent></LoadComponent>
          ) : (
            <BrowseButton
              data-testid={'browsebutton' + uploadertype}
              onClick={onButtonClick}
            >
              {t('Browse')}
            </BrowseButton>
          )}
          {dragActive && <DownloadComponent icon={faDownToLine} />}
          {uploadError && (
            <ErrorComponent data-testid="errorcomponent">
              {uploadError}
              <StatusLogo icon={faExclamationTriangle} />
            </ErrorComponent>
          )}
          {uploadSuccess && (
            <SuccessComponent data-testid="successcomponent">
              {uploadSuccess}
              <StatusLogo icon={faCheck} />
            </SuccessComponent>
          )}
        </DragInfoWrapper>
      </FileInputLabel>
      {dragActive && (
        <DragFileElement
          data-testid={'dropcomponent' + uploadertype}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={(event) => handleDrop(event, uploadertype)}
        ></DragFileElement>
      )}
    </UploadDataWrapper>
  )
}

export const UploadData = () => {
  const UploadComponents = (
    Object.keys(UploaderType) as Array<keyof typeof UploaderType>
  ).map((key) => (
    <UploadDataComponent key={key} uploadertype={key as UploaderType} />
  ))
  return (
    <UploadComponentWrapper data-testid="UploadComponentsWrapper">
      {UploadComponents}
    </UploadComponentWrapper>
  )
}
