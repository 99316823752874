import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import {
  PeriodPosition,
  ScreenShotIconSize,
  ScreenshotComponent
} from '../../../Screenshot/ScreenshotComponent/ScreenshotComponent'

interface ScreenshotWidgetProps {
  wrapperRef?: MutableRefObject<HTMLDivElement | null>
  setScreenshotBorder: Dispatch<SetStateAction<boolean>>
}
export const ScreenshotWidget = ({
  wrapperRef,
  setScreenshotBorder
}: ScreenshotWidgetProps) => {
  return (
    <>
      {wrapperRef !== undefined && (
        <div
          onMouseEnter={() => setScreenshotBorder(true)}
          onMouseLeave={() => setScreenshotBorder(false)}
        >
          <ScreenshotComponent
            size={ScreenShotIconSize.small}
            showIcon={true}
            index={1}
            refs={{ wrapper: wrapperRef }}
            periodPlacement={{
              position: PeriodPosition.bottomLeft,
              margin: { left: 16, bottom: 0 }
            }}
            buttonWrapperStyle={{
              position: 'absolute',
              right: '0.25rem',
              top: '0.25rem'
            }}
            border={false}
            wrapperPadding={{ height: 24 }}
            ignoreTransform
          />
        </div>
      )}
    </>
  )
}
