import { faBarsFilter } from '@fortawesome/pro-regular-svg-icons'
import {
  faCheckDouble,
  faCircleStop,
  faLink,
  faTimesCircle as faTimesCircleSolid
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProviderPatientsType } from '../../../components/ProviderPatients/ProviderPatients'
import { SearchBarComponent } from '../../../components/SearchBarComponent/SearchBarComponent'
import { IntegrationButtonComponent } from './IntegrationButtonComponent/IntegrationButtonComponent'

export enum FilterEnum {
  allFilters = 'allFilters',
  linked = 'linked',
  ignored = 'ignored'
}

export enum DeviceEnum {
  allDevices = 'allDevices',
  dexcom = 'dexcom',
  dexcomApi = 'dexcomApi',
  abbott = 'abbott',
  medtronic = 'medtronic',
  fitbit = 'fitbit'
}

const TableSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const TableSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const TableSubSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const ClearFilters = styled.div`
  cursor: pointer;
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--text-medium);
  text-transform: uppercase;
`
const FilterButton = styled.div<{ active: boolean }>`
  cursor: pointer;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  border: 0.0625rem solid
    ${(props) =>
      props.active ? 'var(--text-primary)' : 'var(--text-lightest)'};
  border-radius: 0.5rem;
  background-color: ${(props) =>
    props.active ? 'var(--text-primary)' : 'var(--white-color)'};
  color: ${(props) =>
    props.active ? 'var(--white-color)' : 'var(--text-primary)'};
`
const FilterIcon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 900;
`
const FilterText = styled.span`
  color: inherit;
  font-size: 0.75rem;
  font-family: inter;
  font-weight: 500;
`

const getFilterIcon = (type: DeviceEnum | FilterEnum) => {
  switch (type) {
    case FilterEnum.linked:
      return faLink
    case FilterEnum.ignored:
      return faCircleStop
    case DeviceEnum.allDevices:
      return faCheckDouble
    case FilterEnum.allFilters:
      return faCheckDouble
    default:
      return undefined
  }
}

interface IntegrationFiltersProps {
  clearFunction: () => void
  passFilters: (searchString: string) => void
  filterState:
    | {
        type: FilterEnum
        state: boolean
      }[]
    | null
  setFilterState: Dispatch<
    SetStateAction<
      | {
          type: FilterEnum
          state: boolean
        }[]
      | null
    >
  >
  deviceState:
    | {
        type: DeviceEnum
        state: boolean
      }[]
    | null
  setDeviceState: Dispatch<
    SetStateAction<
      | {
          type: DeviceEnum
          state: boolean
        }[]
      | null
    >
  >
  showFilters: boolean
  setShowFilters: Dispatch<SetStateAction<boolean>>
  providerId: string | undefined
  searchString: string
  type: ProviderPatientsType
  isCurrentlyDefault: boolean
}
export const IntegrationFilters = ({
  clearFunction,
  passFilters,
  filterState,
  setFilterState,
  deviceState,
  setDeviceState,
  showFilters,
  setShowFilters,
  providerId,
  searchString,
  type,
  isCurrentlyDefault
}: IntegrationFiltersProps) => {
  const { t } = useTranslation()
  function handleClearFilters() {
    clearFunction()
  }
  const handleFilter = (type: FilterEnum | DeviceEnum) => {
    if (Object.keys(DeviceEnum).includes(type)) {
      const newFilter: {
        type: DeviceEnum
        state: boolean
      }[] = JSON.parse(JSON.stringify(deviceState))
      const typeIndex = newFilter.findIndex(
        (e: { type: DeviceEnum; state: boolean }) => e.type === type
      )
      if (type === DeviceEnum.allDevices) {
        if (newFilter[typeIndex].state === false) {
          newFilter.forEach((e) => {
            if (e.type !== DeviceEnum.allDevices) {
              e.state = false
            }
          })
        }
      } else {
        if (newFilter[typeIndex].state === false) {
          newFilter.forEach((e) => {
            if (e.type === DeviceEnum.allDevices) {
              e.state = false
            }
          })
        }
      }
      newFilter[typeIndex].state = !newFilter[typeIndex].state
      setDeviceState(newFilter)
    } else {
      const newFilter: {
        type: FilterEnum
        state: boolean
      }[] = JSON.parse(JSON.stringify(filterState))
      const typeIndex = newFilter.findIndex(
        (e: { type: FilterEnum; state: boolean }) => e.type === type
      )
      if (type === FilterEnum.allFilters) {
        if (newFilter[typeIndex].state === false) {
          newFilter.forEach((e) => {
            if (e.type !== FilterEnum.allFilters) {
              e.state = false
            }
          })
        }
      } else {
        if (newFilter[typeIndex].state === false) {
          newFilter.forEach((e) => {
            if (e.type === FilterEnum.allFilters) {
              e.state = false
            }
          })
        }
      }
      newFilter[typeIndex].state = !newFilter[typeIndex].state
      setFilterState(newFilter)
    }
    passFilters(searchString)
  }
  return (
    <TableSectionWrapper>
      <TableSection>
        <TableSubSection>
          <SearchBarComponent
            searchString={searchString}
            passSearchString={passFilters}
          />
        </TableSubSection>
        <TableSubSection>
          {!isCurrentlyDefault && (
            <ClearFilters
              data-testid="clearFilters"
              onClick={handleClearFilters}
            >
              {t('clear')}
            </ClearFilters>
          )}
          <FilterButton
            data-testid="toggleShowFilters"
            onClick={() => setShowFilters(!showFilters)}
            active={showFilters}
          >
            <FilterIcon
              icon={showFilters ? faTimesCircleSolid : faBarsFilter}
            />
            <FilterText> {t('Filters')}</FilterText>
          </FilterButton>
        </TableSubSection>
      </TableSection>
      {showFilters && (
        <TableSection>
          <TableSubSection>
            {type !== ProviderPatientsType.todo && (
              <>
                {filterState?.map((e) => (
                  <IntegrationButtonComponent
                    key={e.type}
                    type={e.type}
                    icon={getFilterIcon(e.type)}
                    state={e.state}
                    handleFilter={handleFilter}
                  />
                ))}
              </>
            )}
          </TableSubSection>
          <TableSubSection>
            {providerId === undefined &&
              deviceState?.map((e) => (
                <IntegrationButtonComponent
                  key={e.type}
                  type={e.type}
                  icon={getFilterIcon(e.type)}
                  state={e.state}
                  handleFilter={handleFilter}
                />
              ))}
          </TableSubSection>
        </TableSection>
      )}
    </TableSectionWrapper>
  )
}
