import config from '../config'
import { AxiosRequest } from './axios-request'

type startEndProps = {
  start: string
  end: string
}
export async function downloadPatientData(
  patientId: string,
  startEnd: startEndProps,
  options: object = {}
): Promise<Blob> {
  const response = await AxiosRequest.create({
    headers: { accept: 'application/zip' },
    responseType: 'blob'
  }).get(
    `${config.API_URL}/patients/${patientId}/csvzip?start=${startEnd.start}&end=${startEnd.end}`,
    options
  )
  return response.data as Blob
}
