import { UserType } from '../../../core/entities/user.entity'
import { MarkDownIDs, MarkDownTypes } from '../interfaces/interfaces'

export const getMarkDownId = (
  userType: UserType | undefined,
  markDownType: MarkDownTypes
) => {
  const user = userType || UserType.Patient
  if (user === UserType.Patient) {
    switch (markDownType) {
      case MarkDownTypes.link:
        return MarkDownIDs.patientLink
      case MarkDownTypes.phone:
        return MarkDownIDs.patientPhone
      case MarkDownTypes.email:
        return MarkDownIDs.patientEmail
      case MarkDownTypes.dataController:
        return MarkDownIDs.patientDataController
    }
  } else {
    switch (markDownType) {
      case MarkDownTypes.link:
        return MarkDownIDs.hcpLink
      case MarkDownTypes.phone:
        return MarkDownIDs.hcpPhone
      case MarkDownTypes.email:
        return MarkDownIDs.hcpEmail
      case MarkDownTypes.dataController:
        return MarkDownIDs.hcpDataController
    }
  }
}
