import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProviderObject } from '../../../containers/ClinicIntegrations/ClinicIntegrations'
import { useSessionContext } from '../../../core/contexts/session-context'
import { UserType } from '../../../core/entities/user.entity'
import { Modal } from '../../Modal/Modal'
import { ProviderIntegrationModel } from '../ProviderIntegrationModel'
import { GetProviders } from '../getProviders'
import {
  IntegrationComponent,
  IntegrationComponentStatus
} from './IntegrationComponent/IntegrationComponent'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap 0.75rem;
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const Title = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  font-family: inter;
  color: var(--text-primary);
`
export const AddButton = styled.button`
  border: 0.0625rem solid var(--text-lightest);
  box-sizing: border-box;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.6875rem;
  width: 1.75rem;
  height: 1.75rem;
  color: var(--text-primary);
  background-color: var(--white-color);
  cursor: pointer;
  &:hover {
    color: var(--white-color);
    background-color: var(--brand-primary-color);
  }
`
export const AddIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: inherit;
  font-weight: 900;
  background-color: inherit;
`

const NoIntegrationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const NoIntegrationsTitle = styled.span`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-medium);
`
const NoIntegrationsSubTitle = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-lighter);
`
const NoIntegrationsComponent = () => {
  const { t } = useTranslation()
  const { clinic } = useSessionContext()
  return (
    <NoIntegrationsWrapper data-testid="NoIntegrationsComponent">
      <NoIntegrationsTitle>
        {t('NoIntegrations', { deploymentName: clinic?.name })}
      </NoIntegrationsTitle>
      <NoIntegrationsSubTitle>
        {t(
          'Administrators can setup new integrations that will then appear here.'
        )}
      </NoIntegrationsSubTitle>
    </NoIntegrationsWrapper>
  )
}
export const StatusPage = () => {
  const { t } = useTranslation()
  const [refreshProviders, setRefreshProviders] = useState<boolean>(false)
  const { providers, providersError } = GetProviders(refreshProviders)
  const [showProviderSetup, setShowProviderSetup] = useState<boolean>(false)
  const [provider, setProvider] = useState<ProviderObject | null>(null)
  const { user } = useSessionContext()
  const handleShowProviderSetup = (providerProp: ProviderObject | null) => {
    setProvider(providerProp)
    setShowProviderSetup(!showProviderSetup)
  }
  const refresh = () => {
    setRefreshProviders(!refreshProviders)
  }
  const renderIntegrations = (providers: ProviderObject[]) => {
    if (providers.length > 0)
      return providers
        .filter(
          (provider: ProviderObject) =>
            provider.state !== IntegrationComponentStatus.deleted
        )
        .map((provider) => (
          <IntegrationComponent
            key={provider.id}
            provider={provider}
            handleShowProviderSetup={() => handleShowProviderSetup(provider)}
          />
        ))
    else if (user?.type === UserType.HCP) return <NoIntegrationsComponent />
    else return <></>
  }

  if (providers)
    return (
      <Wrapper data-testid="StatusPage">
        <TitleWrapper>
          <Title>{t('Provider Integrations')}</Title>
          {user?.type === UserType.HCPAdmin && (
            <AddButton
              data-testid="AddNewProviderIntegration"
              onClick={() => handleShowProviderSetup(null)}
            >
              <AddIcon icon={faPlus} />
            </AddButton>
          )}
        </TitleWrapper>
        {renderIntegrations(providers)}
        <Modal isOpen={showProviderSetup}>
          <ProviderIntegrationModel
            handleShowProviderSetup={() => setShowProviderSetup(false)}
            provider={provider}
            refreshProviders={refresh}
          />
        </Modal>
      </Wrapper>
    )

  return (
    <div data-testid="statusPageError">
      <p>{t('Error')}</p>
    </div>
  )
}
