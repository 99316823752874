import config from '../config'
import { AxiosRequest } from './axios-request'

export async function doHypoAlgo(
  patientId: string | undefined,
  portalVersion: string | undefined
): Promise<any> {
  const data = await AxiosRequest.create().get(
    config.API_URL +
      `/adaptt2d/${patientId}/nocturnal/request?productType=Portal&version=${portalVersion}`
  )
  return data.data
}
