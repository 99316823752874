import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import { SubmitHandler, UseFormSetValue, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { SearchButton } from '../SearchButton/SearchButton'
import { Spinner, spinnerSize } from '../Spinner/Spinner'

interface CssProps {
  focusState: boolean | null
}

const SearchBarComponent = styled.form<CssProps>`
  border: 1px solid;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.4375rem;
  position: relative;
  padding: 0.125rem;
  border-color: ${(props) =>
    props.focusState ? 'var(--brand-primary-color)' : 'var(--text-lightest)'};
  box-shadow: ${(props) =>
    props.focusState
      ? '0rem 0rem 0rem 0.375rem var(--brand-primary-16-color)'
      : 'var(--text-lightest)'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`
const SearchLogo = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--brand-primary-color);
  margin-left: 1.25rem;
`
const DeleteLogo = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--text-lightest);
  &:hover {
    color: var(--brand-primary-color);
  }
`
const moveLabel = keyframes`
from {
  bottom:11.5%;
  left:20%;
}
to {
  bottom:250%;
  left:0%;
}
`
const moveLabelBack = keyframes`
from {
  bottom:250%;
  left:0%;
}
to {
  bottom:11.5%;
  left:20%;
}
`

const SearchBarLabel = styled.div<CssProps>`
  font-family: inter;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--text-primary);
  position: absolute;
  pointer-events: none;
  line-height: 1.5rem;
  bottom: 11.5%;
  left: 20%;
  &.move {
    animation: ${moveLabel} 0.3s forwards 1;
  }

  &.moveBack {
    animation: ${moveLabelBack} 0.3s forwards 1;
  }
`
const InputWrapper = styled.div`
  gap: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const DeleteButton = styled.button`
  border: none;
  background: none;
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  &:disabled {
    opacity: 0;
    cursor: default;
  }
`
const DeleteButtonWrapper = styled.div`
  width: 1.75rem;
  max-width: 1.75rem;
`
const SearchBarInput = styled.input`
  color: var(--text-primary);
  font-family: inter;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.1875rem;
  border: none;
  width: 13.25rem;
  &:focus {
    outline: none;
  }
`

export const ErrorContainer = styled.div`
  position: absolute;
  color: var(--white-color);
  padding: 1.75rem;
  background: red;
  border-radius: 0.875rem;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
`

const findDiff = (string1: string, string2: string) => {
  let diff = ''
  let pos = null
  string2.split('').forEach((val, i) => {
    if (val !== string1.charAt(i)) {
      diff += val
      pos = i
    }
  })
  return { difference: diff, position: pos }
}
function isNumber(char: string) {
  return /^\d$/.test(char)
}

export type FormValues = {
  CPR: string
}

interface CprFunctionProps {
  setPatientError: (value: string | undefined) => void
  getValues: (field?: string | string[] | undefined) => any
  setCurrentCPR: Dispatch<SetStateAction<string>>
  currentCPR: string
  setValue: UseFormSetValue<{ CPR: string; [any: string]: string }>
}
export function cprFunction({
  setPatientError,
  setValue,
  getValues,
  setCurrentCPR,
  currentCPR
}: CprFunctionProps) {
  setPatientError(undefined)
  const cpr = getValues('CPR')
  const diff = findDiff(currentCPR, cpr)
  if (cpr.match(/-.*-/)) {
    setValue('CPR', currentCPR)
  }
  if (cpr.length === 0) {
    setValue('CPR', cpr)
    setCurrentCPR(cpr)
    return
  }
  if (cpr.length === 1 && cpr.includes('-')) {
    setValue('CPR', '')
    setCurrentCPR('')
    return
  }
  if (cpr.includes('-')) {
    const index = cpr.indexOf('-')
    const firstSix = cpr.substring(0, index)
    const lastFour = cpr.substring(index + 1, cpr.length)
    if (firstSix.replace('-', '').length < 6) {
      if (lastFour.length > 4) {
        setValue('CPR', currentCPR)
        return
      } else {
        setValue('CPR', cpr)
        setCurrentCPR(cpr)
        return
      }
    }
    if (lastFour.length > 4 || firstSix.length > 6) {
      setValue('CPR', currentCPR)
      return
    }
  }
  if (
    !isNumber(diff.difference) &&
    diff.difference !== '' &&
    diff.difference.length < 2
  ) {
    setValue('CPR', currentCPR)
    return
  }
  if (cpr.length > 11) {
    setValue('CPR', currentCPR)
    return
  }
  if (cpr.length > 6 && !cpr.includes('-')) {
    setValue('CPR', cpr.substring(0, 6) + '-' + cpr.substring(6, 11))
    setCurrentCPR(cpr.substring(0, 6) + '-' + cpr.substring(6, 11))
    return
  }
  if (currentCPR.includes('-') && !cpr.includes('-')) {
    const index = currentCPR.indexOf('-')
    const lastFour = cpr.substring(index + 1, cpr.length)
    if (lastFour.length === 0) {
      setValue('CPR', cpr)
    } else {
      setValue('CPR', currentCPR)
    }
    return
  }
  setCurrentCPR(getValues('CPR'))
}

type SearchBarProps = {
  children: ReactNode
  onSuccess: (data: FormValues) => void
  patientError: string | undefined
  setPatientError: Dispatch<SetStateAction<string | undefined>>
  searching: boolean
}

export const SearchBar = ({
  children,
  onSuccess,
  patientError,
  setPatientError,
  searching
}: SearchBarProps) => {
  const {
    register,
    getValues,
    resetField,
    formState: { isValid, isDirty },
    setValue,
    handleSubmit
  } = useForm<FormValues>({ mode: 'onChange', defaultValues: { CPR: '' } })
  const onSubmit: SubmitHandler<FormValues> = (data) => onSuccess(data)
  const [focusState, setFocusState] = useState<boolean | null>(true)
  const [currentCPR, setCurrentCPR] = useState('')
  const { ref, ...reg } = register('CPR', {
    onChange: () => {
      cprFunction({
        setPatientError,
        setValue,
        getValues,
        setCurrentCPR,
        currentCPR
      })
    },
    required: 'Please enter patient ID',
    pattern: {
      value: /^\d{6}-\d{4}$/,
      message: 'Wrong format'
    }
  })
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()
  function handleOnFocus() {
    setFocusState(true)
  }
  function handleOnBlur() {
    setFocusState(isDirty)
  }

  function clearField() {
    resetField('CPR')
    setPatientError(undefined)
    setCurrentCPR('')
  }
  useEffect(() => {
    if (inputRef.current) inputRef.current.select()
  }, [inputRef])

  return (
    <SearchBarComponent
      focusState={focusState}
      onSubmit={handleSubmit(onSubmit)}
      data-testid="searchBar"
    >
      <InputWrapper>
        <SearchBarLabel
          className={
            focusState !== null
              ? focusState === true
                ? 'move'
                : 'moveBack'
              : ''
          }
          focusState={focusState}
          data-testid="SearchBarLabel"
        >
          {t('Enter patient ID')}
        </SearchBarLabel>
        <SearchLogo icon={faSearch}></SearchLogo>
        <SearchBarInput
          {...reg}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          autoComplete="off"
          data-testid="SearchBarInput"
          ref={(e) => {
            ref(e)
            inputRef.current = e
          }}
        ></SearchBarInput>
        <DeleteButtonWrapper>
          {!searching && (
            <DeleteButton
              type="reset"
              disabled={!isDirty}
              onClick={clearField}
              data-testid="clearFieldButton"
            >
              <DeleteLogo icon={faTimesCircle}></DeleteLogo>
            </DeleteButton>
          )}
          {searching && <Spinner spinnersize={spinnerSize.medium}></Spinner>}
        </DeleteButtonWrapper>
      </InputWrapper>
      <SearchButton
        type="submit"
        disabled={!isValid}
        data-testid="submitButton"
      >
        {children}
      </SearchButton>
      {patientError && <ErrorContainer>{patientError}</ErrorContainer>}
    </SearchBarComponent>
  )
}
