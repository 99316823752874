import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'

const Wrapper = styled.div<{ $isPatient: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  justify-content: center;
  align-items: ${(props) => (props.$isPatient ? 'flex-start' : 'center')};
  padding: ${(props) => (props.$isPatient ? '0rem 2rem' : '1.5rem')};
  gap: 0.5rem;
  background-color: var(--element-bg);
  box-sizing: border-box;
  width: ${(props) => (props.$isPatient ? '50%' : '26.75rem')};
  height: 100%;
`
const Title = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 600;
  line-height: 1.5rem;
`
const PatientTitle = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 700;
  line-height: 2.25rem;
`
const ValueUnit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 0.25rem;
`
const Value = styled.span`
  font-family: inter;
  font-size: 2.625rem;
  color: var(--text-primary);
  font-weight: 700;
  line-height: 3rem;
`
const Unit = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-lighter);
  font-weight: 700;
  line-height: 2.25rem;
  vertical-align: bottom;
`
const Text = styled(Title)`
  color: var(--text-lighter);
`
const TextValue = styled(Title)`
  font-size: 1rem;
  line-height: 1.5rem;
`
const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`
const TextValueLight = styled(TextValue)`
  color: var(--text-lighter);
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`
const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

interface CurrentDoseProps {
  isPatient: boolean
  hcpName: string
  time: string
  currentDose: number
}
export const CurrentDose = ({
  isPatient,
  hcpName,
  time,
  currentDose
}: CurrentDoseProps) => {
  const { t } = useTranslation()
  const getTitle = () => {
    if (isPatient) {
      return <PatientTitle>{t('Recommended basal dose')}</PatientTitle>
    } else {
      return <Title>{t('Current dose')}</Title>
    }
  }
  const getFooter = () => {
    if (isPatient) {
      return (
        <Footer>
          <FooterSection>
            <Text>{t('Set by')}</Text>
            <TextValue>{hcpName}</TextValue>
          </FooterSection>
          <FooterSection>
            <Text>{t('Set on')}</Text>
            <TextRow>
              <TextValue> {dayjs(time).format('DD/MM/YYYY')}</TextValue>
              <TextValueLight> @ </TextValueLight>
              <TextValue> {dayjs(time).format('HH:mm')} </TextValue>
            </TextRow>
          </FooterSection>
        </Footer>
      )
    } else {
      return (
        <Text>
          {t('Set on')} {dayjs(time).format('DD/MM/YYYY')}
        </Text>
      )
    }
  }
  return (
    <Wrapper $isPatient={isPatient}>
      {getTitle()}
      <ValueUnit>
        <Value>{currentDose}</Value>
        <Unit>U</Unit>
      </ValueUnit>
      {getFooter()}
    </Wrapper>
  )
}
