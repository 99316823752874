import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StackedDailyTableDataObject } from '../../components/DailyTable/DailyTable'
import { WidgetState } from '../../components/DataWidget/Shared/Interfaces/Interfaces'
import { Insights } from '../../components/Graphs/Insights/Insights'
import { PageTitle, PageTitles } from '../../components/PageTitle/PageTitle'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { TrendsButtons } from '../../components/TrendsComponents/TrendsButtons/TrendsButtons'
import { TrendsGraph } from '../../components/TrendsComponents/TrendsGraph/TrendsGraph'
import {
  getDaysString,
  isTrendsData
} from '../../components/TrendsComponents/TrendsHelpers/TrendsHelpers'
import { WidgetsStack } from '../../components/WidgetsStack/WidgetsStack'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePeriodContext } from '../../core/contexts/period-context'
import { formatNumber } from '../../helpers/helpers'
import { TrendsData, usePatientData } from '../../hooks/GetPatientData'
import { DeviceSettingsResponse } from '../DeviceSettings/DeviceSettings'
import { OverviewResponse } from '../Overview/Overview'
import { DataObject } from '../StackedDaily/Interfaces/Interfaces'

interface CheckBoxProps {
  active: boolean | null
  hover: boolean
}

const Wrapper = styled.div`
  margin: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 1250px) {
    justify-content: flex-start;
  }
  padding-bottom: 3rem;
  gap: 3rem;
`

const OuterContainer = styled.div`
  position: relative;
  align-items: flex-start;
  display: flex;
  gap: 2rem;
  flex-direction: column;
`
const Container = styled.div`
  position: relative;
  align-items: flex-start;
  display: flex;
  gap: 2rem;
  flex-direction: column;
`
const GraphWrapper = styled.div`
  width: 53.3125rem;
  height: 34.875rem;
  position: relative;
  display: block;
`

const GraphContainer = styled.div``

const LoadAbsolute = styled.div`
  position: absolute;
  width: 53.3125rem;
  height: 34.875rem;
`
const LoadWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const LoadPositioning = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(50%);
`
export const CheckBox = styled.div<CheckBoxProps>`
  width: 1rem;
  height: 1rem;
  background-color: ${(props) =>
    props.active ? 'var(--brand-primary-color)' : 'var(--white-color)'};
  border: ${(props) =>
    props.active
      ? '0.03125rem solid var(--brand-darker-color)'
      : '0.03125rem solid var(--element-stroke)'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  z-index: 1;
`
export const CheckMark = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  font-weight: 900;
  color: var(--white-color);
`

const Tooltip = styled.div<{ x: number; y: number }>`
  position: absolute;
  width: 14.375rem;
  background-color: var(--white-color);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  box-shadow: 0rem 0.25rem 0.5rem 0rem var(--tooltip-shadow);
  overflow: hidden;
  left: ${(props) => props.x}%;
  top: ${(props) => props.y}%;
  transform: translateX(calc(-50% - 7.5px)) translateY(-200%);
  @media (max-width: 1250px) {
    transform: translateX(-7.5px) translateY(-200%);
  }
`
const TooltipHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-bottom: 0.0625rem solid var(--text-lightest);
  background: var(--element-bg);
  box-sizing: border-box;
`
const TooltipHeadertext = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
`
const TooltipBody = styled.div`
  width: 100%;
  display: flex;
  padding: 0.375rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`
const TooltipBodytext = styled(TooltipHeadertext)`
  color: var(--text-lighter);
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export interface TrendsTooltipProps {
  index: number
  value: number
}
const TrendsTooltip = ({ index, value }: TrendsTooltipProps) => {
  const { t } = useTranslation()
  const x = ((index + 1) / 48) * 100
  const y = 100 - (value / 30) * 100
  return (
    <Tooltip x={x} y={y}>
      <TooltipHeader>
        <TooltipHeadertext>{`${getTooltipTime(index)} - ${getTooltipTime(
          index + 1
        )}`}</TooltipHeadertext>
      </TooltipHeader>
      <TooltipBody>
        <TooltipBodytext>{t('Median glucose')}</TooltipBodytext>
        <Row>
          <TooltipHeadertext>
            {formatNumber({
              value,
              decimals: 1
            })}
          </TooltipHeadertext>
          <TooltipBodytext>mmol/L</TooltipBodytext>
        </Row>
      </TooltipBody>
    </Tooltip>
  )
}
const getTooltipTime = (index: number): string => {
  const hours = (index * 30) / 60
  const string = `${Number.parseInt(hours.toString())
    .toString()
    .padStart(2, '0')}:${hours % 1 === 0 ? '00' : '30'}`

  return string
}
interface GraphComponentProps {
  daysState: boolean[] | null
  setDaysState: Dispatch<SetStateAction<boolean[]>>
  graphState: boolean[] | null
  setGraphState: Dispatch<SetStateAction<boolean[]>>
  screenShotWrapper: MutableRefObject<HTMLDivElement | null>
  patientViewData:
    | StackedDailyTableDataObject
    | TrendsData
    | DataObject
    | OverviewResponse
    | DeviceSettingsResponse
    | null
  loading: boolean
}
const GraphComponent = ({
  daysState,
  setDaysState,
  graphState,
  setGraphState,
  screenShotWrapper,
  patientViewData,
  loading
}: GraphComponentProps) => {
  const isValidData = patientViewData && isTrendsData(patientViewData)
  const graphData = isValidData ? patientViewData : null
  const [tooltip, setTooltip] = useState<TrendsTooltipProps | null>(null)

  return (
    <GraphContainer>
      {loading && (
        <LoadAbsolute>
          <LoadWrapper>
            <LoadPositioning>
              <Spinner spinnersize={spinnerSize.large}></Spinner>
            </LoadPositioning>
          </LoadWrapper>
        </LoadAbsolute>
      )}
      <TrendsButtons
        daysState={daysState}
        setDaysState={setDaysState}
        graphState={graphState}
        setGraphState={setGraphState}
        refs={{
          wrapper: screenShotWrapper
        }}
      />
      <GraphWrapper data-testid="TrendsDataWrapper">
        {tooltip && (
          <TrendsTooltip index={tooltip.index} value={tooltip.value} />
        )}
        <TrendsGraph
          data={graphData}
          state={graphState}
          setTooltip={setTooltip}
          loading={loading}
        ></TrendsGraph>
      </GraphWrapper>
    </GraphContainer>
  )
}
export function Trends() {
  const screenShotWrapper = useRef<HTMLDivElement | null>(null)
  const [daysState, setDaysState] = useState<Array<boolean>>(
    new Array(7).fill(true)
  )
  const [graphState, setGraphState] = useState<Array<boolean>>(
    new Array(4).fill(true)
  )
  const daysString = getDaysString(daysState)
  const { patient } = usePatientContext()
  const { period } = usePeriodContext()
  const { patientViewData, patientViewError, patientViewLoading } =
    usePatientData(
      patient,
      period,
      { type: 'trends', days: daysString },
      daysState
    )

  const getConfig = () => {
    if (patientViewLoading)
      return { testid: 'trendsLoading', widgetState: WidgetState.Loading }
    else return { testid: 'trendsData', widgetState: WidgetState.Data }
  }

  if (patientViewError) {
    return (
      <Wrapper data-testid="trendsError">
        <div>Error: {patientViewError.message}</div>
      </Wrapper>
    )
  } else {
    const isValid =
      patientViewData !== null &&
      isTrendsData(patientViewData) &&
      daysState.includes(true)
    const config = getConfig()
    return (
      <Wrapper data-testid={isValid ? config.testid : 'trendsNoData'}>
        <OuterContainer>
          <Container ref={screenShotWrapper}>
            <PageTitle type={PageTitles.trends} showTooltip />
            <GraphComponent
              daysState={daysState}
              setDaysState={setDaysState}
              graphState={graphState}
              setGraphState={setGraphState}
              screenShotWrapper={screenShotWrapper}
              patientViewData={patientViewData}
              loading={patientViewLoading}
            />
            {isValid && patientViewData.insightsView !== null && (
              <Insights
                data={patientViewData.insightsView}
                maxPenInjection={patientViewData.maxPenInjection}
              ></Insights>
            )}
          </Container>
        </OuterContainer>
        <WidgetsStack
          timeInRangeBars={isValid ? patientViewData.timeInRange : null}
          totalInsulin={isValid ? patientViewData.totalInsulin : null}
          averageGlucose={isValid ? patientViewData.averageGlucose : null}
          sensorUsage={isValid ? patientViewData.sensorUsage : null}
          standardDeviation={isValid ? patientViewData.standardDeviation : null}
          totalCarbs={isValid ? patientViewData.totalCarbs : null}
          cv={isValid ? patientViewData.cv : null}
          timeInAutomation={isValid ? patientViewData.timeInAutomation : null}
          pumpActivity={isValid ? patientViewData.pumpActivity : null}
          timeInActivity={isValid ? patientViewData.timeInActivity : null}
          injectionWidgets={isValid ? patientViewData.injectionWidgets : null}
          state={config.widgetState}
        ></WidgetsStack>
      </Wrapper>
    )
  }
}
