import {
  defaultPatientPagesObject,
  PatientPagesObjectType,
  PatientView
} from '../core/contexts/patient-view-context'

export const restructurePatientPageArray = (
  pages: PatientView[] | undefined
) => {
  const pagesObject: PatientPagesObjectType = { ...defaultPatientPagesObject }

  pages
    ?.filter((page) => page.page in defaultPatientPagesObject)
    .forEach((page) => {
      pagesObject[page.page] = {
        ...defaultPatientPagesObject,
        show: true,
        subViews: page.subViews
      }
    })
  return pagesObject
}
