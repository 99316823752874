import {
  faArrowRightLong,
  faChevronDown,
  faChevronUp,
  faTriangleExclamation
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { anchorClick } from '../../../../../containers/StackedDaily/StackedDaily'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.75rem 2rem 0.75rem 1rem;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  padding: 1rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  justify-content: center;
  align-items: center;
`
const LeftSectionHeader = styled(Row)`
  gap: 0.625rem;
`
const LeftSectionSub = styled(Row)`
  gap: 0.75rem;
`
const Dot = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  color: var(--text-lightest);
`

const HeaderTitle = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-weight: 600;
  font-size: 1.125rem;
  text-transform: capitalize;
`
const GoToDayButton = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    background-color: var(--element-bg);
  }
`
const Arrow = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: inherit;
`

const Warning = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: var(--text-lighter);
`
const SubText = styled.span`
  font-size: 0.875rem;
  color: var(--text-lighter);
  font-family: inter;
  font-weight: 600;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
`

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 0.625rem;
`

const Badge = styled.div`
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  box-sizing: border-box;
  width: 3rem;
  height: 1.875rem;
`

const NumberBadge = styled(Badge)`
  background-color: var(--veryLow-color);
`

const ExpandBadge = styled(Badge)`
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-bg);
  cursor: pointer;
  &:hover {
    background-color: var(--element-bg-dark);
  }
`
const BadgeText = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--white-color);
`

const ExpandArrow = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  color: var(--text-lighter);
`

interface InsightHeaderProps {
  day: string
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  hypoAmount: number
}
export const InsightHeader = ({
  day,
  setIsOpen,
  isOpen,
  hypoAmount
}: InsightHeaderProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper data-testid="InsightHeader">
      <LeftSection>
        <LeftSectionHeader>
          <HeaderTitle>{dayjs(day).format('dddd DD-MM-YYYY')}</HeaderTitle>
          <GoToDayButton
            data-testid="goToDayButton"
            onClick={() =>
              anchorClick(dayjs(day).add(1, 'days').format('DMMM'))
            }
          >
            <Arrow icon={faArrowRightLong}></Arrow>
          </GoToDayButton>
        </LeftSectionHeader>
        <LeftSectionSub>
          <Row>
            <Warning icon={faTriangleExclamation}></Warning>
            <SubText>{t('hypoEventDetected', { count: hypoAmount })}</SubText>
          </Row>
        </LeftSectionSub>
      </LeftSection>
      <RightSection>
        <NumberBadge>
          <BadgeText>{hypoAmount}</BadgeText>
        </NumberBadge>
        <ExpandBadge
          data-testid="expandInsight"
          onClick={() => setIsOpen(!isOpen)}
        >
          <ExpandArrow
            icon={isOpen ? faChevronUp : faChevronDown}
          ></ExpandArrow>
        </ExpandBadge>
      </RightSection>
    </Wrapper>
  )
}
