import { useEffect, useState } from 'react'
import { getSiteSettings } from '../core/api/getSiteSettings'
import { SiteSettings } from '../core/entities/siteSettings.entity'

export const useSiteSettings = () => {
  const [siteSettings, setSiteSettings] = useState<SiteSettings | null>(null)
  const [siteSettingsError, setSiteSettingsError] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    async function fetchSettings() {
      try {
        const settings = await getSiteSettings({ signal: controller.signal })
        setSiteSettings(settings)
      } catch (error) {
        setSiteSettingsError(true)
      }
    }
    fetchSettings()
    return () => controller.abort()
  }, [])

  return {
    siteSettings,
    siteSettingsError,
    siteSettingsInitialized:
      siteSettingsError === false && siteSettings !== null
  }
}
