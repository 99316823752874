export const getSeperatorLineStyle = (
  index: number | undefined,
  childrenAmount: number | undefined
) => {
  if (childrenAmount === 1)
    return {
      marginTop: '0.1875rem',
      height: 'calc(100% - 0.125rem - 0.75rem)',
      borderRadius: '0.5rem'
    }
  if (index === 0)
    return {
      marginTop: '0.1875rem',
      height: 'calc(100% - 0.125rem - 0.1875rem)',
      borderRadius: '0.5rem 0.5rem 0 0'
    }
  if (childrenAmount && index === childrenAmount - 1)
    return {
      marginBottom: '0.1875rem',
      height: 'calc(100% - 0.125rem - 0.1875rem)',
      borderRadius: '0 0 0.5rem 0.5rem'
    }
  else {
    return {}
  }
}
