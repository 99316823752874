import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import styled from 'styled-components'

const FormInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`
const FormInfoIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  color: var(--text-lighter);
  font-weight: 900;
`
const FormInfoText = styled.span`
  font-size: 0.75rem;
  font-family: inter;
  font-weight: 600;
  color: var(--text-lighter);
  white-space: pre-line;
`

interface FormInfoSectionProps {
  children: ReactNode
}
export const FormInfoSection = ({ children }: FormInfoSectionProps) => {
  return (
    <FormInfoWrapper>
      <FormInfoIcon icon={faInfoCircle} />
      <FormInfoText>{children}</FormInfoText>
    </FormInfoWrapper>
  )
}
