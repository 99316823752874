import { useTranslation } from 'react-i18next'
import { formatSeconds } from '../../../../helpers/formatSeconds'
import { FlexRowWrapper } from '../../../FlexWrappers/FlexRowWrapper'
import { LegendComponent, LegendEntry } from '../../Shared/Legend/Legend'
import { TimeInAutomationConfig } from '../TimeInAutomation'

interface LegendProps {
  data: { automation: number; manual: number } | null
}
export const Legend = ({ data }: LegendProps) => {
  const { t } = useTranslation()
  const automationValue = data ? formatSeconds(data.automation) : '--h --m'
  const manualValue = data ? formatSeconds(data.manual) : '--h --m'
  return (
    <LegendComponent>
      <FlexRowWrapper>
        <LegendEntry
          label={t('Automation')}
          value={automationValue}
          color={TimeInAutomationConfig.automation.color}
        ></LegendEntry>
        <LegendEntry
          label={t('Manual')}
          value={manualValue}
          color={TimeInAutomationConfig.manual.color}
        ></LegendEntry>
      </FlexRowWrapper>
    </LegendComponent>
  )
}
