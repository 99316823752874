import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  Label,
  ReferenceArea,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'
import {
  AutoBolusEvent,
  BolusEventsObject,
  InterruptedBolusValues
} from '../../../containers/StackedDaily/Interfaces/Interfaces'
import { BolusTooltipProps } from '../../BolusTooltip/BolusTooltip'
import { referenceLines } from '../BasalGraph/BasalGraph'

interface BolusGraphProps {
  data: Array<BolusEventsObject> | null
  maxBolus: number | null
  autoBolusEvents: Array<AutoBolusEvent> | null
  setShowBolusTooltip: Dispatch<SetStateAction<BolusTooltipProps | null>>
}

interface ExtendedValues {
  duration: number
  extended: number
  extendedRatio: number
  initial: number
  initialRatio: number
}

export enum WrapsDayType {
  Before = 'Before',
  After = 'After'
}
export interface WrapsDay {
  type: WrapsDayType
  duration: number
}
export interface GraphDataElement {
  time: string
  timeAsNumber: number
  value: number | null
  carbs: number | null
  adjustment: number | null
  recommended: number | null
  automatic: boolean
  glucose: number | null
  activeInsulin: number | null
  glucoseTarget: number | null
  high: number | null
  carbRatio: number | null
  insulinSensitivity: number | null
  wrapsDay: WrapsDay | null
  extendedValues: ExtendedValues | null
  interrupted: InterruptedBolusValues | null
  id: number
}

interface ExtendedLineProps {
  x1: number
  x2: number
  y1: number
  y2: number
  x: number
  y: number
  width: number
  height: number
  extendedWidth: number
  isHovered: boolean
  fill: string
  wrapsBefore: boolean
}
const ExtendedLine = ({
  x1,
  x2,
  y1,
  y2,
  x,
  y,
  width,
  height,
  extendedWidth,
  isHovered,
  fill,
  wrapsBefore
}: ExtendedLineProps) => {
  return (
    <svg
      overflow="auto"
      width="100%"
      x1={x1}
      x2={x2}
      y1={y1}
      y2={y2}
      x={x + width - 0.5}
      y={y + height / 2}
    >
      <g
        style={{
          transform: 'translate(calc(100% - 4px),10px) rotate(180deg)',
          transformBox: 'fill-box'
        }}
      >
        <rect
          transform="translate(0, -2)"
          fill={isHovered ? 'var(--bolus-darker-color)' : fill}
          fillOpacity={1}
          width={`${(extendedWidth / 60 / 24) * 100}%`}
          height={2}
          stroke={'none'}
          strokeOpacity={0}
        />
        <polygon
          transform="translate(0, 0) rotate(180)"
          fill={
            wrapsBefore
              ? 'rgba(0,0,0,0)'
              : isHovered
              ? 'var(--bolus-darker-color)'
              : fill
          }
          fillOpacity={1}
          width={'100%'}
          points={0 - 8 + ',' + 1 + ' ' + 0 + ',' + -3 + ' ' + 0 + ',' + 5}
        />
      </g>
    </svg>
  )
}
const CustomReferenceArea = (props: any) => {
  const isHovered = props.hoverState === props.id
  const color = props.automatic === true ? 'var(--pink)' : props.fill
  const hoverColor =
    props.automatic === true
      ? 'var(--pink-darker)'
      : 'var(--bolus-darker-color)'
  return (
    <g
      onMouseEnter={async () => {
        await props.redraw(props.data, props.id)
        props.setHoverState(props.id)
        props.setShowBolusTooltip({
          bolusValues: props.data.find(
            (el: GraphDataElement) => el.id === props.id
          ),
          maxBolus: props.maxBolus
        })
      }}
      onMouseLeave={() => {
        props.setHoverState(null)
        props.setShowBolusTooltip(null)
      }}
    >
      {props.wrapsDay === null ||
      props.wrapsDay.type === WrapsDayType.Before ? (
        <>
          <rect
            fill={isHovered ? hoverColor : color}
            fillOpacity={1}
            x={props.x}
            y={props.y}
            x1={props.x1}
            x2={props.x2}
            y1={props.y1}
            y2={props.y2}
            width={8}
            height={props.height}
            stroke={'none'}
            strokeOpacity={0}
          />
          <rect
            fill={isHovered ? hoverColor : color}
            fillOpacity={isHovered ? 1 : 0.4}
            x={props.x}
            y={
              props.y -
              Math.abs(props.adjustment / props.y2) * props.height +
              0.1
            }
            x1={props.x1}
            x2={props.x2}
            y1={props.y2}
            y2={props.y2}
            width={8}
            height={Math.abs(props.adjustment / props.y2) * props.height}
            strokeOpacity={0}
            stroke={'none'}
          />

          {props.adjustment !== null && props.adjustment !== 0 && (
            <rect
              fill={'var(--text-primary)'}
              fillOpacity={1}
              x={props.x}
              y={props.adjustment > 0 ? props.y + 4 : props.y}
              x1={props.x1}
              x2={props.x2}
              y1={props.y1}
              y2={props.y2}
              width={8}
              height={2}
              stroke={'none'}
              strokeOpacity={0}
            />
          )}

          {props.adjustment !== null && props.adjustment !== 0 && (
            <polygon
              fill={'var(--text-primary)'}
              fillOpacity={1}
              points={
                props.x +
                ',' +
                (props.adjustment > 0 ? props.y + 6 : props.y) +
                ' ' +
                (props.x + 8) +
                ',' +
                (props.adjustment > 0 ? props.y + 6 : props.y) +
                ' ' +
                (props.x + 4) +
                ',' +
                (props.adjustment > 0 ? props.y + 1 : props.y + 5)
              }
            />
          )}
          {props.interrupted !== null && (
            <rect
              fill={'var(--destructive)'}
              fillOpacity={1}
              x={props.x}
              y={props.interrupted > 0 ? props.y + 4 : props.y}
              x1={props.x1}
              x2={props.x2}
              y1={props.y1}
              y2={props.y2}
              width={8}
              height={2}
              stroke={'none'}
              strokeOpacity={0}
            />
          )}
          {props.carbs && (
            <svg
              width={21}
              height={16}
              x1={props.x1}
              x2={props.x2}
              y1={props.y1}
              y2={props.y2}
              x={props.x - 1 - 21 / 4}
              y={
                props.y -
                20 -
                (props.adjustment < 0
                  ? Math.abs(props.adjustment / props.y2) * props.height
                  : 0)
              }
              rx={8}
            >
              <rect
                fill={'var(--yellow)'}
                fillOpacity={1}
                width="100%"
                height="100%"
                rx={8}
                stroke={'none'}
                strokeOpacity={0}
              />
              <text
                x="50%"
                y={9}
                textAnchor="middle"
                dominantBaseline="middle"
                fontFamily={'inter'}
                fontSize={10}
                fontWeight={600}
                fill="var(--text-primary)"
              >
                {props.carbs}
              </text>
            </svg>
          )}
          {props.extendedValues && (
            <ExtendedLine
              x1={props.x1}
              x2={props.x2}
              y1={props.y1}
              y2={props.y2}
              x={props.x}
              y={props.y}
              width={props.width}
              height={props.height}
              extendedWidth={
                props.wrapsDay?.type === WrapsDayType.Before
                  ? props.wrapsDay.duration
                  : props.extendedValues.duration
              }
              wrapsBefore={props.wrapsDay?.type === WrapsDayType.Before}
              isHovered={isHovered}
              fill={color}
            />
          )}
        </>
      ) : (
        props.extendedValues && (
          <ExtendedLine
            x1={0}
            x2={props.x2 - props.x1}
            y1={props.y1}
            y2={props.y2}
            x={24 / (props.x2 - props.x1) / props.wrapsDay.duration + 10}
            y={props.y}
            width={8}
            height={props.height}
            extendedWidth={props.wrapsDay.duration}
            isHovered={isHovered}
            wrapsBefore={false}
            fill={color}
          />
        )
      )}
    </g>
  )
}
const CustomizedLabel = (props: any) => {
  const { t } = useTranslation()
  return (
    <>
      <svg x={20} y={16} overflow={'visible'}>
        <text fontFamily="inter" fontWeight="600" fontSize="0.75rem">
          <tspan fill="var(--text-primary)">{t('Bolus')}</tspan>
          <tspan
            dx={4}
            fill="var(--text-lighter)"
            fontSize="0.625rem"
            fontWeight="700"
          >
            U
          </tspan>
          <tspan dx={4} fill="var(--text-primary)">{`& ${t(
            'Carbohydrates'
          )}`}</tspan>
          <tspan
            dx={4}
            fill="var(--text-lighter)"
            fontSize="0.625rem"
            fontWeight="700"
          >
            g
          </tspan>
        </text>
      </svg>
    </>
  )
}

const AutoBolusComponent = (props: any) => {
  return (
    <svg x={props.x} y={props.y + 1}>
      <rect width={3} height={6} fill={'var(--pink)'}></rect>
    </svg>
  )
}

export const BolusGraph = ({
  data,
  maxBolus,
  autoBolusEvents,
  setShowBolusTooltip
}: BolusGraphProps) => {
  const yDomainMax = maxBolus ? maxBolus + 5 : 20
  const xDomainMax = 24
  const [graphData, setGraphData] = useState<Array<GraphDataElement>>([])
  const [hoverState, setHoverState] = useState<number | null>(null)

  useEffect(() => {
    const array = []
    if (data && data.length > 0) {
      for (const [idx, element] of data.entries()) {
        array.push({
          time: element.time,
          timeAsNumber:
            dayjs(element.time).hour() +
            dayjs(element.time).minute() / 60 +
            dayjs(element.time).second() / 3600,
          value: element.units,
          carbs: element.carbs,
          adjustment: element.userChange,
          recommended: element.recommended,
          glucose: element.glucose,
          activeInsulin: element.activeInsulin,
          glucoseTarget:
            element.bgTarget.target ?? element.bgTarget.low ?? null,
          high: element.bgTarget.high,
          carbRatio: element.insulinCarbRatio,
          insulinSensitivity: element.insulinSensitivity,
          extendedValues: element.extendedValues,
          interrupted: element.interruptedBolusValues,
          automatic: element.automatic,
          wrapsDay: element.wrapsDay,
          id: idx
        })
      }
    }
    setGraphData(array)
  }, [data])

  function redraw(arr: Array<GraphDataElement>, id: number) {
    const copy = [...arr]
    const dot = copy.find((element) => element.id === id)
    copy.splice(
      copy.findIndex((element) => element.id === id),
      1
    )
    if (dot) copy.push(dot)
    setGraphData(copy)
  }

  return (
    <ResponsiveContainer>
      <BarChart
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        data={graphData}
      >
        <ReferenceArea
          x1={0}
          x2={xDomainMax}
          y1={0}
          y2={yDomainMax}
          fill={'var(--element-bg)'}
          stroke={'var(--text-lightest)'}
          radius={4}
          fillOpacity={1}
        />
        {referenceLines('bolus')}
        <XAxis
          type="number"
          ticks={[
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23, 24
          ]}
          domain={[0, xDomainMax]}
          tick={false}
          dataKey="timeAsNumber"
          name="timeAsNumber"
          tickLine={false}
          orientation={'bottom'}
          axisLine={false}
          height={1}
        >
          <Label content={<CustomizedLabel />} position="insideTopLeft" />
        </XAxis>
        <YAxis
          stroke="#979797"
          fontSize="0.875rem"
          dataKey="value"
          name="value"
          interval={0}
          ticks={[0, Math.round(yDomainMax / 2)]}
          tickFormatter={(value) =>
            value === Math.round(yDomainMax / 2)
              ? `${Math.round(yDomainMax / 2)},0`
              : '0'
          }
          tick={{
            fill: 'var(--text-lighter)',
            fontSize: '0.75rem',
            fontWeight: '600',
            fontFamily: 'inter'
          }}
          tickLine={{ opacity: 0.5 }}
          domain={[0, yDomainMax]}
          axisLine={false}
          width={15}
        />

        <Bar dataKey={'value'} fillOpacity={0} />
        {graphData.map((item, index) => (
          <ReferenceArea
            key={'bar' + index}
            x1={item.timeAsNumber - 0.1 >= 0 ? item.timeAsNumber - 0.1 : 0}
            x2={item.timeAsNumber + 0.1 <= 24 ? item.timeAsNumber + 0.1 : 24}
            y1={0.1}
            y2={item.value ? item.value + 0.1 : 0.1}
            fill={'var(--bolus-color)'}
            shape={
              <CustomReferenceArea
                carbs={item.carbs}
                adjustment={item.adjustment}
                automatic={item.automatic}
                interrupted={item.interrupted}
                extendedValues={item.extendedValues}
                redraw={redraw}
                data={graphData}
                id={item.id}
                hoverState={hoverState}
                setHoverState={setHoverState}
                setShowBolusTooltip={setShowBolusTooltip}
                maxBolus={maxBolus}
                wrapsDay={item.wrapsDay}
              />
            }
          />
        ))}
        {autoBolusEvents !== null &&
          autoBolusEvents.length > 0 &&
          autoBolusEvents.map((auto) => {
            const timeAsNumber =
              dayjs(auto.localTime).hour() +
              dayjs(auto.localTime).minute() / 60 +
              dayjs(auto.localTime).second() / 3600
            return (
              <ReferenceArea
                y1={0}
                y2={0}
                x1={timeAsNumber - 0.1 >= 0 ? timeAsNumber - 0.1 : 0}
                x2={timeAsNumber + 0.1 <= 24 ? timeAsNumber + 0.1 : 24}
                shape={<AutoBolusComponent />}
                key={'autoBolusEvent' + auto.time + auto.device}
              ></ReferenceArea>
            )
          })}
      </BarChart>
    </ResponsiveContainer>
  )
}
