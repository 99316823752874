import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../core/dayjs/dayjs'
import { formatNumber } from '../../helpers/helpers'

export const Wrapper = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 11.3125rem;
  gap: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  position: absolute;
  background-color: var(--white-color);
  z-index: 2;
  border-radius: 0.5rem;
  bottom: ${(props) => `calc(1rem + ${props.height}%)`};
  left: ${(props) => `calc(${props.width}%)`};
  white-space: nowrap;
  transform: translateX(-50%);
  box-shadow: 0rem 0.25rem 0.5rem 0rem var(--tooltip-shadow);
`

export const Header = styled.div`
  width: 100%;
  background-color: var(--element-bg);
  border-bottom: 0.0625rem solid var(--text-lightest);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 0.5rem;
`
export const MainText = styled.span`
  fony-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--text-primary);
`
export const SubText = styled(MainText)`
  color: var(--text-lighter);
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`

const Column = styled(Row)`
  flex-direction: column;
  gap: 0.75rem;
`

const Body = styled.div`
  margin-bottom: 0.5rem;
`
export interface SuspendedObject {
  value: string
  reason: string | null
}
export interface BasalTooltipProps {
  start: string
  end: string
  value: number | SuspendedObject
  maxBasal: number
}
export interface BasalTooltipState {
  start: string
  end: string
  value: number | SuspendedObject
}
export const BasalTooltip = ({
  start,
  end,
  value,
  maxBasal
}: BasalTooltipProps) => {
  const { t } = useTranslation()
  const tooltipPosition = useMemo(() => {
    return (
      (((dayjs(start).hour() +
        dayjs(start).minute() / 60 +
        dayjs(start).second() / 3600) /
        24) *
        100 +
        ((dayjs(end).hour() +
          dayjs(end).minute() / 60 +
          dayjs(end).second() / 3600) /
          24) *
          100) /
      2
    )
  }, [end, start])
  return (
    <Wrapper
      width={tooltipPosition}
      height={
        typeof value === 'number'
          ? Math.round((value / (maxBasal + 0.5)) * 100)
          : 85
      }
    >
      <Header>
        <Row>
          <SubText>{t('From')} </SubText>
          <MainText>{dayjs(start).format('HH:mm')} </MainText>
          <SubText>{t('to')} </SubText>
          <MainText>{dayjs(end).format('HH:mm')} </MainText>
        </Row>
      </Header>
      <Body>
        {typeof value === 'number' ? (
          <Row>
            <MainText data-testid="basalTooltipValue">
              {formatNumber({
                value,
                decimals: 2
              })}
            </MainText>
            <SubText>{t('U/hr')}</SubText>
          </Row>
        ) : (
          <Column>
            <MainText>{t(value.value)}</MainText>
            {value.reason !== null && (
              <SubText
                style={{ textTransform: 'capitalize' }}
                data-testid="basalReasonForSuspension"
              >
                {t(value.reason)}
              </SubText>
            )}
          </Column>
        )}
      </Body>
    </Wrapper>
  )
}
