import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NovoPenObject } from '../../../core/api/getDoseRecommendations'
import { removeNovoPen } from '../../../core/api/remove-novopen'
import { SnackBar, SnackBarType } from '../../../libraries/Toast/SnackBar'
import { ModalComponent } from '../../Modal/Modal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.0625rem solid var(--element-bg);
  background-color: var(--white-color);
  border-radius: 1.5rem;
  width: 31.25rem;
  padding: 2rem;
  box-sizing: border-box;
  gap: 2rem;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 700;
  line-height: 2.25rem;
`

const Text = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  color: var(--text-lighter);
  white-space: pre-line;
`
const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: stretch;
`
const Button = styled.div`
  height: 3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--destructive);
  color: var(--white-color);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.0375rem;
  align-self: stretch;
  cursor: pointer;
  &:hover {
    background-color: var(--destructive-darker);
  }
`
const CancelButton = styled(Button)`
  background-color: var(--element-bg);
  border: 0.0625rem solid var(--text-lightest);
  color: var(--text-primary);
  &:hover {
    background-color: var(--element-bg-dark);
  }
`
export const RemovePen = ({
  closeFun,
  smartPen,
  patientId
}: {
  closeFun: () => void
  smartPen: NovoPenObject
  patientId: string | undefined
}) => {
  const { t } = useTranslation()
  const handleRemove = async () => {
    try {
      await removeNovoPen(smartPen.id, patientId ?? '')
      closeFun()
    } catch {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error removing NovoPen from patient')
      })
    }
  }
  return (
    <ModalComponent closeFun={closeFun} closetestid="closeRemovePen">
      <Wrapper data-testid="removePen">
        <Title>{t('Do you want to remove this NovoPen®?')}</Title>
        <Text>{t('unlinkNovoPen')}</Text>
        <Buttons>
          <Button data-testid="doRemovePen" onClick={handleRemove}>
            {t('Remove NovoPen® from patient')}
          </Button>
          <CancelButton onClick={closeFun}>{t('Cancel')}</CancelButton>
        </Buttons>
      </Wrapper>
    </ModalComponent>
  )
}
