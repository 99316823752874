import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PROMPT_BEFORE_IDLE } from './useIdleDetection'
const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const AbsoluteWrapper = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 0.0625rem solid var(--element-bg);
  background-color: var(--white-color);
  border-radius: 1.5rem;
  width: 37.5rem;
  height: 15rem;
  padding: 2rem;
  box-sizing: border-box;
`

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-self: stretch;
  justify-content: flex-start;
`
const Title = styled.span`
  font-family: inter;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 1.25rem;
  line-height: 2.25rem;
`

const SubTitle = styled.span`
  font-family: inter;
  font-weight: 600;
  color: var(--text-medium);
  font-size: 0.875rem;
  line-height: 150%;
`
const SubTitleDark = styled(SubTitle)`
  color: var(--text-primary);
`
const SubWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`

const Button = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  box-sizing: border-box;
  height: 3rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-bg);
  color: var(--text-primary);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  cursor: pointer;
  &:hover {
    background-color: var(--element-bg-dark);
  }
`
const LogoutButton = styled(Button)`
  background-color: var(--destructive);
  color: var(--white-color);
  &:hover {
    background-color: var(--destructive-darker);
  }
`
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 0.5rem;
`
interface IdleDetectorProps {
  logout: () => void
  stayActive: () => void
}
export const IdleDetector = ({ logout, stayActive }: IdleDetectorProps) => {
  const { t } = useTranslation()
  const [remaining, setRemaining] = useState<number>(PROMPT_BEFORE_IDLE)

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setRemaining(remaining - 1000)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })
  return (
    <RelativeWrapper>
      <AbsoluteWrapper>
        <Wrapper data-testid="idleDetector">
          <TitleSection>
            <Title>{t('Are you still here?')}</Title>
            <SubWrapper>
              <SubTitle>
                {t('You will be signed out automatically in')}
              </SubTitle>
              <SubTitleDark data-testid="idleDetectorSeconds">{`${
                remaining / 1000
              }s`}</SubTitleDark>
            </SubWrapper>
          </TitleSection>
          <Row>
            <Button data-testid="idleDetectorStayActive" onClick={stayActive}>
              {t('Stay signed in')}
            </Button>
            <LogoutButton data-testid="idleDetectorLogout" onClick={logout}>
              {t('Sign out')}
            </LogoutButton>
          </Row>
        </Wrapper>
      </AbsoluteWrapper>
    </RelativeWrapper>
  )
}
