import config from '../config'
import { AxiosRequest } from './axios-request'

interface CreatePatientProps {
  mrn: string
  name: string
}

export async function createPatient(data: CreatePatientProps) {
  return await AxiosRequest.create().post(`${config.API_URL}/patients`, {
    ...data
  })
}
