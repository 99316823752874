import config from '../config'
import dayjs from '../dayjs/dayjs'
import { AxiosRequest } from './axios-request'
type startEndProps = {
  start: Date
  end: Date
}
export interface PatientViewProps {
  type: string
  days: string | null
}
export function getPatientView(
  patientId: string,
  startEnd: startEndProps,
  patientDeviceIds: string | null,
  props: PatientViewProps,
  signal: AbortSignal
) {
  const start = dayjs(startEnd.start).format('YYYY-MM-DDTHH:mm:ss')
  const end = dayjs(startEnd.end).format('YYYY-MM-DDTHH:mm:ss')
  const type = props.type
  const days = props.days ?? undefined

  return AxiosRequest.create().get(
    `${config.API_URL}/patients/${patientId}/view`,
    {
      params: {
        start,
        end,
        type,
        days,
        patientDeviceIds: patientDeviceIds ?? undefined
      },
      signal
    }
  )
}
