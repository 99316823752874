import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import {
  FullTitleComponent,
  TitleComponent,
  TitleWrapperComponent,
  getInfoLogo
} from '../../Shared/Header/Header'
import { SizeEnum, WidgetState } from '../../Shared/Interfaces/Interfaces'

interface HeaderProps {
  size: SizeEnum
  state: WidgetState
  total: number | null
}

const RightTitleComponent = styled.div`
  display: flex;
  flex:direction:row;
  align-items:baseline;
  gap: 0.125rem;
`
const TotalComponent = styled.span`
  font-family: inter;
  font-weight: 700;
  font-size: 1rem;
`
const UComponent = styled.div`
  font-family: inter;
  font-weight: 700;
  font-size: 0.625rem;
  color: var(--text-lighter);
`
export const Header = ({ size, state, total }: HeaderProps) => {
  const { t } = useTranslation()
  return (
    <FullTitleComponent $size={SizeEnum.Small}>
      <TitleWrapperComponent>
        <TitleComponent>
          {size === SizeEnum.Small
            ? t('Total Insulin')
            : t('Avg. Daily Insulin')}
        </TitleComponent>
        {getInfoLogo(
          [
            {
              title: t('Total Insulin'),
              body: t(
                'All basal and bolus insulin delivery (in Units) added together.'
              )
            },
            {
              title: t('How we calculate this'),
              body: t(
                '(%) is the respective total of basal or bolus delivery divided by total insulin delivered for this time period.'
              )
            }
          ],
          state
        )}
      </TitleWrapperComponent>
      <RightTitleComponent>
        <TotalComponent
          style={{
            color:
              total !== null ? 'var(--text-primary)' : 'var(--text-lightest)'
          }}
          data-testid="totalInsulinTotalValue"
        >
          {total !== null ? formatNumber({ value: total, decimals: 0 }) : '--'}
        </TotalComponent>
        <UComponent>U</UComponent>
      </RightTitleComponent>
    </FullTitleComponent>
  )
}
