import { InfusionChangeType } from '../../../containers/Overview/Overview'

interface FillLogoProps {
  xPos?: number
  yPos?: number
  type: InfusionChangeType
}
export const InfusionLogo = ({ type, xPos, yPos }: FillLogoProps) => {
  if (type === InfusionChangeType.pod) {
    const width = 26
    const height = 26
    return (
      <svg
        x={xPos && xPos - width / 2}
        y={yPos && yPos - height / 2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={`infusionLogo${type}`}
      >
        <path
          d="M3.5 7C3.5 3.96243 5.96243 1.5 9 1.5H17C20.0376 1.5 22.5 3.96243 22.5 7V15C22.5 20.2467 18.2467 24.5 13 24.5C7.75329 24.5 3.5 20.2467 3.5 15V7Z"
          fill="white"
          stroke="#333333"
        />
        <path
          d="M7.5 7C7.5 6.17157 8.17157 5.5 9 5.5H17C17.8284 5.5 18.5 6.17157 18.5 7V15C18.5 18.0376 16.0376 20.5 13 20.5C9.96243 20.5 7.5 18.0376 7.5 15V7Z"
          fill="#F3F2F3"
          stroke="#333333"
        />
      </svg>
    )
  } else {
    const width = 26
    const height = type === InfusionChangeType.cannula ? 31 : 32
    return (
      <svg
        x={xPos && xPos - 13}
        y={yPos && yPos - height / 2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={`infusionLogo${type}`}
      >
        <circle cx="13" cy="13" r="12.5" fill="white" stroke="#333333" />
        <rect
          x="7.5"
          y="7.5"
          width="11"
          height="11"
          rx="5.5"
          fill={
            type === InfusionChangeType.tubing
              ? 'var(--element-bg-dark)'
              : 'var(--brand-lighter-color)'
          }
          stroke={
            type === InfusionChangeType.tubing
              ? 'var(--text-primary)'
              : 'var(--element-stroke)'
          }
        />
        <path
          d="M12.9996 18.5C12.9996 18.5 12.7243 21.3635 14 24C14.9622 25.9886 19 30.5 19 30.5"
          stroke={
            type === InfusionChangeType.cannula
              ? 'var(--text-primary)'
              : 'var(--brand-lighter-color)'
          }
          strokeWidth={type === InfusionChangeType.cannula ? '1' : '2'}
        />
      </svg>
    )
  }
}
