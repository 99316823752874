import { faAddressCard } from '@fortawesome/pro-regular-svg-icons'
import { t } from 'i18next'
import { IncorrectInformationBox } from '../../../components/PatientInfoComponents/IncorrectInformationBox'
import { Patient } from '../../../core/entities/patient.entity'
import { User, UserType } from '../../../core/entities/user.entity'
import { SectionComponent } from '../SectionComponent/SectionComponent'
import { InfoFieldComponent } from '../infoFieldComponent/infoFieldComponent'

interface BasicInfoProps {
  user: User | null
  patient: Patient | null
}
export const BasicInfo = ({ user, patient }: BasicInfoProps) => {
  return (
    <SectionComponent title={t('Basic Information')} icon={faAddressCard}>
      {user?.type === UserType.Patient && <IncorrectInformationBox />}
      <InfoFieldComponent
        label={t('Full name')}
        value={patient?.name}
        disabled={true}
      />
      <InfoFieldComponent
        label={t('CPR number')}
        value={
          patient
            ? patient.mrn.substring(0, 6) + '-' + patient.mrn.substring(6, 11)
            : ''
        }
        disabled={true}
      />
    </SectionComponent>
  )
}
