import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import { FlexRowWrapper } from '../../../FlexWrappers/FlexRowWrapper'
import { SizeEnum } from '../../Shared/Interfaces/Interfaces'
import { LegendComponent, LegendEntry } from '../../Shared/Legend/Legend'
import { AutoBadge } from '../Auto/AutoBadge'
import { InsulinData, TotalInsulinConfig } from '../TotalInsulin'

const UnitComponent = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.625rem;
  color: var(--text-lightest);
  line-height: 1.25rem;
  white-space: nowrap;
`
interface LegendProps {
  data: { basal: InsulinData; bolus: InsulinData } | null
  size: SizeEnum
  max: number | null
}
export const Legend = ({ data, max }: LegendProps) => {
  const { t } = useTranslation()
  const basalValue = data ? `${data.basal.value} U` : '-- U'
  const bolusValue = data ? `${data.bolus.value} U` : '-- U'
  const MaxComponent = () => {
    if (max !== null) {
      if (max === 0)
        return (
          <UnitComponent data-testid="totalInsulinMax">Max -- U</UnitComponent>
        )
      else
        return (
          <UnitComponent data-testid="totalInsulinMax">
            {`${t('totalInsulinMax', {
              amount: formatNumber({ value: max, decimals: 0 })
            })} `}
          </UnitComponent>
        )
    }
    return null
  }
  return (
    <LegendComponent>
      <FlexRowWrapper>
        <LegendEntry
          label={t('Basal')}
          value={basalValue}
          color={TotalInsulinConfig.basal.color}
        >
          <AutoBadge percentage={data ? data.basal.autoPercentage : null} />
        </LegendEntry>
        <LegendEntry
          label={t('Bolus')}
          value={bolusValue}
          color={TotalInsulinConfig.bolus.color}
        >
          <AutoBadge percentage={data ? data.bolus.autoPercentage : null} />
        </LegendEntry>
      </FlexRowWrapper>
      {MaxComponent()}
    </LegendComponent>
  )
}
