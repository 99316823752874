import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { CSSProperties } from 'styled-components'

export enum spinnerSize {
  small,
  medium,
  large
}
interface SpinnerProps {
  spinnersize: spinnerSize
}

export const iconStyle = {
  '--fa-primary-color': 'var(--text-primary)',
  '--fa-secondary-color': 'var(--element-bg-darkest)'
}
const SpinnerIcon = styled(FontAwesomeIcon)<SpinnerProps>`
  font-size: ${(props) =>
    props.spinnersize === spinnerSize.small
      ? '1rem'
      : spinnerSize.medium
      ? '1.5rem'
      : '2rem'};
`

export const Spinner = ({ spinnersize }: SpinnerProps) => {
  return (
    <SpinnerIcon
      icon={faSpinnerThird}
      spinnersize={spinnersize}
      className="fa-spin"
      style={iconStyle as CSSProperties}
      data-testid="loadSpinner"
    ></SpinnerIcon>
  )
}
