import config from '../config'
import { AxiosRequest } from './axios-request'

interface NominaldesignProviderProps {
  providerId: string
}

export async function deleteProvider({
  providerId
}: NominaldesignProviderProps) {
  return AxiosRequest.create().delete(
    `${config.API_URL}/cmi/providers/${providerId}`
  )
}
