import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OperatingSystem } from '../../helpers/getOperatingSystem'
import { DownloadStatus } from '../../hooks/use-downloader'
import { DownloadFailed } from './DownloadFailed/DownloadFailed'
import { DownloadFinished } from './DownloadFinished/DownloadFinished'
import { DownloadPage } from './DownloadPage/DownloadPage'
import { useGetUploader } from './use-get-uploader'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
`

export const GetUploader = () => {
  const { t } = useTranslation()
  const {
    state,
    downloadInstaller,
    currentPlatform,
    currentOs,
    otherPlatforms,
    reset
  } = useGetUploader()
  const [recentlyDownloaded, setRecentlyDownloaded] =
    useState<OperatingSystem | null>(currentOs)

  if (state.status === DownloadStatus.Failed) {
    return (
      <Wrapper>
        <DownloadFailed reset={reset} />
      </Wrapper>
    )
  }

  if (state.status === DownloadStatus.Completed) {
    return (
      <Wrapper>
        <DownloadFinished recentlyDownloaded={recentlyDownloaded} />
      </Wrapper>
    )
  }

  return (
    <Wrapper data-testid="getUploaderPage">
      {currentPlatform ? (
        <DownloadPage
          currentPlatform={currentPlatform}
          downloadInstaller={downloadInstaller}
          otherPlatforms={otherPlatforms}
          state={state}
          setRecentlyDownloaded={setRecentlyDownloaded}
        ></DownloadPage>
      ) : (
        <div>{t('No download found for this platform.')}</div>
      )}
    </Wrapper>
  )
}
