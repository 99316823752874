import { SubPopulation } from '../../containers/ClinicPatients/tables/interfaces'
import config from '../config'
import { AxiosRequest } from './axios-request'

interface PopulationPatient {
  id: string
  populationId: string
  patientId: string
  subbpopulation: SubPopulation
}
export async function assignPatientToPopulation(
  populationId: string,
  patientId: string,
  subbpopulation: string
): Promise<PopulationPatient> {
  const response = await AxiosRequest.create().post(
    `${config.API_URL}/population/${populationId}?patientId=${patientId}&subpopulation=${subbpopulation}`
  )
  return response.data
}
