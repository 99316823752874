import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'

const Wrapper = styled.div`
  display: flex;
  width: 48.9375rem;
  height: 15.75rem;
  padding: 0rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.75rem;
  background-color: var(--element-bg);
`
const Title = styled.div`
  font-family: inter:
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-medium);
  line-height: 1.5rem;
`
const Body = styled.span`
  color: var(--text-lighter);
  text-align: center;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
`
export const NoSmartPen = () => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  return (
    <Wrapper data-testid="noActiveSmartPens">
      <Title>{t('No active smart pens')}</Title>
      <Body>
        {t('registerPen', { deploymentName: siteSettings?.deploymentName })}
      </Body>
    </Wrapper>
  )
}
