import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3.5rem;
`
const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
`
const SubTitle = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
`
const Button = styled.button`
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--blue);
  font-family: inter;
  font-size: 0.875rem;
  color: var(--white-color);
  font-weight: 500;
  border: none;
  width: fit-content;
  cursor: pointer;
  &:hover {
    background-color: var(--blue-darker);
  }
`

interface NoAccessProps {
  logout: () => void
}
export const NoAccess = ({ logout }: NoAccessProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper data-testid="noaccess">
      <Title>{t('Error - you do not have permission')}</Title>
      <SubTitle>{t('Contact the clinic if this is a mistake')}</SubTitle>
      <Button onClick={logout}>{t('Sign Out')}</Button>
    </Wrapper>
  )
}
