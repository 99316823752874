import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { DeploymentType } from '../../core/entities/siteSettings.entity'
import { useCapabilities } from '../../hooks/use-capabilities'
const TitleAndLogoComponent = styled(Link)<{ $active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-family: inter;
  cursor: pointer;
  text-decoration: none;
  pointer-events: ${(props) => (props.$active ? 'none' : 'auto')};
  border-right: 0.0625rem solid var(--text-lightest);
  height: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: var(--element-bg);
  }
`
const Icon = styled.img`
  width: 2rem;
  height: 2rem;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const Title = styled.span`
  color: var(--text-primary);
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.0375rem;
`
const Subtitle = styled.span`
  color: var(--text-medium);
  font-weight: 700;
  font-size: 0.625rem;
`
type TitleAndLogoProps = {
  isPatient?: boolean
}

export const TitleAndLogo = ({ isPatient }: TitleAndLogoProps) => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  const path = useLocation()
  const { trendsOrInsights } = useCapabilities()
  const getSubtitle = () => {
    switch (siteSettings?.deploymentType) {
      case DeploymentType.Demo:
        return t('For demo purposes')
      case DeploymentType.Dev:
        return t('For development')
      case DeploymentType.Trial:
        return t('Clinical evaluation only')
      case DeploymentType.Production:
        return null
      default:
        return null
    }
  }
  const subtitle = getSubtitle()
  return (
    <TitleAndLogoComponent
      $active={
        path.pathname === '/portal' ||
        path.pathname === '/portal/patients/search' ||
        (isPatient === true &&
          path.pathname === `/portal/patients/me/${trendsOrInsights}`)
      }
      to={'/portal'}
    >
      <Icon data-testid={'deploymentIcon'} src="/api/deployment/icon" />

      <TitleWrapper>
        <Title data-testid="titleText">{siteSettings?.deploymentName}</Title>
        {subtitle !== null && (
          <Subtitle data-testid="deploymentTypeTitle">{subtitle}</Subtitle>
        )}
      </TitleWrapper>
    </TitleAndLogoComponent>
  )
}
