import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'
import { getDeviceEventTypeText } from '../getDeviceEventConfig'
import { AbstractDeviceEvent, DeviceEventType } from '../Interfaces'
import { DeviceEventListEntry } from './DeviceEventListEntry/DeviceEventListEntry'

const Wrapper = styled.div`
  display: flex;
  width: 39.375rem;
  min-height: 28.75rem;
  max-height: 40rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1.5rem;
  background-color: var(--white-color);
`
const Header = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  border-bottom: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-bg-80);
`
const Title = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
const TitleText = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
  text-transform: capitalize;
`

const TitleSeperator = styled.div`
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: var(--text-lightest);
  border-radius: 50%;
`
const Filters = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
`
const FilterMainText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  color: var(--text-primary);
`
const FilterSubText = styled(FilterMainText)`
  color: var(--text-medium);
`
const Filter = styled.button`
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  cursor: pointer;
  background-color: var(--element-bg-80);
  &:disabled {
    cursor: default;
  }
  &.activeFilter {
    border: 0.0625rem solid var(--brand-primary-16-color);
    background-color: var(--brand-primary-4-color);
    ${FilterMainText} {
      color: var(--brand-primary-color);
    }
    ${FilterSubText} {
      color: var(--text-lighter);
    }
  }
`

const Body = styled.div`
  display: flex;
  padding-right: 1rem;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const DeviceEventList = ({
  deviceEvents,
  eventFilters,
  day
}: {
  deviceEvents: AbstractDeviceEvent[]
  eventFilters: Array<DeviceEventType>
  day: string
}) => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState<DeviceEventType[]>(
    Object.values(DeviceEventType)
  )

  const allFilters = !Object.values(DeviceEventType)
    .map((type) => filters.includes(type))
    .includes(false)
  const handleFilters = (filterType: DeviceEventType | null) => {
    if (allFilters) {
      if (filterType != null) {
        setFilters([filterType])
      }
    } else {
      if (filterType === null) {
        setFilters(Object.values(DeviceEventType))
      } else {
        if (!filters.includes(filterType)) {
          setFilters((filters) => [...filters, filterType])
        } else {
          let tempArray = [...filters]
          const index = tempArray.indexOf(filterType)
          tempArray.splice(index, 1)
          if (tempArray.length === 0) {
            tempArray = Object.values(DeviceEventType)
          }
          setFilters(tempArray)
        }
      }
    }
  }

  return (
    <Wrapper>
      <Header>
        <Title>
          <TitleText>{t('Events')}</TitleText>
          <TitleSeperator />
          <TitleText>{dayjs(day).format('dddd D MMM')}</TitleText>
        </Title>
        <Filters>
          <Filter
            key={'All'}
            data-testid="AllFilter"
            className={allFilters ? 'activeFilter' : ''}
            onClick={() => handleFilters(null)}
          >
            <FilterMainText>{t('All')}</FilterMainText>
            <FilterSubText>{deviceEvents.length}</FilterSubText>
          </Filter>
          {Object.entries(DeviceEventType).map(
            (deviceEventType) =>
              eventFilters.includes(deviceEventType[1]) && (
                <Filter
                  key={deviceEventType[1]}
                  data-testid={deviceEventType[1] + 'Filter'}
                  className={
                    !allFilters && filters.includes(deviceEventType[1])
                      ? 'activeFilter'
                      : ''
                  }
                  onClick={() => handleFilters(deviceEventType[1])}
                >
                  <FilterMainText>
                    {t(getDeviceEventTypeText(deviceEventType[1]))}
                  </FilterMainText>
                  <FilterSubText>
                    {deviceEvents.filter(
                      (deviceEvent) =>
                        deviceEvent.deviceEventType === deviceEventType[0]
                    ).length ?? 0}
                  </FilterSubText>
                </Filter>
              )
          )}
        </Filters>
      </Header>
      <Body>
        {deviceEvents.map(
          (deviceEvent, index) =>
            (allFilters || filters.includes(deviceEvent.deviceEventType)) && (
              <DeviceEventListEntry
                key={deviceEvent.id}
                event={deviceEvent}
                last={index === deviceEvents.length - 1}
              />
            )
        )}
      </Body>
    </Wrapper>
  )
}
