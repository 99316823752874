import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DetectedHypo } from '../interfaces'
import { InsightGraph } from './Graph/Graph'
import { InsightHeader } from './Header/Header'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1.5rem;
  border: 0.0625rem solid var(--text-lightest);
  justify-content: center;
  align-items: center;
  gap: 2rem;
`
const GraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 1rem 3rem;
`

interface InsightProps {
  day: string
  expanded: boolean
  updateExpandState: boolean
  hypos: DetectedHypo[]
  maxPenInjection: number | null
}

export const Insight = ({
  day,
  expanded,
  updateExpandState,
  hypos,
  maxPenInjection
}: InsightProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(expanded)

  useEffect(() => {
    setIsOpen(expanded)
  }, [expanded, updateExpandState])

  return (
    <Container data-testid={'Insight' + day}>
      <InsightHeader
        day={day}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        hypoAmount={hypos.length}
      />
      {isOpen && (
        <GraphsWrapper data-testid={'Insight' + day + 'open'}>
          {hypos.map((hypo) => (
            <InsightGraph
              hypo={hypo}
              key={'hypo' + day + hypo.hypoTimeAndCauses.localTime}
              maxPenInjection={maxPenInjection}
            />
          ))}
        </GraphsWrapper>
      )}
    </Container>
  )
}
