import Keycloak from 'keycloak-js'

export const keycloak = new Keycloak({
  url: '/auth/',
  realm: 'linesystems',
  clientId: 'portal'
})

export const initOptions = {
  checkLoginIframe: false,
  onLoad: 'check-sso'
}
