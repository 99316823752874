import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSiteSettings } from '../../hooks/use-site-settings'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  padding: 1.5rem;
  gap: 0.75rem;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: var(--blue);
  font-weight: 900;
`
const Text = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
`
export const IncorrectInformationBox = () => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettings()
  return (
    <Wrapper data-testid="IncorrectInformationBox">
      <Icon icon={faInfoCircle} />
      <Text>
        {t('patientInfoIncorrectInfo', { name: siteSettings?.deploymentName })}
      </Text>
    </Wrapper>
  )
}
