import {
  faCheckDouble,
  faFileArrowDown,
  faFileArrowUp,
  faFileXmark,
  faUpload,
  faUserCheck,
  faUserMagnifyingGlass
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LoadMoreButton } from '../../components/ProviderPatients/LoadMoreButton/LoadMoreButton'
import { SortEnum } from '../../components/ProviderPatients/ProviderPatients'
import {
  ToggleButton,
  ToggleButtonText
} from '../../components/ToggleButton/ToggleButton'
import { loadIncrement } from '../../constants/loadIncrement'
import { getAuditLog } from '../../core/api/get-audit-log'
import { AuditLogSearch } from './AuditLogSearch/AuditLogSearch'
import { AuditLogSorting, AuditLogTable } from './AuditLogTable/AuditLogTable'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding-bottom: 3rem;
`

export enum AuditLogType {
  VIEWPATIENTDATA = 'VIEWPATIENTDATA',
  DELETEPATIENTUPLOAD = 'DELETEPATIENTUPLOAD',
  MAPPATIENT = 'MAPPATIENT',
  SETUPCMI = 'SETUPCMI',
  DOWNLOADPATIENTDATA = 'DOWNLOADPATIENTDATA',
  UPLOADPATIENTFILE = 'UPLOADPATIENTFILE',
  UPLOADPATIENTDATA = 'UPLOADPATIENTDATA'
}

export interface AuditLogData {
  id?: string
  patientId?: string
  patientMrn?: string
  userId: string
  userUpid: string
  time: string
  type: AuditLogType
}

export interface AuditLogFilters {
  search: string | null
  sorting: { auditSort: AuditLogSorting; order: SortEnum | null }
  auditLogTypes: Array<AuditLogType>
  size: number
}

const FlexWrapperFilterButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
`
const Icon = styled(FontAwesomeIcon)`
  color: inherit;
  font-weight: 900;
  font-size: 0.875rem;
`
const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  min-width: 900px;
`

const clearSortState = () => {
  const filter = Object.entries(AuditLogSorting).map((key) =>
    key[1] === AuditLogSorting.Time
      ? { auditSort: key[1], order: SortEnum.Descending }
      : {
          auditSort: key[1],
          order: null
        }
  )
  return filter
}

const getButtonPayload = (
  type: AuditLogType,
  t: TFunction<'translation', undefined>
) => {
  switch (type) {
    case AuditLogType.VIEWPATIENTDATA:
      return { text: t('View patient data'), icon: faUserMagnifyingGlass }
    case AuditLogType.MAPPATIENT:
      return { text: t('Patient mapping'), icon: faFileArrowUp }
    case AuditLogType.DELETEPATIENTUPLOAD:
      return { text: t('Delete patient data'), icon: faFileXmark }
    case AuditLogType.SETUPCMI:
      return { text: t('Setup integration'), icon: faUserCheck }
    case AuditLogType.DOWNLOADPATIENTDATA:
      return { text: t('Export patient data'), icon: faFileArrowDown }
    case AuditLogType.UPLOADPATIENTFILE:
      return { text: t('Upload patient file'), icon: faUpload }
    case AuditLogType.UPLOADPATIENTDATA:
      return { text: t('Upload patient data'), icon: faUpload }
  }
}

interface AuditFilterButtonProps {
  type: AuditLogType
  events: Array<AuditLogType>
  setEvents: Dispatch<SetStateAction<Array<AuditLogType>>>
}
const AuditFilterButton = ({
  type,
  events,
  setEvents
}: AuditFilterButtonProps) => {
  const { t } = useTranslation()
  const clone = [...events]
  const included = clone.includes(type)
  const handleEvent = () => {
    if (included) {
      clone.splice(events.indexOf(type), 1)
    } else {
      clone.push(type)
    }
    setEvents(clone)
  }
  const payload = getButtonPayload(type, t)
  return (
    <ToggleButton
      state={included}
      onClickFun={handleEvent}
      testid={'auditLogFilterButton' + type}
    >
      <Icon icon={payload.icon}></Icon>
      <ToggleButtonText>{payload.text}</ToggleButtonText>
    </ToggleButton>
  )
}
export const AuditLog = () => {
  const [dataError, setDataError] = useState<boolean>(false)
  const [data, setData] = useState<null | Array<AuditLogData>>(null)
  const [sortState, setSortState] = useState<
    {
      auditSort: AuditLogSorting
      order: SortEnum | null
    }[]
  >(clearSortState())
  const [search, setSearch] = useState<string>('')
  const { t } = useTranslation()
  const [loadAmount, setLoadAmount] = useState<number>(loadIncrement)
  const [events, setEvents] = useState<Array<AuditLogType>>([])

  useEffect(() => {
    const filters: AuditLogFilters = {
      search: search !== '' ? search : null,
      sorting: sortState.find((e) => e.order !== null) ?? {
        auditSort: AuditLogSorting.Time,
        order: SortEnum.Descending
      },
      auditLogTypes:
        events.length === 0
          ? Object.entries(AuditLogType).map((e) => e[1])
          : events,
      size: loadAmount
    }
    const getData = async () => {
      setData(null)
      try {
        const response = await getAuditLog(filters)
        setData(response)
        setDataError(false)
      } catch (error) {
        setDataError(true)
      }
    }
    getData()
  }, [sortState, search, loadAmount, events])

  const getSearchString = (searchString: string) => {
    setSearch(searchString)
  }

  if (!dataError) {
    return (
      <Wrapper data-testid="auditLogData">
        <AuditLogSearch
          getSearchString={getSearchString}
          searchString={search}
        />
        <FlexWrapperFilterButtons>
          <ToggleButton
            state={events.length === 0}
            onClickFun={() => setEvents([])}
          >
            <Icon icon={faCheckDouble}></Icon>
            <ToggleButtonText>{t('All')}</ToggleButtonText>
          </ToggleButton>
          {Object.entries(AuditLogType).map((key) => (
            <AuditFilterButton
              key={key[1]}
              type={key[1]}
              events={events}
              setEvents={setEvents}
            />
          ))}
        </FlexWrapperFilterButtons>
        <TableWrapper>
          <AuditLogTable
            data={data}
            sortState={sortState}
            setSortState={setSortState}
            loadAmount={loadAmount}
          />
          <LoadMoreButton
            setLoadAmount={setLoadAmount}
            loadAmount={loadAmount}
          />
        </TableWrapper>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper data-testid="auditLogError">
        {t('error getting audit log')}
      </Wrapper>
    )
  }
}
