import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PeriodPosition,
  ScreenShotIconSize,
  ScreenshotComponent
} from '../../Screenshot/ScreenshotComponent/ScreenshotComponent'
import { ToggleButton, ToggleButtonText } from '../../ToggleButton/ToggleButton'
import {
  Section,
  SubSection,
  TitleText,
  Wrapper
} from '../InfusionEventType/InfusionEventType'
interface GlucoseOverviewHeaderProps {
  showUserInitiatedBolus: boolean
  setShowUserInitiatedBolus: Dispatch<SetStateAction<boolean>>
  wrapperRef: MutableRefObject<HTMLDivElement | null> | null
}

export const GlucoseOverviewHeader = ({
  showUserInitiatedBolus,
  setShowUserInitiatedBolus,
  wrapperRef
}: GlucoseOverviewHeaderProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Section style={{ width: '100%', justifyContent: 'space-between' }}>
        <TitleText>{t('Glucose Overview')}</TitleText>
        <ScreenshotComponent
          size={ScreenShotIconSize.medium}
          showIcon={true}
          refs={{
            wrapper: wrapperRef
          }}
          index={0}
          periodPlacement={{
            position: PeriodPosition.bottomLeft,
            margin: { bottom: 8, left: 8 }
          }}
          wrapperPadding={{ width: 64, height: 32 }}
          border={true}
        />
      </Section>
      <Section>
        <SubSection>
          <ToggleButton
            state={showUserInitiatedBolus}
            testid="userInitiatedBolusesButton"
            onClickFun={() =>
              setShowUserInitiatedBolus(!showUserInitiatedBolus)
            }
          >
            <ToggleButtonText>
              {t('Show user initiated boluses')}
            </ToggleButtonText>
          </ToggleButton>
        </SubSection>
      </Section>
    </Wrapper>
  )
}
