import { devicesObject } from '../../components/DeviceList/DeviceList'
import config from '../config'
import { AxiosRequest } from './axios-request'

export interface PatientSummaryData {
  devices: Array<devicesObject>
  newestDevices: Array<devicesObject>
  timeslices: Array<{ start: string; end: string; sufficientData: boolean }>
  oldData: boolean
  emptyPatient: boolean
}
export async function getPatientSummary(
  mrn: string,
  options: object = {}
): Promise<PatientSummaryData> {
  const data = await AxiosRequest.create().get(
    `${config.API_URL}/patients/${mrn}/summary`,
    options
  )
  return data.data as PatientSummaryData
}
