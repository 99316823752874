import { createContext, useContext } from 'react'
import { Patient } from '../entities/patient.entity'

type PatientContextState = {
  patient: Patient | null
  setPatient: (patient: Patient | null) => void
  searchPatient?: (value: string) => Promise<Patient | null>
  refreshPatient?: () => Promise<void>
}

export const PatientContext = createContext<PatientContextState>({
  patient: null,
  setPatient: () => null,
  searchPatient: () => Promise.resolve(null),
  refreshPatient: () => Promise.resolve()
})

export function usePatientContext() {
  return useContext(PatientContext)
}

export default PatientContext
