import { SortEnum } from '../../components/ProviderPatients/ProviderPatients'
import { ProductType } from '../../containers/Events/interfaces'
import config from '../config'
import { AxiosRequest } from './axios-request'

export interface TitrationHistoryObject {
  changeFromLastDose: number
  dose: number
  time: string
  localTime: string
  user: string
  context: ProductType
  version: string
}
export async function getTitrationHistory(
  patientId: string,
  size: number,
  order: SortEnum
): Promise<TitrationHistoryObject[]> {
  const response = await AxiosRequest.create().get(
    config.API_URL +
      `/adaptt2d/${patientId}/logs/titration?size=${size}&order=${order}`
  )
  return response.data as TitrationHistoryObject[]
}
