import { AxiosRequest } from './axios-request'
import config from '../config'

export async function changeInfusionTypeEvent(
  patientId: string,
  changeEvent: string,
  options: object = {}
) {
  const response = await AxiosRequest.create().put(
    `${config.API_URL}/patients/${patientId}/infusionsitechangeevent/${changeEvent}`,
    options
  )
  return response.data
}
