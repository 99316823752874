import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SortEnum } from '../../../components/ProviderPatients/ProviderPatients'
import { NoDataRow } from '../../../components/Table/NoDataRow'
import {
  ScrollTableComponent,
  THead,
  TText,
  Td,
  Th,
  Tr
} from '../../../components/Table/ScrollTableComponent'
import { ScrollTableLoading } from '../../../components/Table/ScrollTableLoading.tsx'
import dayjs from '../../../core/dayjs/dayjs'
import { AuditLogData, AuditLogType } from '../AuditLog'

export enum AuditLogSorting {
  Time = 'Time',
  UserUpid = 'UserUpid',
  PatientMrn = 'PatientMrn',
  Type = 'Type'
}
interface AuditLogTableProps {
  data: Array<AuditLogData> | null
  sortState: {
    auditSort: AuditLogSorting
    order: SortEnum | null
  }[]
  setSortState: Dispatch<
    SetStateAction<
      {
        auditSort: AuditLogSorting
        order: SortEnum | null
      }[]
    >
  >
  loadAmount: number
}
const RowBoxWrapper = styled.div`
  min-height: 2.25rem;
  display: flex;
  align-items: center;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  min-height: 2.25rem;
`
const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const SortIcon = styled(FontAwesomeIcon)<{ $active: boolean }>`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) =>
    props.$active ? 'var(--brand-primary-color)' : 'var(--text-lightest)'};
  margin-right: 0.25rem;
`
const SortIconUp = styled(SortIcon)`
  transform: translateY(50%);
`
const SortIconDown = styled(SortIcon)`
  transform: translateY(-50%);
`

const getEventText = (eventType: AuditLogType) => {
  switch (eventType) {
    case AuditLogType.DELETEPATIENTUPLOAD:
      return 'deleted patient upload'
    case AuditLogType.DOWNLOADPATIENTDATA:
      return 'downloaded patient upload'
    case AuditLogType.MAPPATIENT:
      return 'mapped patient'
    case AuditLogType.SETUPCMI:
      return 'setup CMI'
    case AuditLogType.UPLOADPATIENTDATA:
      return 'uploaded patient data'
    case AuditLogType.UPLOADPATIENTFILE:
      return 'uploaded patient file'
    case AuditLogType.VIEWPATIENTDATA:
      return 'viewed patient data'
  }
}
const getHeaderText = (type: AuditLogSorting) => {
  switch (type) {
    case AuditLogSorting.PatientMrn:
      return 'Cpr'
    case AuditLogSorting.UserUpid:
      return 'Employee Id'
    case AuditLogSorting.Time:
      return 'Time'
    case AuditLogSorting.Type:
      return 'Event'
  }
}

interface SortComponentProps {
  sortState: {
    auditSort: AuditLogSorting
    order: SortEnum | null
  }[]
  setSortState: Dispatch<
    SetStateAction<
      {
        auditSort: AuditLogSorting
        order: SortEnum | null
      }[]
    >
  >
  type: AuditLogSorting
}
const SortComponent = ({
  sortState,
  setSortState,
  type
}: SortComponentProps) => {
  const index = sortState.findIndex(
    (e: { auditSort: AuditLogSorting; order: SortEnum | null }) =>
      e.auditSort === type
  )
  const parse: {
    auditSort: AuditLogSorting
    order: null | SortEnum
  }[] = JSON.parse(JSON.stringify(sortState))
  return (
    <SortIconWrapper
      data-testid={'sortbutton' + type}
      onClick={() => {
        if (parse[index].order === null) {
          const filter: Array<{
            auditSort: AuditLogSorting
            order: null | SortEnum
          }> = Object.keys(AuditLogSorting).map((key) => ({
            auditSort: key as AuditLogSorting,
            order: null
          }))
          filter[index].order = SortEnum.Ascending
          setSortState(filter)
        } else {
          parse[index].order =
            parse[index].order === SortEnum.Ascending
              ? SortEnum.Descending
              : SortEnum.Ascending
          setSortState(parse)
        }
      }}
    >
      <SortIconUp
        data-testid={'sorticonup' + type}
        icon={faSortUp}
        $active={parse[index].order === SortEnum.Ascending}
      />
      <SortIconDown
        data-testid={'sorticondown' + type}
        icon={faSortDown}
        $active={parse[index].order === SortEnum.Descending}
      />
    </SortIconWrapper>
  )
}

const AuditLogTableGroup = () => {
  return (
    <colgroup>
      <col style={{ width: '25%' }} />
      <col style={{ width: '25%' }} />
      <col style={{ width: '25%' }} />
      <col style={{ width: '25%' }} />
    </colgroup>
  )
}
export const AuditLogTable = ({
  data,
  sortState,
  setSortState,
  loadAmount
}: AuditLogTableProps) => {
  const { t } = useTranslation()

  return (
    <ScrollTableComponent group={<AuditLogTableGroup />}>
      <THead>
        {Object.entries(AuditLogSorting).map((key) => (
          <Th key={key[1]}>
            <HeaderWrapper>
              <TText>{t(getHeaderText(key[1]))}</TText>
              <SortComponent
                sortState={sortState}
                setSortState={setSortState}
                type={key[1]}
              />
            </HeaderWrapper>
          </Th>
        ))}
      </THead>
      {data ? (
        data.length > 0 ? (
          data.map((e) => (
            <Tr key={e.id} data-testid="auditLogData">
              <Td>
                <RowBoxWrapper>
                  <TText>{dayjs(e.time).format('DD/MM/YYYY - HH:mm')} </TText>
                </RowBoxWrapper>
              </Td>
              <Td>
                <RowBoxWrapper>
                  <TText>{e.userUpid}</TText>
                </RowBoxWrapper>
              </Td>
              <Td>
                <RowBoxWrapper>
                  <TText>{e.patientMrn}</TText>
                </RowBoxWrapper>
              </Td>

              <Td>
                <RowBoxWrapper>
                  <TText>{getEventText(e.type)}</TText>
                </RowBoxWrapper>
              </Td>
            </Tr>
          ))
        ) : (
          <NoDataRow />
        )
      ) : (
        <ScrollTableLoading size={loadAmount} />
      )}
    </ScrollTableComponent>
  )
}
