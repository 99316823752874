import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IFUCDA from '../../assets/PDFs/IFU-LinePortal-ver15-DA-clinician.pdf'
import IFUPDA from '../../assets/PDFs/IFU-LinePortal-ver15-DA.pdf'
// import IFUCEN from '../../assets/PDFs/IFU_Clinician_EN.pdf'
// import IFUPEN from '../../assets/PDFs/IFU_Patient_EN.pdf'
import { useSessionContext } from '../../core/contexts/session-context'
import dayjs from '../../core/dayjs/dayjs'
import { UserType } from '../../core/entities/user.entity'
const PDFDownload = styled.a`
  font-size: 0.875rem;
  font-weight: 700;
  font-family: inter;
  text-decoration: none;
  color: var(--brand-primary-color);
  &:visited {
    color: var(--brand-primary-color);
  }
`

export const IFUPdfDownloader = () => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const language = dayjs.locale()

  const getFile = () => {
    if (user?.type === UserType.Patient && language === 'da') {
      return { file: IFUPDA, name: 'IFU-LinePortal-ver15-DA.pdf' }
    } else if (user?.type === UserType.Patient && language === 'en') {
      return { file: IFUPDA, name: 'IFU-LinePortal-ver15-DA.pdf' }
    } else if (language === 'da') {
      return { file: IFUCDA, name: 'IFU-LinePortal-ver15-DA-clinician.pdf' }
    } else {
      return { file: IFUCDA, name: 'IFU-LinePortal-ver15-DA-clinician.pdf' }
    }
  }
  return (
    <PDFDownload href={getFile().file} download={getFile().name}>
      {t('Download PDF')}
    </PDFDownload>
  )
}
