import { faInfoCircle, faTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'
// each element in array will create a new box stacked below first box.
// this is to scale for tooltips that have more than one information box.

export enum InformationLogoSize {
  small,
  medium,
  large
}
interface InformationTooltipProps {
  paragraph: Array<{ title: string; body: string }>
  size: InformationLogoSize
  first?: boolean
  last?: boolean
  logoStyling?: CSSProperties
}

const getWrapperPos = (
  first: undefined | boolean,
  last: undefined | boolean
) => {
  if (first === true) return 'translateY(0%)'
  else if (last === true) return 'translateY(-100%)'
  else return 'translateY(-50%)'
}
const Wrapper = styled.div`
  position: relative;
`

const InfoLogo = styled(FontAwesomeIcon)<{ $logoSize: InformationLogoSize }>`
  font-weight: 900;
  font-size: ${(props) => getLogoSize(props.$logoSize)};
  color: var(--text-lighter);
`
const ToolTipWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 3;
  pointer-events: none;
`
const ToolTipArrow = styled(FontAwesomeIcon)<{
  logosize: InformationLogoSize
}>`
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--text-lightest);
  position: absolute;
  left: ${(props) => getLogoSize(props.logosize)};
  bottom: -0.25rem;
`
const ToolTipBox = styled.div<{
  logosize: InformationLogoSize
  first: undefined | boolean
  last: undefined | boolean
}>`
  top: ${(props) => getLogoSize(props.logosize)};
  left: ${(props) => `calc(${getLogoSize(props.logosize)} + 1.5rem)`};
  transform: ${(props) => getWrapperPos(props.first, props.last)};
  border-radius: 0.5rem;
  border: 0.125rem solid var(--text-lightest);
  background-color: var(--white-color);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 9rem;
  justify-content: center;
  align-items: center;
  position: absolute;
`
const ToolTipBoxSection = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--text-primary);
  width: 100%;
`
const ToolTipBoxSectionBold = styled(ToolTipBoxSection)`
  font-weight: 900;
`

const getLogoSize = (logoSize: InformationLogoSize) => {
  switch (logoSize) {
    case InformationLogoSize.small:
      return '0.75rem'
    case InformationLogoSize.medium:
      return '1rem'
    case InformationLogoSize.large:
      return '1.25rem'
  }
}

const ToolTip = ({ paragraph, size, first, last }: InformationTooltipProps) => {
  const { t } = useTranslation()
  return (
    <ToolTipWrapper
      onMouseEnter={function (event) {
        event.stopPropagation()
      }}
      data-testid="informationTooltipDescription"
    >
      <ToolTipArrow
        logosize={size}
        className="fa-rotate-270"
        icon={faTriangle}
      ></ToolTipArrow>
      <ToolTipBox logosize={size} first={first} last={last}>
        {paragraph.map((e) => (
          <div key={e.title}>
            <ToolTipBoxSectionBold>{`${t(e.title)}${
              e.title !== '' ? ':' : ''
            }`}</ToolTipBoxSectionBold>
            <ToolTipBoxSection> {e.body} </ToolTipBoxSection>
          </div>
        ))}
      </ToolTipBox>
    </ToolTipWrapper>
  )
}

export const InformationTooltip = ({
  paragraph,
  size,
  first,
  last,
  logoStyling
}: InformationTooltipProps) => {
  const [hovered, setHovered] = useState<boolean>(false)
  return (
    <Wrapper>
      <InfoLogo
        $logoSize={size}
        icon={faInfoCircle}
        data-testid="informationTooltipLogo"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={logoStyling}
      />
      {hovered && (
        <ToolTip paragraph={paragraph} size={size} first={first} last={last} />
      )}
    </Wrapper>
  )
}
