import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BarChart,
  Label,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'

import {
  ActivityInfo,
  ActivityProfile,
  BasalChangeEvents,
  BasalEventsObject
} from '../../../containers/StackedDaily/Interfaces/Interfaces'
import dayjs from '../../../core/dayjs/dayjs'
import { BasalTooltipState } from '../../BasalTooltip/BasalTooltip'
interface BasalGraphProps {
  data: Array<BasalEventsObject> | null
  setShowBasalTooltip: Dispatch<SetStateAction<BasalTooltipState | null>>
  maxBasal: number | null
  activityData: Array<ActivityInfo> | null
}

export const referenceLines = (type: string) =>
  new Array(7)
    .fill(0)
    .map((_e, idx) => (
      <ReferenceLine
        x={3 + idx * 3}
        stroke="#E7E7E7"
        key={`${type}${3 + idx * 3}`}
      />
    ))

interface EventIndicatorProps {
  x: number
  width: number
  originalStart: string
  originalEnd: string
  setShowBasalTooltip: Dispatch<SetStateAction<BasalTooltipState | null>>
  deliveryMode: BasalChangeEvents
  activityInfo: ActivityProfile | null
  reasonForSuspension: 'automatic' | 'manual' | null
}

enum BasalEventType {
  Exercise,
  Sleep,
  Suspended
}
export const EventIndicator = ({
  x,
  width,
  originalStart,
  originalEnd,
  setShowBasalTooltip,
  deliveryMode,
  activityInfo,
  reasonForSuspension
}: EventIndicatorProps) => {
  const [hover, setHover] = useState<boolean>(false)
  const getConfig = () => {
    if (
      deliveryMode === BasalChangeEvents.Suspended &&
      reasonForSuspension !== null
    )
      return {
        color: 'var(--yellow)',
        letter: 'S',
        type: BasalEventType.Suspended,
        width,
        tooltip: {
          value: {
            value: 'Suspended',
            reason: reasonForSuspension ? reasonForSuspension.toString() : ''
          },
          end: originalEnd
        }
      }
    if (activityInfo !== null && activityInfo === ActivityProfile.Exercising)
      return {
        color: 'var(--lime-green)',
        letter: 'E',
        type: BasalEventType.Exercise,
        width,
        tooltip: {
          value: { value: 'Exercise', reason: null },
          end: originalEnd
        }
      }
    if (activityInfo !== null && activityInfo === ActivityProfile.Sleeping)
      return {
        color: 'var(--purple)',
        letter: 'Z',
        type: BasalEventType.Sleep,
        width,
        tooltip: {
          value: {
            value: 'Sleep',
            reason: null
          },
          end: originalEnd
        }
      }
    else return null
  }

  const config = getConfig()
  return (
    <>
      {config !== null && (
        <>
          <rect
            x={x + 1}
            y={'10%'}
            width={config.width}
            height={'89%'}
            fill={config.color}
            fillOpacity={hover ? 0.2 : 0}
            onMouseEnter={() => {
              setShowBasalTooltip({
                start: originalStart,
                end: config.tooltip.end,
                value: config.tooltip.value
              })
              setHover(true)
            }}
            onMouseLeave={() => {
              setShowBasalTooltip(null)
              setHover(false)
            }}
          ></rect>

          <EventLine
            x={x}
            width={config.width}
            letter={config.letter}
            color={config.color}
          />
        </>
      )}
    </>
  )
}

interface EventLineProps {
  x: number
  width: number
  letter: string
  color: string
}

export const EventLine = ({ x, width, letter, color }: EventLineProps) => {
  return (
    <svg y="10%" overflow="visible">
      <g>
        <path
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          d={`M${x} 1 l${width} 0`}
        />
        <circle fill={color} cx={x} cy="1%" r="6" />
        <text
          x={x}
          y="1%"
          dy="3px"
          textAnchor="middle"
          fill="var(--white-color)"
          fontSize="8px"
          fontWeight={'700'}
          fontFamily="inter"
        >
          {letter}
        </text>
      </g>
    </svg>
  )
}
const CustomizedRectangleArea = (props: any) => {
  const [hoverState, setHoverState] = useState(false)
  const fillColor =
    props.deliveryMode === BasalChangeEvents.Automatic
      ? 'var(--pink)'
      : 'var(--basal-color)'

  return (
    <svg data-testid={'databox' + props.index}>
      <EventIndicator
        x={props.x}
        width={props.width}
        originalStart={props.originalStart}
        originalEnd={props.originalEnd}
        setShowBasalTooltip={props.setShowBasalTooltip}
        deliveryMode={props.deliveryMode}
        activityInfo={null}
        reasonForSuspension={props.reasonForSuspension}
      />
      <rect
        x1={props.x1}
        x2={props.x2}
        y1={props.y1}
        y2={props.y2}
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        fill={fillColor}
        fillOpacity={hoverState ? 1 : 0.4}
        onMouseEnter={() => {
          props.setShowBasalTooltip({
            start: props.originalStart,
            end: props.originalEnd,
            value: props.value
          })
          setHoverState(true)
        }}
        onMouseLeave={() => {
          props.setShowBasalTooltip(null)
          setHoverState(false)
        }}
      />
      {props.value !== 0 && (
        <rect
          x1={props.x1}
          x2={props.x2}
          y1={props.y1}
          y2={props.y2}
          x={props.x}
          y={props.y}
          width={props.width}
          height={2}
          fill={
            props.deliveryMode === BasalChangeEvents.Automatic
              ? 'var(--pink)'
              : 'var(--basal-color)'
          }
          fillOpacity={1}
        />
      )}
      {props.start !== 0 && (
        <rect
          x1={props.x1}
          x2={props.x2}
          y1={props.y1}
          y2={props.y2}
          x={
            props.difference > 0
              ? props.x + props.width - 2
              : props.x + props.width
          }
          y={
            props.difference && props.height && props.value
              ? props.difference > 0
                ? props.y -
                  Math.abs(props.difference * (props.height / props.value))
                : props.y
              : 0
          }
          width={2}
          height={
            props.height && props.value && props.difference
              ? Math.abs(props.difference * (props.height / props.value))
              : 0
          }
          fill={
            props.deliveryMode === BasalChangeEvents.Automatic
              ? 'var(--pink)'
              : 'var(--basal-color)'
          }
          fillOpacity={1}
        />
      )}
      {props.prevValue === 0 && (
        <rect
          x1={props.x1}
          x2={props.x2}
          y1={props.y1}
          y2={props.y2}
          x={props.x}
          y={props.y}
          width={2}
          height={props.height}
          fill={
            props.deliveryMode === BasalChangeEvents.Automatic
              ? 'var(--pink)'
              : 'var(--basal-color)'
          }
          fillOpacity={1}
        />
      )}
    </svg>
  )
}
const CustomizedLabel = (props: any) => {
  const { t } = useTranslation()
  return (
    <>
      <text
        x={20}
        y={16}
        fontFamily="inter"
        fontWeight="600"
        fontSize="0.75rem"
      >
        <tspan fill="var(--text-primary)">{t('Basal Rates')}</tspan>
        <tspan
          dx={4}
          fill="var(--text-lighter)"
          fontSize="0.625rem"
          fontWeight="700"
        >
          {t('U/hr')}
        </tspan>
      </text>
    </>
  )
}

const ExerciseAndSleepComponent = (props: any) => {
  return (
    <EventIndicator
      x={props.x}
      width={props.width}
      originalStart={props.activity.start}
      originalEnd={props.activity.end}
      setShowBasalTooltip={props.setShowBasalTooltip}
      deliveryMode={BasalChangeEvents.Temp}
      activityInfo={props.activity.activityProfile}
      reasonForSuspension={null}
    />
  )
}

export const BasalGraph = ({
  data,
  setShowBasalTooltip,
  maxBasal,
  activityData
}: BasalGraphProps) => {
  const graphData = []
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const start =
        dayjs(data[i].start).hour() +
        dayjs(data[i].start).minute() / 60 +
        dayjs(data[i].start).second() / 3600
      const end =
        dayjs(data[i].end).hour() +
        dayjs(data[i].end).minute() / 60 +
        dayjs(data[i].end).second() / 3600
      const connected =
        i + 1 < data.length
          ? dayjs(data[i + 1].start).hour() +
              dayjs(data[i + 1].start).minute() / 60 +
              dayjs(data[i + 1].start).second() / 3600 ===
            end
          : false
      const difference =
        i + 1 < data.length ? data[i + 1].units - data[i].units : 0
      const prevValue = i > 0 ? data[i - 1].units : null

      graphData.push({
        start,
        end,
        value: data[i].units,
        connected,
        difference,
        prevValue,
        originalStart: data[i].start,
        originalEnd: data[i].end,
        deliveryMode: data[i].deliveryMode,
        reasonForSuspension: data[i].reasonForSuspension
      })
    }
  }
  const yDomainMax = maxBasal ? maxBasal + 0.5 : 2
  const xDomainMax = 24
  return (
    <ResponsiveContainer>
      <BarChart
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        data={graphData}
        barCategoryGap={-0.209}
      >
        <ReferenceArea
          x1={0}
          x2={xDomainMax}
          y1={0}
          y2={yDomainMax}
          fill={'var(--element-bg)'}
          radius={4}
          fillOpacity={1}
        />
        {referenceLines('basal')}
        <XAxis
          type="number"
          ticks={[
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23, 24
          ]}
          domain={[0, xDomainMax]}
          dataKey="name"
          tick={false}
          tickLine={false}
          axisLine={false}
          height={1}
        >
          <Label content={<CustomizedLabel />} position="insideTopLeft" />
        </XAxis>
        {activityData !== null &&
          activityData.length > 0 &&
          activityData.map((activity) => {
            const start =
              dayjs(activity.start).hour() +
              dayjs(activity.start).minute() / 60 +
              dayjs(activity.start).second() / 3600
            const end =
              dayjs(activity.end).hour() +
              dayjs(activity.end).minute() / 60 +
              dayjs(activity.end).second() / 3600
            return (
              <ReferenceArea
                x1={start}
                x2={end}
                y1={0}
                y2={0}
                key={activity.start + activity.end}
                shape={
                  <ExerciseAndSleepComponent
                    activity={activity}
                    setShowBasalTooltip={setShowBasalTooltip}
                  />
                }
              />
            )
          })}
        {graphData.map((item, index) => (
          <ReferenceArea
            key={'bar' + index}
            x1={item.start}
            x2={item.end}
            y1={0}
            y2={item.value}
            shape={
              <CustomizedRectangleArea
                value={item.value}
                difference={item.difference}
                index={index}
                prevValue={item.prevValue}
                setShowBasalTooltip={setShowBasalTooltip}
                originalStart={item.originalStart}
                originalEnd={item.originalEnd}
                deliveryMode={item.deliveryMode}
                reasonForSuspension={item.reasonForSuspension}
              />
            }
          />
        ))}

        <YAxis
          stroke="#979797"
          fontSize="0.875rem"
          dataKey="y"
          name="Glucose"
          interval={0}
          ticks={[0, Math.round(yDomainMax / 2)]}
          tickFormatter={(value) =>
            value === Math.round(yDomainMax / 2)
              ? `${Math.round(yDomainMax / 2)},0`
              : '0'
          }
          tick={{
            fill: 'var(--text-lighter)',
            fontSize: '0.75rem',
            fontWeight: '600',
            fontFamily: 'inter'
          }}
          tickLine={{ opacity: 0.5 }}
          domain={[0, yDomainMax]}
          axisLine={false}
          width={15}
        />
        <ReferenceArea
          x1={0}
          x2={xDomainMax}
          y1={0}
          y2={yDomainMax}
          fill={'none'}
          stroke={'var(--text-lightest)'}
          radius={4}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
