import dayjs from 'dayjs'
import {
  BolusEvent,
  CGMEvent,
  InfusionSiteChangesObject,
  OverviewResponse
} from '../../../containers/Overview/Overview'

const splitArray = (
  array: Array<any>,
  firstDay: number,
  lengthOfPeriod: number
) => {
  const weekArray = []
  for (let i = 0; i < Math.ceil(lengthOfPeriod / 7 + 1); i++) {
    if (i === 0) {
      weekArray.push(array.splice(0, 8 - firstDay))
    } else {
      weekArray.push(array.splice(0, 7))
    }
  }
  return weekArray
}
export const createInfusionArrays = (
  data: Array<InfusionSiteChangesObject>,
  lengthOfPeriod: number
) => {
  const firstDay = dayjs(data[0].day).isoWeekday()
  return {
    firstWeek: firstDay,
    weekArray: splitArray([...data], firstDay, lengthOfPeriod)
  }
}
export const createGlucoseArrays = (
  cgmEvents: CGMEvent[] | null,
  bolusEvents: BolusEvent[] | null,
  lengthOfPeriod: number
) => {
  let firstDay = 1
  const weekArray = [...Array(lengthOfPeriod).keys()].map((item) => {
    return {
      cgm: getEvent<CGMEvent>(cgmEvents, item, lengthOfPeriod),
      bolus: getEvent<BolusEvent>(bolusEvents, item, lengthOfPeriod)
    }
  })
  for (const key of Object.entries(weekArray)) {
    if (key[1].cgm !== null) {
      firstDay = dayjs(key[1].cgm.day).isoWeekday()
      break
    } else if (key[1].bolus !== null) {
      firstDay = dayjs(key[1].bolus.day).isoWeekday()
      break
    } else continue
  }
  return {
    firstWeek: firstDay,
    weekArray: splitArray(weekArray, firstDay, lengthOfPeriod)
  }
}

export function getEvent<T>(
  events: T[] | null,
  item: number,
  lengthOfPeriod: number
) {
  return events && item >= lengthOfPeriod - events.length
    ? events[item - (lengthOfPeriod - events.length)]
    : null
}

export function isOverviewData(response: any): response is OverviewResponse {
  return response.type === 'overview'
}
