import { faMoonStars } from '@fortawesome/pro-regular-svg-icons'
import {
  faCircleCheck,
  faCircleQuestion,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { TFunction } from 'react-i18next'
import { NocturnalHypoglycemia } from '../interfaces'

export const getEventConfig = (
  eventType: NocturnalHypoglycemia,
  t: TFunction<'translation', undefined>
) => {
  switch (eventType) {
    case NocturnalHypoglycemia.LowGlucose:
      return {
        type: {
          iconSource: faMoonStars,
          iconColor: 'var(--text-primary)',
          text: t('Nocturnal hypo prediction')
        },
        content: {
          iconSource: faTriangleExclamation,
          iconColor: 'var(--low-color)',
          text: t('Potential low in 3 hours')
        }
      }
    case NocturnalHypoglycemia.NotPredictable:
      return {
        type: {
          iconSource: faMoonStars,
          iconColor: 'var(--text-primary)',
          text: t('Nocturnal hypo prediction')
        },
        content: {
          iconSource: faCircleQuestion,
          iconColor: 'var(--text-lighter)',
          text: t('Inconclusive')
        }
      }
    case NocturnalHypoglycemia.Stable:
      return {
        type: {
          iconSource: faMoonStars,
          iconColor: 'var(--text-primary)',
          text: t('Nocturnal hypo prediction')
        },
        content: {
          iconSource: faCircleCheck,
          iconColor: 'var(--inRange-color)',
          text: t('Stable in 3 hours')
        }
      }
  }
}
