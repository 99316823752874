import {
  faCircleStop,
  faMagnifyingGlassChart
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { assignPatient } from '../../../core/api/assign-patient-api'
import { getPatient } from '../../../core/api/get-patient-api'
import { ignorePatient } from '../../../core/api/ignore-patient'
import dayjs from '../../../core/dayjs/dayjs'
import { FormInfoSection } from '../../FormInfoSection/FormInfoSection'
import {
  FormButton,
  InputField,
  InputFieldLabel,
  InputWrapper
} from '../../FormStyles/FormStyles'
import { ModalComponent } from '../../Modal/Modal'
import {
  Title,
  Wrapper
} from '../../ProviderIntegrationModel/ProviderIntegrationModel'
import { ErrorContainer, cprFunction } from '../../SearchBar/SearchBar'
import { StatusBadge, StatusBadgeState } from '../../StatusBadge/StatusBadge'
import { ProviderPatient, getProviderPlatformTitle } from '../ProviderPatients'

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`
const IgnorePatientWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`
const IgnorePatientIcon = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 0.875rem;
  color: var(--blue-darker);
`

const IgnorePatientText = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--blue-darker);
`
const AssignedComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
const AssignedIconWrapper = styled.div`
  padding: 0.5rem 0.75rem;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  box-sizing: border-box;
  height: 2rem;
  width: 2.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Assignedicon = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 0.875rem;
  color: var(--text-primary);
`
const AssignedText = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-primary);
`

const InfoText = styled.span`
  color: var(--text-lighter);
  font-size: 0.875rem;
  font-family: inter;
  font-weight: 600;
`

interface AssignedComponentProps {
  latestData: string | null
}
const AssignedComponent = ({ latestData }: AssignedComponentProps) => {
  const { t } = useTranslation()
  return (
    <AssignedComponentWrapper>
      <StatusBadge text={t('Active')} state={StatusBadgeState.success} />
      <AssignedIconWrapper>
        <Assignedicon icon={faMagnifyingGlassChart} />
      </AssignedIconWrapper>
      <AssignedText>
        {latestData
          ? `${t('Fetched data')} ${dayjs(latestData).fromNow()}`
          : t('No data')}
      </AssignedText>
    </AssignedComponentWrapper>
  )
}
const handleIgnorePatient = async (
  cmiPatientId: string,
  ignored: boolean,
  refresh: () => void
) => {
  try {
    await ignorePatient({ cmiPatientId, ignored })
    refresh()
  } catch (error) {
    console.log(error)
  }
}
interface IgnorePatientButtonProps {
  cmiPatientId: string | null
  ignored: boolean
  refresh: () => void
}
const IgnorePatientButton = ({
  cmiPatientId,
  ignored,
  refresh
}: IgnorePatientButtonProps) => {
  const { t } = useTranslation()

  return (
    <IgnorePatientWrapper
      onClick={() =>
        cmiPatientId && handleIgnorePatient(cmiPatientId, ignored, refresh)
      }
      data-testid="ignorePatientButton"
    >
      <IgnorePatientIcon icon={faCircleStop} />
      <IgnorePatientText>
        {t(ignored ? 'Unignore patient' : 'Ignore patient')}
      </IgnorePatientText>
    </IgnorePatientWrapper>
  )
}

interface LinkPatientModalProps {
  providerPatient: ProviderPatient
  setShowLinkPatientModal: () => void
  refresh: () => void
}

export const LinkPatientModal = ({
  providerPatient,
  setShowLinkPatientModal,
  refresh
}: LinkPatientModalProps) => {
  const { t } = useTranslation()
  const [currentCPR, setCurrentCPR] = useState('')
  const [patientError, setPatientError] = useState<undefined | string>()
  const {
    register,
    getValues,
    formState: { isValid },
    setValue,
    handleSubmit
  } = useForm<{ CPR: string }>({
    mode: 'onChange',
    defaultValues: {
      CPR:
        providerPatient.localPatientMrn && providerPatient.assignedPatientId
          ? `${providerPatient.localPatientMrn.substring(
              0,
              6
            )}-${providerPatient.localPatientMrn.substring(6, 11)}`
          : ''
    }
  })
  const getFormInfoSectionText = (
    ignored: boolean,
    assignedPatientId: string | null,
    text: string
  ) => {
    if (ignored) {
      return t('unignorepatient', { provider: text })
    } else if (assignedPatientId === null) {
      return t('linkpatient', { provider: text })
    } else {
      return t('unlinkpatient', { provider: text })
    }
  }
  const getFormButtonState = (assignedPatientId: string | null) => {
    if (assignedPatientId !== null) {
      return false
    } else {
      return !isValid
    }
  }

  const onSubmit = async (data: { CPR: string }) => {
    setPatientError(undefined)
    try {
      const patientId = (
        await getPatient(data.CPR.replace(/-/g, ''))
      ).data.pop()?.patientId
      if (patientId) {
        try {
          providerPatient.id &&
            patientId &&
            (await assignPatient(patientId, providerPatient.id, false))
          refresh()
        } catch (error) {
          console.log(error)
        }
      } else {
        setPatientError(t('Patient does not exist'))
      }
    } catch (error) {
      setPatientError(t('Patient does not exist'))
    }
  }
  const onUnAssign = async () => {
    try {
      providerPatient.id &&
        (await assignPatient('patientId', providerPatient.id, true))
      refresh()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <ModalComponent
      closeFun={setShowLinkPatientModal}
      closetestid="closeLinkPatientModal"
    >
      <Wrapper>
        <TitleWrapper>
          <InfoText>
            {`${getProviderPlatformTitle(providerPatient.providerType)} ${t(
              'user'
            )}`}
          </InfoText>
          <Title>{providerPatient.name}</Title>
          <Title>
            {providerPatient.birthday
              ? dayjs(providerPatient.birthday).format('DD/MM/YYYY')
              : '-'}
          </Title>
          {providerPatient.email && <Title>{providerPatient.email}</Title>}
        </TitleWrapper>
        {!providerPatient.ignored && providerPatient.assignedPatientId && (
          <AssignedComponent
            latestData={providerPatient.newestLocalData}
          ></AssignedComponent>
        )}
        {!providerPatient.ignored && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper>
              <InputFieldLabel>{t('CPR')}</InputFieldLabel>
              <InputField
                {...register('CPR', {
                  onChange: () => {
                    cprFunction({
                      setPatientError,
                      setValue,
                      getValues,
                      setCurrentCPR,
                      currentCPR
                    })
                  },
                  required: true,
                  pattern: {
                    value: /^\d{6}-\d{4}$/,
                    message: 'Wrong format'
                  }
                })}
                disabled={providerPatient.assignedPatientId !== null}
                data-testid="LinkPatientModalCPRInput"
                autoComplete="false"
              />
            </InputWrapper>
            <FormButton
              assigned={providerPatient.assignedPatientId !== null}
              onClick={
                providerPatient.assignedPatientId !== null
                  ? onUnAssign
                  : () => null
              }
              disabled={getFormButtonState(providerPatient.assignedPatientId)}
              type={
                providerPatient.assignedPatientId !== null ? 'button' : 'submit'
              }
              data-testid="LinkPatientModalFormButton"
            >
              {t(
                providerPatient.assignedPatientId !== null
                  ? 'unlink patient'
                  : 'link patient'
              )}
            </FormButton>
            {patientError !== undefined && (
              <ErrorContainer
                style={{
                  position: 'relative',
                  top: '0',
                  left: '0',
                  transform: 'translateX(0)',
                  textAlign: 'center',
                  width: '12rem'
                }}
              >
                {patientError}
              </ErrorContainer>
            )}
          </Form>
        )}

        {providerPatient.ignored && (
          <StatusBadge state={StatusBadgeState.ignored} text={t('ignored')} />
        )}

        <FormInfoSection>
          {t(
            getFormInfoSectionText(
              providerPatient.ignored,
              providerPatient.assignedPatientId,
              getProviderPlatformTitle(providerPatient.providerType)
            )
          )}
        </FormInfoSection>
        {providerPatient.assignedPatientId === null && (
          <IgnorePatientButton
            cmiPatientId={providerPatient.id}
            ignored={providerPatient.ignored}
            refresh={refresh}
          />
        )}
      </Wrapper>
    </ModalComponent>
  )
}
