import { faEye } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

interface HiddenComponentProps {
  onClickFunction: null | (() => void)
  testid?: string
}
const HiddenBox = styled.div`
  background-color: var(--element-bg-darkest);
  border-radius: 0.25rem;
  height: 1rem;
  width: 100%;
  cursor: pointer;
  border: none;
`
const EyeWrapper = styled.div`
  opacity: 0;
  width: 100%;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
`
const EyeIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--brand-primary-color);
  opacity: inherit;
  position: absolute;
`
export const UploadsHiddenComponent = ({
  onClickFunction,
  testid
}: HiddenComponentProps) => {
  return (
    <HiddenBox onClick={onClickFunction || (() => null)} data-testid={testid}>
      <EyeWrapper data-testid="hiddeneye">
        <EyeIcon icon={faEye} />
      </EyeWrapper>
    </HiddenBox>
  )
}
