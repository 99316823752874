import { PatientUploaderPings } from '../../containers/PatientInfo/PatientInfo'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getUploaderPings(
  patientId: string | undefined,
  options: object = {}
): Promise<PatientUploaderPings[]> {
  const response = await AxiosRequest.create().get(
    `${config.API_URL}/${patientId}/uploader/pings`,
    options
  )
  return response.data as PatientUploaderPings[]
}
