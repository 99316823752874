import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getClinicUploads } from '../../core/api/get-clinic-uploads-api'
import { getPatientUploads } from '../../core/api/get-patient-uploads-api'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { Patient } from '../../core/entities/patient.entity'
import { SortEnum } from '../ProviderPatients/ProviderPatients'
import { NoDataRow } from '../Table/NoDataRow'
import { ScrollTableComponent } from '../Table/ScrollTableComponent'
import { ScrollTableLoading } from '../Table/ScrollTableLoading.tsx'
import { UploadsHeaderComponent } from './UploadsHeaderComponent/UploadsHeaderComponent'
import { UploadsRowComponent } from './UploadsRowComponent/UploadsRowComponent'

export enum dataState {
  empty = '–',
  hidden = '',
  options = 'options'
}
export enum FileUploadType {
  legacystenopool,
  medtroniccarelink,
  abbottlibreview,
  dexcomclarity
}

export enum ApplicationUploadPlatform {
  ios = 'ios',
  mac = 'mac',
  windows = 'windows',
  android = 'android',
  legacy = 'legacy'
}
export enum ApplicationUploadType {
  clinicuploader,
  homeuploader
}
export enum UploadType {
  cloud = 'cloud',
  application = 'application',
  file = 'file'
}
export interface Upload {
  id: string
  created: string | null
  patientId: string
  location: string | null
  closed: boolean | null
  device: {
    deviceId: string
    serial: string
    usbSerial: string | null
    model: string
    manufacturer: string
    displayName: string
  } | null
  uploadedById: string | null
  daysOfData: number | null
  applicationPlatform: ApplicationUploadPlatform | null
  applicationPlatformString: string | null
  applicationUploadType: ApplicationUploadType | null
  applicationUploadVersion: string | null
  fileUploadType: FileUploadType | null
  cloudUploadProviderId: string | null
  providerName: string | null
  providerType: string | null
  uploadType: UploadType | null
}

interface UploadsProps {
  patient: Patient | null
  fetch: (
    orderBy?: SortEnum,
    size?: number,
    patientId?: string
  ) => Promise<Upload[]>
}

const Wrapper = styled.div`
  gap: 1rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ClinicWrapper = styled(Wrapper)`
  width: 100%;
`

const LoadMoreButton = styled.button`
  padding: 1rem 2rem;
  background-color: var(--brand-primary-color);
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--white-color);
  border: none;
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
`

const PatientUploadsTableGroups = () => {
  return (
    <colgroup data-testid="patientUploadsGroup">
      <col style={{ width: '10%' }} />
      <col style={{ width: '10%' }} />
      <col style={{ width: '25%' }} />
      <col style={{ width: '20%' }} />
      <col style={{ width: '20%' }} />
      <col style={{ width: '15%' }} />
    </colgroup>
  )
}
const ClinicUploadsTableGroups = () => {
  return (
    <colgroup data-testid="clinicUploadsGroup">
      <col style={{ width: '8%' }} />
      <col style={{ width: '4%' }} />
      <col style={{ width: '8%' }} />
      <col style={{ width: '25%' }} />
      <col style={{ width: '14.5%' }} />
      <col style={{ width: '18%' }} />
      <col style={{ width: '14.5%' }} />
      <col style={{ width: '8%' }} />
    </colgroup>
  )
}

const Uploads = ({ patient, fetch }: UploadsProps) => {
  const { user } = useSessionContext()

  const [uploadData, setUploadData] = useState<null | Array<Upload>>(null)
  const [uploadDataError, setUploadDataError] = useState<Error | null>(null)
  const [loadAmount, setLoadAmount] = useState<number>(20)
  const [sorting, setSorting] = useState<SortEnum>(SortEnum.Descending)
  const { t } = useTranslation()
  useEffect(() => {
    const uploadData = async () => {
      const controller = new AbortController()
      setUploadData(null)
      try {
        setUploadData(await fetch(sorting, loadAmount, patient?.patientId))
      } catch (error) {
        if (!controller.signal.aborted) setUploadDataError(error as Error)
      }

      return () => {
        controller.abort()
      }
    }
    uploadData()
  }, [fetch, loadAmount, patient, sorting])

  const onDelete = async () => {
    try {
      const data = await fetch(sorting, loadAmount, patient?.patientId)
      setUploadData(data)
    } catch (error) {
      setUploadDataError(error as Error)
    }
  }
  return (
    <>
      {uploadDataError === null ? (
        <>
          <ScrollTableComponent
            group={
              patient ? (
                <PatientUploadsTableGroups />
              ) : (
                <ClinicUploadsTableGroups />
              )
            }
          >
            <UploadsHeaderComponent
              patient={patient}
              sorting={sorting}
              setSorting={setSorting}
            />
            {uploadData ? (
              uploadData.length > 0 ? (
                uploadData.map((item, index) => (
                  <UploadsRowComponent
                    key={item.id}
                    item={item}
                    index={index}
                    user={user}
                    onDelete={onDelete}
                    patient={patient}
                  />
                ))
              ) : (
                <NoDataRow />
              )
            ) : (
              <ScrollTableLoading size={loadAmount} />
            )}
          </ScrollTableComponent>
          <LoadMoreButton
            data-testid="LoadMoreProviderPatients"
            onClick={() => setLoadAmount(loadAmount + 20)}
          >
            {t('Load more')}
          </LoadMoreButton>
        </>
      ) : (
        <div data-testid="uploadsTableError"></div>
      )}
    </>
  )
}

export const ClinicUploads = () => {
  return (
    <ClinicWrapper>
      <Uploads patient={null} fetch={getClinicUploads} />
    </ClinicWrapper>
  )
}

export const PatientUploads = () => {
  const { patient } = usePatientContext()

  if (!patient) return null

  return (
    <Wrapper>
      <Uploads fetch={getPatientUploads} patient={patient} />
    </Wrapper>
  )
}
