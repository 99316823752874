import { useTranslation } from 'react-i18next'
import {
  FullTitleComponent,
  TitleComponent,
  TitleWrapperComponent,
  getInfoLogo
} from '../../Shared/Header/Header'
import { SizeEnum, WidgetState } from '../../Shared/Interfaces/Interfaces'

interface HeaderProps {
  state: WidgetState
}
export const Header = ({ state }: HeaderProps) => {
  const { t } = useTranslation()
  return (
    <FullTitleComponent $size={SizeEnum.Medium}>
      <TitleWrapperComponent>
        <TitleComponent>{t('Time In Range')}</TitleComponent>
        {getInfoLogo(
          [
            {
              title: t('Readings In Range'),
              body: t('Daily average of the number of CGM readings.')
            }
          ],
          state
        )}
      </TitleWrapperComponent>
    </FullTitleComponent>
  )
}
