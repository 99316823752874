import { useEffect, useState } from 'react'
import { getPatient } from '../core/api/get-patient-api'
import { Patient } from '../core/entities/patient.entity'
import { SnackBar, SnackBarType } from '../libraries/Toast/SnackBar'

export const useWards = (wardMRNs: Array<string> | undefined) => {
  const [wardPatients, setWardPatients] = useState<Array<Patient> | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!wardMRNs) return

    const getAllWards = () => {
      setLoading(true)

      const wardPromise = wardMRNs.map(async (wardMRN) => {
        try {
          const wardPatient = (await getPatient(wardMRN)).data.pop()
          if (wardPatient !== undefined) {
            return wardPatient
          } else {
            throw new Error('Patient does not exist')
          }
        } catch (e) {
          SnackBar({
            type: SnackBarType.Error,
            message: 'Patient does not exist'
          })
        }
      })
      Promise.all(wardPromise).then((data) => {
        const filteredData: Patient[] = data.filter((f): f is Patient => !!f)
        setWardPatients(filteredData)
        setLoading(false)
      })
    }
    getAllWards()
  }, [wardMRNs])
  return { wards: wardPatients, wardsLoading: loading }
}
