import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { PatientsViewNavigation } from '../../components/PatientsViewNavigation/PatientsViewNavigation'
import { getPatientWithId } from '../../core/api/get-patient-withid-api'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePatientLoadingContext } from '../../core/contexts/patient-loading-context'
import PatientViewsContext from '../../core/contexts/patient-view-context'
import { usePeriodContext } from '../../core/contexts/period-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { UserType } from '../../core/entities/user.entity'
import { useCapabilities } from '../../hooks/use-capabilities'
import { usePatientViews } from '../../hooks/use-patient-views'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'

export const Content = styled.div`
  margin: 3.5rem 0;
`

export function PatientDetails() {
  const { t } = useTranslation()
  const { patient, setPatient } = usePatientContext()
  const { period, setPeriod } = usePeriodContext()
  const { user } = useSessionContext()
  const { patientId } = useParams()
  const navigate = useNavigate()
  const { trendsOrInsights, deviceSettingsOrInfo } = useCapabilities()
  const { patientViews, patientViewsError, patientViewsLoading } =
    usePatientViews({
      patientId: patient?.patientId,
      period
    })
  const { patientLoading, setPatientLoading } = usePatientLoadingContext()

  const patientNavPages = patientViews
    ? [
        {
          label: `${t(trendsOrInsights)}`,
          to: trendsOrInsights,
          notification: false,
          show: patientViews.Trends.show
        },
        {
          label: `${t('Overview')}`,
          to: 'overview',
          notification: false,
          show: patientViews.Overview.show
        },
        {
          label: `${t('Stacked Days')}`,
          to: 'stacked_days',
          notification: false,
          show: patientViews.Daily.show
        },
        {
          label: `${t('BG Log')}`,
          to: 'bg_log',
          notification: false,
          show: patientViews.BgLog.show
        },
        {
          label: `${t(
            deviceSettingsOrInfo === 'device_settings'
              ? 'Device Settings'
              : 'Device Info'
          )}`,
          to: deviceSettingsOrInfo,
          notification: false,
          show: patientViews.DeviceSettings.show
        },
        {
          label: t('Pen Dosing'),
          to: 'pen_dosing',
          notification: false,
          show: patientViews.PenDosing.show
        },
        {
          label: `${t('More')}`,
          to: 'more',
          notification: false,
          show: patientViews.More.show
        }
      ]
    : null
  useEffect(() => {
    // The patient needs to be cleared when the user navigates outside of the
    // of this page, unless the user is a patient, in that case, the context
    // should always contain the current patient data.
    return () => {
      if (user?.type !== UserType.Patient) {
        setPatient(null)
        setPeriod(null)
      }
    }
  }, [setPatient, setPeriod, user?.type])

  useEffect(() => {
    if (!patientViewsLoading) {
      setPatientLoading(false)
    }
  }, [patientViewsLoading, setPatientLoading])

  useEffect(() => {
    const abortController = new AbortController()

    if (patientId && !patient && user && user.type !== UserType.Patient) {
      getPatientWithId(patientId, { signal: abortController.signal })
        .then(setPatient)
        .catch(() => {
          SnackBar({
            type: SnackBarType.Error,
            message: `${t('Error - Patient not found')}`
          })
          navigate('/portal')
        })
    }

    return () => abortController.abort()
  }, [user, patientId, patient, setPatient, navigate, t, setPatientLoading])

  useEffect(() => {
    return () => setPatientLoading(false)
  }, [setPatientLoading])

  return (
    <>
      {patientViewsError && (
        <div data-testid="patientDetailsError">
          {t('There was an error loading the page')}
        </div>
      )}
      {patientNavPages !== null && !patientLoading ? (
        <PatientViewsContext.Provider value={{ patientViews }}>
          <PatientsViewNavigation
            pages={patientNavPages}
          ></PatientsViewNavigation>
          <Content>
            <Outlet />
          </Content>
        </PatientViewsContext.Provider>
      ) : null}
    </>
  )
}
