import { formatNumber } from '../../../../helpers/helpers'
import {
  BarComponent,
  BarValue,
  BarsAndValuesComponent
} from '../../Shared/BarComponents/BarComponents'
import { SizeEnum, WidgetState } from '../../Shared/Interfaces/Interfaces'
import { AutoBar } from '../Auto/AutoBar'
import { InsulinData, TotalInsulinConfig } from '../TotalInsulin'

interface BarProps {
  size: SizeEnum
  state: WidgetState
  color: string
  data: InsulinData | null
}
const Bar = ({ size, state, color, data }: BarProps) => {
  const getPercentage = () => {
    if (data?.relativePercentage !== undefined)
      return data.relativePercentage.toString()
    return data !== null
      ? formatNumber({ value: data.percentage, decimals: 0 })
      : '--'
  }
  const isDataState = state === WidgetState.Data
  return (
    <BarsAndValuesComponent $size={size}>
      <BarComponent
        size={size}
        color={color}
        percentage={data?.percentage ?? 0}
        state={state}
      >
        {isDataState && (
          <AutoBar
            color={color}
            width={data?.percentage ?? 0}
            automated={data?.automated || 0}
            className={'showAutoBar'}
          ></AutoBar>
        )}
      </BarComponent>
      <BarValue
        value={getPercentage()}
        state={state}
        color={isDataState ? color : 'var(--text-lightest)'}
        size={size}
      />
    </BarsAndValuesComponent>
  )
}

interface BarsProps {
  data: { basal: InsulinData; bolus: InsulinData } | null
  state: WidgetState
}
export const Bars = ({ data, state }: BarsProps) => {
  return (
    <>
      <Bar
        size={SizeEnum.Small}
        color={TotalInsulinConfig.basal.color}
        data={data ? data.basal : null}
        state={state}
      ></Bar>
      <Bar
        size={SizeEnum.Small}
        color={TotalInsulinConfig.bolus.color}
        data={data ? data.bolus : null}
        state={state}
      ></Bar>
    </>
  )
}
