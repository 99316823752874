import config from '../config'
import { AxiosRequest } from './axios-request'

export async function assignPatient(
  patientId: string,
  cmiPatientId: string,
  assigned: boolean,
  options: object = {}
) {
  return await (assigned
    ? AxiosRequest.create({}).delete(
        `${config.API_URL}/cmi/patients/${cmiPatientId}/assign`
      )
    : AxiosRequest.create({}).post(
        `${config.API_URL}/cmi/patients/${cmiPatientId}/assign/${patientId}`
      ))
}
