import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect } from 'react'

export const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { keycloak } = useKeycloak()

  useEffect(() => {
    if (!keycloak?.authenticated) {
      const url = keycloak.createLoginUrl()
      window.location.replace(url)
    }
  }, [keycloak])

  if (keycloak?.authenticated) {
    return <>{children}</>
  }

  return <></>
}
