import { useTranslation } from 'react-i18next'
import {
  FullTitleComponent,
  TitleComponent,
  TitleWrapperComponent,
  getInfoLogo
} from '../../Shared/Header/Header'
import { SizeEnum, WidgetState } from '../../Shared/Interfaces/Interfaces'

interface HeaderProps {
  state: WidgetState
}

export const Header = ({ state }: HeaderProps) => {
  const { t } = useTranslation()
  return (
    <FullTitleComponent $size={SizeEnum.Small}>
      <TitleWrapperComponent>
        <TitleComponent>{t('Activity')}</TitleComponent>
        {getInfoLogo(
          [
            {
              title: t('Time In Activity'),
              body: t('Time spent in a settings override.')
            }
          ],
          state
        )}
      </TitleWrapperComponent>
    </FullTitleComponent>
  )
}
