import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSessionContext } from '../../../core/contexts/session-context'
import { AddressComponent } from '../../AddressComponent/AddressComponent'
import { MarkDownTypes } from '../../MarkDownComponent/interfaces/interfaces'
import { ModalComponent } from '../../Modal/Modal'
import { MailSection } from '../MailSection/MailSection'
import { MarkDownSection } from '../MarkDownSection/MarkDownSection'
import { ProductInformation } from '../ProductInformation/ProductInformation'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 31.25rem;
  padding: 2rem;
  gap: 1.5rem;
  border-radius: 2rem;
  background-color: var(--white-color);
  height: 40rem;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: stretch;
`
const ProductSection = styled(Section)`
  gap: 0.5rem;
`
export const SectionTitle = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 700;
`

export interface AboutConfig {
  deploymentName: string
  siteId: string
  productName: string
  builtBy: string
  releaseVersion: string
  frontendVersion: string
  backendVersion: string
  productUDI: string
  dateOfManufacture: string
}
interface AboutProps {
  closeFun: () => void
  config: AboutConfig
  showFragileInfo: boolean
}
export const About = ({ closeFun, config, showFragileInfo }: AboutProps) => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  return (
    <ModalComponent closeFun={closeFun} closetestid="closeAboutModal">
      <Wrapper data-testid="aboutModal">
        <Section>
          <SectionTitle>{t('Legal Information')}</SectionTitle>
          <AddressComponent />
          <MarkDownSection
            userType={user?.type}
            markDownType={MarkDownTypes.dataController}
          />
        </Section>
        <ProductSection>
          <SectionTitle>{t('Product Information')}</SectionTitle>
          <ProductInformation
            config={config}
            showFragileInfo={showFragileInfo}
          />
        </ProductSection>
        {showFragileInfo && <MailSection config={config} />}
      </Wrapper>
    </ModalComponent>
  )
}
