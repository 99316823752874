import {
  GetPatientEventLogsProps,
  PatientLogSearchResult
} from '../../containers/Events/interfaces'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getPatientEventLogs({
  patientId,
  size,
  order
}: GetPatientEventLogsProps): Promise<PatientLogSearchResult> {
  const response = await AxiosRequest.create().get(
    `${config.API_URL}/adaptt2d/${patientId}/logs?size=${size}&order=${order}`
  )
  return response.data
}
