import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OperatingSystem } from '../../../helpers/getOperatingSystem'
import { MacGuide } from '../DownloadGuides/MacOs'
import { WindowsGuide } from '../DownloadGuides/Windows'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 37.5rem;
  gap: 2rem;
`
const Title = styled.span`
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 700;
  font-family: inter;
  text-align: center;
  white-space: pre-line;
`

interface DownloadFinishedProps {
  recentlyDownloaded: OperatingSystem | null
}

const getGuide = (os: OperatingSystem | null) => {
  if (os === OperatingSystem.MacOS) return <MacGuide />
  else if (os === OperatingSystem.Windows) return <WindowsGuide />
  else return <></>
}

export const DownloadFinished = ({
  recentlyDownloaded
}: DownloadFinishedProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper data-testid={`uploaderDownloadFinished${recentlyDownloaded}`}>
      <Title>{`${t(
        'Thank you for downloading\nStenopool Uploader for'
      )} ${recentlyDownloaded} 🎉`}</Title>
      {getGuide(recentlyDownloaded)}
    </Wrapper>
  )
}
