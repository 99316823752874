import { BarAndValue } from '../../Shared/BarComponents/BarComponents'
import { SizeEnum, WidgetState } from '../../Shared/Interfaces/Interfaces'
import { TimeInAutomationConfig } from '../TimeInAutomation'

interface BarsProps {
  data: { automation: number; manual: number } | null
  state: WidgetState
}
export const Bars = ({ data, state }: BarsProps) => {
  return (
    <>
      <BarAndValue
        size={SizeEnum.Small}
        state={state}
        color={TimeInAutomationConfig.automation.color}
        percentage={data ? data.automation : null}
      />
      <BarAndValue
        size={SizeEnum.Small}
        state={state}
        color={TimeInAutomationConfig.manual.color}
        percentage={data ? data.manual : null}
      />
    </>
  )
}
