import {
  faBatteryLow,
  faBatterySlash,
  faTriangleExclamation
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { CSSProperties } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  padding: 1rem 2rem;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--yellow-12);
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
`
const ErrorIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  line-height: 2rem;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.625rem;
`
const MainText = styled.span`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--text-primary);
`
const ErrorText = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  color: var(--text-lighter);
`
export enum NovoPenErrorCodes {
  ST_UNRECOVERABLE_ERR = 'ST_UNRECOVERABLE_ERR',
  ST_EXP_END_OF_LIFE = 'ST_EXP_END_OF_LIFE',
  ST_WARNING_EOL = 'ST_WARNING_EOL'
}

export const ErrorComponent = ({
  errorCode
}: {
  errorCode: NovoPenErrorCodes
}) => {
  const getErrorConfig = () => {
    switch (errorCode) {
      case NovoPenErrorCodes.ST_UNRECOVERABLE_ERR:
        return {
          paragraph:
            'The dose memory is defective and will not register further injections.',
          icon: (
            <ErrorIcon
              icon={faTriangleExclamation}
              style={
                {
                  '--fa-primary-color': 'var(--white-color)',
                  '--fa-secondary-color': 'var(--yellow)',
                  '--fa-secondary-opacity': '1'
                } as CSSProperties
              }
            />
          )
        }
      case NovoPenErrorCodes.ST_EXP_END_OF_LIFE:
        return {
          paragraph:
            'The battery is depleted, or the pen has been used for more than 5 years. The dose memory will not register further injections.',
          icon: (
            <ErrorIcon
              icon={faBatterySlash}
              style={
                {
                  '--fa-primary-color': 'var(--destructive-darker)',
                  '--fa-secondary-color': 'var(--text-medium)',
                  '--fa-secondary-opacity': '1'
                } as CSSProperties
              }
            />
          )
        }
      case NovoPenErrorCodes.ST_WARNING_EOL:
        return {
          paragraph:
            'The battery is running low, and the dose memory is approaching end of life.',
          icon: (
            <ErrorIcon
              icon={faBatteryLow}
              style={
                {
                  '--fa-primary-color': 'var(--destructive-darker)',
                  '--fa-secondary-color': 'var(--text-medium)',
                  '--fa-secondary-opacity': '1'
                } as CSSProperties
              }
            />
          )
        }
    }
  }
  const config = getErrorConfig()
  return (
    <Wrapper data-testid={`NovoPenError${errorCode}`}>
      <IconWrapper>{config.icon}</IconWrapper>
      <TextWrapper>
        <MainText>{config.paragraph}</MainText>
        <ErrorText>{errorCode}</ErrorText>
      </TextWrapper>
    </Wrapper>
  )
}
