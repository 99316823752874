import { MutableRefObject, useState } from 'react'
import styled from 'styled-components'
import { OverviewResponse } from '../../../../containers/Overview/Overview'
import { PeriodObject } from '../../../Header/DevicesAndPeriod/DevicesAndPeriod'
import { GlucoseOverview } from '../../GlucoseOverview/GlucoseOverview'
import { GlucoseOverviewHeader } from '../../GlucoseOverviewHeader/GlucoseOverviewHeader'
import { createGlucoseArrays } from '../../OverviewHelpers/OverviewHelpers'
import { GraphContainer, OverviewGraphType } from '../GraphComponent'

interface GraphWrapperProps {
  days: number
  firstDay: number
}

interface GlucoseComponentProps {
  data: OverviewResponse
  period: PeriodObject
  screenshotWrapper: MutableRefObject<HTMLDivElement | null>
}

const GraphWrapper = styled.div<GraphWrapperProps>`
  margin-left: ${(props) => 'calc((' + props.firstDay + ' - 1) * 8.75rem)'};
  width: ${(props) => 'calc(' + props.days + ' * 8.75rem + 15px)'};
  height: calc(5rem + 30px);
`
export const GlucoseComponent = ({
  data,
  period,
  screenshotWrapper
}: GlucoseComponentProps) => {
  const [showUserInitiatedBolus, setShowUserInitiatedBolus] =
    useState<boolean>(true)
  const graphData = createGlucoseArrays(
    data.cgmEvents,
    data.bolusEvents,
    period.lengthOfPeriod
  )
  const entries = graphData.weekArray
    .filter((e) => e.length)
    .map((item, index) => (
      <GraphWrapper
        key={'glucoseOverviewGraph' + index}
        days={item.length}
        firstDay={index === 0 ? graphData.firstWeek : 1}
      >
        <GlucoseOverview
          days={item.length}
          data={item}
          index={index}
          showUserInitiatedBolus={showUserInitiatedBolus}
          glucoseDensity={data.glucoseDensity}
        ></GlucoseOverview>
      </GraphWrapper>
    ))

  return (
    <>
      <GlucoseOverviewHeader
        showUserInitiatedBolus={showUserInitiatedBolus}
        setShowUserInitiatedBolus={setShowUserInitiatedBolus}
        wrapperRef={screenshotWrapper}
      />
      <GraphContainer type={OverviewGraphType.glucose} entries={entries} />
    </>
  )
}
