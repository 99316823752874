import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../helpers/helpers'
import { BasedOnDataComponent } from '../BasedOnDataComponent/BasedOnDataComponent'
import { Total, TotalUnit, Unit } from '../CGMWidget/CGMWidget'
import {
  getInfoLogo,
  TitleComponent,
  TitleWrapperComponent
} from '../DataWidget/Shared/Header/Header'
import { WidgetState } from '../DataWidget/Shared/Interfaces/Interfaces'
import { getInformationTooltipParagraph } from '../InformationTooltip/InformationTooltipParagraphs'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  padding: 1rem;
`
export const Wrapper = styled.div`
  gap: 0.375rem;
  width: 18.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LogoWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export enum SingleWidgetTypeEnum {
  DailySteps = 'DailySteps',
  SensorUsage = 'SensorUsage',
  AvgCarbs = 'AvgCarbs',
  GMI = 'GMI'
}

const getWidgetObject = (type: SingleWidgetTypeEnum) => {
  switch (type) {
    case SingleWidgetTypeEnum.SensorUsage:
      return { name: 'Sensor Usage', color: 'var(--inRange-color)', unit: '%' }
    case SingleWidgetTypeEnum.AvgCarbs:
      return {
        name: 'Avg. Daily Carbs',
        color: 'var(--text-primary)',
        unit: 'g'
      }
    case SingleWidgetTypeEnum.GMI:
      return {
        name: 'GMI (CGM)',
        color: 'var(--high-darker-color)',
        unit: 'mmol/L'
      }
    case SingleWidgetTypeEnum.DailySteps:
      return {
        name: 'Avg. Daily Steps',
        color: 'var(--lime-green)',
        unit: 'steps'
      }
  }
}

interface DataWithDays {
  data: number
  daysWithData: number
  selectedDays: number
}
interface SingleWidgetProps {
  type: SingleWidgetTypeEnum
  value: number | null | DataWithDays
  state: WidgetState
  first?: boolean
  last?: boolean
}

export const getActualState = (state: WidgetState, value: any | null) => {
  if (state === WidgetState.Loading) {
    return state
  } else {
    if (value === null) {
      return WidgetState.Empty
    } else return WidgetState.Data
  }
}
export const SingleWidget = ({
  type,
  value,
  state,
  first,
  last
}: SingleWidgetProps) => {
  const { t } = useTranslation()
  const valueIsNumber = typeof value === 'number'
  const tooltip = getInformationTooltipParagraph(type, t)

  const actualState = getActualState(state, value)

  return (
    <Container>
      <Wrapper>
        <TitleWrapperComponent>
          <TitleComponent>{t(getWidgetObject(type).name)}</TitleComponent>
          {getInfoLogo(tooltip, actualState, first, last)}
        </TitleWrapperComponent>
        <TotalUnit>
          <Total
            color={
              value !== null
                ? getWidgetObject(type).color
                : 'var(--text-lightest)'
            }
          >
            {value !== null
              ? valueIsNumber
                ? formatNumber({ value, decimals: 0 })
                : formatNumber({ value: value.data, decimals: 0 })
              : '--'}
          </Total>
          <Unit>{getWidgetObject(type).unit}</Unit>
        </TotalUnit>
      </Wrapper>
      {value !== null && !valueIsNumber && (
        <BasedOnDataComponent
          daysWithData={value.daysWithData}
          selectedDays={value.selectedDays}
        />
      )}
    </Container>
  )
}
