import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { Body } from '../PatientInfo'

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 46.5rem;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
`

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const HeaderLogo = styled(FontAwesomeIcon)`
  width: 2rem;
  text-align: center;
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 900;
`

const HeaderTitle = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
`
interface SectionComponentProps {
  title: string
  icon?: IconDefinition
  children: ReactNode
}
export const SectionComponent = ({
  title,
  icon,
  children
}: SectionComponentProps) => {
  return (
    <Section>
      <Header>
        <HeaderSection>
          {icon && <HeaderLogo icon={icon} />}
          <HeaderTitle>{title}</HeaderTitle>
        </HeaderSection>
      </Header>
      <Body>{children}</Body>
    </Section>
  )
}
