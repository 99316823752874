import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { useRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import dayjs from '../../../core/dayjs/dayjs'
import { ToggleButton, ToggleButtonText } from '../../ToggleButton/ToggleButton'
import {
  ButtonProps,
  CustomDatePicker,
  DateIcon,
  DatePickerWrapper
} from '../TimeSelector'

export const Button = ({
  name,
  index,
  active,
  handleActive,
  text,
  setEndDate,
  scrollWrapper,
  latestData,
  data,
  periodState
}: ButtonProps) => {
  const { t } = useTranslation()
  const datePickerRef = useRef<ReactDatePicker<
    string,
    boolean | undefined
  > | null>(null)
  const activeState: boolean = index === active
  const selectDatePicker = () => {
    if (datePickerRef?.current) {
      datePickerRef.current.setFocus()
    }
  }
  if (text === 'Custom' && data) {
    return (
      <ToggleButton
        style={{
          padding: '0.75rem 1rem 0.75rem 0.875rem',
          height: '2.5rem',
          zIndex: 'auto'
        }}
        state={periodState === null ? null : activeState}
        onClickFun={selectDatePicker}
        testid={activeState === false ? 'inactiveButton' : 'activeButton'}
      >
        <DateIcon icon={faCalendar}></DateIcon>
        <DatePickerWrapper>
          <CustomDatePicker
            calendarStartDay={1}
            ref={datePickerRef}
            selected={periodState ? periodState.endDate : new Date()}
            dateFormat={['dd/MM/yyyy', 'dd-MM-yyyy']}
            minDate={
              periodState
                ? dayjs(data[0].start)
                    .add(periodState.lengthOfPeriod - 1, 'day')
                    .toDate()
                : new Date()
            }
            maxDate={dayjs(latestData).toDate()}
            onChange={(date: Date) => {
              handleActive(
                index,
                date.getDate() +
                  '/' +
                  (date.getMonth() + 1) +
                  '/' +
                  date.getFullYear(),
                date
              )
              setEndDate(date)
              scrollWrapper.current.scrollTo({
                left:
                  (dayjs(new Date()).diff(dayjs(date), 'day') /
                    dayjs(new Date()).diff(dayjs(data[0].start), 'day')) *
                  scrollWrapper.current.scrollWidth *
                  -1,
                top: 0,
                behavior: 'smooth'
              })
            }}
          />
        </DatePickerWrapper>
      </ToggleButton>
    )
  } else
    return (
      <ToggleButton
        style={
          text === t('Latest Data')
            ? { padding: '0.75rem 1rem 0.75rem 0.875rem', height: '2.5rem' }
            : {
                padding: '0.75rem 1rem 0.75rem 0.875rem',
                width: '7.03125rem',
                height: '2.5rem'
              }
        }
        state={periodState === null ? null : activeState}
        testid={activeState === false ? 'inactiveButton' : 'activeButton'}
        onClickFun={() => {
          if (text === t('Latest Data') && data !== null && latestData) {
            handleActive(
              index,
              dayjs(latestData).format('DD/MM/YYYY'),
              latestData
            )
            scrollWrapper.current.scrollTo({
              left:
                (dayjs(new Date()).diff(dayjs(latestData), 'day') /
                  dayjs(new Date()).diff(dayjs(data[0].start), 'day')) *
                scrollWrapper.current.scrollWidth *
                -1,
              top: 0,
              behavior: 'smooth'
            })
          } else {
            handleActive(
              index,
              text,
              periodState ? dayjs(periodState.endDate).toDate() : new Date()
            )
            if (
              periodState &&
              data &&
              dayjs(periodState.endDate)
                .subtract(parseInt(text), 'day')
                .isBefore(dayjs(data[0].start)) &&
              dayjs(periodState.endDate)
                .add(parseInt(text), 'day')
                .isBefore(dayjs(data[0].end))
            ) {
              handleActive(
                index,
                text,
                dayjs(data[0].start).add(parseInt(text), 'day').toDate()
              )
            }
          }
        }}
      >
        <ToggleButtonText style={{ fontSize: '0.875rem', fontWeight: '500' }}>
          {text} {name === 'period' ? `${t('days')}` : ''}
          {text === t('Latest Data')
            ? dayjs(latestData).isToday()
              ? t('Today')
              : `(${dayjs(latestData).format('DD/MM/YYYY')})`
            : ''}
        </ToggleButtonText>
      </ToggleButton>
    )
}
