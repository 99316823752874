import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  Header,
  MainText
} from '../../../../components/BasalTooltip/BasalTooltip'
import { formatNumber } from '../../../../helpers/helpers'

const Value = styled.span`
  font-family: inter;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-align: right;
  color: var(--text-primary);
`
const Unit = styled.span`
  font-family: inter;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: var(--text-lighter);
`
const Wrapper = styled.div`
  padding: 0.25rem 0.125rem 0.25rem 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  z-index: 3;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  border-radius: 0.125rem;
  background-color: var(--element-bg-80);
  &:hover {
    background-color: var(--blue);
  }
  &:hover ${Value} {
    color: var(--white-color);
  }
  &:hover ${Unit} {
    color: var(--text-lightest);
  }
`
const TooltipWrapper = styled.div`
  display: flex;
  width: 19rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--white-color);
  box-shadow: 0rem 0.25rem 0.5rem 0rem var(--tooltip-shadow);
  z-index: 3;
  position: absolute;
  bottom: 2.25rem;
  right: 1.625rem;
  overflow: hidden;
  transform: translateX(50%);
`

const TooltipBody = styled.div`
  display: flex;
  padding: 0.75rem 1rem 0.75rem 1rem;
  justify-content: space-between;
  align-items: flex-start;
`

export const SensorCoverageDisplay = ({
  sensorCoverage
}: {
  sensorCoverage: number | null | undefined
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const { t } = useTranslation()
  return (
    <Wrapper
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip && (
        <TooltipWrapper data-testid="SensorCoverageDisplayWrapper">
          <Header>
            <MainText>{t('Sensor usage')}</MainText>
          </Header>
          <TooltipBody>
            <MainText>
              {t('Share of the period which has sensor data.')}
            </MainText>
          </TooltipBody>
        </TooltipWrapper>
      )}
      <Value data-testid="SensorCoverageDisplayValue">
        {sensorCoverage === null || sensorCoverage === undefined
          ? '--'
          : formatNumber({ value: sensorCoverage, decimals: 0 })}
      </Value>
      <Unit>%</Unit>
    </Wrapper>
  )
}
