import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DeviceSettingsTable } from '../../../components/DeviceSettingsComponents/DeviceSettingsTable/DeviceSettingsTable'
import { useSessionContext } from '../../../core/contexts/session-context'
import dayjs from '../../../core/dayjs/dayjs'
import { UserType } from '../../../core/entities/user.entity'
import {
  CurrentDeviceSettings,
  Profile,
  PumpSettingsObject
} from '../DeviceSettings'
export enum BgTargetType {
  lowhigh,
  hightarget,
  target
}
interface PumpSettingsProps {
  currentDeviceSettings: CurrentDeviceSettings
  pumpSettings: PumpSettingsObject
}
const Title = styled.div`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
`
const SubTitleSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`
const SubText = styled.span`
  color: var(--text-lighter);
`
const DeviceBox = styled.div`
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
`

const MainText = styled.span`
  color: var(--text-primary);
`

const PatientInfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  gap: 0.75rem;
  background-color: var(--element-bg);
  border-radius: 0.5rem;
  width: 100%;
`

const PatientInfoBoxIcon = styled(FontAwesomeIcon)`
  color: var(--blue);
  font-size: 1.125rem;
  font-weight: 900;
`

const PatientInfoBoxText = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
`
const PatientInfoBox = () => {
  const { t } = useTranslation()
  return (
    <PatientInfoBoxWrapper data-testid="patientinfobox">
      <PatientInfoBoxIcon icon={faInfoCircle} />
      <PatientInfoBoxText>
        {t(
          'Do not change your medical device settings without first consulting your healthcare provider.'
        )}
      </PatientInfoBoxText>
    </PatientInfoBoxWrapper>
  )
}

const getBgTargetType = (entries: Profile[] | undefined) => {
  if (entries !== undefined) {
    for (let j = 0; j < entries.length; j++) {
      for (let i = 0; i < entries[j].entries.length; i++) {
        const currentTarget = entries[j].entries[i].target
        if (currentTarget.low && currentTarget.high) return BgTargetType.lowhigh
        if (currentTarget.high && currentTarget.target)
          return BgTargetType.hightarget
      }
    }
    return BgTargetType.target
  } else return BgTargetType.target
}
export const PumpSettings = ({
  currentDeviceSettings,
  pumpSettings
}: PumpSettingsProps) => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const targetType = getBgTargetType(pumpSettings.profiles)
  const time = dayjs(currentDeviceSettings.time)

  return (
    <>
      <Title>{t('Device Settings')}</Title>
      {user?.type === UserType.Patient && <PatientInfoBox />}
      <SubTitleWrapper>
        <DeviceBox>
          <MainText>{currentDeviceSettings.deviceDisplayName}</MainText>
          <SubText>{currentDeviceSettings.serialnumber}</SubText>
        </DeviceBox>
        <SubTitleSection>
          <SubText>{t('Uploaded on')}</SubText>
          <MainText>{time.format('DD/MM/YYYY')}</MainText>
          <SubText>@</SubText>
          <MainText>{time.format('HH:mm')}</MainText>
        </SubTitleSection>
      </SubTitleWrapper>
      {pumpSettings.profiles.map((data, index) => (
        <DeviceSettingsTable
          data={data}
          index={index}
          targetType={targetType}
          key={data.profileName}
        />
      ))}
    </>
  )
}
