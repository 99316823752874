import dayjs from 'dayjs'
import domtoimage from 'dom-to-image'
import { Dispatch, SetStateAction } from 'react'
import { TFunction } from 'react-i18next'
import { SnackBar, SnackBarType } from '../../../libraries/Toast/SnackBar'
import { PeriodObject } from '../../Header/DevicesAndPeriod/DevicesAndPeriod'
import {
  PeriodPlacement,
  PeriodPosition,
  WrapperPadding
} from '../ScreenshotComponent/ScreenshotComponent'

const copyToClipBoard = async (
  wrapper: HTMLElement,
  icon: HTMLDivElement,
  options: any,
  dateObject: HTMLElement,
  setToggleBorder: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const blob = await domtoimage.toBlob(wrapper as Node, options)
    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob
      })
    ])
    setToggleBorder(false)
  } catch (error) {
    setToggleBorder(false)
    throw new Error('cant find element')
  } finally {
    wrapper.removeChild(dateObject)
    showNodes(icon)
  }
}
interface TakeScreenshotProps {
  refs: {
    wrapper: (HTMLElement | null) | undefined
    icon: HTMLDivElement | null
  }
  period: PeriodObject | null
  setToggleBorder: Dispatch<SetStateAction<boolean>>
  periodPlacement: PeriodPlacement | undefined
  wrapperPadding: WrapperPadding | undefined
  ignoreTransform?: true
  cpr: string | undefined
}
const hideNodes = (icon: HTMLDivElement) => {
  icon.style.opacity = '0'
}
const showNodes = (icon: HTMLDivElement) => {
  icon.style.opacity = '1'
}

const setTextDivPosition = (
  textDiv: HTMLElement,
  periodPlacement: PeriodPlacement | undefined,
  wrapperPadding: WrapperPadding | undefined
) => {
  switch (periodPlacement?.position) {
    case PeriodPosition.topLeft: {
      textDiv.style.top = `${
        periodPlacement.margin.top -
        (wrapperPadding?.height ? wrapperPadding?.height / 2 : 0)
      }px`
      textDiv.style.left = `${
        periodPlacement.margin.left -
        (wrapperPadding?.width ? wrapperPadding?.width / 2 : 0)
      }px`
      break
    }
    case PeriodPosition.topRight: {
      textDiv.style.top = `${
        periodPlacement.margin.top -
        (wrapperPadding?.height ? wrapperPadding?.height / 2 : 0)
      }px`
      textDiv.style.right = `${
        periodPlacement.margin.right -
        (wrapperPadding?.width ? wrapperPadding?.width / 2 : 0)
      }px`
      break
    }
    case PeriodPosition.bottomLeft: {
      textDiv.style.bottom = `${
        periodPlacement.margin.bottom -
        (wrapperPadding?.height ? wrapperPadding?.height / 2 : 0)
      }px`
      textDiv.style.left = `${
        periodPlacement.margin.left -
        (wrapperPadding?.width ? wrapperPadding?.width / 2 : 0)
      }px`
      break
    }
    case PeriodPosition.bottomRight: {
      textDiv.style.bottom = `${
        periodPlacement.margin.bottom -
        (wrapperPadding?.height ? wrapperPadding?.height / 2 : 0)
      }px`
      textDiv.style.right = `${
        periodPlacement.margin.right -
        (wrapperPadding?.width ? wrapperPadding?.width / 2 : 0)
      }px`
      break
    }
    default: {
      textDiv.style.top = `${
        20 - (wrapperPadding?.height ? wrapperPadding?.height / 2 : 0)
      }px`
      textDiv.style.right = `${
        32 - (wrapperPadding?.width ? wrapperPadding?.width / 2 : 0)
      }px`
      break
    }
  }
}

const createTextContent = (
  textDiv: HTMLDivElement,
  period: PeriodObject,
  periodPlacement: PeriodPlacement | undefined,
  wrapperPadding: WrapperPadding | undefined,
  cpr: string | undefined
) => {
  const cprKey = document.createElement('span')
  const cprValue = document.createElement('span')

  cprKey.style.fontFamily = 'inter'
  cprKey.style.fontSize = '0.625rem'
  cprKey.style.color = 'var(--text-lighter)'
  cprKey.style.fontWeight = '600'
  cprKey.textContent = `CPR `
  cprValue.style.fontFamily = 'inter'
  cprValue.style.fontSize = '0.625rem'
  cprValue.style.color = 'var(--text-medium)'
  cprValue.style.fontWeight = '600'
  cprValue.textContent = `${
    cpr !== undefined ? cpr.substring(0, 6) + '-' + cpr.substring(6, 11) : ''
  }`
  textDiv.appendChild(cprKey)
  textDiv.appendChild(cprValue)

  if (periodPlacement) {
    const dateKey = document.createElement('span')
    const dateValue = document.createElement('span')
    dateKey.style.fontFamily = 'inter'
    dateKey.style.fontSize = '0.625rem'
    dateKey.style.color = 'var(--text-lighter)'
    dateKey.style.fontWeight = '600'
    dateKey.style.marginLeft = '0.75rem'
    dateKey.textContent = `Date `
    dateValue.style.fontFamily = 'inter'
    dateValue.style.fontSize = '0.625rem'
    dateValue.style.color = 'var(--text-medium)'
    dateValue.style.fontWeight = '600'
    dateValue.textContent = `${dayjs(
      new Date(period.endDate).setDate(
        new Date(period.endDate).getDate() - period.lengthOfPeriod + 1
      )
    ).format('DD/MM/YYYY')} – 
   ${dayjs(period.endDate).format('DD/MM/YYYY')}`
    textDiv.appendChild(dateKey)
    textDiv.appendChild(dateValue)
  }
  textDiv.style.whiteSpace = 'nowrap'
  textDiv.style.position = 'absolute'
  setTextDivPosition(textDiv, periodPlacement, wrapperPadding)
}

const takeScreenshot = async ({
  refs,
  period,
  setToggleBorder,
  periodPlacement,
  wrapperPadding,
  ignoreTransform,
  cpr
}: TakeScreenshotProps) => {
  try {
    if (refs.wrapper && refs.icon && period) {
      hideNodes(refs.icon)
      const textDiv = document.createElement('div')
      createTextContent(textDiv, period, periodPlacement, wrapperPadding, cpr)
      refs.wrapper.appendChild(textDiv)
      const options = {
        bgcolor: '#FFFFFF',
        width: refs.wrapper.clientWidth + (wrapperPadding?.width ?? 0),
        height: refs.wrapper.clientHeight + (wrapperPadding?.height ?? 0),
        style: ignoreTransform
          ? {}
          : {
              transform: `translateX(${
                wrapperPadding?.width ? wrapperPadding?.width / 2 : '0'
              }px) translateY(${
                wrapperPadding?.height ? wrapperPadding?.height / 2 : '0'
              }px)`
            }
      }
      await copyToClipBoard(
        refs.wrapper,
        refs.icon,
        options,
        textDiv,
        setToggleBorder
      )
    } else {
      SnackBar({
        type: SnackBarType.Error,
        message: 'missing period or elements'
      })
    }
    setToggleBorder(false)
  } catch (err) {
    setToggleBorder(false)
    throw err
  }
}
interface HandleScreenshotProps {
  refs: {
    wrapper: (HTMLElement | null) | undefined
    icon: HTMLDivElement | null
  }
  period: PeriodObject | null
  t: TFunction<'translation', undefined>
  setToggleBorder: Dispatch<SetStateAction<boolean>>
  periodPlacement: PeriodPlacement | undefined
  wrapperPadding: WrapperPadding | undefined
  ignoreTransform?: true
  cpr: string | undefined
}
export const handleScreenshot = async ({
  refs,
  period,
  t,
  setToggleBorder,
  periodPlacement,
  wrapperPadding,
  ignoreTransform,
  cpr
}: HandleScreenshotProps) => {
  try {
    setToggleBorder(true)
    await takeScreenshot({
      refs,
      period,
      setToggleBorder,
      periodPlacement,
      wrapperPadding,
      ignoreTransform,
      cpr
    })
    SnackBar({
      type: SnackBarType.Success,
      message: t('successfully copied to clipboard')
    })
  } catch (err) {
    SnackBar({ type: SnackBarType.Error, message: t('cant find element') })
  }
}
