import { faCalculator } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Modal } from '../../../components/Modal/Modal'
import { Td } from '../../../components/Table/ScrollTableComponent'
import { AlgorithmInfo } from '../interfaces'
import { EventCalculationsModal } from './EventCalculationsModal/EventCalculationsModal'
const Tdd = styled(Td)`
  line-height: 2.25rem;
  background-color: inherit;
`
const CalculationIconWrapper = styled(Tdd)`
  padding: 0.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 0.0625rem solid var(--text-lightest);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background-color: var(--white-color);
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--text-medium);
  &:hover {
    background-color: var(--brand-primary-color);
    color: var(--white-color);
    border: 0.0625rem solid var(--brand-primary-color);
  }
`
const CalculationIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: inherit;
`

interface EventCalculationsProps {
  hover: boolean
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  algorithmInfo: AlgorithmInfo
  probability: number | null
}
export const EventCalculations = ({
  hover,
  showModal,
  setShowModal,
  algorithmInfo,
  probability
}: EventCalculationsProps) => {
  return (
    <>
      {hover && probability !== null && (
        <CalculationIconWrapper
          data-testid="EventCalculationsButton"
          onClick={() => setShowModal(true)}
        >
          <CalculationIcon icon={faCalculator} />
        </CalculationIconWrapper>
      )}
      {probability && (
        <Modal isOpen={showModal}>
          <EventCalculationsModal
            algorithmInfo={algorithmInfo}
            closefn={() => setShowModal(false)}
            probability={probability}
          ></EventCalculationsModal>
        </Modal>
      )}
    </>
  )
}
