import { convertProviderPatientProviderTypeToProviderType } from '../../containers/Integrations/Integrations'
import config from '../config'
import { ProviderPatientProviderType } from '../testdata/ProviderPatientTestData'
import { AxiosRequest } from './axios-request'

export async function fetchDexcomDataApi(
  cmiPatientId: string | null,
  providerType: ProviderPatientProviderType
): Promise<any> {
  const response = await AxiosRequest.create().put(
    `${
      config.API_URL
    }/cmi/patients/${cmiPatientId}/fetch?providerType=${convertProviderPatientProviderTypeToProviderType(
      providerType
    )}`
  )
  return response.data
}
