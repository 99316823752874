import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ClinicViewNavigation } from '../../components/ClinicViewNavigation/ClinicViewNavigation'
import { useClinicViewsContext } from '../../core/contexts/clinic-view-context'
import { Content } from '../PatientDetails/PatientDetails'

export function Clinic() {
  const { t } = useTranslation()
  const clinicViews = useClinicViewsContext()

  const clinicNavPages = [
    {
      label: `${t('Pen Titration')}`,
      to: 'pen_titration',
      notification: false,
      show: clinicViews.PenTitrations.show
    },
    {
      label: `${t('Patients')}`,
      to: 'patients',
      notification: false,
      show: clinicViews.Patients.show
    },
    {
      label: `${t('Integrations')}`,
      to: 'integrations',
      notification: false,
      show: clinicViews.Integrations.show
    },
    {
      label: `${t('Upload Activity')}`,
      to: 'uploadactivity',
      notification: false,
      show: clinicViews.UploadActivity.show
    },
    {
      label: `${t('Audit Log')}`,
      to: 'auditlog',
      notification: false,
      show: clinicViews.AuditLog.show
    }
  ]
  return (
    <>
      <ClinicViewNavigation pages={clinicNavPages}></ClinicViewNavigation>
      <Content>
        <Outlet />
      </Content>
    </>
  )
}
