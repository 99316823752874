export enum UserType {
  HCP = 'HCP',
  HCPAdmin = 'HCPADMIN',
  Patient = 'PATIENT'
}

export interface User {
  userId: string
  name: string
  upid: string
  type: UserType
  patientId: string | null
  wards: Array<string>
  blinded: boolean
}
