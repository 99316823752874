import { faShieldKeyhole } from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowPointer,
  faPhone,
  faVoicemail
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Body } from '../../../containers/PatientInfo/PatientInfo'
import { UserType } from '../../../core/entities/user.entity'
import { getLanguage } from '../../../core/i18n'
import { getMarkDownId } from '../../MarkDownComponent/functions/getMarkDownId'
import { useDeploymentText } from '../../MarkDownComponent/hooks/useDeploymentText'
import { MarkDownTypes } from '../../MarkDownComponent/interfaces/interfaces'
import { Spinner, spinnerSize } from '../../Spinner/Spinner'
import { TitleComponent } from '../ProductInformation/ProductInformation'

const Wrapper = styled(Body)`
  border: none;
  background-color: var(--element-bg);
  align-items: flex-start;
`

export const MarkDownSection = ({
  userType,
  markDownType
}: {
  userType: UserType | undefined
  markDownType: MarkDownTypes
}) => {
  const { text, textError } = useDeploymentText({
    id: getMarkDownId(userType, markDownType),
    language: getLanguage()
  })
  const { t } = useTranslation()
  const getConfig = () => {
    switch (markDownType) {
      case MarkDownTypes.link:
        return { image: [faArrowPointer], uncenterImage: false, title: '' }
      case MarkDownTypes.phone:
        return { image: [faPhone], uncenterImage: true, title: '' }
      case MarkDownTypes.email:
        return { image: [faVoicemail], uncenterImage: true, title: '' }
      case MarkDownTypes.dataController:
        return {
          image: [faShieldKeyhole],
          uncenterImage: true,
          style: {
            border: '0.0625rem solid var(--text-lightest)',
            backgroundColor: 'var(--white-color)'
          },
          title: t('Data controller (GDPR)')
        }
    }
  }
  const config = getConfig()

  if (textError) {
    return <div data-testid="MarkDownError"></div>
  }
  if (text === '') return <div data-testid="emptyMarkDown"></div>

  if (text)
    return (
      <Wrapper style={config.style}>
        <TitleComponent
          markDown={text}
          images={config.image}
          uncenterImage={config.uncenterImage}
          title={config.title}
          showTitle={markDownType === MarkDownTypes.dataController}
        ></TitleComponent>
      </Wrapper>
    )
  return <Spinner spinnersize={spinnerSize.large}></Spinner>
}
