import { ReactNode } from 'react'
import styled from 'styled-components'

interface GuideStepProps {
  stepNumber: number
  title: string
  children: ReactNode
}

export const Guide = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 1rem;
`

export const Step = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
  align-self: stretch;
  border-radius: 1rem;
  border: 0.0625rem solid var(--text-lightest);
  align-items: flex-start;
  justify-content: flex-start;
`
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const StepNumber = styled.span`
  color: var(--brand-primary-color);
  font-family: inter;
  font-size: 0.875rem
  font-weight: 700;
`

export const StepText = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: pre-line;
`
export const StepTitle = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 700;
`
export const GuideComponent = ({
  stepNumber,
  title,
  children
}: GuideStepProps) => {
  return (
    <Step>
      <Section>
        <StepNumber>{`${stepNumber}.`}</StepNumber>
      </Section>
      <Section>
        <StepTitle>{title}</StepTitle>
        {children}
      </Section>
    </Step>
  )
}
