import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SortEnum } from '../../../components/ProviderPatients/ProviderPatients'

import { getPopulations } from '../../../core/api/get-populations'
import { PopulationOverview } from '../tables/interfaces'

export const usePopulationsHook = (
  refreshPopulations: boolean,
  shouldGetPopulations: boolean | undefined
) => {
  const [populations, setPopulations] = useState<PopulationOverview[] | null>(
    null
  )
  const [populationsError, setPopulationsError] = useState<Error | null>(null)
  const { t } = useTranslation()
  useEffect(() => {
    setPopulations(null)
    setPopulationsError(null)
    if (!shouldGetPopulations) return
    const handleGetPopulations = async () => {
      try {
        const allPopulations = await getPopulations(SortEnum.Ascending)
        setPopulations(allPopulations)
      } catch (error) {
        setPopulationsError(new Error('Error getting populations'))
      }
    }
    handleGetPopulations()
  }, [t, refreshPopulations, shouldGetPopulations])
  return {
    populations,
    populationsError
  }
}
