import { faBrowser, faCode } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export enum IntegrationType {
  web,
  api
}
interface IntegrationTypeLabelProps {
  type: IntegrationType
}
const LabelWrapper = styled.div<{ color: string }>`
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  background-color: ${(props) => props.color};
  border-radius: 0.5rem;
`
const LabelIcon = styled(FontAwesomeIcon)`
  color: var(--white-color);
  font-size: 0.6875rem;
  font-weight: 900;
  font-style: normal:
`
const LabelText = styled.span`
  color: var(--white-color);
  font-family: inter;
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
`

const getIntegrationTypeLabelObject = (type: IntegrationType) => {
  switch (type) {
    case IntegrationType.web:
      return { icon: faBrowser, text: 'web', color: 'var(--pink)' }
    case IntegrationType.api:
      return { icon: faCode, text: 'api', color: 'var(--brand-lighter-color)' }
  }
}
export const IntegrationTypeLabel = ({ type }: IntegrationTypeLabelProps) => {
  const label = getIntegrationTypeLabelObject(type)
  return (
    <LabelWrapper color={label.color}>
      <LabelIcon icon={label.icon} />
      <LabelText>{label.text}</LabelText>
    </LabelWrapper>
  )
}
