import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PeriodObject } from '../../Header/DevicesAndPeriod/DevicesAndPeriod'
import {
  InformationLogoSize,
  InformationTooltip
} from '../../InformationTooltip/InformationTooltip'
import { Button } from '../Button/Button'
import { Buttons } from '../TimeLine/TimeLine'
import {
  ButtonSectionProps,
  Section,
  SideTitleWrapper,
  Title
} from '../TimeSelector'

export const ButtonSection = ({
  name,
  title,
  buttons,
  periodState,
  setPeriodState,
  setEndDate,
  scrollWrapper,
  latestData,
  data
}: ButtonSectionProps) => {
  const { t } = useTranslation()
  const [activeState, setActiveState] = useState<number>(
    periodState
      ? name === 'period'
        ? buttons.indexOf(periodState.lengthOfPeriod.toString())
        : periodState.endDate &&
          latestData?.getTime() === periodState.endDate.getTime()
        ? 1
        : 0
      : 0
  )
  const handleActive = (key: number, text: string, date: Date) => {
    setActiveState(key)
    const clone: PeriodObject | null = periodState ? { ...periodState } : null
    if (clone) {
      if (name === 'period') {
        clone.lengthOfPeriod = parseInt(text.split(/\s(.+)/)[0])
        clone.endDate = date
      } else {
        clone.endDate = date
      }
    }
    setPeriodState(clone)
  }
  const getTooltipParagraph = () => {
    // refactor to use enum
    if (name === 'period')
      return [
        {
          title: t('Choose Length of Period'),
          body: t(
            'The number of days you want data displayed for. The time period extends until the specified end date.'
          )
        }
      ]
    else
      return [
        {
          title: t('Choose End Date'),
          body: t(
            'The last date of the selected time period. Select the end date in the calendar or set this to the date with the latest data.'
          )
        }
      ]
  }
  const buttonsToRender = buttons.map((button, index) => (
    <Button
      key={index}
      name={name}
      index={index}
      active={activeState}
      handleActive={handleActive}
      setEndDate={setEndDate}
      text={button}
      scrollWrapper={scrollWrapper}
      latestData={latestData}
      data={data}
      periodState={periodState}
    ></Button>
  ))
  return (
    <Section data-testid="TimelineButtonSectionElement">
      <SideTitleWrapper>
        <Title>{title}</Title>
        <InformationTooltip
          paragraph={getTooltipParagraph()}
          size={InformationLogoSize.medium}
        />
      </SideTitleWrapper>
      <Buttons>{buttonsToRender}</Buttons>
    </Section>
  )
}
