import { faSearch, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { SubmitHandler, UseFormRegister, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
interface SearchProps {
  searchString: string
}
const SearchFieldWrapper = styled.form<{ focusState: boolean }>`
  display: flex;
  width: 26.25rem;
  height 2.5rem;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: var(--element-bg-dark);
   border: ${(props) =>
     props.focusState
       ? '0.0625rem solid var(--brand-primary-color)'
       : '0.0625rem solid rgba(0,0,0,0)'};
  box-shadow: ${(props) =>
    props.focusState
      ? '0rem 0rem 0rem 0.25rem var(--brand-primary-16-color)'
      : 'var(--text-lightest)'};
`
const SearchField = styled.input`
  width: 18rem;
  border: none;
  background-color: var(--element-bg-dark);
  color: var(--text-primary);
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  &:focus {
    outline: none;
  }
`

const SearchLogo = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--text-primary);
  margin-left: 1.25rem;
`
const DeleteLogo = styled(FontAwesomeIcon)`
  font-size: 1rem;
  font-weight: 900;
  color: inherit;
`
const DeleteButtonWrapper = styled.button`
  border: none;
  background: none;
  margin: 0 auto;
  padding: 0;
  &:disabled {
    opacity: 0;
    cursor: auto;
  }
  width: 1.75rem;
  cursor: pointer;
  color: var(--text-lighter);
`
const SearchButton = styled.input`
  padding: 0.75rem;
  text-transform: uppercase;
  border-radius: 0.375rem;
  border: 0.0625rem solid var(--text-primary);
  background-color: var(--text-primary);
  color: var(--white-color);
  font-size: 0.75rem;
  font-family: inter;
  font-weight: 600;
  height: 2.25rem;
  margin-right: 0.125rem;
  line-height: 0.6rem;
  cursor: pointer;
  &:disabled {
    border: 0.0625rem solid var(--element-bg-darkest);
    background-color: var(--element-bg-darkest);
    color: var(--white-color);
    cursor: default;
  }
`
interface SearchFieldComponentProps {
  submit: any
  register: UseFormRegister<{ searchString: string }>
  isDirty: boolean | undefined
  resetField: () => void
  focusState: boolean
  handleOnFocus: () => void
  handleOnBlur: () => void
}
const SearchFieldComponent = ({
  submit,
  register,
  isDirty,
  resetField,
  focusState,
  handleOnFocus,
  handleOnBlur
}: SearchFieldComponentProps) => {
  const { t } = useTranslation()
  useEffect(() => {
    if (!isDirty) {
      submit()
    }
  })
  return (
    <SearchFieldWrapper
      data-testid="FiltersForm"
      focusState={focusState}
      onSubmit={submit}
    >
      <SearchLogo icon={faSearch} />
      <SearchField
        {...register('searchString')}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        autoComplete="off"
        data-testid="SearchBarInput"
        required
      />
      <DeleteButtonWrapper
        type="reset"
        disabled={!isDirty}
        onClick={resetField}
        data-testid="clearFieldButton"
      >
        <DeleteLogo icon={faTimesCircle} />
      </DeleteButtonWrapper>
      <SearchButton
        type="submit"
        disabled={!isDirty}
        data-testid="submitButton"
        value={t('search')}
      ></SearchButton>
    </SearchFieldWrapper>
  )
}
interface SearchBarComponentProps {
  searchString: string
  passSearchString: (searchString: string) => void
}
export const SearchBarComponent = ({
  searchString,
  passSearchString
}: SearchBarComponentProps) => {
  const {
    register,
    resetField,
    formState: { isDirty },
    handleSubmit,
    getValues,
    setValue,
    trigger
  } = useForm<SearchProps>({
    mode: 'onChange',
    defaultValues: { searchString: '' }
  })
  const onSubmit: SubmitHandler<SearchProps> = (data) => onSearch(data)
  const [focusState, setFocusState] = useState<boolean>(false)

  useEffect(() => {
    setValue('searchString', searchString, {
      shouldDirty: true
    })
  }, [searchString, setValue, trigger])

  function handleOnFocus() {
    setFocusState(true)
  }
  function handleOnBlur() {
    setFocusState(isDirty)
  }
  const onSearch = (data: SearchProps) => {
    passSearchString(data.searchString)
  }
  function clearField() {
    resetField('searchString')
    passSearchString(getValues('searchString'))
  }
  return (
    <SearchFieldComponent
      submit={handleSubmit(onSubmit)}
      register={register}
      focusState={focusState}
      handleOnBlur={handleOnBlur}
      handleOnFocus={handleOnFocus}
      resetField={clearField}
      isDirty={isDirty}
    />
  )
}
