import {
  Population,
  PopulationType
} from '../../containers/ClinicPatients/tables/interfaces'
import config from '../config'
import { AxiosRequest } from './axios-request'

interface PopulationCreateRequest {
  name: string
  description: string
  type: PopulationType
}
export async function createPopulation(
  request: PopulationCreateRequest
): Promise<Population> {
  const response = await AxiosRequest.create().post(
    `${config.API_URL}/population`,
    request
  )
  return response.data
}
