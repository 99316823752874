import { faCalendar, faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { faArrowRightLong, faHistory } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { usePeriodContext } from '../../../core/contexts/period-context'
import dayjs from '../../../core/dayjs/dayjs'
import { Modal } from '../../Modal/Modal'
import { TimeSelector, timeDataObject } from '../../TimeSelector/TimeSelector'
import { PeriodObject } from '../DevicesAndPeriod/DevicesAndPeriod'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.125rem;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  border-right: 0.0625rem solid var(--text-lightest);
  color: var(--text-lighter);
  &:hover {
    color: var(--brand-primary-color);
    background-color: var(--element-bg);
  }
`
const Arrow = styled(FontAwesomeIcon)<{ $hovered: boolean }>`
  color: var(--brand-primary-color);
  font-size: 0.6875rem;
  font-weight: 900;
  display: ${(props) => (props.$hovered ? 'block' : 'none')};
`
const Title = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
`
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items center;
  justify-content: space-between;
`
const ButtonWrapper = styled.div`
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

const ButtonElement = styled.div<{ $hovered: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  background-color: ${(props) =>
    props.$hovered ? 'var(--white-color)' : 'var(--element-bg)'};
`
const ButtonElementIcon = styled(FontAwesomeIcon)`
  color: var(--text-primary);
  font-size: 0.6875rem;
`
const ButtonElementText = styled.span`
  color: var(--text-primary);
  font-size: 0.75rem;
  font-family: inter;
  font-weight: 600;
`

interface TimePeriodComponentProps {
  timeData: timeDataObject[] | null
  setPeriodState: Dispatch<SetStateAction<PeriodObject | null>>
  period: PeriodObject | null
  emptyPatient: boolean
}
export const TimePeriodComponent = ({
  timeData,
  setPeriodState,
  period,
  emptyPatient
}: TimePeriodComponentProps) => {
  const { t } = useTranslation()
  const { setPeriod } = usePeriodContext()
  const [showTimeSelector, setShowTimeSelector] = useState<boolean>(false)
  const [hovered, setHovered] = useState<boolean>(false)

  const handlePeriod = (state: PeriodObject) => {
    setPeriodState(state)
    setPeriod(state)
    setShowTimeSelector(!showTimeSelector)
  }

  return (
    <Wrapper
      data-testid="timeperiodcomponent"
      onClick={() => {
        setShowTimeSelector(true)
        setHovered(false)
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Modal isOpen={showTimeSelector}>
        <TimeSelector
          handleTimeSelector={() => setShowTimeSelector(false)}
          icon={faInfoCircle}
          handlePeriod={handlePeriod}
          period={emptyPatient ? null : period}
          timeData={timeData}
        ></TimeSelector>
      </Modal>
      <TitleWrapper>
        <Title>{t('Time Period')}</Title>
        <Arrow icon={faArrowRightLong} $hovered={hovered} />
      </TitleWrapper>

      <ButtonWrapper>
        <ButtonElement
          style={{
            borderRight: '0.0625rem solid var(--text-lightest)'
          }}
          $hovered={hovered}
        >
          <ButtonElementIcon icon={faHistory} />
          <ButtonElementText>
            {period !== null
              ? `${period.lengthOfPeriod} ${t('days')}`
              : t('no data')}
          </ButtonElementText>
        </ButtonElement>
        <ButtonElement $hovered={hovered}>
          <ButtonElementIcon icon={faCalendar} />
          <ButtonElementText data-testid="periodActiveDate">
            {period !== null
              ? `${
                  dayjs(period.endDate).isToday()
                    ? t('Today')
                    : dayjs(period.endDate).format('DD/MM/YYYY')
                }`
              : t('no data')}
          </ButtonElementText>
        </ButtonElement>
      </ButtonWrapper>
    </Wrapper>
  )
}
