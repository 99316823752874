import {
  faCalendarCircleUser as faCalendarCircleUserRegular,
  faClipboardListCheck as faClipboardListCheckRegular,
  faPuzzlePiece as faPuzzlePieceRegular,
  faUsers as faUsersRegular
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCalendarCircleUser as faCalendarCircleUserSolid,
  faClipboardListCheck as faClipboardListCheckSolid,
  faPuzzlePiece as faPuzzlePieceSolid,
  faUsers as faUsersSolid
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { ProviderManagementTypes } from '../../components/ProviderIntegrationModel/ProviderIntegrationModel'
import { IntegrationComponentStatus } from '../../components/ProviderIntegrationModel/StatusPage/IntegrationComponent/IntegrationComponent'
import { GetProviders } from '../../components/ProviderIntegrationModel/getProviders'
import {
  ProviderPatientsType,
  getProviderPlatformTitle
} from '../../components/ProviderPatients/ProviderPatients'
import {
  ItemObject,
  SideMenuComponent
} from '../../components/SideMenuComponent/SideMenuComponent'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { useClinicViewsContext } from '../../core/contexts/clinic-view-context'
import { ClinicViewDisplays } from '../../core/entities/clinic.entity'
import { convertProviderPatientsTypeToProviderPatientProviderType } from '../Integrations/Integrations'

export enum ProviderErrorType {
  email = 'email',
  login = 'login',
  requestpatients = 'requestpatients',
  requestdata = 'requestdata'
}
export interface ProviderObject {
  description: string
  error: ProviderErrorType | null
  errorMessage: string | null
  latestDataRefresh: string | null
  latestPatientsRefresh: string | null
  id: string
  name: string
  state: IntegrationComponentStatus
  type: ProviderPatientsType
  providerType: ProviderManagementTypes
  username: string
  email?: string
  emailHost?: string
  clientId?: string
  clientSecret?: string
}
const ClinicIntegrationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  min-width: 1700px;
`
const PageWrapper = styled.div`
  max-width: 1500px;
  width: 100%;
  margin-right: 2rem;
`

export const ClinicIntegrations = () => {
  const { t } = useTranslation()
  const clinicViews = useClinicViewsContext()
  const { providers, providersError } = GetProviders()

  if (providersError !== null) {
    return (
      <div data-testid="clinicIntegrationsError">
        <p>{t('Error')}</p>
      </div>
    )
  }
  if (providers === null)
    return (
      <div data-testid="clinicIntegrationsLoading">
        <Spinner spinnersize={spinnerSize.large} />
      </div>
    )
  else {
    const data: Array<ItemObject> = [
      {
        icon: {
          regular: faClipboardListCheckRegular,
          solid: faClipboardListCheckSolid
        },
        alias: t('Integration To Do'),
        info: '',
        to: 'to-do',
        show: clinicViews.Integrations.subViews.includes(
          ClinicViewDisplays.IntegrationTODO
        )
      },
      {
        icon: {
          regular: faCalendarCircleUserRegular,
          solid: faCalendarCircleUserSolid
        },
        alias: t('LibreView Consultations'),
        info: '',
        to: 'libreview-consultations',
        show: clinicViews.Integrations.subViews.includes(
          ClinicViewDisplays.LibreViewConsultations
        )
      },
      {
        icon: { regular: faUsersRegular, solid: faUsersSolid },
        alias: t('Provider Patients'),
        info: '',
        to: 'provider-patients',
        show: clinicViews.Integrations.subViews.includes(
          ClinicViewDisplays.ProviderPatients
        ),
        children: providers
          .filter((e) => e.state !== IntegrationComponentStatus.deleted)
          .map((provider: ProviderObject) => {
            return {
              alias: provider.name,
              info: t(
                getProviderPlatformTitle(
                  convertProviderPatientsTypeToProviderPatientProviderType(
                    provider.type
                  )
                )
              ),
              to: `provider-patients/${provider.id}`,
              show: true
            }
          })
      },
      {
        icon: { regular: faPuzzlePieceRegular, solid: faPuzzlePieceSolid },
        alias: t('Provider Integrations'),
        info: '',
        to: 'provider-integrations',
        show: clinicViews.Integrations.subViews.includes(
          ClinicViewDisplays.ProviderIntegrations
        )
      }
    ]

    return (
      <ClinicIntegrationsWrapper data-testid="ClinicIntegrations">
        <SideMenuComponent items={data} />
        <PageWrapper>
          <Outlet />
        </PageWrapper>
      </ClinicIntegrationsWrapper>
    )
  }
}
