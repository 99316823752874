import { useTranslation } from 'react-i18next'
import {
  FullTitleComponent,
  TitleComponent,
  TitleWrapperComponent,
  getInfoLogo
} from '../../Shared/Header/Header'
import { SizeEnum, WidgetState } from '../../Shared/Interfaces/Interfaces'

interface HeaderProps {
  size: SizeEnum
  state: WidgetState
}

export const Header = ({ size, state }: HeaderProps) => {
  const { t } = useTranslation()
  return (
    <FullTitleComponent $size={SizeEnum.Small}>
      <TitleWrapperComponent>
        <TitleComponent>
          {size === SizeEnum.Small
            ? t('Time In Automation')
            : t('Avg. Daily Time In Automation')}
        </TitleComponent>
        {getInfoLogo(
          [
            {
              title: t('Time In Automation'),
              body: t('Daily average of time spent in automation.')
            }
          ],
          state
        )}
      </TitleWrapperComponent>
    </FullTitleComponent>
  )
}
