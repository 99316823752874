import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import { NovoPenObject } from '../../../core/api/getDoseRecommendations'
import { useSessionContext } from '../../../core/contexts/session-context'
import dayjs from '../../../core/dayjs/dayjs'
import { UserType } from '../../../core/entities/user.entity'
import {
  InformationLogoSize,
  InformationTooltip
} from '../../InformationTooltip/InformationTooltip'
import { Modal } from '../../Modal/Modal'
import { FindDeviceId } from '../../SmartPenInfo/FindDeviceId/FindDeviceId'
import {
  getMedicineString,
  MedicineTag
} from '../../SmartPenInfo/Medicine/Medicine'
import { MorePenInfo } from '../../SmartPenInfo/MorePenInfo/MorePenInfo'
import { PenImage, PenImageSize } from '../../SmartPenInfo/SmartPenInfo'
import { Spinner, spinnerSize } from '../../Spinner/Spinner'
const Wrapper = styled.div<{ $isPatient: boolean }>`
  padding: 0.5rem;
  background-color: var(--element-bg);
  width: ${(props) => (props.$isPatient ? '50%' : '100%')};
  border-radius: 1rem;
  height: 100%;
  box-sizing: border-box;
`
const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 33%;
`

const PenInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  padding: 0.5rem;
  width: 100%;
`

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
`

const MiddleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`

const Key = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-lighter);
  line-height: 1.5rem;
`

const Value = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-primary);
  line-height: 1.5rem;
`

const StretchedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`

const Button = styled.div`
  display: flex;
  height: 2.25rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.75rem;
  background-color: var(--white-color);
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  bottom: 0.6125rem;
  left: 0.6125rem;
  color: var(--text-primary);
  &:hover {
    background-color: var(--blue);
    color: var(--white-color);
  }
`
const InfoIcon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 0.875rem;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const Title = styled.span`
  font-family: inter;
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--text-primary);
  line-height: 1.5rem;
`
const TitleIconWrapper = styled.div`
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  align-items: center;
`
const QuestionIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: var(--brand-primary-color);
`

export const PenSection = ({
  smartPen,
  isPatient
}: {
  smartPen: NovoPenObject | null
  isPatient: boolean
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false)
  const [showFindDeviceId, setShowFindDeviceId] = useState<boolean>(false)
  const { user } = useSessionContext()
  const { t } = useTranslation()
  return (
    <Wrapper $isPatient={isPatient}>
      {smartPen === null ? (
        <Spinner spinnersize={spinnerSize.large}></Spinner>
      ) : (
        <>
          <Modal isOpen={showMoreInfo}>
            <MorePenInfo
              closeFun={() => setShowMoreInfo(false)}
              smartPen={smartPen}
            ></MorePenInfo>
          </Modal>
          <Modal isOpen={showFindDeviceId}>
            <FindDeviceId
              closeFun={() => setShowFindDeviceId(false)}
            ></FindDeviceId>
          </Modal>
          <Container>
            {PenImage(smartPen.type, PenImageSize.small)}
            <PenInfo>
              <TopSection>
                <StretchedRow>
                  <TitleWrapper>
                    <Title>{smartPen.name}</Title>
                    <InformationTooltip
                      paragraph={[
                        {
                          title: t('Device name'),
                          body: t(
                            user?.type === UserType.Patient
                              ? 'The name of the NovoPen set by you.'
                              : 'The name of the NovoPen set by the patient.'
                          )
                        }
                      ]}
                      size={InformationLogoSize.medium}
                      logoStyling={{ color: 'var(--brand-primary-color)' }}
                    />
                  </TitleWrapper>
                  <Row>
                    <Button onClick={() => setShowMoreInfo(true)}>
                      <InfoIcon icon={faInfoCircle} />
                    </Button>
                  </Row>
                </StretchedRow>
              </TopSection>

              <BottomSection>
                <Column>
                  <Key>{t('Device ID')}</Key>
                  <Row>
                    <Value>{smartPen.hardwareId}</Value>
                    <TitleIconWrapper
                      data-testid="smartPenShowFindDeviceId"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowFindDeviceId(true)}
                    >
                      <QuestionIcon icon={faCircleQuestion} />
                    </TitleIconWrapper>
                  </Row>
                </Column>
                <Column>
                  <Key>{t('Last connection')}</Key>
                  <Row>
                    <Value>
                      {dayjs(smartPen.lastConnectionZoned).fromNow()}
                    </Value>
                  </Row>
                </Column>
              </BottomSection>

              <MiddleSection>
                <Column>
                  <Key>{t('Medicine')}</Key>
                  <TitleWrapper>
                    <MedicineTag medicineType={smartPen.type} />
                    <Value>{getMedicineString(smartPen.type)}</Value>
                  </TitleWrapper>
                </Column>
              </MiddleSection>
            </PenInfo>
          </Container>
        </>
      )}
    </Wrapper>
  )
}
