import { Dispatch, SetStateAction, createContext, useContext } from 'react'

type BlurContextState = {
  blur: boolean
  setBlur: Dispatch<SetStateAction<boolean>>
}

export const BlurContext = createContext<BlurContextState>({
  blur: false,
  setBlur: () => null
})

export function useBlurContext() {
  return useContext(BlurContext)
}

export default BlurContext
