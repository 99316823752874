import config from '../config'
import { AxiosRequest } from './axios-request'

interface FitbitApiProviderProps {
  name: string
  clientId: string
  clientSecret: string
}

export async function fitbitApiProvider(data: FitbitApiProviderProps) {
  return await AxiosRequest.create().post(
    `${config.API_URL}/cmi/providers/fitbitapiproviders`,
    { ...data, description: '' }
  )
}
