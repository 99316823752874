import { ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import { FlexColumnWrapper } from '../../../FlexWrappers/FlexColumnWrapper'
import { FlexRowWrapper } from '../../../FlexWrappers/FlexRowWrapper'
import { SizeEnum, WidgetState } from '../Interfaces/Interfaces'

interface ColorAndValueProps {
  color: string
  width: number
}

interface BarValueProps {
  state: WidgetState
  value: string
  color: string
  size: SizeEnum
}

interface BarComponentProps {
  size: SizeEnum
  color: string
  percentage: number | null
  state: WidgetState
  children?: ReactNode
}

export interface BarAndValueProps {
  size: SizeEnum
  state: WidgetState
  color: string
  percentage: number | null
}
export const barsGrowAnimation = () => keyframes`
0% { transform: translateX(-100%);}
100% { transform: translateX(0%);}
`

export const showValuesAnimation = () => keyframes`
  0%  {transform: translateX(1rem); opacity: 0;}
  100%  {transform: translateX(0rem); opacity: 1;}
`

export const Percentage = styled.span<{ $size: SizeEnum }>`
  font-family: inter;
  font-weight: 700;
  font-size: ${(props) =>
    props.$size === SizeEnum.Medium ? '0.75' : '0.625'}rem;
  color: var(--text-lighter);
  line-height: ${(props) =>
    props.$size === SizeEnum.Medium ? '1' : '0.75'}rem;
`
export const Value = styled.span<{ $size: SizeEnum }>`
  font-family: inter;
  font-weight: 700;
  font-size: ${(props) => (props.$size === SizeEnum.Medium ? '1.25' : '1')}rem;
  color: ${(props) => props.color};
  line-height: ${(props) =>
    props.$size === SizeEnum.Medium ? '1.25' : '0.75'}rem;
`

export const ValueComponent = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.125rem;
  &.showValues {
    animation-name: ${showValuesAnimation};
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-delay: 0.3s;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`
export const BarsColorComponent = styled.div<ColorAndValueProps>`
  width: ${(props) =>
    props.width > 0 ? 'calc(' + props.width + '% + 0.125rem)' : '0'};
  transform: ${(props) =>
    props.width > 0 ? 'translateX(-100%)' : 'translateX(0%)'};
  height: calc(100% + 0.125rem);
  background-color: ${(props) => props.color};
  position: absolute;
  bottom: -0.0625rem;
  left: -0.0625rem;
  border-radius: 0.125rem;
  &.showBars {
    animation-name: ${barsGrowAnimation};
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-delay: 0.3s;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`
export const BarWrapper = styled.div`
  position: relative;
`

export const BarsAndValuesComponent = styled(FlexRowWrapper)<{
  $size: SizeEnum
}>`
  width: 100%;
  gap: 0.75rem;
  justify-content: space-between;
  align-items: center;
  height: ${(props) =>
    props.$size === SizeEnum.Medium ? '1.25rem' : '0.75rem'};
`
export const BarsComponent = styled(FlexColumnWrapper)<{ $size: SizeEnum }>`
  gap: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.125rem;
  background-color: var(--element-bg);
  width: 14.375rem;
  height: ${(props) =>
    props.$size === SizeEnum.Medium ? '1.125' : '0.625'}rem;
  position: relative;
  overflow: hidden;
`

export const BarValue = ({ state, value, color, size }: BarValueProps) => {
  return (
    <ValueComponent
      className={state === WidgetState.Loading ? '' : 'showValues'}
    >
      <Value data-testid="widgetBarValue" $size={size} color={color}>
        {value}
      </Value>
      <Percentage $size={size}>%</Percentage>
    </ValueComponent>
  )
}

export const BarComponent = ({
  size,
  color,
  percentage,
  state,
  children
}: BarComponentProps) => {
  return (
    <BarWrapper>
      <BarsComponent $size={size}>
        <BarsColorComponent
          color={color}
          width={percentage ?? 0}
          className={state === WidgetState.Loading ? '' : 'showBars'}
        ></BarsColorComponent>
        {children}
      </BarsComponent>
    </BarWrapper>
  )
}

export const BarAndValue = ({
  size,
  state,
  color,
  percentage
}: BarAndValueProps) => {
  const getPercentage = () => {
    return percentage !== null
      ? formatNumber({ value: percentage, decimals: 0 })
      : '--'
  }
  const isDataState = state === WidgetState.Data
  return (
    <BarsAndValuesComponent $size={size}>
      <BarComponent
        size={size}
        color={color}
        percentage={percentage ?? 0}
        state={state}
      ></BarComponent>
      <BarValue
        value={getPercentage()}
        state={state}
        color={isDataState ? color : 'var(--text-lightest)'}
        size={size}
      />
    </BarsAndValuesComponent>
  )
}
