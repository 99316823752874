import styled from 'styled-components'
import { FlexRowWrapper } from '../../../FlexWrappers/FlexRowWrapper'
import { TimeInRangeConfig } from '../TimeInRange'

interface ColorCssProps {
  $color: string
}

const TimeInRangeRangesComponent = styled(FlexRowWrapper)`
  gap: 0.5rem;
`
const TimeInRangeFrameComponent = styled(FlexRowWrapper)<ColorCssProps>`
  box-sizing: border-box;
  padding: 0rem 0rem 0.25rem;
  gap: 0.625rem;
  border-bottom: 0.125rem solid ${(props) => props.$color};
  font-family: inter;
  font-size: 0.625rem;
  font-weight: 700;
  color: var(--text-lighter);
  white-space: nowrap;
`
export const Legend = () => {
  return (
    <TimeInRangeRangesComponent>
      <TimeInRangeFrameComponent $color={TimeInRangeConfig.veryLow.color}>
        {TimeInRangeConfig.veryLow.name}
      </TimeInRangeFrameComponent>
      <TimeInRangeFrameComponent $color={TimeInRangeConfig.low.color}>
        {TimeInRangeConfig.low.name}
      </TimeInRangeFrameComponent>
      <TimeInRangeFrameComponent $color={TimeInRangeConfig.inRange.color}>
        {TimeInRangeConfig.inRange.name}
      </TimeInRangeFrameComponent>
      <TimeInRangeFrameComponent $color={TimeInRangeConfig.high.color}>
        {TimeInRangeConfig.high.name}
      </TimeInRangeFrameComponent>
      <TimeInRangeFrameComponent $color={TimeInRangeConfig.veryHigh.color}>
        {TimeInRangeConfig.veryHigh.name}
      </TimeInRangeFrameComponent>
    </TimeInRangeRangesComponent>
  )
}
