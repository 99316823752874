interface formatNumberProps {
  value: number
  decimals: number
}
export const formatNumber = ({ value, decimals }: formatNumberProps) => {
  return new Intl.NumberFormat('da-DK', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value)
}
