import { faBattery } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { CSSProperties } from 'styled-components'
import { NovoPenBatteryStatus } from '../../Graphs/Insights/interfaces'

const Battery = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
`
const TitleIconWrapper = styled.div`
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  align-items: center;
`
export const BatteryIcon = ({
  batteryStatus
}: {
  batteryStatus: NovoPenBatteryStatus
}) => {
  switch (batteryStatus) {
    case NovoPenBatteryStatus.Ok:
      return (
        <TitleIconWrapper>
          <Battery
            icon={faBattery}
            style={
              {
                '--fa-primary-color': 'var(--green-darker)',
                '--fa-secondary-color': 'var(--text-medium)',
                '--fa-secondary-opacity': '1'
              } as CSSProperties
            }
          ></Battery>
        </TitleIconWrapper>
      )
  }
}
