import { HandleProviderEditData } from '../../components/ProviderIntegrationModel/ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function editAbbottLibreviewProvider(
  data: HandleProviderEditData,
  providerId: string
) {
  return await AxiosRequest.create().put(
    `${config.API_URL}/cmi/providers/abbottlibreviewwebproviders/${providerId}`,
    { ...data, description: '' }
  )
}
