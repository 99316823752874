import config from '../config'
import { AxiosRequest } from './axios-request'

interface MedtronicCarelinkProviderProps {
  name: string
  username: string
  password: string
  email?: string
  emailHost?: string
  emailPassword?: string
}

export async function medtronicCarelinkProvider(
  data: MedtronicCarelinkProviderProps
) {
  return await AxiosRequest.create().post(
    `${config.API_URL}/cmi/providers/medtroniccarelinkwebproviders`,
    { ...data, description: '' }
  )
}
