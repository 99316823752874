import { createContext, Dispatch, SetStateAction, useContext } from 'react'

type PatientLoadingContextState = {
  patientLoading: boolean
  setPatientLoading: Dispatch<SetStateAction<boolean>>
}

export const PatientLoadingContext = createContext<PatientLoadingContextState>({
  patientLoading: false,
  setPatientLoading: () => null
})

export function usePatientLoadingContext() {
  return useContext(PatientLoadingContext)
}

export default PatientLoadingContext
