export enum glucoseType {
  default,
  darker
}
export const glucoseRanges = (value: number, type: glucoseType) => {
  if (value < 3.0)
    return type === glucoseType.darker
      ? 'var(--veryLow-darker-color)'
      : 'var(--veryLow-color)'
  if (value >= 3.0 && value < 3.9)
    return type === glucoseType.darker
      ? 'var(--low-darker-color)'
      : 'var(--low-color)'
  if (value >= 3.9 && value <= 10)
    return type === glucoseType.darker
      ? 'var(--inRange-darker-color)'
      : 'var(--inRange-color)'
  if (value > 10.0 && value <= 13.9)
    return type === glucoseType.darker
      ? 'var(--high-darker-color)'
      : 'var(--high-color)'
  else
    return type === glucoseType.darker
      ? 'var(--veryHigh-darker-color)'
      : 'var(--veryHigh-color)'
}
