import { ReactNode } from 'react'
import styled from 'styled-components'
import { FlexRowWrapper } from '../../../FlexWrappers/FlexRowWrapper'

interface ColorCssProps {
  $color: string
}
export const RangesComponent = styled(FlexRowWrapper)`
  gap: 0.5rem;
`

export const LegendComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.375rem 0rem 0rem;
  border-top: none;
  width: 14.375rem;
`
export const FrameComponent = styled(FlexRowWrapper)<ColorCssProps>`
  box-sizing: border-box;
  padding: 0rem 0rem 0.25rem;
  gap: 0.25rem;
  border-bottom: 0.125rem solid ${(props) => props.$color};
  white-space: nowrap;
`

export const FrameTextComponent = styled.div`
  font-family: inter;
  font-weight: 700;
  font-size: 0.625rem;
  color: var(--text-lighter);
`

export const FrameUnitComponent = styled(FrameTextComponent)<ColorCssProps>`
  color: ${(props) => props.$color};
  display: flex;
  flex-direction: row;
`
interface LegendEntryProps {
  value: string
  color: string
  label: string
  children?: ReactNode
}
export const LegendEntry = ({
  value,
  color,
  label,
  children
}: LegendEntryProps) => {
  return (
    <FrameComponent $color={color} data-testid="legendEntry">
      <FrameTextComponent>{label}</FrameTextComponent>
      <FrameUnitComponent
        $color={value !== '-- U' ? color : 'var(--text-lightest)'}
      >
        {value}
        {children}
      </FrameUnitComponent>
    </FrameComponent>
  )
}
