import {
  AuditLogData,
  AuditLogFilters
} from '../../containers/AuditLog/AuditLog'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getAuditLog(
  filters: AuditLogFilters
): Promise<Array<AuditLogData>> {
  const data = await AxiosRequest.create().post(
    config.API_URL + '/auditlog',
    filters
  )
  return data.data as Array<AuditLogData>
}
