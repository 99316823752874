import { getTimeInRangePercentages } from '../containers/Overview/Overview'
import {
  PumpActivity,
  TimeInAutomationObject,
  TimeInRangeObject,
  TotalInsulinObject
} from '../containers/StackedDaily/Interfaces/Interfaces'

interface GetBarsProps {
  activity: null | PumpActivity
  automation: null | TimeInAutomationObject
  timeInRange: null | TimeInRangeObject
  totalInsulin: null | TotalInsulinObject
  maxTotalInsulin: number | null
  widgetStack: boolean
}
export const getBars = ({
  activity,
  automation,
  timeInRange,
  totalInsulin,
  maxTotalInsulin,
  widgetStack
}: GetBarsProps) => {
  return {
    ActivityBars: activity
      ? {
          exercise: {
            percentage: Math.round(activity.exercise * 100),
            value: activity.timeInExercise
          },
          sleep: {
            percentage: Math.round(activity.sleep * 100),
            value: activity.timeInSleep
          }
        }
      : null,
    AutomationBars: automation
      ? {
          automation: {
            percentage: Math.round(automation.automation * 100),
            value: automation.timeInAutomation
          },
          manual: {
            percentage: Math.round(automation.manual * 100),
            value: automation.timeInManual
          }
        }
      : null,
    timeInRangeBars: getTimeInRangePercentages(timeInRange ?? null),
    TotalInsulinBars: totalInsulin
      ? widgetStack
        ? {
            basal: {
              percentage: Math.round(totalInsulin.basalRelative * 100),
              value: Math.round(totalInsulin.basalTotal),
              automated:
                totalInsulin.basalAutoPercentage !== null
                  ? totalInsulin.basalAutoPercentage *
                    totalInsulin.basalRelative *
                    100
                  : null,
              autoPercentage:
                totalInsulin.basalAutoPercentage !== null
                  ? Math.round(totalInsulin.basalAutoPercentage * 100)
                  : null
            },
            bolus: {
              percentage: Math.round(totalInsulin.bolusRelative * 100),
              value: Math.round(totalInsulin.bolusTotal),
              automated:
                totalInsulin.bolusAutoPercentage !== null
                  ? totalInsulin.bolusAutoPercentage *
                    totalInsulin.bolusRelative *
                    100
                  : null,
              autoPercentage:
                totalInsulin.bolusAutoPercentage !== null
                  ? Math.round(totalInsulin.bolusAutoPercentage * 100)
                  : null
            }
          }
        : {
            basal: {
              percentage:
                (totalInsulin.basalTotal / (maxTotalInsulin ?? 1)) * 100,
              relativePercentage: Math.round(totalInsulin.basalRelative * 100),
              value: Math.round(totalInsulin.basalTotal),
              automated:
                (totalInsulin.basalAutomated / (maxTotalInsulin ?? 1)) * 100,
              autoPercentage:
                totalInsulin.basalAutoPercentage !== null
                  ? totalInsulin.basalAutoPercentage * 100
                  : null
            },
            bolus: {
              percentage:
                (totalInsulin.bolusTotal / (maxTotalInsulin ?? 1)) * 100,
              relativePercentage: Math.round(totalInsulin.bolusRelative * 100),
              value: Math.round(totalInsulin.bolusTotal),
              automated:
                (totalInsulin.bolusAutomated / (maxTotalInsulin ?? 1)) * 100,
              autoPercentage:
                totalInsulin.bolusAutoPercentage !== null
                  ? totalInsulin.bolusAutoPercentage * 100
                  : null
            }
          }
      : null
  }
}
