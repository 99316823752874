import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  glucoseRanges,
  glucoseType
} from '../../constants/ranges/glucoseRanges'
import { formatNumber } from '../../helpers/helpers'
import {
  getInfoLogo,
  TitleComponent,
  TitleWrapperComponent
} from '../DataWidget/Shared/Header/Header'
import { WidgetState } from '../DataWidget/Shared/Interfaces/Interfaces'
import { getInformationTooltipParagraph } from '../InformationTooltip/InformationTooltipParagraphs'

export enum CgmEnum {
  std = 'std',
  avg = 'avg',
  cv = 'cv'
}

interface CGMValues {
  avg: number | null
  std: { stdDev: number; lower: number; upper: number } | null
  state: WidgetState
}
interface CGMValuesProps {
  avg: number | null
  std: { stdDev: number; lower: number; upper: number } | null
  state: WidgetState
  cv: number | null
}
interface WidgetProps {
  type: CgmEnum
  values: CGMValues
}
interface LineProps {
  length: number
  color: string
}
interface IndicatorWrapperProps {
  value: number
}
interface IndicatorProps {
  color: string
}
interface CvComponentProps {
  type: CgmEnum
  value: number | null
  state: WidgetState
}

const CGMWrapper = styled.div`
  width: 18.25rem;
  padding: 0.75rem 1rem 1rem;
  background-color: var(--white-color);
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
`
const CGMSeperator = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  height: 0.0625rem;
  background-color: var(--text-lightest);
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TotalUnit = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-family: inter;
  font-weight: 700;
  align-items: center;
`
export const Total = styled.div<IndicatorProps>`
  font-size: 1rem;
  color: ${(props) => props.color};
`
export const Unit = styled.div`
  font-size: 0.625rem;
  color: var(--text-lighter);
`
const VisualIndicator = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0rem;
  position: relative;
`
const VisualIndicatorHidden = styled.div`
  overflow: hidden;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  border-radius: 0.125rem;
`
const VisualLine = styled.div<LineProps>`
  width: ${(props) => 'calc((' + props.length + ' / 20) * 100%)'};
  background-color: ${(props) => props.color};
  height: 0.125rem;
`
const IndicatorWrapper = styled.div<IndicatorWrapperProps>`
  background-color: var(--white-color);
  width: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -0.4375rem;
  left: ${(props) => 'calc((' + props.value + ' / 20) * 100%)'};
  height: 0.75rem;
`

const Indicator = styled.div<IndicatorProps>`
  background-color: ${(props) => props.color};
  height: 100%;
  border-radius: 0.125rem;
  width: 0.125rem;
`
const WidgetComponent = ({ type, values }: WidgetProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <TitleWrapper>
        <TitleWrapperComponent>
          <TitleComponent>
            {t(
              type === CgmEnum.std
                ? 'Std. Deviation (CGM)'
                : 'Avg. Glucose (CGM)'
            )}
          </TitleComponent>
          {getInfoLogo(getInformationTooltipParagraph(type, t), values.state)}
        </TitleWrapperComponent>
        <TotalUnit>
          <Total
            color={
              type === CgmEnum.avg
                ? values.avg
                  ? glucoseRanges(values.avg, glucoseType.default)
                  : 'var(--text-lightest)'
                : values.std
                ? 'var(--text-primary)'
                : 'var(--text-lightest)'
            }
          >
            {type === CgmEnum.avg
              ? values.avg
                ? `${formatNumber({ value: values.avg, decimals: 1 })}`
                : '--'
              : values.std
              ? formatNumber({ value: values.std.stdDev, decimals: 1 })
              : '--'}
          </Total>
          <Unit>mmol/L</Unit>
        </TotalUnit>
      </TitleWrapper>
      <VisualIndicator>
        <VisualIndicatorHidden>
          {!values.std || !values.avg ? (
            <VisualLine length={20} color={'var(--text-lightest)'} />
          ) : (
            <>
              <VisualLine length={3.9} color={'var(--low-color)'} />
              <VisualLine length={6.1} color={'var(--inRange-color)'} />
              <VisualLine length={10} color={'var(--high-color)'} />
            </>
          )}
        </VisualIndicatorHidden>
        {type === CgmEnum.avg ? (
          values.avg ? (
            <IndicatorWrapper value={values.avg}>
              <Indicator
                color={glucoseRanges(values.avg, glucoseType.default)}
              />
            </IndicatorWrapper>
          ) : (
            <></>
          )
        ) : values.std ? (
          <>
            <IndicatorWrapper value={values.std.lower}>
              <Indicator
                color={glucoseRanges(values.std.lower, glucoseType.default)}
              />
            </IndicatorWrapper>
            <IndicatorWrapper value={values.std.upper}>
              <Indicator
                color={glucoseRanges(values.std.upper, glucoseType.default)}
              />
            </IndicatorWrapper>
          </>
        ) : (
          <></>
        )}
      </VisualIndicator>
    </Wrapper>
  )
}
const CvComponent = ({ type, value, state }: CvComponentProps) => {
  const { t } = useTranslation()
  return (
    <TitleWrapper>
      <TitleWrapperComponent>
        <TitleComponent>CV (CGM)</TitleComponent>
        {getInfoLogo(getInformationTooltipParagraph(type, t), state)}
      </TitleWrapperComponent>
      <TotalUnit>
        <Total color={value ? 'var(--text-primary)' : 'var(--text-lightest)'}>
          {value ?? '--'}
        </Total>
        <Unit>%</Unit>
      </TotalUnit>
    </TitleWrapper>
  )
}
export const CGMWidget = ({ std, avg, cv, state }: CGMValuesProps) => {
  const getActualState = () => {
    if (state === WidgetState.Loading) {
      return state
    } else {
      if (avg === null && std === null && cv === null) {
        return WidgetState.Empty
      } else return WidgetState.Data
    }
  }
  const actualState = getActualState()

  return (
    <CGMWrapper>
      <WidgetComponent
        type={CgmEnum.avg}
        values={{ std, avg, state: actualState }}
      />
      <CGMSeperator />
      <WidgetComponent
        type={CgmEnum.std}
        values={{ std, avg, state: actualState }}
      />
      <CGMSeperator />
      <CvComponent type={CgmEnum.cv} value={cv} state={actualState} />
    </CGMWrapper>
  )
}
