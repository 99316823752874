import { useKeycloak } from '@react-keycloak/web'
import { KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js'
import { useEffect, useState } from 'react'
import { getCurrentClinic } from '../api/get-current-clinic-api'
import { getCurrentUser } from '../api/get-current-user-api'
import { Clinic } from '../entities/clinic.entity'
import { User, UserType } from '../entities/user.entity'

export function useSession() {
  const { keycloak } = useKeycloak()
  const [user, setUser] = useState<User | null>(null)
  const [clinic, setClinic] = useState<Clinic | null>(null)
  const [userError, setUserError] = useState(false)
  const [clinicError, setClinicError] = useState(false)
  const isAuthenticated = keycloak?.authenticated || false

  useEffect(() => {
    if (isAuthenticated) {
      const controller = new AbortController()
      let tempUser: User | null = null
      const getUserClinicWards = async () => {
        try {
          tempUser = await getCurrentUser({ signal: controller.signal })
          // POR-146: Force an empty array for wards as temporary fix
          // TODO Remove this to make it work properly
          tempUser.wards = []
          // TODO Remove comments to make it work
          // if (tempUser?.wards === null) {
          //   const wards = await getWards({ signal: controller.signal })
          //   tempUser = { ...tempUser, wards }
          // }
          setUser(tempUser)
        } catch (e) {
          console.log(e)
          setUserError(true)
        }
        if (tempUser?.type !== UserType.Patient) {
          try {
            const clinic = await getCurrentClinic({ signal: controller.signal })
            setClinic(clinic)
          } catch (e) {
            console.log(e)
            setClinicError(true)
          }
        }
      }
      getUserClinicWards()
      return () => controller.abort()
    }
  }, [isAuthenticated])

  return {
    isAuthenticated,
    user,
    userError,
    clinic,
    clinicError,
    login: async (options?: KeycloakLoginOptions) => {
      await keycloak.login(options)
    },
    logout: async (options?: KeycloakLogoutOptions) => {
      await keycloak.logout(options)
    }
  }
}
