import { faUsersMedical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CreatePopulationModal } from '../CreatePopulationModal/CreatePopulationModal'
import { Modal } from '../Modal/Modal'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`
const CreatePatientButton = styled.div`
  border-radius: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  background-color: var(--blue);
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const CreatePatientIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--white-color);
`
const CreatePatientText = styled.span`
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--white-color);
`
export const CreatePopulation = ({
  refresh,
  updateContext
}: {
  refresh: () => void
  updateContext: () => void
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Modal isOpen={showModal}>
        <CreatePopulationModal
          closeModal={() => setShowModal(false)}
          refresh={refresh}
          updateContext={updateContext}
        />
      </Modal>
      <CreatePatientButton
        onClick={() => setShowModal(true)}
        data-testid="createPopulation"
      >
        <CreatePatientIcon icon={faUsersMedical} />
        <CreatePatientText>{t('New population')}</CreatePatientText>
      </CreatePatientButton>
    </Wrapper>
  )
}
