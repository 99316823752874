import {
  faCircleSmall,
  faLaptop,
  faMobile,
  faScrewdriverWrench
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import styled from 'styled-components'
import {
  StatusBadge,
  StatusBadgeState
} from '../../../components/StatusBadge/StatusBadge'
import { useSessionContext } from '../../../core/contexts/session-context'
import dayjs from '../../../core/dayjs/dayjs'
import { UserType } from '../../../core/entities/user.entity'
import { PatientUploaderPings, UploaderPlatform } from '../PatientInfo'
import { SectionComponent } from '../SectionComponent/SectionComponent'

interface UploaderInformationProps {
  uploaderPings: PatientUploaderPings[] | null
}

const Field = styled.div`
  display: flex;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 0.0625rem solid var(--text-lightest);
  background: var(--white-color);
`
const Label = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
`
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`
const Seperator = styled(FontAwesomeIcon)`
  color: var(--text-lightest);
  font-size: 0.5rem;
`
const LabelIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: var(--text-primary);
`
const LabelLight = styled(Label)`
  color: var(--text-lighter);
`
const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
`

interface AppPingProps {
  ping: PatientUploaderPings
}
const AppPing = ({ ping }: AppPingProps) => {
  const config = getPlatformInfo(ping.uploaderPlatform)
  return (
    <Field>
      <Label>{ping.productType}</Label>
      <Content>
        <StatusBadge state={StatusBadgeState.success} text={t('installed')} />
        <Seperator icon={faCircleSmall} />
        <SectionRow>
          <LabelIcon icon={config.icon}></LabelIcon>
          <Label>{config.text}</Label>
        </SectionRow>
        <Label>{ping.uploaderVersion}</Label>
        <Seperator icon={faCircleSmall} />
        <SectionRow>
          <LabelLight>{t('Latest data')}</LabelLight>
          <Label>{dayjs(ping.latestAccess).fromNow()}</Label>
        </SectionRow>
      </Content>
    </Field>
  )
}
const getPlatformInfo = (platform: UploaderPlatform) => {
  switch (platform) {
    case UploaderPlatform.darwin:
      return { icon: faLaptop, text: 'Mac OS' }
    case UploaderPlatform.win32:
      return { icon: faLaptop, text: 'Windows' }
    case UploaderPlatform.ios:
      return { icon: faMobile, text: 'iOS' }
  }
}
export const AppsInformation = ({
  uploaderPings
}: UploaderInformationProps) => {
  const { user } = useSessionContext()

  return (
    <SectionComponent
      title={t(user?.type === UserType.Patient ? 'My Apps' : 'Apps')}
      icon={faScrewdriverWrench}
    >
      {uploaderPings !== null && uploaderPings.length !== 0 ? (
        uploaderPings.map((ping) => <AppPing ping={ping} />)
      ) : (
        <div>No apps</div>
      )}
    </SectionComponent>
  )
}
