import axios from 'axios'
import config from '../config'

export interface GetDeploymentTextProps {
  language: string
  id: string
}
export async function getDeploymentText({
  language,
  id
}: GetDeploymentTextProps): Promise<string> {
  const response = await axios
    .create()
    .get(`${config.API_URL}/deployment/text?language=${language}&id=${id}`)
  return response.data
}
