import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import {
  InformationLogoSize,
  InformationTooltip
} from '../../../InformationTooltip/InformationTooltip'
import { Spinner, spinnerSize } from '../../../Spinner/Spinner'
import { SizeEnum, WidgetState } from '../Interfaces/Interfaces'

export interface Size {
  $size: SizeEnum
}
export const LogoComponent = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  color: var(--veryHigh-darker-color);
`
export const FullTitleComponent = styled.div<Size>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) =>
    props.$size === SizeEnum.Small ? '0rem' : '0.375rem'};
  position: relative;
`
export const TitleComponent = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-primary);
`
export const TitleWrapperComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.375rem;
  justify-content: center;
`
export const getInfoLogo = (
  tooltip: {
    title: string
    body: string
  }[],
  state: WidgetState,
  first?: boolean,
  last?: boolean
) => {
  if (state === WidgetState.Data) {
    return (
      <InformationTooltip
        size={InformationLogoSize.small}
        paragraph={tooltip}
        first={first}
        last={last}
      />
    )
  } else if (state === WidgetState.Loading)
    return <Spinner spinnersize={spinnerSize.small}></Spinner>
  else return <LogoComponent icon={faTriangleExclamation}></LogoComponent>
}
