import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { getSeperatorLineStyle } from './SideMenuComponentHelpers/SideMenuComponentHelpers'
interface IconObject {
  regular: IconDefinition
  solid: IconDefinition
}
export interface ItemObject {
  to: string
  icon?: IconObject
  alias: string
  info: string
  children?: Array<ItemObject>
  show: boolean
}
interface ItemComponentProps {
  icon?: IconObject
  alias: string
  info: string
  to: string
  index: number
  children?: Array<ItemObject>
  childIdx?: number
  childrenAmount?: number
}
interface SideMenuComponentProps {
  items: Array<ItemObject>
}
const SideMenuWrapper = styled.div`
  position: sticky;
  top: 8.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 22rem;
  width: 22rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  display: block;
`
const ItemWrapper = styled.div`
  diplay: flex;
  flex-direction: column;
  width: 100%;
`
const Item = styled(NavLink)`
  text-decoration: none;
  height: 3rem;
  padding: 0rem 0.75rem 0rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--text-primary);
  border-color: var(--text-lightest);
  &:hover {
    color: var(--brand-primary-color);
    border-color: var(--brand-primary-color);
  }
  &.active {
    color: var(--brand-primary-color);
    border-color: var(--brand-primary-color);
    background-color: var(--brand-primary-4-color);
  }
`
const LogoAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
  border-color: inherit;
`
const LogoComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 100%;
  border-color: inherit;
`
const LogoComponent = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: inherit;
  font-weight: 400;
`
const ItemText = styled.span`
  font-size: 0.875rem;
  font-family: inter;
  font-weight: 500;
  color: inherit;
`
const SeperatorLine = styled.div`
  border-width: 0.0625rem;
  border-color: inherit;
  z-index: inherit;
  border-style: solid;
  height: calc(100% - 0.125rem);
`
const ItemInfo = styled.span`
  font-size: 0.6875rem;
  font-family: inter;
  font-weight: 600;
  color: var(--text-lighter);
  text-align: right;
  white-space: nowrap;
  text-transform: uppercase;
`
const ItemComponent = ({
  alias,
  icon,
  index,
  info,
  to,
  children,
  childIdx,
  childrenAmount
}: ItemComponentProps) => {
  const { t } = useTranslation()

  return (
    <ItemWrapper>
      <Item
        data-testid={`SideMenuComponentItem${index}`}
        to={to}
        end
        className={({ isActive }) => (isActive ? 'active' : '')}
        children={({ isActive }) => (
          <>
            <LogoAndTextWrapper>
              <LogoComponentWrapper>
                {icon ? (
                  <LogoComponent icon={isActive ? icon.solid : icon.regular} />
                ) : (
                  <SeperatorLine
                    style={getSeperatorLineStyle(childIdx, childrenAmount)}
                  />
                )}
              </LogoComponentWrapper>
              <ItemText>{alias}</ItemText>
            </LogoAndTextWrapper>
            <ItemInfo>{info}</ItemInfo>
          </>
        )}
      ></Item>
      {children?.map(
        (item, idx) =>
          item !== null && (
            <ItemComponent
              key={item.alias}
              {...item}
              index={parseInt(`${index}${idx}`)}
              childrenAmount={children.length}
              childIdx={idx}
            />
          )
      )}
    </ItemWrapper>
  )
}
export const SideMenuComponent = ({ items }: SideMenuComponentProps) => {
  return (
    <SideMenuWrapper data-testid="sideMenuComponent">
      {items.map((item, index) =>
        item.show ? (
          <ItemComponent key={item.alias} {...item} index={index} />
        ) : null
      )}
    </SideMenuWrapper>
  )
}
