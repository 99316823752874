import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getUploadEvents(
  patientId: string,
  uploadId: string,
  options: object = {}
): Promise<any> {
  const response = await AxiosRequest.create().get(
    config.API_URL +
      '/patients/' +
      patientId +
      '/uploads/' +
      uploadId +
      '/events',
    options
  )
  return response.data
}
