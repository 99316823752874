import { faCode, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { User, UserType } from '../../../core/entities/user.entity'

interface OptionsComponentProps {
  user: User | null
  getUploadEventsForPatient: any
  deleteUpload: any
  testid?: string
}
const OptionsBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  color: var(--text-primary);
`
const OptionsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  background-color: var(--element-bg);
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--brand-primary-16-color);
    color: var(--brand-primary-color);
  }
`
export const OptionsComponent = ({
  user,
  getUploadEventsForPatient,
  deleteUpload
}: OptionsComponentProps) => {
  return (
    <OptionsBox>
      <OptionsButton
        data-testid={'getuploadevents'}
        onClick={getUploadEventsForPatient}
      >
        <FontAwesomeIcon icon={faCode} />
      </OptionsButton>
      {user && user.type === UserType.HCPAdmin && (
        <OptionsButton data-testid={'deleteupload'} onClick={deleteUpload}>
          <FontAwesomeIcon icon={faTrash} />
        </OptionsButton>
      )}
    </OptionsBox>
  )
}
