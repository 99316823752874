import { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { BlindedPatient } from '../../../containers/BlindedPatient/BlindedPatient'
import BlurContext from '../../../core/contexts/blur-context'
import PatientContext from '../../../core/contexts/patient-context'
import PatientLoadingContext from '../../../core/contexts/patient-loading-context'
import PeriodContext from '../../../core/contexts/period-context'
import { useSessionContext } from '../../../core/contexts/session-context'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'
import {
  Patient,
  infusionSiteChangeEventType
} from '../../../core/entities/patient.entity'
import { UserType } from '../../../core/entities/user.entity'
import { useWards } from '../../../hooks/use-wards'
import { useCurrentPatient } from '../../../hooks/UseCurrentPatient'
import { PeriodObject } from '../../Header/DevicesAndPeriod/DevicesAndPeriod'
import { NoAccess } from '../../NoAccess/NoAccess'
import {
  PortalFooter,
  PortalFooterTheme
} from '../../PortalFooter/PortalFooter'
import { Spinner, spinnerSize } from '../../Spinner/Spinner'
import { HCPAdminHeader } from '../HCPAdminHeader/HCPAdminHeader'
import { HCPHeader } from '../HCPHeader/HCPHeader'
import { PatientHeader } from '../PatientHeader/PatientHeader'

export enum AccessEnum {
  noAccess = 'NoAccess'
}
const MainContent = styled.main`
  margin: 4.5rem 2rem 0;
  width: 100%;
`

export const PortalLayout = () => {
  const { logout, user, userError, clinic } = useSessionContext()
  const { siteSettingsInitialized } = useSiteSettingsContext()
  const { patient, setPatient, searchPatient, refreshPatient } =
    useCurrentPatient()
  const [period, setPeriod] = useState<PeriodObject | null>(null)
  const [blur, setBlur] = useState(false)
  const blurProviderValue = useMemo(() => ({ blur, setBlur }), [blur, setBlur])
  const [patientLoading, setPatientLoading] = useState<boolean>(false)
  const { wards, wardsLoading } = useWards(user?.wards)

  const isPatient = user && user.type === UserType.Patient
  useEffect(() => {
    if (isPatient) {
      if (wards !== null && wards.length > 0) {
        if (user.patientId === null) {
          const patient: Patient = {
            patientId: wards[0].patientId,
            name: wards[0].name,
            mrn: wards[0].mrn,
            userId: wards[0].userId ?? null,
            infusionSiteChangeEvent: infusionSiteChangeEventType.cannula
          }
          setPatient(patient)
        } else {
          const patient: Patient = {
            patientId: user.patientId,
            name: user.name,
            mrn: user.upid,
            userId: user.userId ?? null,
            infusionSiteChangeEvent: infusionSiteChangeEventType.cannula
          }
          setPatient(patient)
        }
      } else {
        if (user.patientId === null) {
          const patient: Patient = {
            patientId: AccessEnum.noAccess,
            name: user.name,
            mrn: user.upid,
            userId: user.userId ?? null,
            infusionSiteChangeEvent: infusionSiteChangeEventType.cannula
          }
          setPatient(patient)
        } else {
          const patient: Patient = {
            patientId: user.patientId,
            name: user.name,
            mrn: user.upid,
            userId: user.userId ?? null,
            infusionSiteChangeEvent: infusionSiteChangeEventType.cannula
          }
          setPatient(patient)
        }
      }
    }
  }, [user, setPatient, isPatient, wardsLoading, wards])

  if (!user || siteSettingsInitialized === false) {
    return <Spinner spinnersize={spinnerSize.large}></Spinner>
  }

  const getContent = () => {
    if (user.blinded === true) {
      return (
        <>
          {renderHeader(null)}
          <BlindedPatient />
        </>
      )
    } else if (
      (patient?.patientId === AccessEnum.noAccess && wards?.length === 0) ||
      userError
    ) {
      return <NoAccess logout={logout} />
    }

    return (
      <PeriodContext.Provider value={{ period, setPeriod }}>
        <PatientContext.Provider
          value={{ patient, setPatient, searchPatient, refreshPatient }}
        >
          <BlurContext.Provider value={blurProviderValue}>
            <PatientLoadingContext.Provider
              value={{ patientLoading, setPatientLoading }}
            >
              {renderHeader(wards)}
              <MainContent>
                <Outlet />
              </MainContent>
            </PatientLoadingContext.Provider>
          </BlurContext.Provider>
        </PatientContext.Provider>
      </PeriodContext.Provider>
    )
  }

  return (
    <>
      {getContent()}
      <PortalFooter theme={PortalFooterTheme.dark} showFragileInfo={true} />
    </>
  )

  /**
   * Returns the appropriate header depending on the type of the user
   *
   * @returns Header component depending on the user type
   */
  function renderHeader(wards: Patient[] | null) {
    switch (user?.type) {
      case UserType.HCP:
        return (
          <HCPHeader
            clinic={clinic}
            patient={patient}
            user={user}
            onLogout={logout}
          />
        )
      case UserType.HCPAdmin:
        return (
          <HCPAdminHeader
            clinic={clinic}
            patient={patient}
            user={user}
            onLogout={logout}
          />
        )
      case UserType.Patient:
        return (
          <PatientHeader
            patient={patient}
            user={user}
            wards={wards}
            onLogout={logout}
          />
        )
      default:
        return null
    }
  }
}
