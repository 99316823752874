import { SortEnum } from '../../../components/ProviderPatients/ProviderPatients'

export enum TableType {
  patient,
  population
}
export enum PopulationType {
  DiaTrust = 'DiaTrust',
  Research = 'Research'
}
export interface Population {
  id: string
  name: string
  description: string
  type: PopulationType
}
export interface SubPopulationDistribution {
  group1: { name: string; size: number } | null
  group2: { name: string; size: number } | null
  group3: { name: string; size: number } | null
}
export interface PopulationOverview {
  population: Population
  total: number
  distribution: SubPopulationDistribution
}

export enum SubPopulation {
  Group1 = 'Group1',
  Group2 = 'Group2',
  Group3 = 'Group3'
}
export interface SubPopulationWrapper {
  name: string
  subpopulation: SubPopulation
}
export enum PatientSort {
  Mrn,
  Name
}
export interface PatientSelection {
  searchString: string | null
  order: SortEnum
  sortBy: PatientSort
  populationIds: string[]
}
export interface PatientSearchSelectionRequest {
  selection: PatientSelection
  size: number
}
export interface PatientPaginationValue {
  patientId: string
  name: string
  mrn: string
  userId: string | null
  populationName: string | null
  subpopulation: SubPopulationWrapper | null
}
export interface PatientSearchResponse {
  size: number
  patients: PatientPaginationValue[]
}
