import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'
import styled from 'styled-components'
import {
  InfusionChangeType,
  InfusionSiteChangesObject
} from '../../../containers/Overview/Overview'
import { anchorClick } from '../../../containers/StackedDaily/StackedDaily'
import dayjs from '../../../core/dayjs/dayjs'
import { Patient } from '../../../core/entities/patient.entity'
import { DateBox, yDomainMax } from '../GlucoseOverview/GlucoseOverview'
import { InfusionLogo } from '../InfusionLogo/InfusionLogo'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
interface InfusionOverviewProps {
  days: number
  index: number
  data: Array<InfusionSiteChangesObject>
  patient: Patient
  hasPumpData: boolean
}

enum StartOrStopType {
  start,
  stop
}
const StartAndStopLogo = (type: StartOrStopType) => {
  const size = '16'
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
      <path
        d={
          type === StartOrStopType.start
            ? 'M10.25 5H5.75C5.3125 5 5 5.34375 5 5.75V10.25C5 10.6875 5.3125 11 5.75 11H10.25C10.6562 11 11 10.6875 11 10.25V5.75C11 5.34375 10.6562 5 10.25 5ZM8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM8 14.5C4.40625 14.5 1.5 11.5938 1.5 8C1.5 4.4375 4.40625 1.5 8 1.5C11.5625 1.5 14.5 4.4375 14.5 8C14.5 11.5938 11.5625 14.5 8 14.5Z'
            : 'M5.875 4.625C6.09375 4.46875 6.40625 4.46875 6.625 4.625L11.125 7.375C11.3438 7.5 11.5 7.75 11.5 8C11.5 8.28125 11.3438 8.53125 11.125 8.65625L6.625 11.4062C6.40625 11.5312 6.09375 11.5625 5.875 11.4062C5.625 11.2812 5.5 11.0312 5.5 10.75V5.25C5.5 5 5.625 4.75 5.875 4.625ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z'
        }
        fill={type === StartOrStopType.start ? 'var(--blue)' : 'var(--yellow)'}
      />
    </svg>
  )
}

const InfusionText = (props: any) => {
  return (
    <text // TEXT
      x={props.xPos}
      y={props.yPos}
      fill="var(--text-primary)"
      fontSize={10}
      fontFamily={'inter'}
      fontWeight={600}
      strokeOpacity={0}
      textAnchor={props.textAnchor}
    >
      {props.text}
    </text>
  )
}

const InfusionBox = (props: any) => {
  const { t } = useTranslation()
  return (
    <svg // PARENT
      x1={props.x1}
      x2={props.x2}
      y1={props.y1}
      y2={props.y2}
      x={props.x}
      y={props.y}
      onMouseEnter={() => props.setIsHovered(true)}
      onMouseLeave={() => props.setIsHovered(false)}
      cursor="pointer"
    >
      <rect // BORDER BOX
        width={props.width}
        height={props.height}
        x1={props.x1}
        x2={props.x2}
        y1={props.y1}
        y2={props.y2}
        rx={props.radius}
        fill={'var(--white-color)'}
        stroke={props.stroke}
      />
      {props.hasPumpData && (
        <>
          <rect // MIDDLE LINE
            width={props.width}
            height={4}
            fill={'var(--element-stroke)'}
            y={props.height / 2 - 2}
          />
          {props.changeType !== InfusionChangeType.none && (
            <InfusionLogo // MIDDLE LOGO
              xPos={props.width / 2}
              yPos={props.height / 2}
              type={props.changeType}
            />
          )}
        </>
      )}

      {props.hasPumpData && (
        <>
          {props.days && props.changeType !== InfusionChangeType.none && (
            <InfusionText // DAYS SINCE LAST
              xPos={6}
              yPos={props.height - 8}
              text={`${props.days} ${t('days')}`}
            />
          )}
          {props.time && props.isHovered === props.index && (
            <InfusionText // DAYS SINCE LAST
              xPos={props.width - 32}
              yPos={props.height - 8}
              text={`${props.time.substring(0, props.time.length - 3)}`}
            />
          )}
        </>
      )}
    </svg>
  )
}
export const InfusionOverview = ({
  days,
  index,
  data,
  patient,
  hasPumpData
}: InfusionOverviewProps) => {
  const [isHovered, setIsHovered] = useState<number | null>(null)

  return (
    <Wrapper data-testid={`infusionGraph${index}`}>
      <ResponsiveContainer>
        <ComposedChart
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          }}
        >
          <YAxis
            type={'number'}
            domain={[-3.9, yDomainMax]}
            interval={0}
            tick={false}
            tickLine={false}
            axisLine={false}
            width={15}
          />
          <XAxis
            type={'number'}
            dataKey="hour"
            domain={[0, days * 24]}
            tick={false}
            tickLine={false}
            axisLine={false}
          />
          {data.map((item, index) => (
            <ReferenceArea
              key={'box' + index}
              x1={index * 24}
              x2={index * 24 + 24}
              y1={-3.9}
              y2={yDomainMax}
              stroke={'var(--element-stroke)'}
              shape={
                <InfusionBox
                  changeType={item.changeType}
                  days={item.daysSinceLast}
                  date={item.day}
                  time={item.time}
                  type={patient.infusionSiteChangeEvent}
                  hasPumpData={hasPumpData}
                  setIsHovered={setIsHovered}
                  isHovered={isHovered}
                  index={index}
                />
              }
              radius={8}
            />
          ))}
          {data &&
            data.map((item, index) => (
              <ReferenceArea
                shape={
                  <DateBox
                    date={item.day}
                    isHovered={isHovered}
                    index={index}
                  />
                }
                key={'date' + index}
                y1={15.5}
                y2={23.5}
                x1={0.5 + 24.1 * index}
                x2={
                  isHovered === index
                    ? 16.615 + 24.1 * index
                    : 4.615 + 24.1 * index
                }
              />
            ))}
          {data &&
            data.map((item, index) => (
              <ReferenceArea
                cursor={'pointer'}
                data-testid={'boxInfront' + index}
                key={'boxInfront' + index}
                x1={index * 24}
                x2={index * 24 + 24}
                y1={-3.9}
                y2={yDomainMax}
                stroke={'var(--text-primary)'}
                strokeOpacity={isHovered === index ? 1 : 0}
                fillOpacity={0}
                radius={8}
                onMouseOver={() => {
                  setIsHovered(index)
                }}
                onMouseLeave={() => {
                  setIsHovered(null)
                }}
                onClick={() =>
                  anchorClick(dayjs(item.day).add(1, 'days').format('DMMM'))
                }
              />
            ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}
