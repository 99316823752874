import { useKeycloak } from '@react-keycloak/web'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import styled, { CSSProperties } from 'styled-components'

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  align-self: stretch;
  height: 3rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`
const OpenUploaderButton = styled(Button)`
  background-color: var(--blue);
  &:hover {
    background-color: var(--blue-darker);
  }
`
const OpenLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  color: var(--white-color);
  justify-content: center;
  align-items: center;
`

interface OpenUploaderProps {
  styling?: CSSProperties
}
export const OpenUploader = ({ styling }: OpenUploaderProps) => {
  const { keycloak } = useKeycloak()

  return (
    <OpenUploaderButton style={styling} data-testid="openUploaderButton">
      {keycloak.refreshToken && (
        <OpenLink target="_blank" to={'/portal/uploader/open'}>
          {t('openUploader', {
            deploymentName: 'Stenopool'
          })}
        </OpenLink>
      )}
    </OpenUploaderButton>
  )
}
