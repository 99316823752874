import {
  defaultClinicPagesObject,
  pagesObjectType
} from '../core/contexts/clinic-view-context'
import { ClinicView } from '../core/entities/clinic.entity'

export const restructureClinicPageArray = (pages: ClinicView[] | undefined) => {
  const pagesObject: pagesObjectType = { ...defaultClinicPagesObject }

  pages
    ?.filter((page) => page.page in defaultClinicPagesObject)
    .forEach((page) => {
      pagesObject[page.page] = {
        ...defaultClinicPagesObject,
        show: true,
        subViews: page.subViews
      }
    })

  return pagesObject
}
