import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { convertProviderPatientProviderTypeToProviderPatientsType } from '../../../containers/Integrations/Integrations'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'
import dayjs from '../../../core/dayjs/dayjs'
import { getProviderTypeInfoText } from '../../ProviderIntegrationModel/ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'
import { ProviderPatient } from '../ProviderPatients'

interface ProviderInformationProps {
  providerPatient: ProviderPatient
}
const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`
const BodyTitle = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--text-medium);
  text-transform: uppercase;
`
const BodySubWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const BodySubKey = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-lighter);
`
const BodySubValue = styled(BodySubKey)`
  color: var(--text-primary);
`
export const ProviderInformation = ({
  providerPatient
}: ProviderInformationProps) => {
  const { t } = useTranslation()
  return (
    <Body data-testid={'providerInformation' + providerPatient.id}>
      <BodyTitle>{t('provider information')}</BodyTitle>
      {providerPatient.name && (
        <BodySubWrapper>
          <BodySubKey>{t('Full name')}</BodySubKey>
          <BodySubValue>{providerPatient.name}</BodySubValue>
        </BodySubWrapper>
      )}
      {providerPatient.mrn && (
        <BodySubWrapper>
          <BodySubKey>{t('MRN')}</BodySubKey>
          <BodySubValue>{providerPatient.mrn}</BodySubValue>
        </BodySubWrapper>
      )}
      {providerPatient.birthday && (
        <BodySubWrapper>
          <BodySubKey>{t('Date of birth')}</BodySubKey>
          <BodySubValue>
            {dayjs(providerPatient.birthday).format('YYYY-DD-MM')}
          </BodySubValue>
        </BodySubWrapper>
      )}
    </Body>
  )
}

export const ProviderData = ({ providerPatient }: ProviderInformationProps) => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  const providerName = getProviderTypeInfoText(
    convertProviderPatientProviderTypeToProviderPatientsType(
      providerPatient.providerType
    )
  )
  return (
    <Body data-testid={'providerData' + providerPatient.id}>
      <BodyTitle>{t('Data')}</BodyTitle>
      {providerPatient.latestFetchTime && (
        <BodySubWrapper>
          <BodySubKey>{t('Latest fetch')}</BodySubKey>
          <BodySubValue>
            {dayjs(providerPatient.latestFetchTime).fromNow()}
          </BodySubValue>
        </BodySubWrapper>
      )}
      {providerPatient.newestProviderData && (
        <BodySubWrapper>
          <BodySubKey>{t('newestProviderData', { providerName })}</BodySubKey>
          <BodySubValue>
            {dayjs(providerPatient.newestProviderData).fromNow()}
          </BodySubValue>
        </BodySubWrapper>
      )}
      {providerPatient.newestLocalData && (
        <BodySubWrapper>
          <BodySubKey>
            {t('newestLocalData', {
              providerName,
              deploymentName: siteSettings?.deploymentName
            })}
          </BodySubKey>
          <BodySubValue>
            {dayjs(providerPatient.newestLocalData).fromNow()}
          </BodySubValue>
        </BodySubWrapper>
      )}
    </Body>
  )
}
