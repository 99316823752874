import { KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js'
import { createContext, useContext } from 'react'
import { Clinic } from '../entities/clinic.entity'
import { User } from '../entities/user.entity'

type SessionContextState = {
  isAuthenticated: boolean
  user: User | null
  userError: boolean
  clinic: Clinic | null
  clinicError: boolean
  login: (options?: KeycloakLoginOptions) => Promise<void>
  logout: (options?: KeycloakLogoutOptions) => Promise<void>
}

export const SessionContext = createContext<SessionContextState>({
  isAuthenticated: false,
  user: null,
  userError: false,
  clinic: null,
  clinicError: false,
  login: (options?: KeycloakLoginOptions) => Promise.resolve(),
  logout: (options?: KeycloakLogoutOptions) => Promise.resolve()
})

export function useSessionContext() {
  return useContext(SessionContext)
}

export default SessionContext
