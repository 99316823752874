import { useEffect, useState } from 'react'
import {
  Dot,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import styled from 'styled-components'
import {
  glucoseRanges,
  glucoseType
} from '../../../constants/ranges/glucoseRanges'
import { BgmEvent } from '../../../containers/BgLog/BgLog'
import dayjs from '../../../core/dayjs/dayjs'
import { formatNumber } from '../../../helpers/helpers'
import { CustomizedAxisTick } from '../EventGraph/EventGraph'
import { CustomTooltip } from '../GlucoseGraph/GlucoseGraph'

export enum GlucoseValueType {
  scan = 'scanned',
  manual = 'manual',
  linked = 'linked',
  meter = 'meter'
}
interface BgLogGraphProps {
  data: Array<BgmEvent> | null
  showvalues: boolean
  index: number
  showscans: boolean
  showmanuals: boolean
}
export interface DataProps {
  x: number
  y: number
  value: number
  index: number
  id: number
  source: GlucoseValueType
}
interface GraphProps {
  index: number
}
const ResponsiveWrapper = styled(ResponsiveContainer)<GraphProps>`
  height: ${(props) =>
    props.index === 0 ? 'calc(100% + 30px)' : '100%'} !important;
  margin-bottom: ${(props) => (props.index === 0 ? '30px' : '0px')} !important;
`
const BgDot = (props: any) => {
  if (
    (props.source === GlucoseValueType.scan && props.showscans) ||
    ((props.source === GlucoseValueType.manual ||
      props.source === GlucoseValueType.meter ||
      props.source === GlucoseValueType.linked) &&
      props.showmanuals)
  ) {
    return (
      <svg>
        <Dot
          cx={props.cx}
          cy={props.cy + props.showValues ? (props.index === 0 ? 42 : 12) : 0}
          stroke={
            props.hoverState === props.id
              ? glucoseRanges(props.value, glucoseType.darker)
              : glucoseRanges(props.value, glucoseType.default)
          }
          strokeWidth={props.source === GlucoseValueType.scan ? 2 : 0}
          fill={
            props.hoverState === props.id
              ? glucoseRanges(props.value, glucoseType.darker)
              : glucoseRanges(props.value, glucoseType.default)
          }
          fillOpacity={props.source === GlucoseValueType.scan ? 0 : 1}
          r={props.source === GlucoseValueType.scan ? 4 : 5}
          onMouseEnter={async () => {
            await props.redraw(props.data, props.id)
            props.setHoverState(props.id)
          }}
          onMouseLeave={() => props.setHoverState(null)}
        />
        {props.showValues && (
          <>
            <rect
              cx={props.cx}
              cy={props.cy}
              x={props.x - 9.5}
              y={props.y + 3}
              width={28}
              height={16}
              strokeWidth={props.hoverState === props.id ? 1 : 0}
              stroke={
                props.hoverState === props.id
                  ? 'var(--brand-primary-color'
                  : 'rgba(255,255,255,0)'
              }
              rx={props.hoverState === props.id ? 2 : 0}
              fill={'var(--element-bg)'}
            ></rect>
            <text
              fill="var(--text-lighter)"
              textAnchor="middle"
              cx={props.cx}
              cy={props.cy}
              x={props.x + 4.5}
              y={props.y + 15}
              fontSize={12}
              fontWeight={600}
              fontFamily={'inter'}
            >
              {formatNumber({ value: props.value, decimals: 1 })}
            </text>
          </>
        )}
      </svg>
    )
  } else return <svg></svg>
}
export const BgLogGraph = ({
  data,
  showvalues,
  index,
  showscans,
  showmanuals
}: BgLogGraphProps) => {
  const [graphData, setGraphData] = useState<Array<DataProps>>([])
  const [hoverState, setHoverState] = useState<number | null>(null)

  useEffect(() => {
    const array = []
    if (data && data.length > 0) {
      for (const [idx, element] of data.entries()) {
        array.push({
          time: element.localTime,
          x:
            dayjs(element.localTime).hour() +
            dayjs(element.localTime).minute() / 60 +
            dayjs(element.localTime).second() / 3600,
          y: 1,
          value: element.value,
          index,
          id: idx,
          source: element.subType as GlucoseValueType
        })
      }
    }
    setGraphData(array)
  }, [data, index])

  function redraw(arr: Array<DataProps>, id: number) {
    const copy = [...arr]
    const dot = copy.find((element) => element.id === id)
    copy.splice(
      copy.findIndex((element) => element.id === id),
      1
    )
    if (dot) copy.push(dot)
    setGraphData(copy)
  }

  return (
    <ResponsiveWrapper index={index}>
      <ScatterChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
        <ReferenceArea
          x1={0}
          x2={24.4}
          y1={0}
          y2={2}
          fill={'var(--element-bg)'}
          stroke={'var(--text-lightest)'}
          radius={4}
        />
        <XAxis
          type="number"
          name="Time"
          ticks={[0, 3, 6, 9, 12, 15, 18, 21, 24]}
          tick={index === 0 ? <CustomizedAxisTick /> : false}
          domain={[0, 24.4]}
          dataKey="x"
          tickLine={false}
          orientation={'top'}
          axisLine={false}
          interval={0}
          tickMargin={2}
          height={index === 0 ? 30 : 0}
          width={0}
        ></XAxis>
        <YAxis
          height={0}
          width={0}
          stroke="#979797"
          fontSize="0.875rem"
          dataKey="y"
          name="Glucose"
          interval={0}
          tick={false}
          tickLine={{ opacity: 0.5 }}
          domain={[0, 2]}
          axisLine={false}
        />
        <ReferenceLine x={3} stroke="#E7E7E7" />
        <ReferenceLine x={6} stroke="#E7E7E7" />
        <ReferenceLine x={9} stroke="#E7E7E7" />
        <ReferenceLine x={12} stroke="#E7E7E7" />
        <ReferenceLine x={15} stroke="#E7E7E7" />
        <ReferenceLine x={18} stroke="#E7E7E7" />
        <ReferenceLine x={21} stroke="#E7E7E7" />
        {data && graphData.length > 0 && (
          <Scatter
            shape={
              <BgDot
                redraw={redraw}
                data={graphData}
                showValues={showvalues}
                showscans={showscans}
                showmanuals={showmanuals}
                hoverState={hoverState}
                setHoverState={setHoverState}
              />
            }
            data={graphData}
            fillOpacity={1}
            isAnimationActive={false}
          />
        )}
        (
        <Tooltip
          animationDuration={200}
          cursor={false}
          content={<CustomTooltip />}
          allowEscapeViewBox={{ x: true, y: true }}
          formatter={function (value, name) {
            return `${value}`
          }}
          wrapperStyle={{ outline: 'none', zIndex: 1 }}
          isAnimationActive={false}
        />
        )
      </ScatterChart>
    </ResponsiveWrapper>
  )
}
