import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import {
  BarValue,
  BarWrapper,
  BarsAndValuesComponent,
  BarsColorComponent,
  BarsComponent
} from '../../Shared/BarComponents/BarComponents'
import { SizeEnum, WidgetState } from '../../Shared/Interfaces/Interfaces'
import { TimeInRangeConfig } from '../TimeInRange'

const TITRLine = styled.div<{ position: number }>`
  width: 0.0625rem;
  height: 100%;
  position: absolute;
  left: ${(props) => props.position}%;
  background-color: var(--inRange-tight);
`
const TITRInfoComponent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.25rem;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
`
const TITRInfoText = styled.span`
  font-family: inter;
  font-size: 0.625rem;
  color: var(--text-primary);
  font-weight: 700;
`
const TITRInfoLogo = styled(FontAwesomeIcon)`
  font-weight: 400;
  font-size: 0.625rem;
`
const TITRTooltip = styled.div`
  width: 14.125rem;
  padding: 0.375rem 0.5rem;
  height: 1.5rem;
  position: absolute;
  top: -0.25rem;
  left: 0.1875rem;
  background-color: var(--white-color);
  transform: translateY(-100%);
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-sizing: border-box;
`
const TITRToolTipText = styled.span`
  font-family: inter;
  font-size: 0.625rem;
  font-weight: 500;
  color: var(--text-primary);
`
const TITRToolTipSubText = styled(TITRToolTipText)`
  font-weight: 700;
  color: var(--text-lighter);
`

interface BarProps {
  color: string
  state: WidgetState
  percentage: number | null
}
const Bar = ({ color, state, percentage }: BarProps) => {
  const { t } = useTranslation()
  const isDataState = state === WidgetState.Data
  const displayValue =
    percentage !== null
      ? formatNumber({ value: percentage, decimals: 0 })
      : '--'
  return (
    <BarsAndValuesComponent $size={SizeEnum.Medium}>
      <BarWrapper>
        <BarsComponent $size={SizeEnum.Medium}>
          <BarsColorComponent
            color={color}
            width={percentage ?? 0}
            className={isDataState ? 'showBars' : ''}
          ></BarsColorComponent>
        </BarsComponent>
      </BarWrapper>
      <BarValue
        value={displayValue}
        state={state}
        color={isDataState ? color : 'var(--text-lightest)'}
        size={SizeEnum.Medium}
      />
    </BarsAndValuesComponent>
  )
}

interface TitrBarProps {
  color: string
  state: WidgetState
  percentage: number | null
  indicator: number | null
  showTITR: boolean
  setShowTITR: Dispatch<SetStateAction<boolean>>
}
const TitrBar = ({
  color,
  state,
  percentage,
  indicator,
  showTITR,
  setShowTITR
}: TitrBarProps) => {
  const { t } = useTranslation()
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const isDataState = state === WidgetState.Data
  const displayValue =
    percentage !== null
      ? formatNumber({ value: percentage, decimals: 0 })
      : '--'
  return (
    <BarsAndValuesComponent $size={SizeEnum.Medium}>
      <BarWrapper
        data-testid={'titrbar'}
        onMouseEnter={() => setShowTITR(true)}
        onMouseLeave={() => setShowTITR(false)}
      >
        {showTooltip === true && (
          <TITRTooltip data-testid="TITRTooltip">
            <TITRToolTipText>{t('Time In Tight Range (TITR)')}</TITRToolTipText>
            <TITRToolTipSubText>3.9-7.8 mmol/L</TITRToolTipSubText>
          </TITRTooltip>
        )}
        <BarsComponent $size={SizeEnum.Medium}>
          <BarsColorComponent
            color={color}
            width={percentage ?? 0}
            className={isDataState ? 'showBars' : ''}
          ></BarsColorComponent>
          {showTITR && (
            <TITRInfoComponent>
              <TITRInfoText>TITR</TITRInfoText>
              <TITRInfoLogo
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                icon={faInfoCircle}
                data-testid="TITRInfoLogo"
              ></TITRInfoLogo>
            </TITRInfoComponent>
          )}

          {indicator !== null && <TITRLine position={indicator}></TITRLine>}
        </BarsComponent>
      </BarWrapper>
      <BarValue
        value={displayValue}
        state={state}
        color={isDataState ? color : 'var(--text-lightest)'}
        size={SizeEnum.Medium}
      />
    </BarsAndValuesComponent>
  )
}
interface BarsProps {
  data: {
    veryHigh: number
    high: number
    inRange: number
    low: number
    veryLow: number
    titr: number
  } | null
  state: WidgetState
}
export const Bars = ({ data, state }: BarsProps) => {
  const [showTITR, setShowTITR] = useState<boolean>(false)
  return (
    <>
      <Bar
        state={state}
        percentage={data ? data.veryHigh : null}
        color={TimeInRangeConfig.veryHigh.color}
      />
      <Bar
        state={state}
        percentage={data ? data.high : null}
        color={TimeInRangeConfig.high.color}
      />
      <TitrBar
        state={state}
        percentage={data ? (showTITR ? data.titr : data.inRange) : null}
        color={
          showTITR
            ? TimeInRangeConfig.titr.color
            : TimeInRangeConfig.inRange.color
        }
        showTITR={showTITR}
        setShowTITR={setShowTITR}
        indicator={data?.titr ?? null}
      />
      <Bar
        state={state}
        percentage={data ? data.low : null}
        color={TimeInRangeConfig.low.color}
      />
      <Bar
        state={state}
        percentage={data ? data.veryLow : null}
        color={TimeInRangeConfig.veryLow.color}
      />
    </>
  )
}
