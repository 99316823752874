import {
  faArrowRightLong,
  faClipboard
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  ConsultationData,
  getConsultations
} from '../../core/api/get-consultations'
import { uploadConsultationCsv } from '../../core/api/upload-consultation-csv'
import dayjs from '../../core/dayjs/dayjs'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import { Modal, ModalComponent } from '../Modal/Modal'
import {
  ScrollTableComponent,
  THead,
  TText,
  Td,
  Th,
  Tr
} from '../Table/ScrollTableComponent'
import { ScrollTableLoading } from '../Table/ScrollTableLoading.tsx'
import { UploadDataComponent, UploaderType } from '../UploadData/UploadData'
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 2.25rem;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-bottom: 2rem;
`
const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ImportButton = styled.div`
  cursor: pointer;
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  color: var(--white-color);
  background-color: var(--brand-primary-color);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
`
const UploadButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--brand-primary-12-color);
  background-color: var(--element-bg);
  color: var(--brand-primary-color);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`
const ConsultationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 27.25rem;
  padding: 2rem;
  gap: 2rem;
  border-radius: 2rem;
  background-color: var(--white-color);
`
const ModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const ModalHeaderText = styled.span`
  font-family: inter;
  font-weight 600;
  font-size; 0.875rem;
  color: var(--text-lighter);
`
const ModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
const ModalTitle = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`
const ModalSubTitle = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-primary);
`

const ModalLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const LinkBox = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-bg);
  color: var(--text-medium);
  &:hover {
    background-color: var(--brand-primary-12-color);
    border-color: var(--brand-primary-color);
    color: var(--brand-primary-color);
  }
`

const LinkArrow = styled(FontAwesomeIcon)`
  font-weight: 400;
  font-size: 0.875rem;
  color: inherit;
`
const LinkText = styled.a`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  color: inherit;
  cursor: pointer;
`
const LinkIcon = styled(FontAwesomeIcon)`
  font-weight: 400;
  font-size: 0.875rem;
  color: inherit;
`

const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
const InstructionsSteps = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.25rem;
`
const InstructionsText = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--text-lighter);
`
interface ConsultationModalComponentProps {
  link: string | null
  handleShowUploadModal: () => void
  consultation: ConsultationData
}

const ConsultationModalComponent = ({
  link,
  handleShowUploadModal,
  consultation
}: ConsultationModalComponentProps) => {
  const { t } = useTranslation()

  const handleCopyToClipboard = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link)
      SnackBar({
        type: SnackBarType.Success,
        message: t('Successfully copied to clipboard')
      })
    } catch (error) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error copying to clipboard')
      })
    }
  }

  const openLink = (link: URL) => {
    window.open(link, '_blank')
  }

  return (
    <ModalComponent
      closeFun={handleShowUploadModal}
      closetestid="consultationUploadModal"
    >
      <ConsultationModalWrapper>
        <ModalHeaderWrapper>
          <ModalHeaderText>{t('Abbott LibreView user')}</ModalHeaderText>
        </ModalHeaderWrapper>
        <ModalTitleWrapper>
          <ModalTitle>{consultation.name}</ModalTitle>
          <ModalTitle>
            {dayjs(consultation.consultationTime).format('DD/MM/YYYY')}
          </ModalTitle>
          <ModalTitle>{consultation.name}</ModalTitle>
        </ModalTitleWrapper>
        <ModalLinkWrapper>
          {link && (
            <>
              <LinkBox>
                <LinkText
                  data-testid="link"
                  onClick={() => openLink(new URL(link))}
                >
                  {t('Open patient in LibreView')}
                </LinkText>
                <LinkArrow icon={faArrowRightLong} />
              </LinkBox>

              <LinkBox
                data-testid="linkCopy"
                onClick={() => handleCopyToClipboard(link)}
              >
                <LinkIcon icon={faClipboard}></LinkIcon>
                <LinkText>{t('Copy Link')}</LinkText>
              </LinkBox>
            </>
          )}
        </ModalLinkWrapper>
        <InstructionsWrapper>
          <InstructionsText style={{ textTransform: 'uppercase' }}>
            {t('instructions')}
          </InstructionsText>
          <InstructionsSteps>
            <InstructionsText>
              1. {t('Open patient in LibreView')}
            </InstructionsText>
            <InstructionsText>
              2.{' '}
              {t(
                'Download the CSV file from their profile (solve Captcha if needed)'
              )}
            </InstructionsText>
            <InstructionsText>
              3. {t('Upload the CSV file below')}
            </InstructionsText>
          </InstructionsSteps>
        </InstructionsWrapper>
        <UploadDataComponent
          uploadertype={UploaderType.abbott}
          patientId={consultation.localPatientId}
          strict={true}
          style={{ width: '100%' }}
        />
      </ConsultationModalWrapper>
    </ModalComponent>
  )
}

interface ConsultationRowProps {
  consultation: ConsultationData
}
const ConsultationRow = ({ consultation }: ConsultationRowProps) => {
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={showUploadModal}>
        <ConsultationModalComponent
          handleShowUploadModal={() => setShowUploadModal(false)}
          link={consultation.link}
          consultation={consultation}
        />
      </Modal>
      <Tr key={consultation.name} data-testid="consulationData">
        <Td>
          <TText>{consultation.name}</TText>
        </Td>
        <Td>
          <TText>{consultation.mrn}</TText>
        </Td>
        <Td>
          <TText>
            {dayjs(consultation.consultationTime).format('DD/MM/YYYY HH:mm')}
          </TText>
        </Td>
        <Td>
          <TText>
            {consultation.latestLocalData
              ? dayjs(consultation.latestLocalData).format('DD/MM/YYYY HH:mm')
              : '-'}
          </TText>
        </Td>
        <Td>
          <TText>
            {consultation.latestProviderData
              ? dayjs(consultation.latestProviderData).format(
                  'DD/MM/YYYY HH:mm'
                )
              : '-'}
          </TText>
        </Td>
        <Td>
          <UploadButton
            data-testid="consultationUploadButton"
            onClick={() => setShowUploadModal(true)}
          >
            {t('Upload')}
          </UploadButton>
        </Td>
      </Tr>
    </>
  )
}

export const Consultations = () => {
  const [data, setData] = useState<Array<ConsultationData> | null>(null)
  const [dataError, setDataError] = useState<boolean>(false)
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [refresh, setRefresh] = useState<boolean>(false)
  useEffect(() => {
    const getData = async () => {
      setDataError(false)
      setData(null)
      try {
        const response = await getConsultations()
        setData(response)
      } catch (error) {
        setDataError(true)
      }
    }
    getData()
  }, [refresh])

  const handleChange = function (e: any) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files)
    }
  }

  const handleFile = (file: FileList) => {
    const formData = new FormData()
    formData.append('file', file[0])
    uploadConsultationCsv(formData)
      .then((response) => {
        if (response.failed)
          SnackBar({ type: SnackBarType.Error, message: 'file is corrupt' })
        if (response.unknownMRN.length > 0)
          SnackBar({
            type: SnackBarType.Error,
            message: `unknown MRNs: ${response.unknownMRN.join(', ')}`
          })
        setRefresh(!refresh)
      })
      .catch((error: Error) => {
        // TODO update to more specific error types/messages
        console.log(error.message)
      })
  }

  if (dataError) {
    return (
      <div data-testid="consultationError">Error getting consultations</div>
    )
  } else {
    return (
      <Wrapper data-testid="consultationData">
        <input
          ref={inputRef}
          type="file"
          multiple={false}
          style={{ display: 'none', position: 'absolute' }}
          onChange={(event) => handleChange(event)}
          data-testid={'fileinput'}
        />
        <Section>
          <ImportButton
            data-testid="csvImportButton"
            onClick={() => inputRef.current && inputRef.current.click()}
          >
            {t('IMPORT CSV')}
          </ImportButton>
        </Section>
        <ScrollTableComponent>
          <THead>
            <Th>
              <HeaderWrapper>
                <TText>{t('Patient Name')}</TText>
              </HeaderWrapper>
            </Th>
            <Th>
              <HeaderWrapper>
                <TText>{t('CPR Number')}</TText>
              </HeaderWrapper>
            </Th>
            <Th>
              <TText>{t('Consultation Time')}</TText>
            </Th>
            <Th>
              <HeaderWrapper>
                <TText>{t('Latest Local Data')}</TText>
              </HeaderWrapper>
            </Th>
            <Th>
              <HeaderWrapper>
                <TText>{t('Latest Provider Data')}</TText>
              </HeaderWrapper>
            </Th>
            <Th>
              <HeaderWrapper>
                <TText>{t('Upload')}</TText>
              </HeaderWrapper>
            </Th>
          </THead>
          {data ? (
            data.map((e) => (
              <ConsultationRow
                key={e.consultationId}
                consultation={e}
              ></ConsultationRow>
            ))
          ) : (
            <ScrollTableLoading size={20} />
          )}
        </ScrollTableComponent>
      </Wrapper>
    )
  }
}
