import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { RelativeWrapper } from '../DeviceList/DeviceList'
export const AbsoluteWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: -1.5625rem;
  left: -2rem;
  right: 0;
  bottom: 0;
  background-color: var(--modalBackground);
  z-index: 3;
`

const UserModalComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: var(--element-bg);
  border-radius: 1.75rem;
  gap: 0.625rem;
  position: absolute;
  right: 3rem;
  top: 6.75rem;
`

const TitleAreaComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0rem 0.5rem 0.5625rem;
  gap: 1rem;
`
const FrameComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`
const UsernameTextComponent = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--text-primary);
`
const SignOutButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 5rem;
  background-color: var(--destructive);
  border-radius: 0.75rem;
  font-family: inter;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--white-color);
  cursor: pointer;
`

type UserModalProps = {
  username: string
  onLogout: () => void
  closeDropdown: () => void
}
export const UserModal = ({
  username,
  onLogout,
  closeDropdown
}: UserModalProps) => {
  const { t } = useTranslation()
  return (
    <RelativeWrapper data-testid="closeLogoutDropdown" onClick={closeDropdown}>
      <UserModalComponent
        onClick={function (event) {
          event.stopPropagation()
        }}
        data-testid="UserModalComponent"
      >
        <TitleAreaComponent>
          <FrameComponent>
            <UsernameTextComponent>{username}</UsernameTextComponent>
            <SignOutButtonComponent onClick={onLogout}>
              {t('Sign Out')}
            </SignOutButtonComponent>
          </FrameComponent>
        </TitleAreaComponent>
      </UserModalComponent>
    </RelativeWrapper>
  )
}
