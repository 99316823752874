import { Resizable } from 're-resizable'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  AverageGlucoseObject,
  TimeInRangeObject
} from '../../containers/StackedDaily/Interfaces/Interfaces'
import {
  ViewOptionsComponent,
  anchorClick,
  viewStateEnum
} from '../../containers/StackedDaily/StackedDaily'
import dayjs from '../../core/dayjs/dayjs'
import { Patient } from '../../core/entities/patient.entity'
import { usePatientData } from '../../hooks/GetPatientData'
import { PeriodObject } from '../Header/DevicesAndPeriod/DevicesAndPeriod'
import { ScrollTableComponent, TText, Th } from '../Table/ScrollTableComponent'
interface TotalInsulinObject {
  total: number
  bolusTotal: number
  bolusRelative: number
  basalTotal: number
  basalRelative: number
  basalAutomated: number
}
interface StackedDailyTableDay {
  averageGlucose: AverageGlucoseObject | null
  day: string
  timeInRange: TimeInRangeObject
  totalInsulin: TotalInsulinObject | null
}

export interface StackedDailyTableDataObject {
  name: string
  type: string
  days: Array<StackedDailyTableDay>
}
interface StackedDailyTableProps {
  patient: Patient | null
  period: PeriodObject | null
  viewState: viewStateEnum
  setState: Dispatch<SetStateAction<viewStateEnum>>
}
interface DailyTableProps {
  data: StackedDailyTableDataObject | null
  setState: Dispatch<SetStateAction<viewStateEnum>>
}
interface TimeInRangeProps {
  color: string
  percentage: number
}
interface DataValueObject {
  value: string
  unit: string | null
}
interface DataObject {
  days: Array<string>
  averageGlucose: Array<DataValueObject>
  manualBolus: Array<DataValueObject>
  totalInsulin: Array<DataValueObject>
  timeInRange: Array<TimeInRangeObject>
}
interface TimeInRangeData {
  data: Array<TimeInRangeObject>
  days: Array<string>
  setState: Dispatch<SetStateAction<viewStateEnum>>
}
interface ValuesData {
  data: Array<DataValueObject>
  name: string
}
interface AnchorProps {
  last: boolean
}
interface ToolTipObject {
  percentage: number
  index: number
}
export const TableWrapper = styled.div`
  width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const Box = styled.td`
  padding: 1rem 0;
`
export const BoxValues = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.375rem;
  gap: 0.5rem;
`
const Value = styled.div``
const Unit = styled.div`
  color: var(--text-lighter);
`
const DateColumn = styled.div`
  min-width: 6.6875rem;
  max-width: 12rem;
  width: 6.6875rem;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 0.0625rem solid var(--text-lightest);
`
export const ColumnResize = styled(Resizable)`
  min-width: 6.6875rem;
  max-width: 12rem;
  width: 6.6875rem;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 0.0625rem solid var(--text-lightest);
`
const TimeInRangeColumn = styled.div`
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`
const TimeInRangeBox = styled.div`
  height: 3rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  position: relative;
`

const TimeInRangeWrap = styled.div`
  width: calc(100% - 1rem);
  height: 1rem;
  border-radius: 0.25rem;
  overflow: hidden;
`
const TimeInRangeBar = styled.div`
  display: flex;
  border: 0.0625rem solid var(--text-lightest);
  flex-direction: row;
  width: calc(100% - 0.125rem);
  height: calc(100% - 0.125rem);
  border-radius: 0.25rem;
  position: relative;
`
const TimeInRangeFill = styled.div`
  width: calc(100% + 0.125rem);
  height: calc(100% + 0.125rem);
  position: absolute;
  left: -0.0625rem;
  bottom: -0.0625rem;
  display: flex;
  flex-direction: row;
`
const TimeInRangeFiller = styled.div<TimeInRangeProps>`
  background-color: ${(props) => props.color};
  width: ${(props) => props.percentage + '%'};
  height: 100%;
  z-index: 1;
`
const Footer = styled.div`
  width: calc(100% - 4rem);
  height: 3.125rem;
  background-color: var(--element-bg-90);
  position: fixed;
  bottom: 3.125rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
`
const AnchorToCard = styled.div<AnchorProps>`
  height: 2.9375rem;
  width: calc(100vw - 4rem);
  cursor: pointer;
  position: absolute;
  right: 0;
  border-top: 0.03125rem solid var(--text-lightest);
  border-bottom: ${(props) =>
    props.last
      ? '0.09375rem solid var(--text-lightest)'
      : '0.03125rem solid var(--text-lightest)'};
  &:hover {
    width: calc(100vw - 4.125rem);
    height: 2.905rem;
    border-top: 0.0625rem solid var(--brand-primary-color);
    border-bottom: 0.0625rem solid var(--brand-primary-color);
    border-left: 0.0625rem solid var(--brand-primary-color);
    border-right: 0.0625rem solid var(--brand-primary-color);
    z-index: 1;
  }
`

const TimeInRangeToolTip = styled.div<TimeInRangeProps>`
  background-color: var(--white-color);
  border: 0.0625rem solid var(--text-lightest);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 1rem;
  gap: 1rem;
  color: ${(props) => props.color};
  left: ${(props) => 'calc(' + props.percentage + '% + 0.5rem)'};
  transform: translateX(-50%) translateY(-100%);
`
const TimeInRangeToolTipTitle = styled.div`
  font-size: 1rem;
  font-family: inter;
  font-weight: 700;
  color: inherit;
`
const TimeInRangeToolTipText = styled.div`
  font-size: 1.5rem;
  font-family: inter;
  font-weight: 600;
  color: var(--text-primary);
`
const getTimeInRangeColor = (index: number) => {
  switch (index) {
    case 0:
      return 'var(--veryLow-color)'
    case 1:
      return 'var(--low-color)'
    case 2:
      return 'var(--inRange-color)'
    case 3:
      return 'var(--high-color)'
    default:
      return 'var(--veryHigh-color)'
  }
}
const getTimeInRangename = (index: number) => {
  switch (index) {
    case 0:
      return 'Very Low'
    case 1:
      return 'Low'
    case 2:
      return 'In Range'
    case 3:
      return 'High'
    default:
      return 'Very High'
  }
}

const ValueComponent = ({ data, name }: ValuesData) => {
  const allData = data.map((item, index) => (
    <Box
      data-testid={name + index}
      id={name + index}
      key={'valueBox' + name + index}
    >
      <BoxValues>
        <Value>{Math.round(parseInt(item.value))}</Value>
        {item.unit && <Unit>{item.unit}</Unit>}
      </BoxValues>
    </Box>
  ))
  return (
    <ColumnResize
      minWidth={108}
      handleStyles={{
        left: { display: 'none' },
        top: { display: 'none' },
        bottom: { display: 'none' },
        bottomLeft: { display: 'none' },
        bottomRight: { display: 'none' },
        topRight: { display: 'none' },
        topLeft: { display: 'none' },
        right: { width: '0.5rem', zIndex: '1', right: '-0.0625rem' }
      }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
    >
      <Th>
        <TText>{name}</TText>
      </Th>
      {allData}
    </ColumnResize>
  )
}
interface TimeInRangeRowComponentProps {
  setState: Dispatch<SetStateAction<viewStateEnum>>
  anchorClick: (id: string) => void
  index: number
  item: TimeInRangeObject
  days: string[]
  data: TimeInRangeObject[]
}
const TimeInRangeRowComponent = ({
  setState,
  anchorClick,
  index,
  item,
  days,
  data
}: TimeInRangeRowComponentProps) => {
  const [toolTipValue, setToolTipValue] = useState<ToolTipObject | null>(null)
  const [position, setPosition] = useState<number>(0)
  useEffect(() => {
    if (toolTipValue) {
      let position = 0
      Object.values(item).forEach((e, idx) => {
        if (idx === toolTipValue.index) {
          position += e.percentage / 2
        } else {
          position += idx < toolTipValue.index ? e.percentage : 0
        }
      })
      setPosition(position)
    }
  }, [toolTipValue, item])
  return (
    <TimeInRangeBox
      data-testid={'anchor' + index}
      id={'timeInRange' + index}
      onClick={() => {
        setState(viewStateEnum.cards)
        anchorClick(dayjs(days[index]).add(1, 'days').format('DMMM'))
      }}
    >
      {toolTipValue && (
        <TimeInRangeToolTip
          color={getTimeInRangeColor(toolTipValue.index)}
          percentage={position}
        >
          <TimeInRangeToolTipTitle>
            {getTimeInRangename(toolTipValue.index)}
          </TimeInRangeToolTipTitle>
          <TimeInRangeToolTipText>
            {Math.round(toolTipValue.percentage * 100) / 100} %
          </TimeInRangeToolTipText>
        </TimeInRangeToolTip>
      )}
      <AnchorToCard last={index === data.length - 1}></AnchorToCard>
      <TimeInRangeWrap>
        <TimeInRangeBar>
          <TimeInRangeFill>
            {Object.values(item).map((value, idx) => (
              <TimeInRangeFiller
                data-testid={idx + 'timeInRange' + index}
                color={getTimeInRangeColor(idx)}
                percentage={value.percentage}
                onMouseEnter={() =>
                  setToolTipValue({
                    percentage: value.percentage,
                    index: idx
                  })
                }
              />
            ))}
          </TimeInRangeFill>
        </TimeInRangeBar>
      </TimeInRangeWrap>
    </TimeInRangeBox>
  )
}
const TimeInRangeComponent = ({ data, days, setState }: TimeInRangeData) => {
  const timeInRangeColumn = data.map((item, index) => (
    <TimeInRangeRowComponent
      key={'timeInRangeBox' + index}
      setState={setState}
      anchorClick={anchorClick}
      index={index}
      item={item}
      days={days}
      data={data}
    />
  ))
  return (
    <TimeInRangeColumn>
      <Th>
        <TText>Time In Range</TText>
      </Th>
      {timeInRangeColumn}
    </TimeInRangeColumn>
  )
}
const TableComponent = ({ data, setState }: DailyTableProps) => {
  const dataObject: DataObject = {
    days: [],
    averageGlucose: [],
    manualBolus: [],
    totalInsulin: [],
    timeInRange: []
  }
  if (data) {
    for (const element of data.days) {
      dataObject.days.push(element.day)
      dataObject.averageGlucose.push({
        value: element.averageGlucose
          ? element.averageGlucose.average.toString()
          : '-',
        unit: element.averageGlucose ? element.averageGlucose.units : '-'
      })
      dataObject.manualBolus.push({
        value: element.totalInsulin
          ? element.totalInsulin.total.toString()
          : '-',
        unit: null
      })
      dataObject.totalInsulin.push({
        value: element.totalInsulin
          ? element.totalInsulin.total.toString()
          : '-',
        unit: 'U'
      })
      element.timeInRange && dataObject.timeInRange.push(element.timeInRange)
    }
    const dates = dataObject.days.map((item, index) => (
      <Box
        id={dayjs(item).format('DD/MM/YYYY')}
        style={{ justifyContent: 'center' }}
        key={'date' + index}
      >
        {dayjs(item).format('DD/MM/YYYY')}
      </Box>
    ))

    return (
      <ScrollTableComponent data-testid="DailyTable">
        <DateColumn>
          <Th>
            <TText style={{ marginLeft: '1rem' }}>Date</TText>
          </Th>
          {dates}
        </DateColumn>
        <ValueComponent name={'Manual bolus'} data={dataObject.manualBolus} />
        <ValueComponent
          name={'Avg. Glucose'}
          data={dataObject.averageGlucose}
        />
        <ValueComponent name={'Total Insulin'} data={dataObject.totalInsulin} />
        <TimeInRangeComponent
          data={dataObject.timeInRange}
          days={dataObject.days}
          setState={setState}
        ></TimeInRangeComponent>
      </ScrollTableComponent>
    )
  } else {
    return <>no data</>
  }
}
function isStackedDailyTableData(
  object: any
): object is StackedDailyTableDataObject {
  return 'days' in object
}

export const DailyTable = ({
  patient,
  period,
  viewState,
  setState
}: StackedDailyTableProps) => {
  const { patientViewData, patientViewError } = usePatientData(
    patient,
    period,
    {
      type: 'stackeddailytable',
      days: null
    },
    null
  )
  return (
    <TableWrapper>
      <ViewOptionsComponent viewState={viewState} setViewState={setState} />
      <TableComponent
        data={
          patientViewData && isStackedDailyTableData(patientViewData)
            ? patientViewData
            : null
        }
        setState={setState}
      />
      <Footer />
    </TableWrapper>
  )
}
