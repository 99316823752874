import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSessionContext } from '../../../core/contexts/session-context'
import { UserType } from '../../../core/entities/user.entity'
import { getBrowserSpecs } from '../../../helpers/getBrowserSpecs'
import { getOperatingSystem } from '../../../helpers/getOperatingSystem'

export enum MailType {
  feedback = 'feedback',
  support = 'support'
}
interface MailConfig {
  type: MailType
  frontendVersion: string
  backendVersion: string
  deploymentName: string
  productUDI: string
}
interface MailProps {
  config: MailConfig
}

const MailButton = styled.a`
  font-family: inter;
  font-weight: 600;
  font-size: 0.75rem;
  color: inherit;
  cursor: pointer;
  border-radius: 0.25rem;
  text-decoration: none;
  width: 100%;
  height: 100%;
  padding: 0.375rem 0.5rem;
`

export const Mail = ({ config }: MailProps) => {
  const base64Url = btoa(window.location.href)
  const browser = getBrowserSpecs()
  const operatingSystem = getOperatingSystem()
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const getEmailInfo = () => {
    switch (config.type) {
      case MailType.feedback:
        return {
          text: t('Provide feedback'),
          email: 'feedback@linesystems.io',
          subject: 'feedback',
          body: `Beskriv dit forbedringsforslag så grundigt som muligt. Husk også dine kontaktoplysninger så vi kan få fat på dig, hvis vi har spørgsmål til dit forslag.





        
DU MÅ IKKE SKRIVE CPR-NUMMER I DENNE MAIL! HVERKEN SOM TEKST ELLER PÅ SKÆRMBILLEDER.


––––––––––––– SLET IKKE INFO NEDENFOR –––––––––––––

Installation: ${config.deploymentName}
Backend version: ${config.backendVersion}
Frontend version: ${config.frontendVersion}
Produkt UDI: ${config.productUDI}
Operativ system: ${operatingSystem}
Browser og version: ${`${browser.name} v.${browser.version}`}
Brugertype: ${user?.type}
Bruger ID: ${user?.type !== UserType.Patient ? user?.upid : user?.userId}
Kontekst: ${base64Url}

––––––––––––– SLET IKKE INFO OVENFOR –––––––––––––
`
        }
      case MailType.support:
        return {
          text: t('Report an issue'),
          email: 'support@linesystems.io',
          subject: 'support',
          body: `Beskriv fejlen du oplever så grundigt som muligt. Inkludér gerne en fejlbeskrivelse fra portalen og skærmbilleder hvis det er relevant. Husk også dine kontaktoplysninger.




        
DU MÅ IKKE SKRIVE CPR-NUMMER I DENNE MAIL! HVERKEN SOM TEKST ELLER PÅ SKÆRMBILLEDER.


––––––––––––– SLET IKKE INFO NEDENFOR –––––––––––––

Installation: ${config.deploymentName}
Backend version: ${config.backendVersion}
Frontend version: ${config.frontendVersion}
Produkt UDI: ${config.productUDI}
Operativ system: ${operatingSystem}
Browser og version: ${`${browser.name} v.${browser.version}`}
Brugertype: ${user?.type}
Bruger ID: ${user?.type !== UserType.Patient ? user?.upid : user?.userId}
Kontekst: ${base64Url}

––––––––––––– SLET IKKE INFO OVENFOR –––––––––––––
`
        }
    }
  }
  return (
    <MailButton
      href={`mailto:${getEmailInfo().email}?subject=${
        encodeURIComponent(getEmailInfo().subject) || ''
      }&body=${encodeURIComponent(getEmailInfo().body) || ''}`}
    >
      {getEmailInfo().text}
    </MailButton>
  )
}
