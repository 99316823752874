import styled from 'styled-components'
import NovoRapid from '../../../assets/Penfills/NovoRapid.png'
import Tresiba from '../../../assets/Penfills/Tresiba.png'
import { NovoPenMedicine } from '../../Graphs/Insights/interfaces'

const MedicineVisual = styled.div`
  border-radius: 0.5rem;
  background-color: var(--element-bg);
`

const MedicineIMG = styled.img`
  width: 12.3125rem;
  height: 2.625rem;
`
const MedicineTagWrapper = styled.div<{ $medicineType: NovoPenMedicine }>`
  display: flex;
  padding: 0.25rem 0.375rem;
  border-radius: 0.375rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  background-color: ${(props) =>
    props.$medicineType === NovoPenMedicine.Basal
      ? 'var(--basal-color)'
      : 'var(--bolus-color)'};
`
const MedicineTagText = styled.span<{ $medicineType: NovoPenMedicine }>`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) =>
    props.$medicineType === NovoPenMedicine.Basal
      ? 'var(--white-color)'
      : 'var(--text-primary)'};
`
export const MedicineTag = ({
  medicineType
}: {
  medicineType: NovoPenMedicine
}) => {
  return (
    <MedicineTagWrapper $medicineType={medicineType}>
      <MedicineTagText
        data-testid="medicineTagText"
        $medicineType={medicineType}
      >
        {medicineType}
      </MedicineTagText>
    </MedicineTagWrapper>
  )
}

export const MedicineImage = ({
  medicineType
}: {
  medicineType: NovoPenMedicine
}) => {
  return (
    <MedicineVisual>
      <MedicineIMG
        src={medicineType === NovoPenMedicine.Basal ? Tresiba : NovoRapid}
        alt={'medicineImg'}
      ></MedicineIMG>
    </MedicineVisual>
  )
}

export const getMedicineString = (medicineType: NovoPenMedicine) => {
  switch (medicineType) {
    case NovoPenMedicine.Basal:
      return 'Tresiba® 100 penfill'
    case NovoPenMedicine.Bolus:
      return 'Novorapid® 100 penfill'
  }
}
