import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Patient } from '../../../core/entities/patient.entity'
import { SortEnum } from '../../ProviderPatients/ProviderPatients'
import { THead, TText, Th } from '../../Table/ScrollTableComponent'
interface HeaderComponentProps {
  patient: Patient | null
  sorting: SortEnum
  setSorting: Dispatch<SetStateAction<SortEnum>>
}
const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const SortIcon = styled(FontAwesomeIcon)<{ $active: boolean }>`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) =>
    props.$active ? 'var(--brand-primary-color)' : 'var(--text-lightest)'};
  margin-right: 0.25rem;
`
export const SortIconUp = styled(SortIcon)`
  transform: translateY(50%);
`
export const SortIconDown = styled(SortIcon)`
  transform: translateY(-50%);
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  min-height: 2.25rem;
`
export const UploadsHeaderComponent = ({
  patient,
  sorting,
  setSorting
}: HeaderComponentProps) => {
  const { t } = useTranslation()
  return (
    <THead>
      <Th>
        <HeaderWrapper>
          <TText>{t('Date of upload')}</TText>
          <SortIconWrapper
            data-testid="sortbutton"
            onClick={() =>
              setSorting(
                sorting === SortEnum.Ascending
                  ? SortEnum.Descending
                  : SortEnum.Ascending
              )
            }
          >
            <SortIconUp
              icon={faSortUp}
              $active={sorting === SortEnum.Ascending}
            />
            <SortIconDown
              icon={faSortDown}
              $active={sorting === SortEnum.Descending}
            />
          </SortIconWrapper>
        </HeaderWrapper>
      </Th>
      <Th>
        <TText>{t('Time')}</TText>
      </Th>
      {!patient && (
        <Th>
          <TText>{t('CPR')}</TText>
        </Th>
      )}
      {!patient && (
        <Th>
          <TText>{t('Patient name')}</TText>
        </Th>
      )}
      <Th>
        <TText>{t('Devices')}</TText>
      </Th>
      <Th>
        <TText>{t('Context')}</TText>
      </Th>
      <Th>
        <TText>{t('Upload user')}</TText>
      </Th>
      <Th>
        <TText>{t('Options')}</TText>
      </Th>
    </THead>
  )
}
