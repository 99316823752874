import { ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { getPatient } from '../../../core/api/get-patient-api'
import { usePatientContext } from '../../../core/contexts/patient-context'
import { useCapabilities } from '../../../hooks/use-capabilities'
import { BoxValues } from '../../DailyTable/DailyTable'
import { Td } from '../../Table/ScrollTableComponent'
import { dataState } from '../Uploads'
import { UploadsHiddenComponent } from '../UploadsHiddenComponent/UploadsHiddenComponent'

interface BoxEntryProps {
  value: string | dataState
  styling?: React.CSSProperties
  onClickFunction: null | (() => void)
  patientId: string
  children?: ReactChild
  testid?: string
}
interface ValueProps {
  hover: boolean
}
const RowBoxWrapper = styled.div`
  min-height: 2.25rem;
  display: flex;
  align-items: center;
`
const Value = styled.div<ValueProps>`
  color: var(--text-primary);
  &:hover {
    color: ${(props) =>
      props.hover ? 'var(--brand-primary-color)' : 'var(--text-primary)'};
    cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
  }
`
export const UploadsBoxComponent = ({
  value,
  styling,
  onClickFunction,
  patientId,
  children,
  testid
}: BoxEntryProps) => {
  const { setPatient } = usePatientContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const cprRegex = /^\d{6}-\d{4}$/
  const { trendsOrInsights } = useCapabilities()
  const linkToPatient = () => {
    getPatient(value.replace(/-/g, ''))
      .then((response) => {
        const patient = response.data.pop()
        if (!patient) {
          throw new Error(t('Patient not found'))
        }
        setPatient(patient)
        navigate(`/portal/patients/${patientId}/${trendsOrInsights}`)
      })
      .catch(function (error) {
        console.log(error.message)
      })
  }
  return (
    <Td
      style={
        value === dataState.hidden
          ? { ...styling, justifyContent: 'center' }
          : styling
      }
      data-testid={testid}
    >
      {' '}
      <RowBoxWrapper>
        {value !== dataState.hidden && value !== dataState.options && (
          <BoxValues>
            <Value
              onClick={cprRegex.test(value) ? linkToPatient : () => null}
              hover={cprRegex.test(value)}
              data-testid={`${testid}value`}
            >
              {value}
            </Value>
            {!Object.values(dataState).includes(value as dataState) &&
              children && <> {children} </>}
          </BoxValues>
        )}
        {value === dataState.hidden && (
          <UploadsHiddenComponent
            onClickFunction={onClickFunction}
            testid={`${testid}hidden`}
          />
        )}
        {value === dataState.options && <>{children}</>}
      </RowBoxWrapper>
    </Td>
  )
}
