import {
  faArrowsFromLine,
  faArrowsToLine
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Insight } from './Insight/Insight'
import { InsightViewObject } from './interfaces'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  width: 53.3125rem;
  z-index: 1;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  align-self: stretch;
`
const HeaderTitle = styled.span`
  font-family: inter;
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--text-primary);
`

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
`

const HeaderButton = styled.div`
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  cursor: pointer;
  background-color: var(--white-color);
  &:hover {
    background-color: var(--element-bg);
  }
`

const HeaderButtonIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: var(--text-primary);
`
const HeaderButtonText = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  font-family: inter;
  color: var(--text-primary);
`

export const Insights = ({
  data,
  maxPenInjection
}: {
  data: InsightViewObject
  maxPenInjection: number | null
}) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [updateExpandState, setUpdateExpandState] = useState<boolean>(false)

  if (data.reason !== null) return <div data-testid="HypoNodata"></div>
  return (
    <Wrapper data-testid="HypoData">
      <Header>
        <HeaderTitle>{t('Hypoglycemia')}</HeaderTitle>
        <HeaderButtons>
          <HeaderButton
            onClick={() => {
              setExpanded(true)
              setUpdateExpandState(!updateExpandState)
            }}
            data-testid="expandall"
          >
            <HeaderButtonIcon icon={faArrowsFromLine}></HeaderButtonIcon>
            <HeaderButtonText>{t('Expand All')}</HeaderButtonText>
          </HeaderButton>
          <HeaderButton
            onClick={() => {
              setExpanded(false)
              setUpdateExpandState(!updateExpandState)
            }}
            data-testid="collapseall"
          >
            <HeaderButtonIcon icon={faArrowsToLine}></HeaderButtonIcon>
            <HeaderButtonText>{t('Collapse All')}</HeaderButtonText>
          </HeaderButton>
        </HeaderButtons>
      </Header>
      {data?.days.map((day) => (
        <Insight
          key={'insight' + day.date}
          day={day.date}
          expanded={expanded}
          updateExpandState={updateExpandState}
          hypos={day.hypos}
          maxPenInjection={maxPenInjection}
        />
      ))}
    </Wrapper>
  )
}
