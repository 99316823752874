import { createContext, useContext } from 'react'
import { PeriodObject } from '../../components/Header/DevicesAndPeriod/DevicesAndPeriod'

type PeriodContextState = {
  period: PeriodObject | null
  setPeriod: (period: PeriodObject | null) => void
}

export const PeriodContext = createContext<PeriodContextState>({
  period: null,
  setPeriod: () => null
})

export function usePeriodContext() {
  return useContext(PeriodContext)
}

export default PeriodContext
