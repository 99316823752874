import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { setLanguage } from '../../core/i18n'

const Wrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
`
const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
`
const DropdownWrapper = styled.select`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: var(--white-color);
  background-color: var(--element-white-40);
  padding: 0.75rem 2.5rem 0.75rem 0.875rem;
  border-radius: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: '';
  text-indent: 0.0625rem;
  &::-ms-expand {
    display: none;
  }
  outline: none;
`
const DropdownText = styled.option`
  color: var(--white-color);
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
`
const SelectArrow = styled(FontAwesomeIcon)`
  color: var(--white-color);
  font-size: 1rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
`

export const ChangeLanguage = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation()

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
    setLanguage(value)
  }

  return (
    <Wrapper>
      <RelativeWrapper>
        <DropdownWrapper
          className={className}
          onChange={(e) => changeLanguage(e.target.value)}
          value={i18n.language}
          data-testid="selectLanguage"
        >
          <DropdownText data-testid="languageOption" value="da">
            Dansk
          </DropdownText>
          <DropdownText data-testid="languageOption" value="en">
            English
          </DropdownText>
        </DropdownWrapper>
        <SelectArrow icon={faChevronDown}></SelectArrow>
      </RelativeWrapper>
    </Wrapper>
  )
}
