import {
  faInfoCircle,
  faMoonStars,
  faUtensils
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalComponent } from '../../../components/Modal/Modal'
import { Spinner, spinnerSize } from '../../../components/Spinner/Spinner'
import { useSessionContext } from '../../../core/contexts/session-context'
import { UserType } from '../../../core/entities/user.entity'

interface HypoPredictionModalProps {
  closeFun: () => void
  handleHypoPrediction: () => void
  loadingHypo: boolean
}

const Wrapper = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  width: 31.25rem;
  gap: 2rem;
  box-sizing: border-box;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 2.25rem;
`
const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
`
const InfoWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.75rem;
`

const InfoIcon = styled(FontAwesomeIcon)`
  color: var(--blue);
  font-size: 1.125rem;
  line-height: 1.25rem;
`
const MainBodyText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--text-primary);
`
const Section = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.75rem;
  align-self: stretch;
`
const SectionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
`

const SectionIconWrapper = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
`
const SectionIcon = styled(FontAwesomeIcon)`
  color: var(--text-primary);
  font-size: 1rem;
  line-height: 1.5rem;
`
const SectionTitle = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2rem;
  color: var(--text-primary);
`
const SectionBody = styled(MainBodyText)`
  width: calc(100% - 2rem - 0.75rem);
`
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--brand-primary-color);
  height: 3rem;
  cursor: pointer;
  font-family: inter;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0375rem;
  font-weight: 600;
  color: var(--white-color);
  &:hover {
    background-color: var(--brand-darker-color);
  }
`
export const HypoPredictionModal = ({
  closeFun,
  handleHypoPrediction,
  loadingHypo
}: HypoPredictionModalProps) => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  return (
    <ModalComponent
      closeFun={closeFun}
      closetestid={'closeHypoPredictionModal'}
    >
      <Wrapper>
        <Title>{t('Intended use of hypo prediction')}</Title>
        {user?.type !== UserType.Patient && (
          <InfoContainer>
            <InfoWrapper>
              <InfoIcon icon={faInfoCircle}></InfoIcon>
              <MainBodyText>
                {t(
                  'This prediction will not be shown to the patient as it is only meant for testing the algorithm and will only be visible on the list overview when logged in as an admin.'
                )}
              </MainBodyText>
            </InfoWrapper>
          </InfoContainer>
        )}
        <Section>
          <SectionHeader>
            <SectionIconWrapper>
              <SectionIcon icon={faMoonStars}></SectionIcon>
            </SectionIconWrapper>
            <SectionTitle>
              {t('Intented for use before going to bed at night')}
            </SectionTitle>
          </SectionHeader>
          <SectionBody>
            {t(
              'The system is designed to predict low blood glucose (>3.9 mmol/L) during the following night from the time of prediction to 6.00 am.'
            )}
          </SectionBody>
        </Section>
        <Section>
          <SectionHeader>
            <SectionIconWrapper>
              <SectionIcon icon={faUtensils}></SectionIcon>
            </SectionIconWrapper>
            <SectionTitle>{t('Works best 4+ hours after dinner')}</SectionTitle>
          </SectionHeader>
          <SectionBody>
            {t(
              'The algorithm relies on glycemic patterns throughout the day, and especially during the evening after dinner, to calculate the risk of low blood glucose during the night. Since the CGM data has a 3-hour delay, it is advised to use the algorithm as late as possible before bedtime and at least 4 hours after dinner for more accurate predictions.'
            )}
          </SectionBody>
        </Section>
        <Button
          onClick={handleHypoPrediction}
          data-testid="hypoPredictionUnderstand"
        >
          {loadingHypo ? (
            <Spinner spinnersize={spinnerSize.small}></Spinner>
          ) : (
            t('I understand, make hypo prediction')
          )}
        </Button>
      </Wrapper>
    </ModalComponent>
  )
}
