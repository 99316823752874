import { ReactKeycloakProvider } from '@react-keycloak/web'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { AxiosRequest } from './core/api/axios-request'
import './core/i18n'
import { initOptions, keycloak } from './core/keycloak/keycloak'
import './index.css'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('groot')
const root = createRoot(container!)

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error)
  // If the user is logged out or there's an issue refreshing the token, the
  // user needs to login again.
  if (['onAuthLogout', 'onAuthRefreshError'].includes(event as string)) {
    window.location.reload()
  }
}

const tokenLogger = (tokens: any) => {
  console.log('onKeycloakTokens', tokens)
  AxiosRequest.setAccessToken(tokens?.token as string)
}

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={initOptions}
    onEvent={eventLogger}
    onTokens={tokenLogger}
    LoadingComponent={<div>Loading ...</div>}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReactKeycloakProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
