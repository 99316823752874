import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProviderPatientsType } from '../../../ProviderPatients/ProviderPatients'
import { BodyText } from '../../ProviderIntegrationModel'
import { getProviderTypeInfoText } from '../../ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`
interface SuccessPageProps {
  currentProvider: ProviderPatientsType | null
}
export const SuccessPage = ({ currentProvider }: SuccessPageProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <BodyText>
        {`${t('Congratulations')}, ${
          currentProvider ? getProviderTypeInfoText(currentProvider) : ''
        } ${t('is now connected')} `}
        &#127881;
      </BodyText>
      <BodyText>
        {t(
          'Please allow some hours for our system to automatically fetch all patients from the platform. Once that has been completed, you can start linking patients from the platform to your patients so that data is automatically fetched going forward'
        )}
      </BodyText>
      <BodyText>
        {t(
          'You can follow the status of automated data fetching on the Provider integration list.'
        )}
      </BodyText>
      <BodyText>
        {t(
          'Any new patients that have been pulled from the platform will show up on your clinics To Do, so that it can be mapped to a patient in your system'
        )}
      </BodyText>
    </Wrapper>
  )
}
