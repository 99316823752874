import {
  PatientSearchResponse,
  PatientSearchSelectionRequest
} from '../../containers/ClinicPatients/tables/interfaces'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getAllPatients(
  request: PatientSearchSelectionRequest
): Promise<PatientSearchResponse> {
  const response = await AxiosRequest.create().post(
    `${config.API_URL}/patients/all`,
    request
  )
  return response.data
}
