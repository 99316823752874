import { MutableRefObject } from 'react'
import styled from 'styled-components'
import {
  InfusionSiteChangesObject,
  OverviewResponse
} from '../../../../containers/Overview/Overview'
import { Patient } from '../../../../core/entities/patient.entity'
import { PeriodObject } from '../../../Header/DevicesAndPeriod/DevicesAndPeriod'
import { InfusionEventType } from '../../InfusionEventType/InfusionEventType'
import { InfusionOverview } from '../../InfusionOverview/InfusionOverview'
import { NoDataComponent } from '../../NoDataComponent/NoDataComponent'
import { createInfusionArrays } from '../../OverviewHelpers/OverviewHelpers'
import { GraphContainer, OverviewGraphType } from '../GraphComponent'

interface GraphWrapperProps {
  days: number
  firstDay: number
}
const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
`
const GraphWrapper = styled.div<GraphWrapperProps>`
  margin-left: ${(props) => 'calc((' + props.firstDay + ' - 1) * 8.75rem)'};
  width: ${(props) => 'calc(' + props.days + ' * 8.75rem + 15px)'};
  height: calc(5rem + 30px);
`
interface InfusionGraphData {
  firstWeek: number
  weekArray: Array<Array<InfusionSiteChangesObject>>
}
interface InfusionComponentProps {
  data: OverviewResponse
  period: PeriodObject
  patient: Patient
  screenshotWrapper: MutableRefObject<HTMLDivElement | null>
  handleUpdateOverview: () => void
}
export const InfusionComponent = ({
  data,
  period,
  patient,
  screenshotWrapper,
  handleUpdateOverview
}: InfusionComponentProps) => {
  if (data.hasPumpData) {
    const graphData: InfusionGraphData = createInfusionArrays(
      data.infusionSiteChanges,
      period.lengthOfPeriod
    )
    const entries = graphData.weekArray.map((item, index) => (
      <GraphWrapper
        key={'infusionOverviewGraph' + index}
        days={item.length}
        firstDay={index === 0 ? graphData.firstWeek : 1}
      >
        <InfusionOverview
          data={item}
          days={item.length}
          index={index}
          patient={patient}
          hasPumpData={data.hasPumpData}
        />
      </GraphWrapper>
    ))
    return (
      <>
        <InfusionEventType
          patient={patient}
          infusionSiteChangeEvent={data.infusionSiteChangeEvent}
          showInfusionSiteToggles={data.showInfusionSiteToggles}
          handleUpdateOverview={handleUpdateOverview}
          avg={data.averageInfusionSiteChange}
          wrapperRef={screenshotWrapper}
        />
        <GraphContainer type={OverviewGraphType.infusion} entries={entries} />
      </>
    )
  } else {
    return <NoDataComponent type={OverviewGraphType.infusion} />
  }
}
