import config from '../config'
import { AxiosRequest } from './axios-request'

export async function putNewDoseRecommendation(
  patientId: string,
  dose: number,
  portalVersion: string | undefined
): Promise<any> {
  const response = await AxiosRequest.create().put(
    config.API_URL +
      `/adaptt2d/${patientId}/doses/recommendations?dose=${dose}&productType=Portal&version=${portalVersion}`
  )
  return response.data as any
}
