import { faChevronRight, faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProviderObject } from '../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { useSessionContext } from '../../../../core/contexts/session-context'
import { UserType } from '../../../../core/entities/user.entity'
import { IntegrationTypeLabel } from '../../../IntegrationTypeLabel/IntegrationTypeLabel'
import {
  StatusBadge,
  getBadgeStatus,
  getStatusText
} from '../../../StatusBadge/StatusBadge'
import { ProviderManagementTypes } from '../../ProviderIntegrationModel'
import {
  getIntegrationType,
  getProviderTypeInfoText
} from '../../ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'

export enum IntegrationComponentStatus {
  notSetup = 'initial',
  deleted = 'deleted',
  verificationFailed = 'verificationFailed',
  idle = 'idle',
  verifying = 'verifying',
  running = 'running',
  failed = 'failed',
  failing = 'failing',
  disconnected = 'disconnected'
}
interface IntegrationComponentProps {
  provider: ProviderObject
  handleShowProviderSetup: () => void
}

const IntegrationComponentWrapper = styled.div<{ type: UserType | undefined }>`
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  width: 43.875rem;
  cursor: pointer;
  color: var(--text-primary);
  background-color: var(--white-color);
  &:hover {
    background-color: var(--brand-primary-4-color);
    color: var(--brand-primary-color);
    border-color: var(--brand-primary-12-color);
  }
  pointer-events: ${(props) =>
    props.type === UserType.HCPAdmin ? 'auto' : 'none'};
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const Title = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  font-family: inter;
  color: inherit;
`
const SubTitle = styled.span<{ color: string }>`
  font-size: 0.875rem;
  font-weight: 500;
  font-family: inter;
  color: ${({ color }) => color};
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`

const TextSeperator = styled(FontAwesomeIcon)`
  color: var(--text-lightest);
  font-size: 0.5rem;
  font-weight: 900;
  font-style: normal;
`
const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items center;
  color:inherit;
`

const ArrowIcon = styled(FontAwesomeIcon)<{ color: string }>`
  font-weight: 900;
  font-size: 1rem;
`

export const getProviderManagementTypeText = (
  type: ProviderManagementTypes | undefined,
  t: TFunction<'translation', undefined>
) => {
  switch (type) {
    case ProviderManagementTypes.patientmanaged:
      return t('Managed by patient')
    case ProviderManagementTypes.clinicmanaged:
      return t('Managed by clinic')
    default:
      return t('Managed by you')
  }
}
export const IntegrationComponent = ({
  provider,
  handleShowProviderSetup
}: IntegrationComponentProps) => {
  const [hover, setHover] = useState<boolean>(false)
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const text = getStatusText(provider.state, t)

  return (
    <IntegrationComponentWrapper
      type={user?.type}
      data-testid="IntegrationComponentWrapper"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleShowProviderSetup}
    >
      <TitleWrapper>
        <Title data-testid="IntegrationComponentTitle">{provider.name}</Title>
        <RowWrapper>
          <IntegrationTypeLabel type={getIntegrationType(provider.type)} />
          <SubTitle
            data-testid="IntegrationComponentSubTitle"
            color={hover ? 'var(--brand-primary-color)' : 'var(--text-medium)'}
          >
            {t(getProviderTypeInfoText(provider.type))}
          </SubTitle>
          <TextSeperator icon={faCircleSmall} />
          <SubTitle
            color={hover ? 'var(--brand-primary-color)' : 'var(--text-medium)'}
          >
            {getProviderManagementTypeText(provider.providerType, t)}
          </SubTitle>
        </RowWrapper>
      </TitleWrapper>
      <StatusWrapper>
        <StatusBadge text={text} state={getBadgeStatus(provider.state)} />
        {user?.type === UserType.HCPAdmin && (
          <ArrowIcon
            icon={faChevronRight}
            color={hover ? 'var(--brand-primary-color)' : 'var(--text-lighter)'}
          ></ArrowIcon>
        )}
      </StatusWrapper>
    </IntegrationComponentWrapper>
  )
}
