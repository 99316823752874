import { useTranslation } from 'react-i18next'
import { WarningInfo } from '../DownloadWarning/DownloadWarning'
import { OpenUploader } from '../OpenUploader/OpenUploader'
import { Guide, GuideComponent, Section, StepText } from './GuideComponent'

export const WindowsGuide = () => {
  const { t } = useTranslation()
  return (
    <Guide>
      <GuideComponent stepNumber={1} title={t('Install the application')}>
        <div>
          <StepText>
            {t('Right-click on the donwloaded file and select')}
          </StepText>
          <StepText style={{ color: 'var(--brand-primary-color)' }}>
            {' '}
            {t('Install as admin.')}
          </StepText>
        </div>
        <StepText>
          {`\n${t(
            'Then follow the installation wizard and complete all the steps.'
          )}`}
        </StepText>
        <WarningInfo
          styling={{ marginLeft: '-1.5rem' }}
          text={t(
            'If the application is not installed as an admin, the application may not work, as some of the device drivers require system admin rights.'
          )}
        />
      </GuideComponent>
      <GuideComponent stepNumber={2} title={t('Launch the application')}>
        <StepText>
          {t(
            'Find ‘Stenopool Uploader’ in you Applications folder and launch it.'
          )}
        </StepText>
      </GuideComponent>
      <GuideComponent
        stepNumber={3}
        title={t('Initialise the application and login')}
      >
        <Section>
          <StepText>
            {t(
              'When the application launches it will ask you to intialise the application. You do that by launching the application here from the portal. Click the button below:'
            )}
          </StepText>
          <OpenUploader styling={{ marginLeft: '-1.5rem' }} />
        </Section>
      </GuideComponent>
    </Guide>
  )
}
