import { PeriodObject } from '../../components/Header/DevicesAndPeriod/DevicesAndPeriod'
import config from '../config'
import { PatientView } from '../contexts/patient-view-context'
import { AxiosRequest } from './axios-request'

export async function getPatientPages({
  patientId,
  period
}: {
  patientId: string | undefined
  period: PeriodObject
}) {
  const start = new Date(
    new Date(period.endDate).setDate(
      period.endDate.getDate() - period.lengthOfPeriod + 1
    )
  )
  const end = new Date(
    new Date(period.endDate).setDate(period.endDate.getDate())
  )

  return (
    await AxiosRequest.create().get<{ pages: PatientView[] }>(
      `${config.API_URL}/patients/${patientId}/widgets`,
      {
        params: period.deviceID
          ? { start, end, devices: period.deviceID }
          : { start, end }
      }
    )
  ).data
}
