import { AxiosRequest } from './axios-request'
import config from '../config'
import { Patient } from '../entities/patient.entity'

export async function getPatientWithId(
  patientId: string,
  options: object = {}
): Promise<Patient> {
  const response = await AxiosRequest.create().get(
    config.API_URL + '/patients/' + patientId,
    options
  )
  return response.data as Patient
}
