import config from '../config'
import { AxiosRequest } from './axios-request'

interface ConsultationUploadData {
  failed: boolean
  unknownMRN: Array<string>
}
export async function uploadConsultationCsv(
  formData: FormData
): Promise<ConsultationUploadData> {
  const response = await AxiosRequest.create({
    headers: {
      'content-type': 'multipart/form-data'
    }
  }).post(config.API_URL + '/consultations/upload', formData)
  return response.data as ConsultationUploadData
}
