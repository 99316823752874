import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useMemo } from 'react'

export const useUploaderAuthSchemeUrl = () => {
  const { keycloak } = useKeycloak()

  const url = useMemo(() => {
    if (keycloak.refreshToken) {
      return `lineuploader://auth?refreshToken=${keycloak.refreshToken}`
    }
    return null
  }, [keycloak.refreshToken])

  return url
}

export const useRedirectOnUrlChange = (url: string | null | undefined) => {
  useEffect(() => {
    if (url) {
      window.location.assign(url)
    }
  }, [url])
}
