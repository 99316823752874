import {
  NovoPenBatteryStatus,
  NovoPenMedicine
} from '../../components/Graphs/Insights/interfaces'
import { NovoPenErrorCodes } from '../../components/SmartPenInfo/ErrorComponent/ErrorComponent'
import config from '../config'
import { AxiosRequest } from './axios-request'

export enum DoseRecommendationState {
  Ok = 'Ok',
  LowSensorCoverage = 'LowSensorCoverage'
}
export interface DoseRecommendationValue {
  recommendedDose: number
  bgReference: number
  errorValue: number
  state: DoseRecommendationState
  currentDose: number
  setBy: string
  currentDoseTime: string
  currentDoseTimeZone: string
}
export interface NovoPenObject {
  id: string | null
  name: string
  hardwareId: string
  lastConnection: string
  batteryStatus: NovoPenBatteryStatus
  modelName: string
  firmwareRevision: string
  type: NovoPenMedicine
  activePatientId: string
  newestUploadId: string
  lastConnectionZoned: string
  memory: number
  events: Array<NovoPenErrorCodes>
  removedByHcp: boolean | null
}
export interface getDoseRecommendationsResponse {
  doseRecommendation: DoseRecommendationValue
  novoPen: NovoPenObject
}

export async function getDoseRecommendations(
  patientId: string
): Promise<getDoseRecommendationsResponse> {
  const response = await AxiosRequest.create().get(
    config.API_URL + `/adaptt2d/${patientId}/doses/recommendations`
  )
  return response.data as getDoseRecommendationsResponse
}
