import config from '../config'
import { AxiosRequest } from './axios-request'

export async function removeNovoPen(
  hardwareId: string | null,
  patientId: string
): Promise<void> {
  const response = await AxiosRequest.create().post(
    `${config.API_URL}/patients/${patientId}/novopen_devices/${hardwareId}/unlink`
  )
  return response.data
}
