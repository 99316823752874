import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'

const Page = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
const Wrapper = styled.div`
  display: flex;
  width: 37.5rem;
  height: 18.625rem;
  padding: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 2rem;
  background-color: var(--element-bg);
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateY(50%) translateX(-50%);
  box-sizing: border-box;
`
const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 2rem;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.125rem;
  align-self: stretch;
  display: flex;
`

const SubTitle = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
`
export const BlindedPatient = () => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  return (
    <Page data-testid="blindedPatientPage">
      <Wrapper>
        <Title>
          {t('Sorry – You don’t have access to see your data in here.')}
        </Title>
        <SubTitle>
          {t('BlindedNoAccessMessage', {
            deploymentName: siteSettings?.deploymentName
          })}
        </SubTitle>
      </Wrapper>
    </Page>
  )
}
