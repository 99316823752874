import { TFunction } from 'react-i18next'
import { abbottLibreviewProvider } from '../../../core/api/abbottLibreviewProvider'
import { editAbbottLibreviewProvider } from '../../../core/api/editAbbottLibreviewProvider'
import { editDexcomApiProvider } from '../../../core/api/editDexcomApiProvider'
import { editFitbitApiProvider } from '../../../core/api/editFitbitApiProvider'
import { editMedtronicCarelinkProvider } from '../../../core/api/editMedtronicCarelinkProvider'
import { medtronicCarelinkProvider } from '../../../core/api/medtronicCarelinkProvider'
import { dexcomApiProvider } from '../../../core/api/post-dexcom-api-provider'
import { fitbitApiProvider } from '../../../core/api/post-fitbit-api-provider'
import { SnackBar, SnackBarType } from '../../../libraries/Toast/SnackBar'
import { IntegrationType } from '../../IntegrationTypeLabel/IntegrationTypeLabel'
import { ProviderPatientsType } from '../../ProviderPatients/ProviderPatients'
import { FormValues, ProviderTypes } from '../ProviderIntegrationModel'

const handleAbbottLibreviewProvider = async (data: FormValues) => {
  try {
    await abbottLibreviewProvider({
      name: data.alias,
      username: data.username,
      password: data.password,
      email: data.emailAddress,
      emailHost: data.emailHost,
      emailPassword: data.emailPassword
    })
  } catch (error) {
    console.log(error)
  }
}
const handleMedtronicCarelinkProvider = async (data: FormValues) => {
  try {
    await medtronicCarelinkProvider({
      name: data.alias,
      username: data.username,
      password: data.password,
      email: data.emailAddress,
      emailHost: data.emailHost,
      emailPassword: data.emailPassword
    })
  } catch (error) {
    console.log(error)
  }
}

const handleDexcomApiProvider = async (data: FormValues) => {
  try {
    await dexcomApiProvider({
      name: data.alias,
      clientId: data.clientId,
      clientSecret: data.clientSecret
    })
  } catch (error) {
    console.log(error)
  }
}
const handleFitbitApiProvider = async (data: FormValues) => {
  try {
    await fitbitApiProvider({
      name: data.alias,
      clientId: data.clientId,
      clientSecret: data.clientSecret
    })
  } catch (error) {
    console.log(error)
  }
}
export const handleProviderSubmit = (
  currentProvider: string | null,
  data: FormValues
) => {
  switch (currentProvider) {
    case ProviderTypes.abbottlibreviewwebprovider:
      return handleAbbottLibreviewProvider(data)
    case ProviderTypes.medtroniccarelinkwebprovider:
      return handleMedtronicCarelinkProvider(data)
    case ProviderTypes.dexcomapiprovider:
      return handleDexcomApiProvider(data)
    case ProviderTypes.fitbitapiprovider:
      return handleFitbitApiProvider(data)
  }
}

export interface HandleProviderEditData {
  name: string
  username?: string
  password?: string | null
  email?: string
  emailHost?: string
  emailPassword?: string | null
  clientId?: string | null
  clientSecret?: string
}
interface HandleEditProps {
  data: HandleProviderEditData
  providerId: string
  t: TFunction<'translation', undefined>
}

interface HandleEditWithProviderProps {
  data: HandleProviderEditData
  providerId: string
  t: TFunction<'translation', undefined>
  currentProvider: ProviderPatientsType | null
}
const handleEditAbbottLibreviewProvider = async ({
  data,
  providerId,
  t
}: HandleEditProps) => {
  try {
    await editAbbottLibreviewProvider(
      {
        name: data.name,
        username: data.username,
        password: data.password,
        email: data.email,
        emailHost: data.emailHost,
        emailPassword: data.emailPassword
      },
      providerId
    )
  } catch (error) {
    SnackBar({
      type: SnackBarType.Error,
      message: t(
        'An error has occurred when trying to edit the provider integration'
      )
    })
  }
}
const handleEditMedtronicCarelinkProvider = async ({
  data,
  providerId,
  t
}: HandleEditProps) => {
  try {
    await editMedtronicCarelinkProvider(
      {
        name: data.name,
        username: data.username,
        password: data.password,
        email: data.email,
        emailHost: data.emailHost,
        emailPassword: data.emailPassword
      },
      providerId
    )
  } catch (error) {
    SnackBar({
      type: SnackBarType.Error,
      message: t(
        'An error has occurred when trying to edit the provider integration'
      )
    })
  }
}

const handleEditDexcomApiProvider = async ({
  data,
  providerId,
  t
}: HandleEditProps) => {
  try {
    await editDexcomApiProvider(
      {
        name: data.name,
        clientId: data.clientId
      },
      providerId
    )
  } catch (error) {
    SnackBar({
      type: SnackBarType.Error,
      message: t(
        'An error has occurred when trying to edit the provider integration'
      )
    })
  }
}
const handleEditFitbitApiProvider = async ({
  data,
  providerId,
  t
}: HandleEditProps) => {
  try {
    await editFitbitApiProvider(
      {
        name: data.name,
        clientId: data.clientId
      },
      providerId
    )
  } catch (error) {
    SnackBar({
      type: SnackBarType.Error,
      message: t(
        'An error has occurred when trying to edit the provider integration'
      )
    })
  }
}
export const handleProviderEdit = async ({
  currentProvider,
  data,
  providerId,
  t
}: HandleEditWithProviderProps) => {
  switch (currentProvider) {
    case ProviderPatientsType.abbottlibreviewwebprovider:
      return handleEditAbbottLibreviewProvider({ data, providerId, t })
    case ProviderPatientsType.medtroniccarelinkwebprovider:
      return handleEditMedtronicCarelinkProvider({ data, providerId, t })
    case ProviderPatientsType.dexcomapiprovider:
      return handleEditDexcomApiProvider({ data, providerId, t })
    case ProviderPatientsType.fitbitapiprovider:
      return handleEditFitbitApiProvider({ data, providerId, t })
  }
}

export const getIntegrationType = (type: ProviderPatientsType) => {
  switch (type) {
    case ProviderPatientsType.dexcomapiprovider:
      return IntegrationType.api
    case ProviderPatientsType.fitbitapiprovider:
      return IntegrationType.api
    case ProviderPatientsType.dexcomclaritywebprovider:
      return IntegrationType.web
    case ProviderPatientsType.medtroniccarelinkwebprovider:
      return IntegrationType.web
    case ProviderPatientsType.abbottlibreviewwebprovider:
      return IntegrationType.web
    default:
      return IntegrationType.api
  }
}

export const getIntegrationTypeText = (
  type: IntegrationType,
  t: TFunction<'translation', undefined>
) => {
  switch (type) {
    case IntegrationType.web:
      return t('Web Integration')
    case IntegrationType.api:
      return t('API Integration')
  }
}
export const getIntegrationManagementText = (
  type: IntegrationType,
  t: TFunction<'translation', undefined>
) => {
  switch (type) {
    case IntegrationType.web:
      return t('Clinic Managed')
    case IntegrationType.api:
      return t('Patient Managed')
  }
}

export const getProviderTypeInfoText = (type: ProviderPatientsType | null) => {
  switch (type) {
    case ProviderPatientsType.abbottlibreviewwebprovider:
      return 'Abbott Libreview'
    case ProviderPatientsType.dexcomclaritywebprovider:
      return 'Dexcom Clarity'
    case ProviderPatientsType.medtroniccarelinkwebprovider:
      return 'Medtronic Carelink'
    case ProviderPatientsType.dexcomapiprovider:
      return 'Dexcom'
    case ProviderPatientsType.fitbitapiprovider:
      return 'Fitbit'
    default:
      return 'Unknown'
  }
}
