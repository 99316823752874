import { OperatingSystem } from '../../helpers/getOperatingSystem'

export interface Platform {
  os: OperatingSystem
  description: string
  platform: string
}

interface AvailablePlatforms {
  [key: string]: Platform
}

export const availablePlatforms: AvailablePlatforms = {
  [OperatingSystem.MacOS]: {
    os: OperatingSystem.MacOS,
    platform: 'darwin',
    description: 'Universal (Apple Silicon and Intel)'
  },
  [OperatingSystem.Windows]: {
    os: OperatingSystem.Windows,
    platform: 'win32',
    description: '32 and 64 bit'
  }
}

/**
 * Returns a platform supported by the installer to download
 *
 * @param os Operating system supported
 * @returns Platform name supported
 */

export function getPlatform(os: OperatingSystem | null): Platform | null {
  if (!os || !availablePlatforms[os]) {
    return null
  }
  return availablePlatforms[os]
}

/**
 * Returns a list of operating systems supported
 *
 * @param os Current operating system
 * @returns The rest of available operating systems
 */

export function getOtherPlatforms(platform: Platform | null): Platform[] {
  if (!platform) {
    return []
  }
  return Object.values(availablePlatforms).filter((value) => {
    return value.platform !== platform.platform
  })
}
