import { useTranslation } from 'react-i18next'
import { OpenUploader } from '../OpenUploader/OpenUploader'
import { Guide, GuideComponent, Section, StepText } from './GuideComponent'

export const MacGuide = () => {
  const { t } = useTranslation()
  return (
    <Guide>
      <GuideComponent stepNumber={1} title={t('Install the application')}>
        <StepText>
          {t(
            'Double-click the file you donwloaded and drag it to the Applications folder on your Mac.'
          )}
        </StepText>
      </GuideComponent>
      <GuideComponent stepNumber={2} title={t('Launch the application')}>
        <StepText>
          {t(
            'Find ‘Stenopool Uploader’ in you Applications folder and launch it.'
          )}
        </StepText>
      </GuideComponent>
      <GuideComponent
        stepNumber={3}
        title={t('Initialise the application and login')}
      >
        <Section>
          <StepText>
            {t(
              'When the application launches it will ask you to intialise the application. You do that by launching the application here from the portal. Click the button below:'
            )}
          </StepText>
          <OpenUploader styling={{ marginLeft: '-1.5rem' }} />
        </Section>
      </GuideComponent>
    </Guide>
  )
}
