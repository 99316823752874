import styled from 'styled-components'

export const SearchButton = styled.button`
  background-color: var(--brand-primary-color);
  color: var(--white-color);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 0.75rem;
  border: none;
  padding: 1.125rem 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 9rem;
  width: 9rem;
  cursor: pointer;
  &:disabled {
    cursor: default;
    background-color: var(--text-lightest);
  }
`
