import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface BasedOnDataComponentProps {
  daysWithData: number
  selectedDays: number
}

const Wrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  width: calc(100% - 1rem);
  gap: 0.5rem;
`
const Icon = styled(FontAwesomeIcon)`
  color: var(--blue);
  font-size: 0.875rem;
  font-weight: 900;
`

const Text = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 500;
`
export const BasedOnDataComponent = ({
  daysWithData,
  selectedDays
}: BasedOnDataComponentProps) => {
  const { t } = useTranslation()
  if (daysWithData === selectedDays) {
    return null
  }
  return (
    <Wrapper data-testid="basedOnComponent">
      <Icon icon={faInfoCircle}></Icon>
      <Text>{t('basedOn', { daysWithData, selectedDays })}</Text>
    </Wrapper>
  )
}
