/* eslint-disable prettier/prettier */
import { BarAndValue } from '../../Shared/BarComponents/BarComponents';
import { SizeEnum, WidgetState } from '../../Shared/Interfaces/Interfaces';
import { TimeInActivityConfig } from '../TimeInActivity';

interface BarsProps {
  data: { exercise: number; sleep: number } | null
  state: WidgetState
}
export const Bars = ({ data, state }: BarsProps) => {
  return (
    <>
      <BarAndValue
        size={SizeEnum.Small}
        state={state}
        color={TimeInActivityConfig.exercise.color}
        percentage={data ? data.exercise : null}
      />
      <BarAndValue
        size={SizeEnum.Small}
        state={state}
        color={TimeInActivityConfig.sleep.color}
        percentage={data ? data.sleep : null}
      />
    </>
  )
}
