import { MutableRefObject } from 'react'
import styled from 'styled-components'
import {
  ScreenshotComponent,
  ScreenShotIconSize
} from '../../../../components/Screenshot/ScreenshotComponent/ScreenshotComponent'
import dayjs from '../../../../core/dayjs/dayjs'

const EntryHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  padding-left: 1rem;
`
const EntryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;
  height: 2rem;
`
export const DayText = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-primary);
  text-transform: capitalize;
  line-height: 1.5rem;
`
export const DateText = styled(DayText)`
  text-transform: uppercase;
`

interface StackedDailyHeaderProps {
  day: string
  showScreenShot: boolean
  screenShotWrapper: MutableRefObject<HTMLDivElement | null>
  index: number
}
export const StackedDayHeader = ({
  day,
  showScreenShot,
  screenShotWrapper,
  index
}: StackedDailyHeaderProps) => {
  return (
    <EntryHeader>
      <EntryHeaderTitle>
        <DayText>{dayjs(day).format('dddd')}</DayText>
        <DateText>{dayjs(day).format('D MMM')}</DateText>
      </EntryHeaderTitle>
      <ScreenshotComponent
        size={ScreenShotIconSize.medium}
        showIcon={showScreenShot}
        index={index}
        refs={{
          wrapper: screenShotWrapper
        }}
        wrapperPadding={{ width: 64, height: 32 }}
        border={true}
      />
    </EntryHeader>
  )
}
