/* eslint-disable prettier/prettier */
import { createContext, useContext } from 'react'
import { ClinicViewDisplays, ClinicViewTypes } from '../entities/clinic.entity'

export type pagesObjectType = {
  [key in ClinicViewTypes]: { show: boolean; subViews: ClinicViewDisplays[] }
}

export const defaultClinicPagesObject = {
  Patients: { show: false, subViews: [] },
  Integrations: { show: false, subViews: [] },
  AuditLog: { show: false, subViews: [] },
  UploadActivity: { show: false, subViews: [] },
  PenTitrations: { show: false, subViews: [] }
}

export const ClinicViewsContext = createContext<pagesObjectType>(
  defaultClinicPagesObject
)

export function useClinicViewsContext() {
  return useContext(ClinicViewsContext)
}

export default ClinicViewsContext
