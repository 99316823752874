import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../core/dayjs/dayjs'
import { formatNumber } from '../../helpers/helpers'
import { Header, Wrapper } from '../BasalTooltip/BasalTooltip'
import { GraphDataElement, WrapsDayType } from '../Graphs/BolusGraph/BolusGraph'

const UnitRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`
const ToolTipEntryWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.375rem 0.5rem;
`
const ToolTipText = styled.span<{ color: string }>`
  fony-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props) => props.color};
`
const UnitText = styled.span`
  fony-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--text-lighter);
`
const HeaderText = styled(UnitText)`
  color: var(--text-primary);
`
const Body = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
`
const ToolTipLine = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: var(--text-lightest);
`
export interface BolusTooltipProps {
  bolusValues: GraphDataElement
  maxBolus: number
}

const checkValue = (value: number | null | undefined): value is number => {
  if (value === null || value === undefined || value === 0) {
    return false
  } else return true
}
interface TooltipEntryProps {
  name: { value: string; color: string }
  value: { value: string; color: string }
  unit?: string
}
export const ToolTipEntry = ({ name, value, unit }: TooltipEntryProps) => {
  return (
    <ToolTipEntryWrapper>
      <ToolTipText color={name.color}>{name.value}</ToolTipText>
      {unit ? (
        <UnitRow>
          <ToolTipText color={value.color}>{value.value}</ToolTipText>
          <UnitText>{unit}</UnitText>
        </UnitRow>
      ) : (
        <ToolTipText color={value.color}>{value.value}</ToolTipText>
      )}
    </ToolTipEntryWrapper>
  )
}
export const BolusTooltip = ({ bolusValues, maxBolus }: BolusTooltipProps) => {
  const getActualValue = (value: number, adjustment: number | null) => {
    if (adjustment !== null && adjustment !== null) {
      return value + Math.abs(adjustment)
    } else return value
  }
  const { t } = useTranslation()
  const tooltipPosition = useMemo(() => {
    if (
      bolusValues.wrapsDay !== null &&
      bolusValues.wrapsDay.type === WrapsDayType.After
    ) {
      return 5
    }
    return (
      ((dayjs(bolusValues.time).hour() +
        dayjs(bolusValues.time).minute() / 60 +
        dayjs(bolusValues.time).second() / 3600) /
        24) *
      100
    )
  }, [bolusValues])
  const grouping1 =
    checkValue(bolusValues.glucose) ||
    checkValue(bolusValues.carbs) ||
    checkValue(bolusValues.activeInsulin) ||
    bolusValues.extendedValues !== null ||
    checkValue(bolusValues.recommended)

  const grouping3 =
    checkValue(bolusValues.carbRatio) ||
    checkValue(bolusValues.insulinSensitivity) ||
    checkValue(bolusValues.high) ||
    checkValue(bolusValues.glucoseTarget)
  return (
    <Wrapper
      style={{ gap: '0' }}
      width={tooltipPosition}
      height={
        checkValue(bolusValues.value)
          ? Math.round(
              (getActualValue(bolusValues.value, bolusValues.adjustment) /
                (maxBolus + 5)) *
                100
            ) + (checkValue(bolusValues.carbs) ? 16 : 0)
          : 0
      }
    >
      <Header>
        <HeaderText>{dayjs(bolusValues.time).format('HH:mm')}</HeaderText>
      </Header>
      <Body>
        {checkValue(bolusValues.glucose) && (
          <ToolTipEntry
            name={{ value: t('Glucose'), color: 'var(--text-lighter)' }}
            value={{
              value: formatNumber({ value: bolusValues.glucose, decimals: 1 }),
              color: 'var(--text-primary)'
            }}
          />
        )}
        {checkValue(bolusValues.carbs) && (
          <ToolTipEntry
            name={{ value: t('Carbs'), color: 'var(--text-lighter)' }}
            value={{
              value: formatNumber({ value: bolusValues.carbs, decimals: 1 }),
              color: 'var(--text-primary)'
            }}
            unit={'g'}
          />
        )}
        {checkValue(bolusValues.activeInsulin) && (
          <ToolTipEntry
            name={{
              value: t('Active insulin'),
              color: 'var(--text-lighter)'
            }}
            value={{
              value: formatNumber({
                value: bolusValues.activeInsulin,
                decimals: 1
              }),
              color: 'var(--text-primary)'
            }}
            unit={'U'}
          />
        )}
        {bolusValues.extendedValues !== null && (
          <>
            <ToolTipEntry
              name={{
                value: `${t('First')} (${formatNumber({
                  value: bolusValues.extendedValues.initialRatio * 100,
                  decimals: 0
                })}%)`,
                color: 'var(--text-lighter)'
              }}
              value={{
                value: formatNumber({
                  value: bolusValues.extendedValues.initial,
                  decimals: 1
                }),
                color: 'var(--text-primary)'
              }}
              unit={'U'}
            />
            <ToolTipEntry
              name={{
                value: `${t('Overtime', {
                  amount: bolusValues.extendedValues.duration
                })} (${formatNumber({
                  value: bolusValues.extendedValues.extendedRatio * 100,
                  decimals: 0
                })}%)`,
                color: 'var(--text-lighter)'
              }}
              value={{
                value: formatNumber({
                  value: bolusValues.extendedValues.extended,
                  decimals: 1
                }),
                color: 'var(--text-primary)'
              }}
              unit={'U'}
            />
          </>
        )}
        {checkValue(bolusValues.adjustment) &&
          checkValue(bolusValues.recommended) && (
            <ToolTipEntry
              name={{ value: t('Suggested'), color: 'var(--text-primary)' }}
              value={{
                value: formatNumber({
                  value: bolusValues.recommended,
                  decimals: 1
                }),
                color: 'var(--text-primary)'
              }}
              unit={'U'}
            />
          )}
        {grouping1 && <ToolTipLine data-testid="grouping1" />}
        {checkValue(bolusValues.adjustment) && (
          <ToolTipEntry
            name={{ value: t('Adjusted'), color: 'var(--brand-primary-color)' }}
            value={{
              value:
                (bolusValues.adjustment > 0 ? '+' : '') +
                formatNumber({
                  value: bolusValues.adjustment,
                  decimals: 1
                }),
              color: 'var(--brand-primary-color)'
            }}
            unit={'U'}
          />
        )}
        {bolusValues.interrupted !== null && (
          <>
            <ToolTipEntry
              name={{ value: t('Programmed'), color: 'var(--text-lighter)' }}
              value={{
                value: formatNumber({
                  value: bolusValues.interrupted.expectedNormal,
                  decimals: 1
                }),
                color: 'var(--text-primary)'
              }}
              unit={'U'}
            />
            <ToolTipEntry
              name={{
                value: t('Interrupted'),
                color: 'var(--low-darker-color)'
              }}
              value={{
                value:
                  (bolusValues.interrupted.normal >
                  bolusValues.interrupted.expectedNormal
                    ? '+'
                    : '-') +
                  formatNumber({
                    value: bolusValues.interrupted.difference,
                    decimals: 1
                  }),
                color: 'var(--low-darker-color)'
              }}
              unit={'U'}
            />
          </>
        )}
        {checkValue(bolusValues.value) && (
          <ToolTipEntry
            name={{ value: t('Delivered'), color: 'var(--text-primary)' }}
            value={{
              value: formatNumber({
                value: bolusValues.value,
                decimals: 1
              }),
              color: 'var(--text-primary)'
            }}
            unit={'U'}
          />
        )}
        {grouping3 && <ToolTipLine data-testid="grouping3" />}
        {checkValue(bolusValues.carbRatio) && (
          <ToolTipEntry
            name={{ value: t('Carb ratio'), color: 'var(--text-lighter)' }}
            value={{
              value: formatNumber({
                value: bolusValues.carbRatio,
                decimals: 1
              }),
              color: 'var(--text-primary)'
            }}
            unit={'g/U'}
          />
        )}
        {checkValue(bolusValues.insulinSensitivity) && (
          <ToolTipEntry
            name={{
              value: t('Correction factor'),
              color: 'var(--text-lighter)'
            }}
            value={{
              value: formatNumber({
                value: bolusValues.insulinSensitivity,
                decimals: 1
              }),
              color: 'var(--text-primary)'
            }}
          />
        )}
        {checkValue(bolusValues.glucoseTarget) && (
          <ToolTipEntry
            name={{
              value: t('Glucose target'),
              color: 'var(--text-lighter)'
            }}
            value={{
              value: formatNumber({
                value: bolusValues.glucoseTarget,
                decimals: 1
              }),
              color: 'var(--text-primary)'
            }}
          />
        )}
        {checkValue(bolusValues.high) && (
          <ToolTipEntry
            name={{
              value: t('High'),
              color: 'var(--text-lighter)'
            }}
            value={{
              value: formatNumber({
                value: bolusValues.high,
                decimals: 1
              }),
              color: 'var(--text-primary)'
            }}
          />
        )}
      </Body>
    </Wrapper>
  )
}
