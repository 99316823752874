import {
  faBallPile as faBallPileRegular,
  faUsers as faUsersRegular
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBallPile as faBallPileSolid,
  faUsers as faUsersSolid
} from '@fortawesome/pro-solid-svg-icons'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import {
  ItemObject,
  SideMenuComponent
} from '../../components/SideMenuComponent/SideMenuComponent'
import { useClinicViewsContext } from '../../core/contexts/clinic-view-context'
import { ClinicViewDisplays } from '../../core/entities/clinic.entity'
import { usePopulationsHook } from './functions/usePopulationsHook'
import { PopulationOverview } from './tables/interfaces'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  min-width: 1700px;
`

export const ClinicPatients = () => {
  const clinicViews = useClinicViewsContext()
  const [updateMenu, setUpdateMenu] = useState<boolean>(false)
  const shouldGetPopulations = clinicViews.Patients.subViews.includes(
    ClinicViewDisplays.Population
  )
  const { populations, populationsError } = usePopulationsHook(
    updateMenu,
    shouldGetPopulations
  )
  const { t } = useTranslation()

  const data: Array<ItemObject> = [
    {
      icon: {
        regular: faUsersRegular,
        solid: faUsersSolid
      },
      alias: t('All Patients'),
      info: '',
      to: 'all-patients',
      show: clinicViews.Patients.subViews.includes(
        ClinicViewDisplays.AllPatient
      )
    },
    {
      icon: { regular: faBallPileRegular, solid: faBallPileSolid },
      alias: t('Population Management'),
      info: '',
      to: 'population-management',
      children: populations
        ? populations.map((population: PopulationOverview) => {
            return {
              alias: population.population.name,
              info: '',
              to: `population-management/${population.population.id}`,
              show: true
            }
          })
        : undefined,
      show: clinicViews.Patients.subViews.includes(
        ClinicViewDisplays.Population
      )
    }
  ]
  return (
    <Wrapper>
      <SideMenuComponent items={data}></SideMenuComponent>
      <Outlet context={{ updateMenu, setUpdateMenu }} />
    </Wrapper>
  )
}
