import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BgLogGraph } from '../../components/Graphs/BgLogGraph/BgLogGraph'
import {
  PeriodPosition,
  ScreenShotIconSize,
  ScreenshotComponent
} from '../../components/Screenshot/ScreenshotComponent/ScreenshotComponent'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import {
  ToggleButton,
  ToggleButtonText
} from '../../components/ToggleButton/ToggleButton'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePeriodContext } from '../../core/contexts/period-context'
import { formatNumber } from '../../helpers/helpers'
import { usePatientData } from '../../hooks/GetPatientData'
import { CheckBox, CheckMark } from '../Trends/Trends'

export interface BgmEvent {
  device: string | null
  localTime: string | null
  outOfRange: boolean | null
  subType: string | null
  time: string | null
  type: string | null
  units: string | null
  value: number
}

export interface BgmEventObject {
  day: string
  events: Array<BgmEvent>
}
export interface BgLogResponse {
  name: string
  type: string
  bgmEvents: Array<BgmEventObject>
  avgManualADay: number
  avgScansADay: number
}
interface ShowValues {
  showvalues: boolean
}

const ButtonText = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: inherit;
`
const ButtonSubTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const ButtonSubDarkText = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--text-primary);
`
const ButtonSubLightText = styled(ButtonSubDarkText)`
  color: var(--text-lighter);
`
const BgLogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1250px) {
    align-items: flex-start;
  }
`
const Container = styled.div`
  position: relative;
  width: fit-content;
  display: column;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`
const BgLogTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  width: calc(1134px + 9rem);
  min-width: calc(400px + 9rem);
  justify-content: space-between;
  align-items: flex-start;
`
const BgLogTitleText = styled.div`
  font-family: inter;
  font-weight: 700;
  font-size: 1rem;
  color: var(--text-primary);
`
const BgLogTitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 2rem;
`
const BgLogTitleSubSection = styled(BgLogTitleSection)`
  gap: 0.25rem;
`
const BgLogTitleSubText = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--text-primary);
  white-space: nowrap;
`
const BgLogGraphAndTitleWrapper = styled.div<ShowValues>`
  height: ${(props) => (props.showvalues ? '2.4375' : '1.375')}rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
`
const BgLogGraphWrapper = styled.div`
  height: 100%;
  width: 1134px;
  min-width: 400px;
`
const BgLogGraphTitle = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  width: 9rem;
`
const BgLogGraphTitleText = styled.span`
  font-size: 0.875rem;
  font-family: inter;
  font-weight: 500;
  color: var(--text-primary);
  text-transform: capitalize;
`
const BgLogGraphTitleDate = styled.span`
  font-size: 0.875rem;
  font-family: inter;
  color: var(--text-primary);
  font-weight: 700;
  text-transform: uppercase;
`
function isBgLogData(object: any): object is BgLogResponse {
  return object.type === 'bglog'
}
export const BgLog = () => {
  const screenShotWrapper = useRef<HTMLDivElement | null>(null)
  const [showValues, setShowValues] = useState<boolean>(false)
  const [showScans, setShowScans] = useState<boolean>(true)
  const [showManualReadings, setShowManualReadings] = useState<boolean>(true)
  const { patient } = usePatientContext()
  const { period } = usePeriodContext()
  const { patientViewData, patientViewError, patientViewLoading } =
    usePatientData(patient, period, { type: 'bglog', days: null }, null)
  const { t } = useTranslation()
  const isBgData = patientViewData && isBgLogData(patientViewData)
  const anyManualReadings = isBgData && patientViewData.avgManualADay !== 0
  const anyScanReadings = isBgData && patientViewData.avgScansADay !== 0

  if (patientViewError) {
    return <div>Error: {patientViewError.message}</div>
  } else if (!patient || !period) {
    return (
      <div data-testid="BgLogGraphNoPeriodOrPatient">
        {t('No Patient or Period found')}
      </div>
    )
  } else {
    return (
      <BgLogWrapper data-testid="BgLogPage" id="BgLogPage">
        <Container ref={screenShotWrapper}>
          <BgLogTitleWrapper>
            <BgLogTitleSection
              style={{ marginLeft: '9rem', justifyContent: 'flex-end' }}
            >
              <BgLogTitleText>{t('BG Log')}</BgLogTitleText>
              {patientViewLoading && (
                <Spinner spinnersize={spinnerSize.large} />
              )}
            </BgLogTitleSection>
            <BgLogTitleSection>
              <BgLogTitleSubSection>
                <CheckBox
                  active={showValues}
                  hover={true}
                  onClick={() => setShowValues(!showValues)}
                  data-testid="ShowValuesCheckBox"
                >
                  <CheckMark icon={faCheck}></CheckMark>
                </CheckBox>
                <BgLogTitleSubText>{t('Show values')}</BgLogTitleSubText>
              </BgLogTitleSubSection>
              <BgLogTitleSubSection>
                <ToggleButton
                  state={anyScanReadings ? showScans : null}
                  onClickFun={
                    anyScanReadings
                      ? () => setShowScans(!showScans)
                      : () => null
                  }
                  testid="ShowScansButton"
                >
                  <ButtonText>{t('Scans')}</ButtonText>
                  <ButtonSubTextWrapper>
                    {anyScanReadings && (
                      <>
                        <ButtonSubDarkText>
                          {formatNumber({
                            value: patientViewData.avgScansADay,
                            decimals: 1
                          })}
                        </ButtonSubDarkText>
                        <ButtonSubLightText>
                          {t('/day avg.')}
                        </ButtonSubLightText>
                      </>
                    )}
                  </ButtonSubTextWrapper>
                </ToggleButton>
                <ToggleButton
                  state={anyManualReadings ? showManualReadings : null}
                  onClickFun={
                    anyManualReadings
                      ? () => setShowManualReadings(!showManualReadings)
                      : () => null
                  }
                  testid="ShowManualButton"
                >
                  <ToggleButtonText>{t('Manual readings')}</ToggleButtonText>
                  <ButtonSubTextWrapper>
                    {anyManualReadings && (
                      <>
                        <ButtonSubDarkText>
                          {formatNumber({
                            value: patientViewData.avgManualADay,
                            decimals: 1
                          })}
                        </ButtonSubDarkText>

                        <ButtonSubLightText>
                          {t('/day avg.')}
                        </ButtonSubLightText>
                      </>
                    )}
                  </ButtonSubTextWrapper>
                </ToggleButton>
                <ScreenshotComponent
                  size={ScreenShotIconSize.medium}
                  showIcon={true}
                  index={0}
                  refs={{ wrapper: screenShotWrapper }}
                  wrapperPadding={{ width: 16, height: 48 }}
                  periodPlacement={{
                    position: PeriodPosition.bottomLeft,
                    margin: { bottom: 0, left: 8 }
                  }}
                  border={true}
                />
              </BgLogTitleSubSection>
            </BgLogTitleSection>
          </BgLogTitleWrapper>
          {isBgData && (anyManualReadings || anyScanReadings) ? (
            patientViewData.bgmEvents.map((item, index) => (
              <BgLogGraphAndTitleWrapper
                showvalues={showValues}
                key={'BgLogGraph' + index}
                data-testid={'BgLogGraph' + index}
              >
                <BgLogGraphTitle>
                  <BgLogGraphTitleText>
                    {dayjs(item.day).format('dddd')}
                  </BgLogGraphTitleText>
                  <BgLogGraphTitleDate>
                    {dayjs(item.day).format('D MMM')}
                  </BgLogGraphTitleDate>
                </BgLogGraphTitle>
                <BgLogGraphWrapper
                  style={index === 0 ? { marginBottom: '60px' } : {}}
                >
                  <BgLogGraph
                    data={item.events}
                    showvalues={showValues}
                    index={index}
                    showscans={showScans}
                    showmanuals={showManualReadings}
                  />
                </BgLogGraphWrapper>
              </BgLogGraphAndTitleWrapper>
            ))
          ) : patientViewLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner spinnersize={spinnerSize.large} />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {t('There is no BGM data')}
            </div>
          )}
        </Container>
      </BgLogWrapper>
    )
  }
}
