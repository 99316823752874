import config from '../config'
import { Patient } from '../entities/patient.entity'
import { AxiosRequest } from './axios-request'

export function getPatient(mrn: string, options: object = {}) {
  return AxiosRequest.create().get<Patient[]>(config.API_URL + '/patients', {
    params: { mrn },
    ...options
  })
}
