import { BlurComponent } from '../Blur/Blur'
import {
  PatientViewNavigationWrapper,
  PatientsViewButton,
  PatientsViewNavigationComponent,
  PatientsViewNavigationProps
} from '../PatientsViewNavigation/PatientsViewNavigation'

export const ClinicViewNavigation = ({
  pages
}: PatientsViewNavigationProps) => {
  const pagesToRender = pages.map((item) =>
    item.show ? (
      <PatientsViewButton
        key={item.to}
        to={item.to}
        notification={item.notification}
      >
        {item.label}
      </PatientsViewButton>
    ) : null
  )
  return (
    <div style={{ paddingBottom: '3rem' }}>
      <BlurComponent />
      <PatientViewNavigationWrapper>
        <PatientsViewNavigationComponent>
          {pagesToRender}
        </PatientsViewNavigationComponent>
      </PatientViewNavigationWrapper>
    </div>
  )
}
