import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'
import {
  ApplyButton,
  ButtonWrapper,
  CancelButton,
  HeaderTitle
} from '../../DeviceList/DeviceList'
import { HeaderComponent, HeaderProps } from '../TimeSelector'

const MainTitle = styled.div`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`
const SubTitle = styled.div`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-lighter);
`
export const Header = ({ cancel, apply, period, periodState }: HeaderProps) => {
  const { t } = useTranslation()
  return (
    <HeaderComponent data-testid="TimelineHeaderElement">
      <HeaderTitle>
        <MainTitle>{t('Select Time Period')}</MainTitle>
        <SubTitle>{t('Selected time period will affect all views')}</SubTitle>
      </HeaderTitle>
      <ButtonWrapper>
        <CancelButton onClick={cancel} data-testid="cancelButton">
          {t('Cancel')}
        </CancelButton>
        <ApplyButton
          onClick={apply}
          disabled={
            periodState && period
              ? periodState.lengthOfPeriod === period.lengthOfPeriod &&
                dayjs(periodState.endDate).format('DD-MM-YYYY') ===
                  dayjs(period.endDate).format('DD-MM-YYYY')
              : true
          }
          data-testid="applyButton"
        >
          {t('Apply Changes')}
        </ApplyButton>
      </ButtonWrapper>
    </HeaderComponent>
  )
}
