import { SortEnum } from '../../components/ProviderPatients/ProviderPatients'
import { UserType } from '../../core/entities/user.entity'

export interface GetPatientEventLogsProps {
  patientId: string
  size: number
  order: SortEnum
}

export enum NocturnalHypoglycemia {
  LowGlucose = 'LowGlucose',
  NotPredictable = 'NotPredictable',
  Stable = 'Stable'
}
export enum ProductType {
  Uploader = 'Uploader',
  Portal = 'Portal',
  AdaptMobileApp = 'AdaptMobileApp'
}
export enum PhoneOs {
  Android = 'Android',
  IOS = 'IOS'
}
export interface AlgorithmInfo {
  minValDay: number
  minValNight: number
  gvpDiffAfternoon: number
  probability: string
}
export interface PatientLogValue {
  patientId: string
  time: string
  localTime: string
  probability: number | null
  prediction: NocturnalHypoglycemia
  phoneOs: null | PhoneOs
  userId: string
  userName: string
  version: string
  you: boolean
  productType: ProductType
  algorithmInfo: AlgorithmInfo
  callerType: UserType
}
export interface PatientLogSearchResult {
  size: number
  order: SortEnum
  logs: Array<PatientLogValue>
}
