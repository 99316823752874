import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Patient } from '../../../../../core/entities/patient.entity'

const WardWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.75rem;
  background-color: var(--element-bg);
  &:hover {
    background-color: var(--element-bg-dark);
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.375rem;
`
const MRN = styled.span`
  color: var(--brand-primary-color);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.75rem;
`
const Name = styled.span`
  font-family: inter;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
  line-height: 1.75rem;
`
const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

enum RadioButtonState {
  selected,
  notSelected
}

const RadioButton = styled.div<{ $state: RadioButtonState }>`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.1875rem 0.234375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  border: ${(props) =>
    props.$state === RadioButtonState.notSelected
      ? '0.046875rem solid var(--element-stroke)'
      : '0.375rem solid var(--brand-primary-color)'};
  background-color: var(--white-color);
  box-sizing: border-box;
`

const You = styled.div`
  display: flex;
  height: 2rem;
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--green-darker);
  background: var(--green-gradient);
  font-family: inter;
  color: var(--green-darker);
  font-size: 0.75rem;
  font-weight: 700;
`
interface WardComponentProps {
  patient: Patient | null
  wards: Array<Patient | undefined>
  selectedPatient: Patient
  setSelectedPatient: Dispatch<SetStateAction<Patient>>
}
export const WardComponent = ({
  patient,
  wards,
  selectedPatient,
  setSelectedPatient
}: WardComponentProps) => {
  const { t } = useTranslation()

  const Ward = ({ ward, you }: { ward: Patient; you: boolean }) => {
    const isActive = ward.mrn === selectedPatient.mrn
    return (
      <WardWrapper
        onClick={() => setSelectedPatient(ward)}
        data-testid="wardComponent"
      >
        <LeftContent>
          <RadioButton
            $state={
              isActive
                ? RadioButtonState.selected
                : RadioButtonState.notSelected
            }
          ></RadioButton>
          <Column>
            <MRN>
              {ward.mrn.substring(0, 6) + '-' + ward.mrn.substring(6, 11)}
            </MRN>
            <Name>{ward.name}</Name>
          </Column>
        </LeftContent>
        {you && <You>{t('YOU')}</You>}
      </WardWrapper>
    )
  }

  return (
    <>
      {patient !== null && wards[0]?.mrn !== patient.mrn && (
        <Ward ward={patient} you={true}></Ward>
      )}

      {wards.map(
        (ward) =>
          ward && <Ward ward={ward} you={false} key={`ward${ward.name}`}></Ward>
      )}
    </>
  )
}
