import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'

interface ToggleButtonProps {
  state: true | false | null
  children?: ReactNode
  style?: CSSProperties
  testid?: string
  onClickFun?: () => void
}
const Button = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  z-index: 1;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`
export const ToggleButtonText = styled.div`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: inherit;
  white-space: nowrap;
`

const getButtonStyle = (state: true | false | null) => {
  switch (state) {
    case true:
      return {
        backgroundColor: 'var(--brand-primary-4-color)',
        border: '0.0625rem solid var(--brand-primary-12-color)',
        color: 'var(--brand-primary-color)'
      }
    case false:
      return {
        backgroundColor: 'var(--white-color)',
        border: '0.0625rem solid var(--text-lightest)',
        color: 'var(--text-primary)'
      }
    case null:
      return {
        backgroundColor: 'var(--element-bg)',
        border: '0.0625rem solid var(--text-lightest)',
        color: 'var(--text-lightest)',
        cursor: 'default'
      }
  }
}
export const ToggleButton = ({
  state,
  children,
  style,
  testid,
  onClickFun
}: ToggleButtonProps) => {
  return (
    <Button
      style={{ ...getButtonStyle(state), ...style }}
      data-testid={testid}
      onClick={onClickFun}
    >
      {children}
    </Button>
  )
}
