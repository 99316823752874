import {
  faArrowDownFromLine,
  faArrowUpFromLine
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DoseEditorState } from '../DoseEditor/DoseEditorBadge/DoseEditorBadge'

const Wrapper = styled.div<{ $color: string }>`
  color: ${(props) => props.$color};
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: inherit;
`
const Text = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: inherit;
`
interface DoseChangeMessageProps {
  state: DoseEditorState
  previousDose: number
  nextDose: number
}

export const DoseChangeMessage = ({
  state,
  previousDose,
  nextDose
}: DoseChangeMessageProps) => {
  const { t } = useTranslation()
  const changeValue = nextDose - previousDose
  const getConfig = () => {
    if (nextDose === previousDose)
      return { icon: null, text: t('No change'), color: 'var(--green)' }
    else
      return {
        icon: changeValue < 0 ? faArrowDownFromLine : faArrowUpFromLine,
        text: t(changeValue < 0 ? 'doseDecreased' : 'doseIncreased', {
          value: Math.abs(changeValue)
        }),
        color: state === DoseEditorState.auto ? 'var(--pink)' : 'var(--blue)'
      }
  }
  const config = getConfig()
  return (
    <Wrapper $color={config.color}>
      {config.icon !== null && <ArrowIcon icon={config.icon} />}
      <Text>{config.text}</Text>
    </Wrapper>
  )
}
