export enum SizeEnum {
  Small,
  Medium
}

export enum WidgetState {
  Loading,
  Empty,
  Data
}

export type BarsAndValuesProps = {
  percentage: number
  relativePercentage?: number
  value: number | null
  automated?: number | null
  autoPercentage?: number | null
}
