import Toastify from 'toastify-js'

export enum SnackBarType {
  Success,
  Error,
  Warning,
  Info
}

interface SnackBarMainProps {
  type: SnackBarType
  message: string
  onClick?: () => void
}

const SnackBarStyle = {
  borderRadius: '0.5rem',
  fontFamily: 'inter',
  fontSize: '1rem',
  color: 'var(--white-color)',
  padding: '1rem 0.5rem'
}
const SnackBarSuccessStyle = {
  ...SnackBarStyle,
  backgroundColor: 'var(--inRange-darker-color)'
}
const SnackBarErrorStyle = {
  ...SnackBarStyle,
  backgroundColor: 'var(--veryLow-darker-color)'
}
const SnackBarWarningStyle = {
  ...SnackBarStyle,
  backgroundColor: 'var(--veryHigh-darker-color)'
}
const SnackBarInfoStyle = {
  ...SnackBarStyle,
  backgroundColor: 'var(--brand-primary-color)'
}

const GetSnackBarStyle = (type: SnackBarType) => {
  switch (type) {
    case SnackBarType.Success:
      return SnackBarSuccessStyle
    case SnackBarType.Error:
      return SnackBarErrorStyle
    case SnackBarType.Warning:
      return SnackBarWarningStyle
    case SnackBarType.Info:
      return SnackBarInfoStyle
  }
}

export const SnackBar = ({ type, message, onClick }: SnackBarMainProps) =>
  Toastify({
    text: message,
    duration: 5000,
    close: true,
    offset: { x: '50%', y: '0%' },
    gravity: 'bottom', // `top` or `bottom`
    style: {
      ...GetSnackBarStyle(type),
      zIndex: '999',
      position: 'fixed',
      left: '50%',
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem'
    },
    onClick
  }).showToast()
