import { TrendsData } from '../../../hooks/GetPatientData'

export enum StateValues {
  monday = 0,
  tuesday = 1,
  wednesday = 2,
  thursday = 3,
  friday = 4,
  saturday = 5,
  sunday = 6,
  median = 0,
  fifty = 1,
  eighty = 2,
  hundred = 3
}
export function isTrendsData(object: any): object is TrendsData {
  return object.type === 'trends'
}

export const getDaysString = (state: Array<boolean>) => {
  let string = ''
  if (state[StateValues.monday]) string += '1,'
  if (state[StateValues.tuesday]) string += '2,'
  if (state[StateValues.wednesday]) string += '3,'
  if (state[StateValues.thursday]) string += '4,'
  if (state[StateValues.friday]) string += '5,'
  if (state[StateValues.saturday]) string += '6,'
  if (state[StateValues.sunday]) string += '7'
  if (string[string.length - 1] === ',')
    return string.substring(0, string.length - 1)
  else return string
}
