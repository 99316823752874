import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPatient } from '../core/api/get-patient-api'
import { Patient } from '../core/entities/patient.entity'

export const useCurrentPatient = () => {
  const [patient, setPatient] = useState<Patient | null>(null)
  const { t } = useTranslation()

  async function searchPatient(value: string): Promise<Patient | null> {
    try {
      const response = await getPatient(value.replace(/-/g, ''))
      const patient = response.data.pop()
      if (patient === undefined) {
        setPatient(null)
      } else {
        setPatient(patient)
      }
      return patient !== undefined ? patient : null
    } catch (error) {
      throw new Error(t('An error has occurred'))
    }
  }

  async function refreshPatient() {
    if (patient) {
      await searchPatient(patient?.mrn)
    }
  }

  return {
    patient,
    setPatient,
    searchPatient,
    refreshPatient
  }
}
