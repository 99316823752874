import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import styled from 'styled-components'
import { OperatingSystem } from '../../../../helpers/getOperatingSystem'

const DownloadButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--white-color);
  padding: 1rem;
  cursor: pointer;
  color: var(--text-lighter);
  &:hover {
    background-color: var(--brand-primary-4-color);
    color: var(--brand-primary-color);
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`

const ButtonTitle = styled.span<{ $hover: boolean }>`
  color: ${(props) =>
    props.$hover ? 'var(--brand-primary-color)' : 'var(--text-primary)'};
  font-family: inter;
  font-size: 1.125rem;
  font-weight: 600;
`
const ButtonInfo = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
`
const DownloadIcon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 900;
`
interface DownloadButtonComponentProps {
  children: ReactNode
  platform: string
  os: string
  downloadInstaller: (platform: string) => Promise<void>
  setRecentlyDownloaded: Dispatch<SetStateAction<OperatingSystem | null>>
}
export const DownloadButtonComponent = ({
  children,
  platform,
  os,
  downloadInstaller,
  setRecentlyDownloaded
}: DownloadButtonComponentProps) => {
  const [hover, setHover] = useState<boolean>(false)

  return (
    <DownloadButton
      onClick={() => {
        setRecentlyDownloaded(os as OperatingSystem)
        downloadInstaller(platform)
      }}
      key={os}
      data-testid={os}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Section>
        <ButtonTitle data-testid={`ButtonTitle${os}`} $hover={hover}>
          {os}
        </ButtonTitle>
        <ButtonInfo>{children}</ButtonInfo>
      </Section>
      <Section>
        <DownloadIcon icon={faArrowDownToLine} />
      </Section>
    </DownloadButton>
  )
}
