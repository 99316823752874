import { MutableRefObject, useState } from 'react'
import { formatNumber } from '../../../helpers/helpers'
import { SizeEnum, WidgetState } from '../Shared/Interfaces/Interfaces'
import { ScreenshotWidget } from '../Shared/ScreenshotWidget/ScreenshotWidget'
import { Wrapper, getWidgetState } from '../Shared/Wrapper/Wrapper'
import { Bars } from './Bars/Bars'
import { Header } from './Header/Header'
import { Legend } from './Legend/Legend'

interface TimeInRangeProps {
  size: SizeEnum
  wrapperRef?: MutableRefObject<HTMLDivElement | null>
  data: number[] | null
  state: WidgetState
}

export const TimeInRangeConfig = {
  veryHigh: {
    name: `> ${formatNumber({ value: 13.9, decimals: 1 })}`,
    color: 'var(--veryHigh-color)'
  },
  high: {
    name: `${formatNumber({ value: 10.0, decimals: 1 })}-${formatNumber({
      value: 13.9,
      decimals: 1
    })}`,
    color: 'var(--high-color)'
  },
  inRange: {
    name: `${formatNumber({ value: 3.9, decimals: 1 })}-${formatNumber({
      value: 10.0,
      decimals: 1
    })}`,
    color: 'var(--inRange-color)'
  },
  low: {
    name: `${formatNumber({
      value: 3.0,
      decimals: 1
    })}-${formatNumber({
      value: 3.9,
      decimals: 1
    })}`,
    color: 'var(--low-color)'
  },
  veryLow: {
    name: `< ${formatNumber({
      value: 3.0,
      decimals: 1
    })}`,
    color: 'var(--veryLow-color)'
  },
  titr: {
    name: '< 3.0',
    color: 'var(--inRange-tight)'
  }
}
export const TimeInRange = ({
  size,
  wrapperRef,
  data,
  state
}: TimeInRangeProps) => {
  const [screenshotBorder, setScreenshotBorder] = useState<boolean>(false)
  const actualState = getWidgetState(state, data)
  return (
    <Wrapper
      $screenshotBorder={screenshotBorder}
      $size={size}
      data-testid="timeInRange"
      ref={wrapperRef}
    >
      <ScreenshotWidget
        wrapperRef={wrapperRef}
        setScreenshotBorder={setScreenshotBorder}
      />
      <Header state={actualState} />
      <Bars
        data={
          data
            ? {
                veryHigh: data[0],
                high: data[1],
                inRange: data[2],
                low: data[3],
                veryLow: data[4],
                titr: data[5]
              }
            : null
        }
        state={actualState}
      />
      <Legend />
    </Wrapper>
  )
}
