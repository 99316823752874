import {
  faBatteryLow,
  faBatterySlash,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { TFunction } from 'react-i18next'
import { NovoPenDeviceEvent } from './Interfaces'

export const getNovoPenEventConfig = (
  novoPenEvent: NovoPenDeviceEvent,
  t: TFunction<'translation', undefined>
) => {
  switch (novoPenEvent) {
    case NovoPenDeviceEvent.ST_WARNING_EOL:
      return {
        icon: faBatteryLow,
        colors: 'var(--text-primary)',
        text: t(
          'The battery is running low, and the dose memory is approaching end of life.'
        )
      }
    case NovoPenDeviceEvent.ST_SENSOR_ERR:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t('The injected dose was not registered in the dose log.')
      }
    case NovoPenDeviceEvent.ST_INTERRUPTED_DOSE:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t(
          'The injected dose was not registered in the dose log. The injecting was initiated but not finished after 15 minutes.'
        )
      }
    case NovoPenDeviceEvent.ST_RECOVERABLE_ERR:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t('The injected dose was not registered in the dose log.')
      }
    case NovoPenDeviceEvent.ST_UNRECOVERABLE_ERR:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t(
          'The dose memory is defective and will not register further injections.'
        )
      }
    case NovoPenDeviceEvent.ST_BIG_DOSE:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t(
          'The injected dose was bigger than the pens display was able to show.'
        )
      }
    case NovoPenDeviceEvent.ST_CRC_CORRUPTED:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t('The injected dose was not registered in the dose log.')
      }
    case NovoPenDeviceEvent.ST_EXP_END_OF_LIFE:
      return {
        icon: faBatterySlash,
        colors: 'var(--text-primary)',
        text: t(
          'The battery is depleted, or the pen has been used for more than 5 years. The dose memory will not register further injections.'
        )
      }
    case NovoPenDeviceEvent.ST_DOSE_IN_PROGRESS:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t(
          'A dose has been selected or an injection has been initiated, data transfer is not possible. Dial the dose selector to zero and push it in, repeat the data transfer.'
        )
      }
  }
}
