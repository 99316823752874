import styled, { keyframes } from 'styled-components'

interface AutoBarProps {
  color: string
  width: number
  automated: number
}
const showAutoBar = () => keyframes`
 0% {opacity:0;}
 100% {opacity:1;}
`
export const AutoBar = styled.div<AutoBarProps>`
  opacity: 0;
  width: ${(props) =>
    props.automated > 0 ? 'calc(' + props.automated + '% + 0.125rem)' : '0'};
  height: calc(100% + 0.125rem);
  position: absolute;
  bottom: -0.0625rem;
  border-radius: 0.125rem;
  left: ${(props) => `calc(${props.width}% - ${props.automated}%)`};
  background-color: var(--pink);
  &.showAutoBar {
    animation-name: ${showAutoBar};
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-delay: 0.7s;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`
