import rehypeKatex from 'rehype-katex'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import style from '../../../markdown-styles.module.css'
import { MarkDownStylingModels } from '../interfaces/interfaces'
export const getStylingModel = (
  stylingModel: MarkDownStylingModels
): { className: string; remarkPlugins: any; rehypePlugins: any } => {
  switch (stylingModel) {
    case MarkDownStylingModels.table:
      return {
        className: style.table,
        remarkPlugins: [remarkGfm],
        rehypePlugins: []
      }
    case MarkDownStylingModels.math:
      return {
        className: style.math,
        remarkPlugins: [remarkMath],
        rehypePlugins: [[rehypeKatex, { output: 'mathml' }]]
      }
  }
}
