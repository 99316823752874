import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  SubmitHandler,
  UseFormRegister,
  UseFormSetValue,
  useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { createPatient } from '../../core/api/create-patient'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import {
  InputField,
  InputFieldLabel,
  InputFieldWrapper,
  InputWrapper
} from '../FormStyles/FormStyles'
import { cprFunction } from '../SearchBar/SearchBar'

interface CreatePatientModalProps {
  closeModal: () => void
  refresh: () => void
}

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  width: 31.25rem;
  gap: 2rem;
`

const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const SubmitButton = styled.button`
  border: 0.0625rem solid var(--brand-primary-color);
  width: 100%;
  padding: 1rem;
  background-color:var(--brand-primary-color);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: inter;
  color: var(--white-color);
  text-transform: uppercase;
  cursor: pointer;
  &:disabled {
    opacity: 0.16;
    cursor: default;
    border: 0.0625rem solid rgb(0,0,0,0);
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  width: 100%;
  box-sizing: border-box;
`
const InfoBoxIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  font-weight: 900;
  color: var(--yellow);
`
const InfoBoxText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--yellow-darker);
`
interface FormValues {
  CPR: string
  FullName: string
}
interface InputComponentProps {
  label: 'CPR' | 'FullName'
  register: UseFormRegister<FormValues>
  resetError: Dispatch<SetStateAction<Error | undefined>>
  errorState?: Error | undefined
  setValue: UseFormSetValue<any>
  getValues: (field?: string | string[] | undefined) => any
}
const InfoBoxComponent = () => {
  const { t } = useTranslation()
  return (
    <InfoBox data-testid="createPatientInfoBox">
      <InfoBoxIcon icon={faTriangleExclamation}></InfoBoxIcon>
      <InfoBoxText>
        {t('An error occurred when creating the patient')}
      </InfoBoxText>
    </InfoBox>
  )
}

const InputComponent = ({
  label,
  register,
  resetError,
  errorState,
  setValue,
  getValues
}: InputComponentProps) => {
  const { t } = useTranslation()
  const getLabel = (label: 'CPR' | 'FullName') => {
    switch (label) {
      case 'CPR':
        return t('CPR')
      case 'FullName':
        return t('Full name')
    }
  }
  const getPlaceHolder = (label: 'CPR' | 'FullName') => {
    switch (label) {
      case 'CPR':
        return t('Enter CPR number')
      case 'FullName':
        return t('Enter full name of patient')
    }
  }
  const [currentCPR, setCurrentCPR] = useState('')
  return (
    <InputWrapper>
      <InputFieldLabel
        style={
          errorState !== undefined ? { color: 'var(--yellow-darker)' } : {}
        }
      >
        {getLabel(label)}
      </InputFieldLabel>
      <InputFieldWrapper>
        <InputField
          data-testid={`createPatientInput${label}`}
          style={
            errorState !== undefined ? { borderColor: 'var(--yellow)' } : {}
          }
          {...register(label, {
            onChange:
              label === 'CPR'
                ? () =>
                    cprFunction({
                      setPatientError: () => resetError(undefined),
                      setValue,
                      getValues,
                      setCurrentCPR,
                      currentCPR
                    })
                : () => resetError(undefined),
            required: true,
            pattern:
              label === 'CPR'
                ? {
                    value: /^\d{6}-\d{4}$/,
                    message: t('wrong format')
                  }
                : undefined
          })}
          placeholder={getPlaceHolder(label)}
          autoComplete="off"
        ></InputField>
      </InputFieldWrapper>
    </InputWrapper>
  )
}
export const CreatePatientModal = ({
  closeModal,
  refresh
}: CreatePatientModalProps) => {
  const [errorState, setErrorState] = useState<Error | undefined>()

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      CPR: '',
      FullName: ''
    }
  })
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await createPatient({ mrn: data.CPR, name: data.FullName })
      closeModal()
      refresh()
      SnackBar({
        type: SnackBarType.Success,
        message: t('The patient has been created')
      })
    } catch (err) {
      setErrorState(new Error())
    }
  }
  return (
    <RelativeWrapper onClick={closeModal} data-testid="closeCreatePatientModal">
      <Wrapper
        onClick={function (event) {
          event.stopPropagation()
        }}
        data-testid="createPatientModal"
      >
        <Title>{t('Create new patient')}</Title>
        {errorState !== undefined && <InfoBoxComponent />}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputComponent
            label={'CPR'}
            register={register}
            resetError={setErrorState}
            errorState={errorState}
            setValue={setValue}
            getValues={getValues}
          />
          <InputComponent
            label={'FullName'}
            register={register}
            resetError={setErrorState}
            setValue={setValue}
            getValues={getValues}
          />
          <SubmitButton
            disabled={!isValid}
            type={'submit'}
            data-testid="createPatientButton"
          >
            {t('create patient')}
          </SubmitButton>
        </Form>
      </Wrapper>
    </RelativeWrapper>
  )
}
