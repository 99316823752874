import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import {
  glucoseRanges,
  glucoseType
} from '../../../constants/ranges/glucoseRanges'
import { formatNumber } from '../../../helpers/helpers'
import { CgmEnum } from '../../CGMWidget/CGMWidget'
import { LogoComponent } from '../../DataWidget/Shared/Header/Header'
import { WidgetState } from '../../DataWidget/Shared/Interfaces/Interfaces'
import {
  InformationLogoSize,
  InformationTooltip
} from '../../InformationTooltip/InformationTooltip'
import {
  TooltipType,
  getInformationTooltipParagraph
} from '../../InformationTooltip/InformationTooltipParagraphs'
import { SingleWidgetTypeEnum } from '../../SingleWidget/SingleWidget'
import { Spinner, spinnerSize } from '../../Spinner/Spinner'

const StatCss = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
`
const StatSideLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
`
const StatSideRight = styled(StatSideLeft)`
  width: 7rem;
  justify-content: flex-start;
`
const Title = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-primary);
`

const LogoWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Value = styled.div`
  font-family: inter;
  font-weight: 700;
  font-size: 1rem;
  text-align: right;
  width: 4.5rem;
`
const Unit = styled.div`
  font-family: inter;
  font-weight: 700;
  font-size: 0.625rem;
  color: var(--text-lighter);
  width: 3rem;
`

interface StatProps {
  title: string
  value: number | null
  unit: string
  state: WidgetState
  type:
    | CgmEnum.avg
    | SingleWidgetTypeEnum.SensorUsage
    | TooltipType.totalCarbs
    | SingleWidgetTypeEnum.DailySteps
}

export const Stat = ({ title, value, unit, state, type }: StatProps) => {
  const { t } = useTranslation()
  const getActualState = () => {
    if (state === WidgetState.Loading) {
      return state
    } else {
      if (value === null) {
        return WidgetState.Empty
      } else return WidgetState.Data
    }
  }
  const actualState = getActualState()
  const getLogo = () => {
    return (
      <>
        {actualState !== WidgetState.Data ? (
          <LogoComponent icon={faExclamationTriangle}></LogoComponent>
        ) : (
          <InformationTooltip
            paragraph={getInformationTooltipParagraph(type, t)}
            size={InformationLogoSize.small}
          />
        )}
      </>
    )
  }

  const getColor = () => {
    if (actualState !== WidgetState.Data)
      return { color: 'var(--text-lightest)' }
    else {
      switch (type) {
        case CgmEnum.avg:
          return { color: glucoseRanges(value ?? 0, glucoseType.default) }
        case SingleWidgetTypeEnum.DailySteps:
          return { color: 'var(--lime-green)' }
        case SingleWidgetTypeEnum.SensorUsage:
          return { color: 'var(--inRange-color)' }
        case TooltipType.totalCarbs:
          return { color: 'var(--text-primary)' }
      }
    }
  }

  return (
    <StatCss>
      <StatSideLeft>
        <Title>{title}</Title>
        <LogoWrapper>
          {actualState === WidgetState.Loading ? (
            <Spinner spinnersize={spinnerSize.small} />
          ) : (
            getLogo()
          )}
        </LogoWrapper>
      </StatSideLeft>
      <StatSideRight>
        <Value style={getColor()}>
          {value !== null && actualState === WidgetState.Data
            ? formatNumber({ value, decimals: type === CgmEnum.avg ? 1 : 0 })
            : '--'}
        </Value>
        <Unit>{t(unit)}</Unit>
      </StatSideRight>
    </StatCss>
  )
}
