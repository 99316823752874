import styled from 'styled-components'
import {
  InjectionWidgetsObject,
  StandardDeviation,
  WidgetsStack
} from '../../components/WidgetsStack/WidgetsStack'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePeriodContext } from '../../core/contexts/period-context'
import { usePatientData } from '../../hooks/GetPatientData'

import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WidgetState } from '../../components/DataWidget/Shared/Interfaces/Interfaces'
import {
  GraphComponent,
  OverviewGraphType
} from '../../components/OverviewComponents/GraphComponent/GraphComponent'
import { isOverviewData } from '../../components/OverviewComponents/OverviewHelpers/OverviewHelpers'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { infusionSiteChangeEventType } from '../../core/entities/patient.entity'
import {
  AverageGlucoseObject,
  BolusEventsObject,
  GlucoseEventsObject,
  PumpActivity,
  TimeInAutomationObject,
  TimeInRangeObject,
  TotalInsulinObject
} from '../StackedDaily/Interfaces/Interfaces'

export interface DailyActivity {
  time: number
  lightly: number | null
  fairly: number | null
  very: number | null
  steps: number | null
}
export interface TimeInActivityObject {
  activity: DailyActivity
  daysWithData: number
  selectedDays: number
}
export interface AverageCarbs {
  avgCarbs: number
  daysWithData: number
  selectedDays: number
}
export enum InfusionChangeType {
  pod = 'pod',
  none = 'none',
  tubing = 'tubing',
  cannula = 'cannula',
  multiple = 'multiple'
}
export interface CGMEvent {
  day: string
  events: Array<GlucoseEventsObject>
}
export interface BolusEvent {
  day: string
  events: Array<BolusEventsObject>
}
export interface InfusionSiteChangesObject {
  changeType: InfusionChangeType
  day: string
  daysSinceLast: number | null
  time: string | null
}

export interface AverageInfusionSiteChangeObject {
  avgInfusionSiteChange: number
  daysWithData: number
  selectedDays: number
}
export interface OverviewResponse {
  type: string
  name: string
  cgmEvents: Array<CGMEvent> | null
  bolusEvents: Array<BolusEvent> | null
  averageGlucose: AverageGlucoseObject | null
  sensorUsage: number | null
  totalCarbs: AverageCarbs | null
  timeInRange: TimeInRangeObject | null
  totalInsulin: TotalInsulinObject | null
  standardDeviation: StandardDeviation | null
  cv: number | null
  infusionSiteChanges: InfusionSiteChangesObject[]
  showInfusionSiteToggles: boolean
  infusionSiteChangeEvent: infusionSiteChangeEventType
  hasPumpData: boolean
  glucoseDensity: number | null
  averageInfusionSiteChange: AverageInfusionSiteChangeObject | null
  timeInAutomation: TimeInAutomationObject | null
  pumpActivity: PumpActivity | null
  timeInActivity: TimeInActivityObject | null
  injectionWidgets: InjectionWidgetsObject | null
}

const Wrapper = styled.div`
  margin: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 1400px) {
    justify-content: flex-start;
  }
  padding-bottom: 3rem;
`
const Container = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  gap: 2rem;
  flex-direction: row;
`
const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const SpinnerWrapper = styled.div`
  width: calc(7 * 8.75rem + 15px);
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const getTimeInRangePercentages = (ranges: TimeInRangeObject | null) => {
  if (ranges === null) {
    return null
  } else {
    return [
      ranges.range5,
      ranges.range4,
      ranges.range3,
      ranges.range2,
      ranges.range1,
      ranges.timeInTightRange
    ].map((r) => r.percentage)
  }
}
export function Overview() {
  const { t } = useTranslation()
  const { patient } = usePatientContext()
  const { period } = usePeriodContext()
  const screenShotWrapper = useRef<HTMLDivElement | null>(null)
  const [updateOverview, setUpdateOverview] = useState<boolean>(false)
  const handleUpdateOverview = () => {
    setUpdateOverview(!updateOverview)
  }
  const { patientViewData, patientViewError, patientViewLoading } =
    usePatientData(
      patient,
      period,
      { type: 'overview', days: null },
      updateOverview
    )
  if (patientViewError) {
    return (
      <div data-testid="overviewNoData">
        {patientViewError && <div>Error: {patientViewError.message}</div>}
      </div>
    )
  } else if (
    period &&
    patient &&
    patientViewData &&
    isOverviewData(patientViewData)
  ) {
    return (
      <Wrapper>
        <Container ref={screenShotWrapper}>
          <SubSection>
            <GraphComponent
              data={patientViewData}
              period={period}
              type={OverviewGraphType.glucose}
              patient={patient}
              handleUpdateOverview={handleUpdateOverview}
            />
            <GraphComponent
              data={patientViewData}
              period={period}
              type={OverviewGraphType.infusion}
              patient={patient}
              handleUpdateOverview={handleUpdateOverview}
            />
          </SubSection>
          <WidgetsStack
            timeInRangeBars={patientViewData.timeInRange}
            totalInsulin={patientViewData.totalInsulin}
            averageGlucose={patientViewData.averageGlucose}
            sensorUsage={patientViewData.sensorUsage}
            standardDeviation={patientViewData.standardDeviation}
            cv={patientViewData.cv}
            totalCarbs={patientViewData.totalCarbs}
            state={WidgetState.Data}
            timeInAutomation={patientViewData.timeInAutomation}
            pumpActivity={patientViewData.pumpActivity}
            timeInActivity={patientViewData.timeInActivity}
            injectionWidgets={patientViewData.injectionWidgets}
          ></WidgetsStack>
        </Container>
      </Wrapper>
    )
  } else if (!patient || !period) {
    return (
      <Wrapper>
        <div>{t('No overview data available')}</div>
        <WidgetsStack
          totalCarbs={null}
          totalInsulin={null}
          cv={null}
          averageGlucose={null}
          sensorUsage={null}
          standardDeviation={null}
          timeInRangeBars={null}
          timeInAutomation={null}
          pumpActivity={null}
          timeInActivity={null}
          injectionWidgets={null}
          state={patientViewLoading ? WidgetState.Loading : WidgetState.Empty}
        ></WidgetsStack>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <Container>
          <SpinnerWrapper>
            <Spinner spinnersize={spinnerSize.large}></Spinner>
          </SpinnerWrapper>
          <WidgetsStack
            totalCarbs={null}
            totalInsulin={null}
            cv={null}
            averageGlucose={null}
            sensorUsage={null}
            standardDeviation={null}
            timeInRangeBars={null}
            timeInAutomation={null}
            pumpActivity={null}
            timeInActivity={null}
            injectionWidgets={null}
            state={WidgetState.Loading}
          ></WidgetsStack>
        </Container>
      </Wrapper>
    )
  }
}
