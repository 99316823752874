import { saveAs } from 'file-saver'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUploaderBlobByPlatform } from '../../core/api/get-uploader'
import {
  OperatingSystem,
  getOperatingSystem
} from '../../helpers/getOperatingSystem'
import { DownloadStatus, useDownloader } from '../../hooks/use-downloader'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import {
  availablePlatforms,
  getOtherPlatforms,
  getPlatform
} from './platform-utils'

export function useGetUploader() {
  const { t } = useTranslation()
  const { state, download, reset } = useDownloader()
  const currentOs = getOperatingSystem()
  const currentPlatform = getPlatform(currentOs)
  const otherPlatforms = getOtherPlatforms(currentPlatform)

  useEffect(() => {
    if (state.status === DownloadStatus.Failed) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('The download process failed, please try again.')
      })
    }
  }, [state, t])

  async function downloadInstaller(platform: string) {
    const data = await download(
      (progressCb: (progress: ProgressEvent) => void) => {
        return getUploaderBlobByPlatform(platform, progressCb)
      }
    )
    if (data) {
      saveAs(data, getFileName(platform))
    }
  }

  return {
    state,
    currentOs,
    currentPlatform,
    otherPlatforms,
    downloadInstaller,
    reset
  }
}

function getExtension(platform: string) {
  if (platform === availablePlatforms[OperatingSystem.MacOS].platform) {
    return '.dmg'
  }
  if (platform === availablePlatforms[OperatingSystem.Windows].platform) {
    return '.exe'
  }
  return ''
}

function getFileName(platform: string) {
  if (platform === availablePlatforms[OperatingSystem.MacOS].platform) {
    return `Stenopool-Uploader-mac-current${getExtension(platform)}`
  }
  if (platform === availablePlatforms[OperatingSystem.Windows].platform) {
    return `Stenopool-Uploader-win-current${getExtension(platform)}`
  }
  return ''
}
