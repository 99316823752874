import styled from 'styled-components'
import { TimeInRangeObject } from '../../../StackedDaily/Interfaces/Interfaces'

interface TimeInRangeProps {
  color: string
  percentage: number
}
const TimeInRangeWrap = styled.div`
  width: 100%;
  height: 1rem;
  border-radius: 0.25rem;
  overflow: hidden;
`
const TimeInRangeBar = styled.div`
  display: flex;
  border: 0.0625rem solid var(--text-lightest);
  flex-direction: row;
  width: calc(100% - 0.125rem);
  height: calc(100% - 0.125rem);
  border-radius: 0.25rem;
  position: relative;
`
const TimeInRangeFill = styled.div`
  width: calc(100% + 0.125rem);
  height: calc(100% + 0.125rem);
  position: absolute;
  left: -0.0625rem;
  bottom: -0.0625rem;
  display: flex;
  flex-direction: row;
`
const TimeInRangeFiller = styled.div<TimeInRangeProps>`
  background-color: ${(props) => props.color};
  width: ${(props) => props.percentage + '%'};
  height: 100%;
  z-index: 1;
`
const getTimeInRangeColor = (index: number) => {
  switch (index) {
    case 0:
      return 'var(--veryLow-color)'
    case 1:
      return 'var(--low-color)'
    case 2:
      return 'var(--inRange-color)'
    case 3:
      return 'var(--high-color)'
    default:
      return 'var(--veryHigh-color)'
  }
}
export const TimeInRangeComponent = ({
  data
}: {
  data: TimeInRangeObject | null
}) => {
  return (
    <TimeInRangeWrap>
      <TimeInRangeBar>
        <TimeInRangeFill>
          {data !== null &&
            Object.values(data).map(
              (value, idx) =>
                idx !== 5 && (
                  <TimeInRangeFiller
                    key={'timeInRangeFiller' + idx}
                    data-testid={idx + 'timeInRange'}
                    color={getTimeInRangeColor(idx)}
                    percentage={value.percentage}
                  />
                )
            )}
        </TimeInRangeFill>
      </TimeInRangeBar>
    </TimeInRangeWrap>
  )
}
