import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DeviceEnum, FilterEnum } from '../IntegrationFilters'

const Wrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid
    ${(props) =>
      props.active ? 'var(--brand-primary-16-color)' : 'var(--text-lightest)'};
  background-color: ${(props) =>
    props.active ? 'var(--brand-primary-4-color)' : 'none'};
  color: ${(props) =>
    props.active ? 'var(--brand-primary-color)' : 'var(--text-primary)'};
  gap: 0.375rem;
`
const Icon = styled(FontAwesomeIcon)`
  color: inherit;
  font-weight: 900;
  font-size: 0.875rem;
`
const Text = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: inherit;
`

const getFilterButtonText = (
  type: DeviceEnum | FilterEnum,
  t: TFunction<'translation', undefined>
) => {
  switch (type) {
    case DeviceEnum.allDevices:
      return t('All devices')
    case DeviceEnum.dexcom:
      return t('Dexcom Clarity')
    case DeviceEnum.dexcomApi:
      return t('Dexcom API')
    case DeviceEnum.abbott:
      return t('Abbott Libreview')
    case DeviceEnum.medtronic:
      return t('Medtronic Carelink')
    case DeviceEnum.fitbit:
      return t('Fitbit')
    case FilterEnum.allFilters:
      return t('All filters')
    case FilterEnum.linked:
      return t('Linked')
    case FilterEnum.ignored:
      return t('Ignored')
  }
}
interface IntegrationButtonComponentProps {
  type: FilterEnum | DeviceEnum
  state: boolean
  icon?: IconDefinition
  handleFilter: (type: FilterEnum | DeviceEnum) => void
}
export const IntegrationButtonComponent = ({
  type,
  state,
  icon,
  handleFilter
}: IntegrationButtonComponentProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper
      onClick={() => handleFilter(type)}
      active={state}
      data-testid={'integrationButtonComponent' + type}
    >
      {icon && <Icon icon={icon}></Icon>}
      <Text>{getFilterButtonText(type, t)}</Text>
    </Wrapper>
  )
}
