import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OverviewGraphType } from '../GraphComponent/GraphComponent'

const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
`
const TitleText = styled.div`
  font-family: inter;
  font-weight: 700;
  font-size; 1.5rem;
  color: var(--text-primary);
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 4rem;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 1.5rem;
  height: 12.5rem;
`
const Title = styled.div`
  font-family: inter:
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-medium);
`
const Body = styled.span`
  color: var(--text-lighter);
  text-align: center;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
`
interface NoDataComponentProps {
  type: OverviewGraphType
}
export const NoDataComponent = ({ type }: NoDataComponentProps) => {
  const { t } = useTranslation()
  return (
    <NoDataWrapper>
      <TitleText>
        {t(
          type === OverviewGraphType.infusion
            ? 'Infusion Site Changes'
            : 'Glucose Overview'
        )}
      </TitleText>
      <Wrapper>
        <Title>{t('No infusion set changes detected for this period')}</Title>
        <Body>{t('This may be because the user does not have a pump.')}</Body>
      </Wrapper>
    </NoDataWrapper>
  )
}
