import { ProviderObject } from '../../containers/ClinicIntegrations/ClinicIntegrations'
import config from '../config'
import { AxiosRequest } from './axios-request'
export async function getProvider(
  options: object = {}
): Promise<Array<ProviderObject>> {
  const response = await AxiosRequest.create().get(
    `${config.API_URL}/cmi/providers`,
    options
  )
  return response.data
}
