import { BasedOnDataComponent } from '../../BasedOnDataComponent/BasedOnDataComponent'
import { SizeEnum, WidgetState } from '../Shared/Interfaces/Interfaces'
import { Wrapper, getWidgetState } from '../Shared/Wrapper/Wrapper'
import { Bars } from './Bars/Bars'
import { Header } from './Header/Header'
import { Legend } from './Legend/Legend'

export interface InsulinData {
  percentage: number
  value: number
  relativePercentage?: number
  automated: number | null
  autoPercentage: number | null
}

export interface TotalInsulinWidgetData {
  bolus: InsulinData
  basal: InsulinData
}
interface TotalInsulinProps {
  size: SizeEnum
  state: WidgetState
  data: {
    bars: TotalInsulinWidgetData | null
    total: number | null
    basedOn: { selectedDays: number; daysWithData: number } | null
  }
  max: number | null
}

export const TotalInsulinConfig = {
  basal: {
    color: 'var(--basal-color)'
  },
  bolus: {
    color: 'var(--bolus-color)'
  }
}
export const TotalInsulin = ({ size, state, data, max }: TotalInsulinProps) => {
  const actualState = getWidgetState(state, data.bars)

  return (
    <Wrapper $size={size} data-testid="totalInsulin">
      <Header size={size} state={actualState} total={data.total} />
      <BasedOnDataComponent
        daysWithData={data.basedOn?.daysWithData ?? 0}
        selectedDays={data.basedOn?.selectedDays ?? 0}
      />
      <Bars data={data.bars} state={actualState} />
      <Legend data={data.bars} size={size} max={max} />
    </Wrapper>
  )
}
