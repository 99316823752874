import config from '../config'
import { AxiosRequest } from './axios-request'

export interface ConsultationData {
  consultationId: string
  consultationTime: string
  localPatientId: string
  mrn: string
  name: string
  latestLocalData: null | string
  latestProviderData: null | string
  link: null | string
}
export async function getConsultations(): Promise<Array<ConsultationData>> {
  const response = await AxiosRequest.create().get(
    config.API_URL + '/consultations'
  )
  return response.data as Array<ConsultationData>
}
