import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { deleteUpload } from '../../../core/api/delete-upload-api'
import { getPatientWithId } from '../../../core/api/get-patient-withid-api'
import { getUploadEvents } from '../../../core/api/get-upload-events'
import { getUserWithId } from '../../../core/api/get-user-withid-api'
import { Patient } from '../../../core/entities/patient.entity'
import { User } from '../../../core/entities/user.entity'
import { DeleteModal } from '../../DeleteModal/DeleteModal'
import { Modal } from '../../Modal/Modal'
import { Tr } from '../../Table/ScrollTableComponent'
import { UploadContext } from '../UploadContext/UploadContext'
import { dataState, Upload } from '../Uploads'
import { UploadsBoxComponent } from '../UploadsBoxComponent/UploadsBoxComponent'
import { OptionsComponent } from '../UploadsOptionsComponent/UploadsOptionsComponent'

interface RowComponentProps {
  item: Upload
  index: number
  user: User | null
  onDelete: () => void
  patient: Patient | null
}

export const UploadUserButton = styled.div`
  background-color: var(--brand-primary-12-color);
  color: var(--brand-darker-color);
  padding: 0.125rem 0.25rem;
  display flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  font-family: inter;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
`

export const UploadsRowComponent = ({
  item,
  index,
  user,
  onDelete,
  patient
}: RowComponentProps) => {
  const { t } = useTranslation()
  const [patientInfo, setPatientInfo] = useState<{
    cpr: string
    name: string
  } | null>(null)
  const [userName, setUsername] = useState<string | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  // TODO snackbar issue for this function
  const getPatientInfo = () => {
    getPatientWithId(item.patientId).then((response) => {
      setPatientInfo({
        cpr: response.mrn.substring(0, 6) + '-' + response.mrn.substring(6, 10),
        name: response.name
      })
    })
  }
  // TODO snackbar issue for this function
  const getUserInfo = () => {
    item.uploadedById &&
      getUserWithId(item.uploadedById).then((response) => {
        setUsername(response.name)
      })
  }
  const getUploadEventsForPatient = () => {
    getUploadEvents(item.patientId, item.id).then((response) => {
      const jsonString =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(response))
      const link = document.createElement('a')
      link.href = jsonString
      link.download = `${item.id}.json`
      link.click()
      link.remove()
    })
  }
  const deleteUploadfForPatient = async () => {
    await deleteUpload(item.patientId, item.id)
    onDelete()
  }
  return (
    <>
      <Modal isOpen={showDeleteModal}>
        <DeleteModal
          title={t('Are you sure you want to delete this upload?')}
          body={t(
            'This should only be done if the upload was made by mistake. This action can not be undone.'
          )}
          buttonText={t('Delete upload')}
          acceptfn={() => deleteUploadfForPatient()}
          cancelfn={() => setShowDeleteModal(false)}
        ></DeleteModal>
      </Modal>
      <Tr key={'uploadrow' + index} data-testid={'uploadrow' + index}>
        <UploadsBoxComponent
          value={
            item.created
              ? dayjs(item.created).format('DD/MM/YYYY')
              : dataState.empty
          }
          onClickFunction={null}
          patientId={item.patientId}
        />
        <UploadsBoxComponent
          value={
            item.created
              ? dayjs(item.created).format('HH:mm').toString()
              : dataState.empty
          }
          onClickFunction={null}
          patientId={item.patientId}
        />
        {!patient && (
          <UploadsBoxComponent
            value={patientInfo ? patientInfo.cpr : dataState.hidden}
            onClickFunction={getPatientInfo}
            patientId={item.patientId}
            testid={'cpr' + index}
          />
        )}
        {!patient && (
          <UploadsBoxComponent
            value={patientInfo ? patientInfo.name : dataState.hidden}
            onClickFunction={getPatientInfo}
            patientId={item.patientId}
            testid={'name' + index}
          />
        )}
        <UploadsBoxComponent
          value={
            item.device && item.device.displayName
              ? item.device.displayName
              : dataState.empty
          }
          onClickFunction={null}
          patientId={item.patientId}
        />
        <UploadContext upload={item}></UploadContext>
        <UploadsBoxComponent
          value={
            item.uploadedById
              ? user?.userId === item.uploadedById
                ? user?.name
                : userName || dataState.hidden
              : dataState.empty
          }
          onClickFunction={getUserInfo}
          patientId={item.patientId}
          testid={`uploadedby${index}`}
        >
          {user?.userId === item.uploadedById ? (
            <UploadUserButton data-testid={`${index}you`}>
              {t('you')}
            </UploadUserButton>
          ) : (
            <></>
          )}
        </UploadsBoxComponent>
        <UploadsBoxComponent
          value={dataState.options}
          styling={{ justifyContent: 'flex-end' }}
          onClickFunction={null}
          patientId={item.patientId}
        >
          <OptionsComponent
            getUploadEventsForPatient={getUploadEventsForPatient}
            deleteUpload={() => setShowDeleteModal(true)}
            user={user}
          />
        </UploadsBoxComponent>
      </Tr>
    </>
  )
}
