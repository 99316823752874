import { SizeEnum, WidgetState } from '../Shared/Interfaces/Interfaces'
import { Wrapper, getWidgetState } from '../Shared/Wrapper/Wrapper'
import { Bars } from './Bars/Bars'
import { Header } from './Header/Header'
import { Legend } from './Legend/Legend'

export interface TimeInActivityWidgetData {
  exercise: { percentage: number; value: number }
  sleep: { percentage: number; value: number }
}

interface TimeInActivityProps {
  size: SizeEnum
  state: WidgetState
  data: TimeInActivityWidgetData | null
}

export const TimeInActivityConfig = {
  exercise: {
    color: 'var(--lime-green)'
  },
  sleep: {
    color: 'var(--purple)'
  }
}
export const TimeInActivity = ({ size, state, data }: TimeInActivityProps) => {
  const actualState = getWidgetState(state, data)

  return (
    <Wrapper $size={size} data-testid="timeInAutomation">
      <Header state={actualState} />
      <Bars
        data={
          data
            ? {
                exercise: data.exercise.percentage,
                sleep: data.sleep.percentage
              }
            : null
        }
        state={actualState}
      />
      <Legend
        data={
          data
            ? {
                exercise: data.exercise.value,
                sleep: data.sleep.value
              }
            : null
        }
      />
    </Wrapper>
  )
}
