import {
  faArrowsFromDottedLine,
  faArrowsRotate,
  faArrowUpFromArc,
  faBatteryLow,
  faBatterySlash,
  faClock,
  faComment,
  faCrosshairsSimple,
  faDownFromDottedLine,
  faDownRight,
  faDroplet,
  faDropletSlash,
  faQuestion,
  faTriangleExclamation,
  faUpRight
} from '@fortawesome/pro-solid-svg-icons'
import dayjs from 'dayjs'
import { TFunction } from 'react-i18next'
import { formatNumber } from '../../helpers/helpers'
import { getNovoPenEventConfig } from './getNovoPenEventConfig'
import {
  AbstractDeviceEvent,
  CalibrationEvent,
  DeviceEventConfig,
  DeviceEventSubType,
  DeviceEventType,
  DeviceNoteEvent,
  NovoPenEvent,
  PrimeEvent,
  ReservoirChangeEvent,
  TimeChangeEvent
} from './Interfaces'

export const getDeviceEventTypeText = (type: DeviceEventType) => {
  switch (type) {
    case DeviceEventType.GlucoseSensor:
      return 'Glucose sensor'
    case DeviceEventType.InsulinPump:
      return 'Insulin pump'
    case DeviceEventType.InsulinPen:
      return 'Insulin pen'
    case DeviceEventType.Comments:
      return 'Comments'
  }
}

export const getDeviceEventConfig = (
  event: AbstractDeviceEvent,
  t: TFunction<'translation', undefined>
): DeviceEventConfig | null => {
  switch (event.deviceEventSubType) {
    case DeviceEventSubType.Calibration:
      return {
        icon: faCrosshairsSimple,
        colors: 'var(--text-primary)',
        text: `${t('Calibration')} ${formatNumber({
          value: (event as CalibrationEvent).value,
          decimals: 1
        })} ${(event as CalibrationEvent).units}`
      }
    case DeviceEventSubType.NoInsulin:
      return {
        icon: faDropletSlash,
        colors: 'var(--text-primary)',
        text: t('No insulin')
      }
    case DeviceEventSubType.NoDelivery:
      return {
        icon: faDropletSlash,
        colors: 'var(--text-primary)',
        text: t('No delivery')
      }
    case DeviceEventSubType.SiteOcclusion:
      return {
        icon: faDropletSlash,
        colors: 'var(--text-primary)',
        text: t('Site occlusion')
      }
    case DeviceEventSubType.TimeChange:
      return {
        icon: faClock,
        colors: 'var(--text-primary)',
        text: `${t('Time change from')} ${dayjs(
          (event as TimeChangeEvent).from
        ).format('HH:mm')} ${t('to')} ${dayjs(
          (event as TimeChangeEvent).to
        ).format('HH:mm')}`
      }
    case DeviceEventSubType.Prime:
      return {
        icon: faDroplet,
        colors: 'var(--text-primary)',
        text: `Prime - ${formatNumber({
          value: (event as PrimeEvent).volume ?? 0,
          decimals: 1
        })} U`
      }
    case DeviceEventSubType.ReservoirChange:
      return {
        icon: faArrowsRotate,
        colors: 'var(--text-primary)',
        text: `${t('Reservoir change')}${
          (event as ReservoirChangeEvent).status
            ? ` - ${(event as ReservoirChangeEvent).status}`
            : ''
        }`
      }
    case DeviceEventSubType.FillCannula:
      return {
        icon: faArrowsRotate,
        colors: 'var(--text-primary)',
        text: `${t('Fill cannula')} - ${formatNumber({
          value: (event as PrimeEvent).volume ?? 0,
          decimals: 1
        })} U`
      }
    case DeviceEventSubType.FillTube:
      return {
        icon: faArrowsRotate,
        colors: 'var(--text-primary)',
        text: `${t('Fill tube')} - ${formatNumber({
          value: (event as PrimeEvent).volume ?? 0,
          decimals: 1
        })} U`
      }
    case DeviceEventSubType.LowPower:
      return {
        icon: faBatteryLow,
        colors: 'var(--text-primary)',
        text: t('Low power')
      }
    case DeviceEventSubType.Note:
      return {
        icon: faComment,
        colors: 'var(--blue)',
        text: (event as DeviceNoteEvent).note
      }
    case DeviceEventSubType.HighSoon:
      return {
        icon: faUpRight,
        colors: 'var(--high-darker-color)',
        text: t('High soon')
      }
    case DeviceEventSubType.LowSoon:
      return {
        icon: faDownRight,
        colors: 'var(--low-darker-color)',
        text: t('Low soon')
      }
    case DeviceEventSubType.UrgentLow:
      return {
        icon: faDownFromDottedLine,
        colors: 'var(--veryLow-darker-color)',
        text: t('Urgent low')
      }
    case DeviceEventSubType.OutOfRange:
      return {
        icon: faArrowsFromDottedLine,
        colors: 'var(--text-primary)',
        text: t('Out of range')
      }
    case DeviceEventSubType.NoReadings:
      return {
        icon: faQuestion,
        colors: 'var(--text-primary)',
        text: t('No readings')
      }
    case DeviceEventSubType.NovoPenEvent:
      return getNovoPenEventConfig((event as NovoPenEvent).novoPenEventType, t)
    case DeviceEventSubType.Suspended:
      return null
    case DeviceEventSubType.Resumed:
      return null
    case DeviceEventSubType.SensorConnected:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t('Sensor connected')
      }
    case DeviceEventSubType.ChangeSensor:
      return {
        icon: faTriangleExclamation,
        colors: 'var(--text-primary)',
        text: t('Change sensor')
      }
    case DeviceEventSubType.NoPower:
      return {
        icon: faBatterySlash,
        colors: 'var(--text-primary)',
        text: t('No power')
      }
    case DeviceEventSubType.AutoOff:
      return {
        icon: faDropletSlash,
        colors: 'var(--text-primary)',
        text: t('Auto Off')
      }
    case DeviceEventSubType.AppUpload:
      return {
        icon: faArrowUpFromArc,
        colors: 'var(--text-primary)',
        text: t('Data upload')
      }
    case DeviceEventSubType.Unknown:
      return null
  }
}
