import styled from 'styled-components'
import { Spinner, spinnerSize } from '../Spinner/Spinner'
import { Td, Tr } from './ScrollTableComponent'

const LoadingWrapper = styled(Td)<{ size: number }>`
  border-bottom: 0.0625rem solid var(--text-lightest);
  border-right: 0.0625rem solid var(--text-lightest);
  border-left: 0.0625rem solid var(--text-lightest);
  border-radius-bottom-left: 0.5rem;
  border-radius-bottom-right: 0.5rem;
  height: ${(props) => `calc(${props.size} * 3rem)`};
  width: 100%;
  text-align: center;
`
interface ScrollTableLoadingProps {
  size: number
}
export const ScrollTableLoading = ({ size }: ScrollTableLoadingProps) => {
  return (
    <Tr data-testid="scrollTableLoading">
      <LoadingWrapper colSpan={100} size={size}>
        <Spinner spinnersize={spinnerSize.large}></Spinner>
      </LoadingWrapper>
    </Tr>
  )
}
