import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ChangeLanguage } from '../../components/ChangeLanguage/ChangeLanguage'
import {
  PortalFooter,
  PortalFooterTheme
} from '../../components/PortalFooter/PortalFooter'
import { useSessionContext } from '../../core/contexts/session-context'

const Screen = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--brand-primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 8rem;
  height: 8rem;
`
const LogoWrapper = styled.div`
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Container = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const LoginButton = styled.button`
  display: block;
  width: 18.75rem;
  padding: 1.25rem;
  background: #ffffff;
  border: 1px solid #1903da;
  border-radius: 16px;
  color: var(--brand-primary-color);
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 4rem;
  cursor: pointer;
`

export const Login = () => {
  const location = useLocation()
  const { login, isAuthenticated } = useSessionContext()
  const { t, i18n } = useTranslation()

  const currentLocationState: any = location.state || {
    from: { pathname: '/portal' }
  }

  if (isAuthenticated) {
    return <Navigate to={currentLocationState?.from as string} />
  }

  return (
    <Screen>
      <ChangeLanguage />
      <Container>
        <LogoWrapper>
          <Logo src="/api/deployment/icon" />
        </LogoWrapper>
        <LoginButton
          data-testid="login-button"
          type="button"
          onClick={() => {
            login({
              redirectUri: '/portal',
              locale: i18n.language
            })
          }}
        >
          {t('Sign in')}
        </LoginButton>
      </Container>
      <PortalFooter theme={PortalFooterTheme.light} showFragileInfo={false} />
    </Screen>
  )
}
