import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Manufacturer from '../../assets/ManufacturerImage.svg'
import { Body } from '../../containers/PatientInfo/PatientInfo'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  justify-content: flex-start;
`

export const IconWrapper = styled.div<{ $index: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  position: ${(props) => (props.$index > 0 ? 'absolute' : 'relative')};
  top: ${(props) => (props.$index > 0 ? '2.75rem' : '0')};
`
const Icon = styled.img`
  width: 2rem;
  height: 2rem;
  position: relative;
  bottom: 0.5rem;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Title = styled.span`
  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 700;
  font-family: inter;
`
const Text = styled.span`
  color: var(--text-medium);
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
`
export const Website = styled.a`
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  text-decoration: none;
  color: var(--brand-primary-color);
  &:visited {
    color: var(--brand-primary-color);
  }
`

export const AddressComponent = () => {
  const { t } = useTranslation()
  const getConfig = () => {
    return {
      title: t('Manufacturer'),
      image: Manufacturer,
      address: {
        name: 'Line Systems ApS',
        street: 'Bredgade 45',
        city: '1260 København K',
        cvr: 'CVR 42400491',
        website: 'linesystems.io'
      }
    }
  }
  const config = getConfig()
  return (
    <Body>
      <Wrapper>
        <IconWrapper $index={0}>
          <Icon src={config.image}></Icon>
        </IconWrapper>
        <Section>
          <Title style={{ marginBottom: '0.5rem' }}>{config.title}</Title>
          {Object.entries(config.address).map((value) =>
            value[0] === 'website' ? (
              <Website
                target="_blank"
                href={`https://${value[1]}`}
                key={value[0]}
              >
                {value[1]}
              </Website>
            ) : (
              <Text key={value[0]}>{value[1]}</Text>
            )
          )}
        </Section>
      </Wrapper>
    </Body>
  )
}
