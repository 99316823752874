import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  justify-content: center;
  align-items: flex-start;
  padding: 0.125rem 1rem;
  box-sizing: border-box;
  height: 100%;
  border-right: 0.0625rem solid var(--text-lightest);
  background-color: var(--brand-primary-color);
`
const MRN = styled.span`
  color: var(--brand-primary-color);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
`
const Name = styled.span`
  font-family: inter;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--white-color);
`
interface NameComponentProps {
  name: string | undefined
}

export const ClinicNameComponent = ({ name }: NameComponentProps) => {
  return (
    <Wrapper>
      <Name>{name}</Name>
    </Wrapper>
  )
}
