import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { BlurComponent } from '../Blur/Blur'

export const PatientViewNavigationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--transparentWhite80-color);
  border-top: 0.0625rem solid var(--text-lightest);
  border-bottom: 0.0625rem solid var(--text-lightest);
  box-sizing: border-box;
  height: 3rem;
  width: 100%;
  position: fixed;
  z-index: 3;
  display: flex;
  left: 0;
`
export const PatientsViewNavigationComponent = styled.div`
  display: flex;
  justify-content: center;
  background-color: var(--white-color);
  z-index: 3;
  height: 100%;
`
export const AbsoluteWrapper = styled.div`
  position: absolute;
  height: calc(100% - 0.125rem);
`
export type PatientsViewButtonProps = {
  children: ReactNode
  to: string
  notification: boolean
}

export const PatientsViewButtonComponent = styled(NavLink)`
  padding: 1rem;
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--text-medium);
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 0.125rem solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  height: calc(100% + 0.1875rem);
  bottom: 0.0625rem;
  position: relative;
  &:hover {
    color: var(--brand-primary-color);
    background-color: var(--brand-primary-4-color);
    border-bottom: 0.125rem solid rgba(0, 0, 0, 0);
  }

  &.active {
    color: var(--brand-primary-color);
    background-color: var(--brand-primary-4-color);
    border-bottom: 0.125rem solid var(--brand-primary-color);
  }
`
interface NotificationLabelProps {
  notification: boolean
}
const NotificationLabel = styled.div<NotificationLabelProps>`
  border-radius: 50%;
  border: none;
  background-color: var(--destructive-darker);
  width: 0.625rem;
  height: 0.625rem;

  display: ${(props) => (props.notification ? 'block' : 'none')};
`

export const PatientsViewButton = ({
  children,
  to,
  notification
}: PatientsViewButtonProps) => {
  return (
    <PatientsViewButtonComponent
      data-testid={to + '_nav'}
      id={to + '_nav'}
      to={to}
    >
      <PatientsViewButtonText>
        <NotificationLabel notification={notification}> </NotificationLabel>
        {children}
      </PatientsViewButtonText>
    </PatientsViewButtonComponent>
  )
}

export const PatientsViewButtonText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor-pointer: none;
  text-transform: capitalize;
`

export type PatientsViewNavigationProps = {
  pages: Array<{
    label: string
    to: string
    notification: boolean
    show: boolean
  }>
}

export const PatientsViewNavigation = ({
  pages
}: PatientsViewNavigationProps) => {
  const pagesToRender = pages.map((item, index) =>
    item.show ? (
      <PatientsViewButton
        key={index}
        to={item.to}
        notification={item.notification}
      >
        {item.label}
      </PatientsViewButton>
    ) : null
  )
  return (
    <div style={{ paddingBottom: '3rem' }}>
      <BlurComponent />
      <PatientViewNavigationWrapper>
        <AbsoluteWrapper>
          <PatientsViewNavigationComponent>
            {pagesToRender}
          </PatientsViewNavigationComponent>
        </AbsoluteWrapper>
      </PatientViewNavigationWrapper>
    </div>
  )
}
