import { AxiosRequest } from './axios-request'
import config from '../config'

export async function deleteUpload(
  patientId: string,
  uploadId: string,
  options: object = {}
) {
  const response = await AxiosRequest.create().delete(
    config.API_URL + '/patients/' + patientId + '/uploads/' + uploadId,
    options
  )
  return response.data
}
