import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import './modal.css'

export const Modal = ({ children, ...props }: ReactModal.Props) => {
  return (
    <ReactModal className="modal" overlayClassName="modal-overlay" {...props}>
      {children}
    </ReactModal>
  )
}

interface CloseModalProps {
  closeFun: () => void
  closetestid: string
}
const CloseModalWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background-color: var(--element-bg-dark);
  cursor: pointer;
  border: 0.0625rem solid var(--text-lightest);
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
`
const CloseModalIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 900;
`
export const CloseModal = ({ closeFun, closetestid }: CloseModalProps) => {
  return (
    <CloseModalWrapper onClick={closeFun} data-testid={closetestid}>
      <CloseModalIcon icon={faTimes}></CloseModalIcon>
    </CloseModalWrapper>
  )
}

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const AbsoluteWrapper = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
`
const RelativeContent = styled.div`
  position: relative;
`
interface ModalComponentProps {
  children: ReactNode
  closeFun: () => void
  closetestid: string
  hideButton?: boolean
}
export const ModalComponent = ({
  children,
  closeFun,
  closetestid,
  hideButton
}: ModalComponentProps) => {
  return (
    <RelativeWrapper onClick={closeFun} data-testid={`${closetestid}wrapper`}>
      <AbsoluteWrapper>
        <RelativeContent onClick={(event) => event.stopPropagation()}>
          <>
            {children}
            {!hideButton && (
              <CloseModal closeFun={closeFun} closetestid={closetestid} />
            )}
          </>
        </RelativeContent>
      </AbsoluteWrapper>
    </RelativeWrapper>
  )
}
