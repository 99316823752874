import { faUserMagnifyingGlass as faUserMagnifyingGlassRegular } from '@fortawesome/pro-regular-svg-icons'
import { faUserMagnifyingGlass as faUserMagnifyingGlassSolid } from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const PatientSearchComponent = styled(Link)`
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  box-sizing: border-box;
  padding: 1.25rem 1.5rem;
  &:hover {
    color: var(--brand-primary-color);
    background-color: var(--element-bg);
  }
  border-right: 0.0625rem solid var(--text-lightest);
`
const SearchLogo = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: inherit;
`

export const PatientSearch = () => {
  const [hovered, setHovered] = useState<boolean>(false)
  return (
    <PatientSearchComponent
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      data-testid="patientSearchButton"
      to={'/portal'}
    >
      <SearchLogo
        icon={
          hovered ? faUserMagnifyingGlassSolid : faUserMagnifyingGlassRegular
        }
      ></SearchLogo>
    </PatientSearchComponent>
  )
}
