import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalComponent } from '../../../components/Modal/Modal'
import { OperatingSystem } from '../../../helpers/getOperatingSystem'
import { Platform } from '../platform-utils'
import { DownloadButtonComponent } from './DownloadButtonComponent/DownloadButtonComponent'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 31.25rem;
  padding: 2rem;
  gap: 1.5rem;
  border-radius: 2rem;
  background-color: var(--white-color);
  height: 40rem;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
`
interface AllDownloadsProps {
  closeFun: () => void
  allPlatforms: Platform[]
  downloadInstaller: (platform: string) => Promise<void>
  setRecentlyDownloaded: Dispatch<SetStateAction<OperatingSystem | null>>
}

export const AllDownloads = ({
  closeFun,
  allPlatforms,
  downloadInstaller,
  setRecentlyDownloaded
}: AllDownloadsProps) => {
  const { t } = useTranslation()

  return (
    <ModalComponent
      closeFun={closeFun}
      closetestid="closeAllUploaderDownloadsModal"
    >
      <Wrapper>
        <Title>{`Stenopool ${t('Uploader Versions')}`}</Title>
        {allPlatforms.map((platform) => (
          <DownloadButtonComponent
            key={platform.platform}
            os={platform.os}
            platform={platform.platform}
            downloadInstaller={downloadInstaller}
            setRecentlyDownloaded={setRecentlyDownloaded}
          >
            {platform.description}
          </DownloadButtonComponent>
        ))}
      </Wrapper>
    </ModalComponent>
  )
}
