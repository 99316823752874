import { faUserPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PopulationOverview } from '../../containers/ClinicPatients/tables/interfaces'
import { AddPatientToPopulationModal } from '../AddPatientToPopulationModal/AddPatientToPopulationModal'
import { Modal } from '../Modal/Modal'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`
const Button = styled.div`
  border-radius: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
  background-color: var(--blue);
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--white-color);
`
const Text = styled.span`
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--white-color);
`
export const AddPatientToPopulation = ({
  populationid,
  populations,
  refresh
}: {
  populationid: string
  populations: PopulationOverview[]
  refresh: () => void
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Modal isOpen={showModal}>
        <AddPatientToPopulationModal
          closeModal={() => setShowModal(false)}
          populationid={populationid}
          populations={populations}
          refresh={refresh}
        />
      </Modal>
      <Button
        onClick={() => setShowModal(true)}
        data-testid="addPatientToPopulation"
      >
        <Icon icon={faUserPlus} />
        <Text>{t('Add patient')}</Text>
      </Button>
    </Wrapper>
  )
}
