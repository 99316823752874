import { Dispatch, MutableRefObject, SetStateAction } from 'react'

export const handleTableScroll = (
  ref: MutableRefObject<HTMLTableElement | null>,
  setBlur: Dispatch<SetStateAction<boolean>>
) => {
  if (ref.current) {
    const rect = ref.current.getBoundingClientRect()
    setBlur(rect.top < 120)
  }
}
