import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { StatusBadge, StatusBadgeState } from '../../StatusBadge/StatusBadge'
import { TText, Td } from '../../Table/ScrollTableComponent'
import { PMIStateValues, ProviderPatient } from '../ProviderPatients'
const Wrapper = styled(Td)`
  &:hover {
    cursor: pointer;
    background-color: var(--brand-primary-4-color);
  }
  position: relative;
`
const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: calc(100% - 2rem);
`
const LinkText = styled(TText)`
  overflow: hidden;
  text-overflow: ellipsis;
`
const showChevron = () => keyframes`
 0% {
  transform: translateX(150%) translateY(-50%);
  opacity: 0;
}
 100% {
  transform: translateX(0%) translateY(-50%);
  opacity: 1;
}
`
const hideChevron = () => keyframes`
 0% {
  transform: translateX(0%) translateY(-50%);
  opacity: 1;
}
 100% {
  transform: translateX(150%) translateY(-50%);
  opacity: 0;
}
`

const SeperatorDot = styled(FontAwesomeIcon)`
  color: var(--text-lighter);
  font-size: 0.25rem;
`
const ChevronWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--brand-primary-16-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateX(150%) translateY(50%);
  opacity: 0;
  &.showChevron {
    animation-name: ${showChevron};
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  &.hideChevron {
    animation-name: ${hideChevron};
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`
const Chevron = styled(FontAwesomeIcon)`
  color: var(--brand-primary-color);
  font-size: 0.625rem;
`

interface LinkPatientProps {
  setShowLinkPatientModal: () => void
  patient: ProviderPatient
}

export const LinkPatientButton = ({
  setShowLinkPatientModal,
  patient
}: LinkPatientProps) => {
  const { t } = useTranslation()
  const getPmiBadge = (state: PMIStateValues) => {
    switch (state) {
      case PMIStateValues.CONNECTING:
        return {
          text: t('not completed'),
          state: StatusBadgeState.disconnected
        }
      case PMIStateValues.CONNECTED:
        return { text: t('connected'), state: StatusBadgeState.success }
      case PMIStateValues.DISCONNECTED:
        return { text: t('disconnected'), state: StatusBadgeState.disconnected }
      case PMIStateValues.NOT_SETUP:
        return { text: t('not setup'), state: StatusBadgeState.disconnected }
    }
  }
  const getCmiBadge = (assignedPatientId: string | null, ignored: boolean) => {
    if (ignored) {
      return { text: t('ignored'), state: StatusBadgeState.ignored }
    } else if (assignedPatientId === null) {
      return { text: t('no link'), state: StatusBadgeState.default }
    } else {
      return { text: t('active'), state: StatusBadgeState.success }
    }
  }

  const [chevronClass, setChevronClass] = useState<string>('')
  const isConnecting = patient.pmiStateValue === PMIStateValues.CONNECTING
  const badge = patient.pmiStateValue
    ? getPmiBadge(patient.pmiStateValue)
    : getCmiBadge(patient.assignedPatientId, patient.ignored)
  return (
    <Wrapper
      style={isConnecting ? { pointerEvents: 'none' } : {}}
      data-testid={'LinkPatientButtonWrapper' + patient.id}
      onClick={isConnecting ? () => null : setShowLinkPatientModal}
      onMouseEnter={
        isConnecting ? () => null : () => setChevronClass('showChevron')
      }
      onMouseLeave={
        isConnecting ? () => null : () => setChevronClass('hideChevron')
      }
    >
      <BadgeWrapper>
        <StatusBadge text={badge.text} state={badge.state} />
      </BadgeWrapper>
      <ChevronWrapper className={chevronClass}>
        <Chevron icon={faChevronRight}></Chevron>
      </ChevronWrapper>
    </Wrapper>
  )
}
