export enum DeploymentType {
  Production = 'Production',
  Trial = 'Trial',
  Dev = 'Dev',
  Demo = 'Demo'
}

export enum Capabilities {
  uploaderInPortal = 'uploaderInPortal',
  adaptt2d = 'adaptt2d'
}
export interface SiteSettings {
  buildTime: string
  clinicName: string
  glucoseUnits: string
  githubRunId: string
  patientCanDeleteThey: boolean
  version: string
  deploymentType: DeploymentType
  deploymentName: string
  siteId: string
  releaseVersion: string
  productUDI: string
  frontendVersion: string
  backendVersion: string
  productName: string
  builtBy: string
  dateOfManufacture: string
  uploaderVersion: string
  capabilities: Array<Capabilities>
}
