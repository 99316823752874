import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getUploaderBlobByPlatform(
  platform: string,
  onDownload: (progress: ProgressEvent) => void,
  options: object = {}
): Promise<Blob> {
  const url = `${config.API_URL}/uploader/download?platform=${platform}`
  const response = await AxiosRequest.create({
    responseType: 'blob'
  }).get(url, {
    ...options,
    onDownloadProgress: onDownload
  })

  return response.data as Blob
}
