import { faCloud } from '@fortawesome/pro-regular-svg-icons'
import { faCheck, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { FlexColumnWrapper } from '../FlexWrappers/FlexColumnWrapper'
import { FlexRowWrapper } from '../FlexWrappers/FlexRowWrapper'
import { UploadOrigin } from './uploadOrigin'

const IntegrationFlexWrapper = styled(FlexRowWrapper)`
  justify-content: center;
  align-items: center;
`
const NotificationLabel = styled.div`
  border-radius: 50%;
  border: none;
  background-color: var(--destructive-darker);
  width: 0.625rem;
  height: 0.625rem;
`

interface CheckBoxProps {
  state: boolean | null
}
const CheckBox = styled.button<CheckBoxProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.25rem 0.3125rem;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid
    ${(props) =>
      props.state ? 'var(--brand-darker-color)' : 'var(--element-stroke)'};
  background: ${(props) =>
    props.state ? 'var(--brand-lighter-color)' : 'var(--white-color)'};
  cursor: pointer;
  width: 2rem;
  height: 2rem;
`
const CheckMark = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: var(--white-color);
`
const IntegrationComponentWrapper = styled(FlexRowWrapper)`
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  gap: 0.625rem;
  isolation: isolate;
  background-color: var(--element-bg);
  border-radius: 0.75rem;
  position: relative;
  width: 44.625rem;
`

const TitleStack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const IntegrationTitleWrapper = styled(FlexColumnWrapper)`
  gap: 0.625rem;
`
const IntegrationTitle = styled.div`
  font-family: inter;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--text-primary);
`
const IntegrationNote = styled.div`
  font-family: inter;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: var(--text-primary);
  text-align: right;
`
const IntegrationStatusWrapper = styled(FlexRowWrapper)`
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

const UpdateMessage = styled.div`
  font-family: inter;
  font-weight: 500;
  font-size: 1rem;
  color: var(--text-primary);
  text-align: right;
`

const SetupButton = styled(NavLink)`
    padding:0
    margin: 0 auto;
    border:none;
    background:none;
`
const SetupLogo = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 1.25rem;
`
const TitleAndLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  aling-items: center;
  gap: 0.5rem;
`
const CloudLogo = styled(FontAwesomeIcon)`
  color: var(--brand-primary-color);
  font-size: 1.25rem;
  font-weight: 400;
`
interface IntegrationStatusProps {
  active: boolean
  suspended: boolean
}
const IntegrationStatus = styled.div<IntegrationStatusProps>`
  padding: 0.5rem 0.75rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  font-family: inter;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${(props) =>
    props.active
      ? 'var(--green)'
      : props.suspended
      ? 'var(--yellow-darker)'
      : 'var(--text-lighter)'};
  background-color: ${(props) =>
    props.active ? 'var(--green)' : props.suspended ? 'var(--yellow)' : 'none'};
`
export const defaultDevicesObject = {
  device: 'default',
  notification: false,
  title: 'default',
  serial: 'default',
  time: 'default',
  statusBar: false,
  active: false,
  suspended: false,
  checkBox: true,
  patientDeviceIds: [''],
  uploadOrigin: '',
  deviceDisplayName: ''
}
export const defaultIntegrationObject = {
  notification: false,
  title: 'default',
  serial: 'default',
  time: 'default',
  statusBar: false,
  active: false,
  suspended: false,
  checkBox: null
}

type IntegrationComponentProps = {
  index: number
  notification: boolean
  title: string
  serial: string | null
  time: string | null
  uploadOrigin: UploadOrigin
  statusBar: boolean
  active: boolean
  suspended: boolean
  checkBox: boolean | null
  childNode?: ReactNode
  handleSingleCheckBox: null | ((index: number) => void)
  device: string
}

const GoToIntegrations = () => {
  document.getElementById('integrations_nav')?.click()
}
export const IntegrationComponent = ({
  index,
  notification,
  title,
  serial,
  time,
  uploadOrigin,
  statusBar,
  active,
  suspended,
  checkBox,
  childNode,
  handleSingleCheckBox,
  device
}: IntegrationComponentProps) => {
  const statusMsg = active ? 'active' : suspended ? 'suspended' : 'not setup'
  return (
    <IntegrationFlexWrapper data-testid={'integrationComponent' + index}>
      {notification && <NotificationLabel></NotificationLabel>}
      <IntegrationComponentWrapper>
        <TitleStack>
          {checkBox !== null && handleSingleCheckBox !== null && (
            <CheckBox
              onClick={() => handleSingleCheckBox(index)}
              state={checkBox}
              data-testid={'checkbox' + index}
            >
              <CheckMark icon={faCheck}></CheckMark>
            </CheckBox>
          )}
          <IntegrationTitleWrapper>
            <TitleAndLogo>
              <IntegrationTitle data-testid="integrationComponentTitle">
                {title}
              </IntegrationTitle>
              {uploadOrigin === UploadOrigin.cloud && (
                <CloudLogo icon={faCloud} />
              )}
            </TitleAndLogo>
            {serial && device !== 'DexcomG7APP' && (
              <IntegrationNote>{serial}</IntegrationNote>
            )}
            {childNode}
          </IntegrationTitleWrapper>
        </TitleStack>
        <IntegrationStatusWrapper>
          {time && <UpdateMessage>{time}</UpdateMessage>}
          {statusBar && (
            <IntegrationStatus active={active} suspended={suspended}>
              {statusMsg}
            </IntegrationStatus>
          )}
          {uploadOrigin === UploadOrigin.cloud && (
            <SetupButton to={'integrations'}>
              <SetupLogo icon={faChevronRight}></SetupLogo>
            </SetupButton>
          )}
        </IntegrationStatusWrapper>
      </IntegrationComponentWrapper>
    </IntegrationFlexWrapper>
  )
}
