import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  DeviceSettingsTarget,
  Profile
} from '../../../containers/DeviceSettings/DeviceSettings'
import { BgTargetType } from '../../../containers/DeviceSettings/PumpSettings/PumpSettings'
import { formatNumber } from '../../../helpers/helpers'
import {
  ScrollTableComponent,
  TText,
  Td,
  Th,
  Tr
} from '../../Table/ScrollTableComponent'
import { Title } from '../../TimeSelector/TimeSelector'
import { DeviceSettingsInsulin } from '../DeviceSettingsInsulin/DeviceSettingsInsulin'

interface DeviceSettingsTableProps {
  data: Profile
  index: number
  targetType: BgTargetType
}

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  width: 100%;
  max-width: 68.75rem;
`
const TableTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ShowTableButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  background-color: var(--element-bg-dark);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
`
const ShowTableLogo = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: 900;
  color: var(--text-primary);
`
const BoxSubText = styled.span`
  color: var(--text-lighter);
`
const Line = styled.div`
  background-color: var(--text-lightest);
  height: 0.0625rem;
  width: 100%;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`
const ActiveIndicator = styled.div`
  font-family: inter;
  font-weight: 800;
  font-size: 0.6875rem;
  text-transform: uppercase;
  background-color: var(--blue);
  color: var(--white-color);
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
`
const GapDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`

const GetBgTargetHeaders = (
  targetType: BgTargetType,
  t: TFunction<'translation', undefined>
) => {
  switch (targetType) {
    case BgTargetType.lowhigh:
      return (
        <>
          <Th>
            <GapDiv>
              <TText>{t('BG Target Low')}</TText>
              <BoxSubText>mmol/L</BoxSubText>
            </GapDiv>
          </Th>
          <Th>
            <GapDiv>
              <TText>{t('BG Target High')}</TText>
              <BoxSubText>mmol/L</BoxSubText>
            </GapDiv>
          </Th>
        </>
      )
    case BgTargetType.hightarget:
      return (
        <>
          <Th>
            <GapDiv>
              <TText>{t('Glucose Target')}</TText>
              <BoxSubText>mmol/L</BoxSubText>
            </GapDiv>
          </Th>
          <Th>
            <GapDiv>
              <TText>{t('Correct Above')}</TText>
              <BoxSubText>mmol/L</BoxSubText>
            </GapDiv>
          </Th>
        </>
      )
    case BgTargetType.target:
      return (
        <Th>
          <GapDiv>
            <TText>{t('BG Target')}</TText>
            <BoxSubText>mmol/L</BoxSubText>
          </GapDiv>
        </Th>
      )
  }
}
const getBgTargetRows = (
  targetType: BgTargetType,
  target: DeviceSettingsTarget
) => {
  switch (targetType) {
    case BgTargetType.lowhigh:
      return (
        <>
          <Td>
            <TText>
              {target.low
                ? formatNumber({
                    value: target.low,
                    decimals: 1
                  })
                : '-'}
            </TText>
          </Td>
          <Td>
            <TText>
              {target.high
                ? formatNumber({
                    value: target.high,
                    decimals: 1
                  })
                : '-'}
            </TText>
          </Td>
        </>
      )
    case BgTargetType.hightarget:
      return (
        <>
          <Td>
            <TText>
              {target.target
                ? formatNumber({
                    value: target.target,
                    decimals: 1
                  })
                : '-'}
            </TText>
          </Td>
          <Td>
            <TText>
              {target.high
                ? formatNumber({
                    value: target.high,
                    decimals: 1
                  })
                : '-'}
            </TText>
          </Td>
        </>
      )
    case BgTargetType.target:
      return (
        <Td>
          <TText>
            {target.target
              ? formatNumber({
                  value: target.target,
                  decimals: 1
                })
              : '-'}
          </TText>
        </Td>
      )
  }
}
export const DeviceSettingsTable = ({
  data,
  index,
  targetType
}: DeviceSettingsTableProps) => {
  const { t } = useTranslation()
  const [active, setActive] = useState<boolean>(false)
  return (
    <TableWrapper>
      {index === 0 && <Line />}
      <TableTitleWrapper>
        <RowWrapper style={{ marginLeft: '1rem' }}>
          <Title>{data.profileName}</Title>
          {data.current && (
            <ActiveIndicator>{t('Active at upload')}</ActiveIndicator>
          )}
        </RowWrapper>
        <ShowTableButton
          onClick={() => setActive(!active)}
          data-testid={'deviceSettingsTableButton' + index}
        >
          <ShowTableLogo
            icon={active ? faChevronUp : faChevronDown}
          ></ShowTableLogo>
        </ShowTableButton>
      </TableTitleWrapper>
      {active && (
        <>
          <ScrollTableComponent>
            <Tr>
              <Th>
                <TText>{t('Start Time')}</TText>
              </Th>
              <Th>
                <GapDiv>
                  <TText>{t('Basal Rates')}</TText>
                  <BoxSubText>{t('U/hr')}</BoxSubText>
                </GapDiv>
              </Th>
              {GetBgTargetHeaders(targetType, t)}
              <Th>
                <GapDiv>
                  <TText>{t('Carb ratio')}</TText>
                  <BoxSubText>g/U</BoxSubText>
                </GapDiv>
              </Th>
              <Th>
                <GapDiv>
                  <TText>{t('Correction Factor')}</TText>
                  <BoxSubText>mmol/L/U</BoxSubText>
                </GapDiv>
              </Th>
            </Tr>
            {data.entries?.map((item) => (
              <Tr key={item.start}>
                <Td>
                  <TText>
                    {item.start.substring(0, item.start.length - 3)}
                  </TText>
                </Td>
                <Td>
                  <TText>
                    {item.basalRate
                      ? formatNumber({
                          value: item.basalRate,
                          decimals: 3
                        })
                      : '-'}
                  </TText>
                </Td>
                {getBgTargetRows(targetType, item.target)}
                <Td>
                  <TText>
                    {item.carbRatio
                      ? formatNumber({
                          value: item.carbRatio,
                          decimals: 1
                        })
                      : '-'}
                  </TText>
                </Td>
                <Td>
                  <TText>
                    {item.correctionFactor
                      ? formatNumber({
                          value: item.correctionFactor,
                          decimals: 1
                        })
                      : '-'}
                  </TText>
                </Td>
              </Tr>
            ))}
            <Tr style={{ backgroundColor: 'var(--element-bg-90)' }}>
              <Td>
                <TText>{t('Total')}</TText>
              </Td>
              <Td colSpan={5}>
                <TText>
                  {data.totalBasal !== null
                    ? formatNumber({
                        value: data.totalBasal,
                        decimals: 3
                      })
                    : '-'}
                </TText>
              </Td>
            </Tr>
          </ScrollTableComponent>
          <DeviceSettingsInsulin data={data.bolusSettings} />
        </>
      )}

      <Line></Line>
    </TableWrapper>
  )
}
