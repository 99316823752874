import { PatientPenTitrationEvent } from '../../containers/PenTitrations/PenTitrations'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getTitrationLog(): Promise<PatientPenTitrationEvent[]> {
  const response = await AxiosRequest.create().get(
    config.API_URL + `/adaptt2d/titration/logs`
  )
  return response.data as PatientPenTitrationEvent[]
}
