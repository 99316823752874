import { useMemo, useState } from 'react'
import {
  Area,
  ComposedChart,
  Dot,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis
} from 'recharts'
import styled from 'styled-components'
import { BolusEvent, CGMEvent } from '../../../containers/Overview/Overview'
import { GlucoseEventsObject } from '../../../containers/StackedDaily/Interfaces/Interfaces'
import { anchorClick } from '../../../containers/StackedDaily/StackedDaily'
import dayjs from '../../../core/dayjs/dayjs'

export interface GlucoseOverviewProps {
  cgm: CGMEvent | null
  bolus: BolusEvent | null
}
interface OverviewGraphProps {
  days: number
  data: Array<GlucoseOverviewProps> | null
  index: number
  showUserInitiatedBolus: boolean
  glucoseDensity: number | null
}

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const yDomainMax = 25.1

export const DateBox = (props: any) => {
  return (
    <svg
      y1={props.y1}
      y2={props.y2}
      x1={props.x1}
      x2={props.x2}
      x={props.x}
      y={props.y}
    >
      <g>
        <rect
          width={props.width}
          height={props.height}
          rx={4}
          fill={
            props.isHovered === props.index
              ? 'var(--text-primary)'
              : 'var(--transparentWhite20-color)'
          }
          fillOpacity={1}
          stroke={
            props.isHovered === props.index
              ? 'var(--text-primary)'
              : 'var(--text-lightest)'
          }
          strokeOpacity={1}
        ></rect>

        <text
          x={props.width / 2}
          y={props.y + props.height / 2}
          textAnchor="middle"
          fontFamily="inter"
          fontSize="0.625rem"
          fontWeight="600"
        >
          {props.isHovered === props.index ? (
            <>
              <tspan fill="var(--transparentWhite80-color)">
                {dayjs(props.date).format('ddd ')}
              </tspan>
              <tspan fill="var(--white-color)">
                {dayjs(props.date).format('MMM DD')}
              </tspan>
              <tspan fill="var(--transparentWhite80-color)"> , </tspan>
              <tspan fill="var(--white-color)">
                {dayjs(props.date).format('YYYY')}
              </tspan>
            </>
          ) : (
            <tspan fill="var(--text-primary)">{dayjs(props.date).date()}</tspan>
          )}
        </text>
      </g>
    </svg>
  )
}
export const date = (item: GlucoseOverviewProps) => {
  if (item.cgm) return item.cgm.day
  if (item.bolus) return item.bolus.day
  return null
}
const getDataValue = (object: GlucoseEventsObject) => {
  if (object.outOfRange === null) return object.value - 4.9
  else {
    switch (object.outOfRange.value) {
      case 'high':
        return 24
      case 'low':
        return -2.9
    }
  }
}
const EventDot = (props: any) => {
  return <Dot cx={props.cx} cy={props.cy} fill={props.fill} r={3} />
}
const getCutOffs = (
  data: {
    cgm: {
      hour: number
      glucose: number | undefined
    }[]
    bolus: {
      hour: number
      event: number
    }[]
  },
  interval: number
) => {
  const cutOffsArray = []
  for (let i = 1; i < data.cgm.length; i++) {
    if (data.cgm[i].hour - data.cgm[i - 1].hour > (1.25 * interval) / 60) {
      cutOffsArray.push({ start: data.cgm[i - 1].hour, stop: data.cgm[i].hour })
    }
  }
  return cutOffsArray
}
const updateData = (data: GlucoseOverviewProps[] | null) => {
  const cgm = []
  const bolus = []
  if (data)
    for (const [i, element] of data.entries()) {
      if (element.cgm) {
        for (const cgmelement of element.cgm.events) {
          const time = dayjs(cgmelement.localTime)
          cgm.push({
            hour:
              i * 24 + time.hour() + time.minute() / 60 + time.second() / 3600,
            glucose: getDataValue(cgmelement)
          })
        }
      }
      if (element.bolus) {
        for (const boluselement of element.bolus.events) {
          const time = dayjs(boluselement.time)
          bolus.push({
            hour:
              i * 24 + time.hour() + time.minute() / 60 + time.second() / 3600,
            event: -2.9
          })
        }
      }
    }
  return { cgm, bolus }
}
export const GlucoseOverview = ({
  days,
  data,
  index,
  showUserInitiatedBolus,
  glucoseDensity
}: OverviewGraphProps) => {
  const graphData = useMemo(() => updateData(data), [data])
  const interval = glucoseDensity ?? 5
  const cutOffs = getCutOffs(graphData, interval)
  const max = Math.max(...graphData.cgm.map((item) => item.hour))
  const [isHovered, setIsHovered] = useState<number | null>(null)

  return (
    <Wrapper
      data-testid={data ? 'OverviewGraphData' : 'OverviewGraphNoData'}
      className={'overviewClass'}
    >
      <ResponsiveContainer>
        <ComposedChart
          data={
            data && graphData.cgm.length > 0
              ? [
                  ...graphData.cgm,
                  {
                    hour: graphData.cgm[graphData.cgm.length - 1].hour,
                    glucose: graphData.cgm[graphData.cgm.length - 1].glucose
                  },
                  {
                    hour: graphData.cgm[graphData.cgm.length - 1].hour,
                    glucose: yDomainMax
                  },
                  {
                    hour: graphData.cgm[graphData.cgm.length - 1].hour,
                    glucose: -3.9
                  }
                ]
              : undefined
          }
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          }}
        >
          <YAxis
            type={'number'}
            domain={[-3.9, yDomainMax]}
            ticks={[0, 6.1]}
            interval={0}
            tickFormatter={(value, _index) => (value === 0 ? '3,9' : '10,0')}
            tickSize={0}
            tickMargin={6}
            tick={{
              fontSize: '0.75rem',
              fontWeight: 600,
              fill: 'var(--text-lighter)'
            }}
            axisLine={false}
            width={15}
          />
          <XAxis
            type={'number'}
            dataKey="hour"
            domain={[0, days * 24]}
            tickCount={2 * days}
            ticks={Array.from({ length: days * 2 + 1 }, (_, n) => n * 12)}
            tickFormatter={(_value, index) =>
              index % 2 === 0 ? '0:00' : '12:00'
            }
            tickSize={0}
            tickMargin={6}
            tick={{
              fontSize: '0.75rem',
              fontWeight: 600,
              fill: 'var(--text-lighter)'
            }}
            axisLine={false}
          />
          {data && graphData.cgm.length > 0 && (
            <defs>
              <linearGradient id="fill" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset={1 - 10 / 29}
                  stopColor="var(--veryHigh-darker-color)"
                  stopOpacity={1}
                />
                <stop offset={1 - 9.98 / 29} stopOpacity={0} />
                <stop offset={1 - 3.8 / 29} stopOpacity={0} />
                <stop
                  offset={1 - 3.9 / 29}
                  stopColor="var(--veryLow-darker-color)"
                  stopOpacity={1}
                />
              </linearGradient>
              <linearGradient id={'stroke' + index} x1="0" y1="0" x2="1" y2="0">
                <stop
                  offset={graphData.cgm[0].hour / max}
                  stopOpacity={1}
                  stopColor="#333333"
                />
                <stop
                  offset={
                    graphData.cgm[graphData.cgm.length - 1].hour / max -
                    1 / (max * 4) -
                    0.005
                  }
                  stopOpacity={1}
                  stopColor="#333333"
                />
                <stop
                  offset={
                    graphData.cgm[graphData.cgm.length - 1].hour / max -
                    1 / (max * 4) -
                    0.005
                  }
                  stopOpacity={0}
                  stopColor="rgba(0,0,0,0)"
                />
                <stop offset={1} stopOpacity={0} stopColor="rgba(0,0,0,0)" />
              </linearGradient>
            </defs>
          )}
          <ReferenceArea
            y1={0}
            y2={6.1}
            fill={'var(--element-bg-dark)'}
            fillOpacity={1}
          />
          {new Array(days).fill(0).map((_item, index) => (
            <ReferenceLine
              key={'line' + index}
              x={index * 24 + 12}
              stroke={'var(--element-stroke)'}
              strokeDasharray="2 2"
              strokeWidth={0.5}
            />
          ))}
          <Area
            type="monotone"
            dataKey="glucose"
            stroke={'url(#stroke' + index + ')'}
            fill="url(#fill)"
            isAnimationActive={false}
          />
          {cutOffs.map((item, index) => (
            <ReferenceArea
              key={'cutoffbottom' + index}
              y1={-3.8}
              y2={yDomainMax - 0.2}
              x1={item.start}
              x2={item.stop}
              fill={'var(--white-color'}
              fillOpacity={1}
            ></ReferenceArea>
          ))}
          {cutOffs.map((item, index) => (
            <ReferenceArea
              key={'cutoffmiddle' + index}
              y1={0}
              y2={6.1}
              x1={item.start}
              x2={item.stop}
              fill={'var(--element-bg-dark)'}
              fillOpacity={1}
            />
          ))}
          {showUserInitiatedBolus && (
            <Scatter
              data={graphData.bolus}
              dataKey="event"
              shape={<EventDot />}
              fill="var(--bolus-color)"
              isAnimationActive={false}
            />
          )}
          {data &&
            data.map(
              (item, index) =>
                date(item) && (
                  <ReferenceArea
                    shape={
                      <DateBox
                        date={date(item)}
                        isHovered={isHovered}
                        index={index}
                      />
                    }
                    key={'date' + index}
                    y1={15.5}
                    y2={23.5}
                    x1={0.5 + 24.1 * index}
                    x2={
                      isHovered === index
                        ? 16.615 + 24.1 * index
                        : 4.615 + 24.1 * index
                    }
                  />
                )
            )}
          {new Array(days).fill(0).map((_item, index) => (
            <ReferenceArea
              key={'box' + index}
              x1={index * 24}
              x2={index * 24 + 24}
              y1={-3.9}
              y2={yDomainMax}
              stroke={'var(--element-stroke)'}
              fillOpacity={0}
              radius={8}
            />
          ))}
          {data &&
            data.map((item, index) => (
              <ReferenceArea
                cursor={'pointer'}
                data-testid={'boxInfront' + index}
                key={'boxInfront' + index}
                x1={index * 24}
                x2={index * 24 + 24}
                y1={-3.9}
                y2={yDomainMax}
                stroke={'var(--text-primary)'}
                strokeOpacity={isHovered === index ? 1 : 0}
                fillOpacity={0}
                radius={8}
                onMouseOver={() => {
                  setIsHovered(index)
                }}
                onMouseLeave={() => {
                  setIsHovered(null)
                }}
                onClick={() =>
                  anchorClick(
                    dayjs(
                      item.cgm
                        ? item.cgm.day
                        : item.bolus
                        ? item.bolus.day
                        : new Date()
                    )
                      .add(1, 'days')
                      .format('DMMM')
                  )
                }
              />
            ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}
