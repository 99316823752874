import {
  faCircleStop,
  faCircleXmark,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TFunction } from 'react-i18next'
import styled from 'styled-components'
import { IntegrationComponentStatus } from '../ProviderIntegrationModel/StatusPage/IntegrationComponent/IntegrationComponent'

export enum StatusBadgeState {
  default = 'default',
  warning = 'warning',
  error = 'error',
  success = 'success',
  ignored = 'ignored',
  disconnected = 'disconnected'
}
interface StatusBadgeProps {
  state: StatusBadgeState
  text: string
}
const StatusBadgeWrapper = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid;
  height: 2rem;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`
const StatusBadgeIcon = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 0.875rem;
  color: inherit;
`
const StatusBadgeText = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  font-family: inter;
  text-transform: uppercase;
  color: inherit;
  white-space: nowrap;
`
export const getBadgeStatus = (status: IntegrationComponentStatus) => {
  switch (status) {
    case IntegrationComponentStatus.notSetup:
      return StatusBadgeState.warning
    case IntegrationComponentStatus.running:
      return StatusBadgeState.success
    case IntegrationComponentStatus.verificationFailed:
      return StatusBadgeState.error
    case IntegrationComponentStatus.verifying:
      return StatusBadgeState.warning
    case IntegrationComponentStatus.idle:
      return StatusBadgeState.success
    case IntegrationComponentStatus.deleted:
      return StatusBadgeState.ignored
    case IntegrationComponentStatus.failed:
      return StatusBadgeState.error
    case IntegrationComponentStatus.disconnected:
      return StatusBadgeState.disconnected
    case IntegrationComponentStatus.failing:
      return StatusBadgeState.warning
  }
}

export const getStatusText = (
  status: IntegrationComponentStatus,
  t: TFunction<'translation', undefined>
) => {
  switch (status) {
    case IntegrationComponentStatus.notSetup:
      return t('not setup')
    case IntegrationComponentStatus.running:
      return t('running')
    case IntegrationComponentStatus.verificationFailed:
      return t('failed')
    case IntegrationComponentStatus.verifying:
      return t('verifying')
    case IntegrationComponentStatus.idle:
      return t('active')
    case IntegrationComponentStatus.deleted:
      return t('deleted')
    case IntegrationComponentStatus.failed:
      return t('failed')
    case IntegrationComponentStatus.disconnected:
      return t('disconnected')
    case IntegrationComponentStatus.failing:
      return t('failing')
  }
}
const getStatusBadgeIcon = (state: StatusBadgeState) => {
  switch (state) {
    case StatusBadgeState.warning:
      return faExclamationTriangle
    case StatusBadgeState.error:
      return faCircleXmark
    default:
      return faCircleStop
  }
}
export const getStatusBadgeStyle = (state: StatusBadgeState) => {
  switch (state) {
    case StatusBadgeState.warning:
      return {
        color: 'var(--yellow)',
        borderColor: 'var(--yellow)',
        background: 'var(--yellow-gradient)'
      }
    case StatusBadgeState.error:
      return {
        color: 'var(--veryLow-darker-color)',
        borderColor: 'var(--veryLow-darker-color)',
        background: 'var(--yellow-gradient)'
      }
    case StatusBadgeState.success:
      return {
        color: 'var(--green-darker)',
        borderColor: 'var(--green-darker)',
        background: 'var(--green-gradient)'
      }
    case StatusBadgeState.ignored:
      return {
        color: 'var(--blue-darker)',
        borderColor: 'var(--blue-darker)',
        background: 'var(--blue-gradient)'
      }
    case StatusBadgeState.disconnected:
      return {
        color: 'var(--yellow)',
        borderColor: 'var(--yellow)',
        background: 'var(--yellow-gradient)'
      }
    default:
      return {
        color: 'var(--text-lighter)',
        borderColor: 'var(--text-lightest)',
        backgroundColor: 'var(--white-color)'
      }
  }
}

export const StatusBadge = ({ state, text }: StatusBadgeProps) => {
  return (
    <StatusBadgeWrapper
      style={getStatusBadgeStyle(state)}
      data-testid="StatusBadgeWrapper"
    >
      {state !== StatusBadgeState.default &&
        state !== StatusBadgeState.success && (
          <StatusBadgeIcon
            icon={getStatusBadgeIcon(state)}
            data-testid="StatusBadgeIcon"
          />
        )}
      <StatusBadgeText data-testid="StatusBadgeText">{text}</StatusBadgeText>
    </StatusBadgeWrapper>
  )
}
