import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'
type TimeStampProps = {
  index: number
  last: boolean
  daysSinceToday: number
}
type DateWrapperProps = {
  last: boolean
  margin: number
}
type TodayWrapperProps = {
  last: boolean
}
export const Line = styled.div`
  width: 0.0625rem;
  height: 50%;
  background-color: var(--text-primary);
  position: absolute;
  top: 0;
`
const DateWrapper = styled.div<DateWrapperProps>`
  display: flex;
  position: relative;
  align-items: flex-end;
  width: 0;
  margin-right: ${(props) => props.margin}%;
  direction: ${(props) => (props.last ? 'ltr' : 'rtl')};
`
const TodayWrapper = styled.div<TodayWrapperProps>`
  margin-right: ${(props) => (props.last ? '0rem' : '0.25rem')};
  margin-left: ${(props) => (props.last ? '0.25rem' : '0rem')};
  font-family: inter;
  font-weight: 600;
  margin-top: 0.125rem;
`
export const DateText = styled.div`
  font-size: 0.625rem;
  color: var(--text-lighter);
`
export const TimeStamp = ({ index, last, daysSinceToday }: TimeStampProps) => {
  const date = dayjs(new Date()).subtract(index, 'month')
  return (
    <DateWrapper
      margin={
        index > 0 ? (dayjs(date).daysInMonth() / daysSinceToday) * 100 : 0
      }
      last={last}
      data-testid="TimeStampElement"
    >
      <TodayWrapper last={last}>
        <DateText>
          {dayjs(date).date()}/{dayjs(date).month() + 1}/
          {dayjs(date).year() - 2000}
        </DateText>
      </TodayWrapper>
      <Line></Line>
    </DateWrapper>
  )
}
