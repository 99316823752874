import { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  AverageInfusionSiteChangeObject,
  InfusionChangeType
} from '../../../containers/Overview/Overview'
import { changeInfusionTypeEvent } from '../../../core/api/change-infusion-type-event'
import {
  Patient,
  infusionSiteChangeEventType
} from '../../../core/entities/patient.entity'
import { formatNumber } from '../../../helpers/helpers'
import { BasedOnDataComponent } from '../../BasedOnDataComponent/BasedOnDataComponent'
import {
  PeriodPosition,
  ScreenShotIconSize,
  ScreenshotComponent
} from '../../Screenshot/ScreenshotComponent/ScreenshotComponent'
import { InfusionLogo } from '../InfusionLogo/InfusionLogo'
interface InfusionEventTypeProps {
  patient: Patient
  infusionSiteChangeEvent: infusionSiteChangeEventType
  showInfusionSiteToggles: boolean
  handleUpdateOverview: () => void
  avg: AverageInfusionSiteChangeObject | null
  wrapperRef: MutableRefObject<HTMLDivElement | null> | null
}
interface HandleChangeInfusionTypeEventProps {
  patientId: string
  event: string
  handleUpdateOverview: () => void
}
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`

const ToggleCircle = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.75rem;
  border: 0.125rem solid var(--text-lightest);
  background-color: var(--white-color);
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ActiveCircle = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 1rem;
  border: 0.3125rem solid var(--brand-primary-color);
  background-color: var(--white-color);
  pointer-events: none;
`
const RowSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.1875rem;
`
const TitleInfoText = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
`
const TitleInfoTextValue = styled(TitleInfoText)`
  color: var(--text-primary);
`
export const TitleText = styled.div`
  font-family: inter;
  font-weight: 700;
  font-size; 1.5rem;
  color: var(--text-primary);
`
export const SubTitleText = styled.div`
  font-family: inter;
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 600;
`
export const SubSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
export const SectionTitle = styled.div`
  font-family: inter;
  font-size: 1rem;
  color: var(--text-primary);
  font-weight: 700;
  white-space: nowrap;
`

const handleChangeInfusionTypeEvent = async ({
  patientId,
  event,
  handleUpdateOverview
}: HandleChangeInfusionTypeEventProps) => {
  try {
    await changeInfusionTypeEvent(patientId, event)
    handleUpdateOverview()
  } catch (error) {
    console.log(error)
  }
}
export const InfusionEventType = ({
  patient,
  infusionSiteChangeEvent,
  showInfusionSiteToggles,
  handleUpdateOverview,
  avg,
  wrapperRef
}: InfusionEventTypeProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper data-testid="infusionSiteChangesPage">
      <RowSpace>
        <TitleText>{t('Infusion Site Changes')}</TitleText>
        <Row style={{ gap: '0.5rem' }}>
          <Row style={{ gap: '1rem' }}>
            {avg !== null && (
              <BasedOnDataComponent
                daysWithData={avg.daysWithData}
                selectedDays={avg.selectedDays}
              />
            )}
            <Row>
              <TitleInfoText>{t('Avg. set change')}:</TitleInfoText>
              <TitleInfoTextValue>
                {avg
                  ? `${formatNumber({
                      value: avg.avgInfusionSiteChange,
                      decimals: 1
                    })} ${t('days')}`
                  : '-'}
              </TitleInfoTextValue>
            </Row>
          </Row>
          <ScreenshotComponent
            size={ScreenShotIconSize.medium}
            showIcon={true}
            refs={{
              wrapper: wrapperRef
            }}
            index={0}
            periodPlacement={{
              position: PeriodPosition.bottomLeft,
              margin: { bottom: 8, left: 8 }
            }}
            wrapperPadding={{ width: 64, height: 32 }}
            border={true}
          />
        </Row>
      </RowSpace>
      <Section style={{ gap: '0.25rem' }}>
        <SubTitleText>{t('We are using')}</SubTitleText>
        <SubTitleText
          data-testid="subtitleEventText"
          style={{ fontWeight: 700 }}
        >
          {infusionSiteChangeEvent === infusionSiteChangeEventType.tubing
            ? t('Fill Tubing')
            : t('Fill Cannula')}
        </SubTitleText>
        <SubTitleText>
          {t('events to visualise infusion site changes.')}
        </SubTitleText>
      </Section>
      {showInfusionSiteToggles === true && (
        <Section>
          <SubSection>
            <ToggleCircle
              data-testid="fillcannulabutton"
              onClick={() =>
                handleChangeInfusionTypeEvent({
                  patientId: patient.patientId,
                  event: 'cannula',
                  handleUpdateOverview
                })
              }
            >
              {infusionSiteChangeEvent ===
                infusionSiteChangeEventType.cannula && <ActiveCircle />}
            </ToggleCircle>
            <SectionTitle>{t('Fill Cannula')}</SectionTitle>
            <InfusionLogo type={InfusionChangeType.cannula} />
          </SubSection>
          <SubSection>
            <ToggleCircle
              data-testid="filltubingbutton"
              onClick={() =>
                handleChangeInfusionTypeEvent({
                  patientId: patient.patientId,
                  event: 'tubing',
                  handleUpdateOverview
                })
              }
            >
              {infusionSiteChangeEvent ===
                infusionSiteChangeEventType.tubing && <ActiveCircle />}
            </ToggleCircle>
            <SectionTitle>{t('Fill Tubing')}</SectionTitle>
            <InfusionLogo type={InfusionChangeType.tubing} />
          </SubSection>
        </Section>
      )}
    </Wrapper>
  )
}
