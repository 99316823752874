import { CSSProperties } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { Spinner, spinnerSize } from '../Spinner/Spinner'
import { getStylingModel } from './functions/getStylingModel'
import { MarkDownStylingModels } from './interfaces/interfaces'

const Wrapper = styled.div``
interface MarkDownComponentProps {
  text: string
  wrapperStyle: CSSProperties
  stylingModel: MarkDownStylingModels
}
export const MarkDownComponent = ({
  text,
  wrapperStyle,
  stylingModel
}: MarkDownComponentProps) => {
  const styling = getStylingModel(stylingModel)

  return (
    <Wrapper style={wrapperStyle} data-testid="MarkDownData">
      {text !== null ? (
        <ReactMarkdown
          children={text}
          className={styling.className}
          remarkPlugins={styling.remarkPlugins}
          rehypePlugins={styling.rehypePlugins}
          linkTarget="_blank"
        ></ReactMarkdown>
      ) : (
        <Spinner spinnersize={spinnerSize.large}></Spinner>
      )}
    </Wrapper>
  )
}
