import { useEffect, useState } from 'react'
import {
  GetDeploymentTextProps,
  getDeploymentText
} from '../../../core/api/get-deployment-text'

export const useDeploymentText = ({ id, language }: GetDeploymentTextProps) => {
  const [text, setText] = useState<string | null>(null)
  const [textError, setTextError] = useState<Error | null>(null)
  useEffect(() => {
    const getText = async () => {
      try {
        const response = await getDeploymentText({ language, id })
        setText(response)
      } catch (err) {
        if (err instanceof Error) setTextError(err)
        setTextError(new Error('unknown error'))
      }
    }
    getText()
  }, [language, id])

  return { text, textError }
}
