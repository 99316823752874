import { useEffect, useState } from 'react'
import { ProviderObject } from '../../containers/ClinicIntegrations/ClinicIntegrations'
import { getProvider } from '../../core/api/get-provider'

export const GetProviders = (refreshProviders?: boolean) => {
  const [providers, setProviders] = useState<Array<ProviderObject> | null>(null)
  const [providersError, setProvidersError] = useState<Error | null>(null)
  useEffect(() => {
    setProviders(null)
    setProvidersError(null)
    const controller = new AbortController()
    const handleGetProvider = async () => {
      try {
        const providers = await getProvider({ signal: controller.signal })
        setProviders(providers)
      } catch (error) {
        setProvidersError(new Error('Error getting providers'))
      }
    }
    handleGetProvider()
  }, [refreshProviders])
  return { providers, providersError }
}
