import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BolusSettings } from '../../../containers/DeviceSettings/DeviceSettings'
import {
  ScrollTableComponent,
  Td,
  Th,
  Tr,
  TText
} from '../../Table/ScrollTableComponent'

interface DeviceSettingsInsulinProps {
  data: BolusSettings
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
  width: 100%;
  max-width: 18.0625rem;
`
const TdCentered = styled(Td)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const DeviceSettingsInsulin = ({ data }: DeviceSettingsInsulinProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper data-testid="devicesettingsinsulin">
      <ScrollTableComponent>
        <Tr>
          <Th colSpan={2}>
            <TText>{t('Insulin settings')}</TText>
          </Th>
        </Tr>
        <Tr>
          <Td
            style={{
              backgroundColor: 'var(--element-bg-90)',
              width: '50%'
            }}
          >
            <TText>{t('Max bolus')}</TText>
          </Td>
          <TdCentered>
            <TText
              style={{ marginRight: '0.5rem' }}
              data-testid="maxbolusvalue"
            >
              {data.maxBolus ?? '-'}
            </TText>
            {data.maxBolus !== null && (
              <TText style={{ color: 'var(--text-lighter)' }}>{t('U')}</TText>
            )}
          </TdCentered>
        </Tr>
        <Tr>
          <Td
            style={{
              backgroundColor: 'var(--element-bg-90)',
              width: '50%'
            }}
          >
            <TText>{t('Insulin duration')}</TText>
          </Td>
          <TdCentered>
            <TText
              style={{ marginRight: '0.5rem' }}
              data-testid="activeinsulinvalue"
            >
              {data.activeInsulin ?? '-'}
            </TText>
            {data.activeInsulin !== null && (
              <TText style={{ color: 'var(--text-lighter)' }}>{t('min')}</TText>
            )}
          </TdCentered>
        </Tr>
      </ScrollTableComponent>
    </Wrapper>
  )
}
