import { useTranslation } from 'react-i18next'
import {
  Dot,
  Label,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import styled from 'styled-components'
import {
  glucoseRanges,
  glucoseType
} from '../../../constants/ranges/glucoseRanges'

import {
  GlucoseEventsObject,
  OutOfRangeObject,
  Thresholds
} from '../../../containers/StackedDaily/Interfaces/Interfaces'
import dayjs from '../../../core/dayjs/dayjs'
import { formatNumber } from '../../../helpers/helpers'
import { referenceLines } from '.././BasalGraph/BasalGraph'
import { GlucoseValueType } from '.././BgLogGraph/BgLogGraph'
import { NovoPenEventValue } from '../Insights/interfaces'
export enum DotSizeEnum {
  small = 'Small',
  medium = 'Medium',
  large = 'Large'
}
interface GraphProps {
  data: Array<GlucoseEventsObject> | null
  insights: null | {
    timestamp: string
    penEvents: NovoPenEventValue[]
  }
}
export interface DataProps {
  time: string
  x: number
  y: number
  value: number
  source: string | null
  dotSize: DotSizeEnum
  outOfRange: OutOfRangeObject | null
}
interface DataArray {
  data: Array<DataProps>
}

export const CustomTooltipWrapper = styled.div`
  background-color: var(--white-color);
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  box-shadow: 0rem 0.25rem 0.5rem 0rem var(--tooltip-shadow);
  width: 11.25rem;
  overflow: hidden;
  position: relative;
`
export const TooltipHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 0.375rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-bottom: 0.0625rem solid var(--text-lightest);
  background: var(--element-bg);
  box-sizing: border-box;
`
export const TooltipValueWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const TooltipKey = styled.div`
  font-family: inter;
  font-size: 0.875rem;
  color: var(--text-lighter);
  font-weight: 600;
  margin: 0.5rem;
`
export const TooltipValue = styled(TooltipKey)`
  color: var(--text-primary);
  text-transform: capitalize;
`
const yDomainMax = 28
const xDomainMax = 24

const CustomizedLabel = (props: any) => {
  const { t } = useTranslation()
  return (
    <>
      <text
        x={20}
        y={17}
        fontFamily="inter"
        fontWeight="600"
        fontSize="0.75rem"
      >
        <tspan fill="var(--text-primary)">{t('Glucose')}</tspan>
        <tspan
          dx={4}
          fill="var(--text-lighter)"
          fontSize="0.625rem"
          fontWeight="700"
        >
          mmol/L
        </tspan>
      </text>
    </>
  )
}
export const CustomTooltip = (props: any) => {
  const { t } = useTranslation()
  if (props.active && props.payload && props.payload.length) {
    return (
      <CustomTooltipWrapper>
        <TooltipHeader>
          <TooltipValue style={{ margin: 0 }}>
            {dayjs(props.payload[1].payload.time).format('HH:mm')}
          </TooltipValue>
        </TooltipHeader>
        <TooltipValueWrapper>
          <TooltipKey>{t(props.payload[1].name)}</TooltipKey>
          <TooltipValue>
            {formatNumber({
              value: props.payload[1].payload.value,
              decimals: 1
            })}
          </TooltipValue>
        </TooltipValueWrapper>
        <TooltipValueWrapper>
          <TooltipKey>{t('Source')}</TooltipKey>
          <TooltipValue>
            {props.payload[0].payload.source ?? t('sensor')}
          </TooltipValue>
        </TooltipValueWrapper>
        {props.payload[0].payload.outOfRange && (
          <TooltipValueWrapper>
            <TooltipKey>{t('Threshold')}</TooltipKey>
            <TooltipValue>
              {t(props.payload[0].payload.outOfRange.value)}
            </TooltipValue>
          </TooltipValueWrapper>
        )}
      </CustomTooltipWrapper>
    )
  }
  return null
}

const GetDotSize = (size: DotSizeEnum) => {
  switch (size) {
    case DotSizeEnum.small: {
      return 1.5
    }
    case DotSizeEnum.medium: {
      return 3
    }
    default: {
      return 5
    }
  }
}

const EventDot = (props: any) => {
  const size = GetDotSize(props.dotSize)
  const color = glucoseRanges(props.payload.y, glucoseType.default)
  return (
    <svg>
      <Dot
        cx={props.cx}
        cy={props.cy}
        fill={color}
        fillOpacity={props.source === GlucoseValueType.scan ? 0 : 1}
        stroke={color}
        strokeWidth={props.source === GlucoseValueType.scan ? 2 : 0}
        r={props.source === GlucoseValueType.scan ? 4 : size}
      />
      {props.dotSize !== DotSizeEnum.small &&
        (props.payload.value >= 27.5 ||
          props.payload.outOfRange?.value === Thresholds.high) && (
          <svg
            x={props.cx - 3}
            y={props.cy - 1.5}
            width={6}
            height={3}
            viewBox="0 0 6 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.30078 0.449219L5.05078 2.19922C5.17383 2.32227 5.21484 2.51367 5.14648 2.67773C5.07812 2.8418 4.91406 2.95117 4.75 2.95117H1.25C1.07227 2.95117 0.908203 2.8418 0.839844 2.67773C0.771484 2.51367 0.8125 2.32227 0.935547 2.19922L2.68555 0.449219C2.84961 0.271484 3.13672 0.271484 3.30078 0.449219Z"
              fill="#656565"
            />
          </svg>
        )}
      {props.dotSize !== DotSizeEnum.small &&
        (props.payload.value <= 1.1 ||
          props.payload.outOfRange?.value === Thresholds.low) && (
          <svg
            x={props.cx - 3}
            y={props.cy - 1.5}
            width={6}
            height={3}
            viewBox="0 0 6 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.30078 2.81445C3.13672 2.99219 2.84961 2.99219 2.68555 2.81445L0.935547 1.06445C0.8125 0.941406 0.771484 0.75 0.839844 0.585938C0.908203 0.421875 1.07227 0.3125 1.25 0.3125H4.75C4.91406 0.3125 5.07812 0.421875 5.14648 0.585938C5.21484 0.75 5.17383 0.941406 5.05078 1.06445L3.30078 2.81445Z"
              fill="white"
            />
          </svg>
        )}
    </svg>
  )
}

export const calculateInsightXValue = (
  timestamp: dayjs.Dayjs,
  t: dayjs.Dayjs,
  difference: number,
  timestampNumber: number,
  tNumber: number
) => {
  // 14.4 is 60% of 24 (the max value for x.) 60% is the starting position for the event timestamp according to the UI design.
  // the insight graph shows 10 hours of the 24 hour day. which is the reason for the 2.4 multiplication.
  if (timestamp.isSame(t, 'day')) {
    return 14.4 + difference * 2.4
  } else if (timestamp.isAfter(t, 'day')) {
    return 14.4 - timestampNumber * 2.4 - Math.abs(tNumber - 24) * 2.4
  } else {
    return (24 + difference) * 2.4 + 14.4
  }
}

export const GlucoseGraph = ({ data, insights }: GraphProps) => {
  const graphData: DataArray = {
    data: []
  }
  const getXPosition = (time: string) => {
    const t = dayjs(time)
    const tNumber = t.hour() + t.minute() / 60 + t.second() / 3600
    if (insights === null) {
      return tNumber
    } else {
      const timestamp = dayjs(insights.timestamp)
      const timestampNumber =
        timestamp.hour() + timestamp.minute() / 60 + timestamp.second() / 3600
      const difference = tNumber - timestampNumber
      const number = calculateInsightXValue(
        timestamp,
        t,
        difference,
        timestampNumber,
        tNumber
      )
      return number
    }
  }

  if (data && data.length > 0) {
    for (const element of data) {
      graphData.data.push({
        time: element.localTime,
        x: getXPosition(element.localTime),
        y: element.value > 27.5 ? 27.5 : element.value,
        value: element.value,
        source: element.subType,
        dotSize: element.type === 'cgm' ? element.dotSize : DotSizeEnum.large,
        outOfRange: element.outOfRange
      })
    }
  }

  return (
    <ResponsiveContainer>
      <ScatterChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
        <ReferenceArea
          x1={0}
          x2={xDomainMax}
          y1={0}
          y2={yDomainMax}
          fill={'var(--element-bg)'}
          stroke={'var(--text-lightest)'}
          radius={4}
          fillOpacity={1}
        />
        {insights === null && referenceLines('stackedDaily')}
        {insights !== null && (
          <ReferenceLine
            x={14.4}
            stroke="var(--veryLow-color)"
            key={`hypoLine`}
          />
        )}
        <ReferenceLine y={3.9} stroke="#E7E7E7" strokeDasharray="4 3" />
        <ReferenceLine y={10} stroke="#E7E7E7" strokeDasharray="4 3" />
        <Tooltip
          animationDuration={200}
          cursor={false}
          content={<CustomTooltip />}
          formatter={function (value, name) {
            return `${value}`
          }}
          wrapperStyle={{ outline: 'none' }}
          isAnimationActive={false}
        />

        <XAxis
          type="number"
          name="Time"
          tick={false}
          domain={[0, xDomainMax]}
          dataKey="x"
          tickLine={false}
          orientation={'top'}
          axisLine={false}
          tickMargin={5}
          height={1}
        >
          <Label content={<CustomizedLabel />} position="insideTopLeft" />
        </XAxis>
        <YAxis
          stroke="#979797"
          fontSize="0.875rem"
          dataKey="y"
          name="Glucose"
          interval={0}
          ticks={[3.9, 10, 13.9]}
          tickFormatter={(value) =>
            value === 3.9 ? '3,9' : value === 10 ? '10,0' : '13,9'
          }
          tick={{
            fill: 'var(--text-lighter)',
            fontSize: '0.75rem',
            fontWeight: '600',
            fontFamily: 'inter'
          }}
          tickLine={{ opacity: 0.5 }}
          domain={[0, yDomainMax]}
          axisLine={false}
          width={15}
        />

        {data && data.length > 0 ? (
          <Scatter
            shape={<EventDot />}
            data={graphData.data}
            fillOpacity={1}
            isAnimationActive={false}
          />
        ) : (
          <Scatter data={[]} fill="#E8563D" isAnimationActive={true} />
        )}
      </ScatterChart>
    </ResponsiveContainer>
  )
}
