import {
  faArrowDownFromLine,
  faArrowsToLine,
  faArrowUpFromLine,
  faCircleQuestion,
  faSortDown,
  faSortUp
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { UploadContext } from '../../containers/Events/EventContext/EventContext'
import { TitrationHistoryObject } from '../../core/api/getTitrationHistory'
import { useSessionContext } from '../../core/contexts/session-context'
import dayjs from '../../core/dayjs/dayjs'
import { UserType } from '../../core/entities/user.entity'
import {
  HeaderWrapper,
  SortEnum,
  SortIconWrapper
} from '../ProviderPatients/ProviderPatients'
import { NoDataRow } from '../Table/NoDataRow'
import {
  ScrollTableComponent,
  Td,
  Th,
  THead,
  Tr,
  TText
} from '../Table/ScrollTableComponent'
import { ScrollTableLoading } from '../Table/ScrollTableLoading.tsx'
import {
  SortIconDown,
  SortIconUp
} from '../Uploads/UploadsHeaderComponent/UploadsHeaderComponent'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding-bottom: 3rem;
`
const RowBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ChangeText = styled(TText)<{ $color: string }>`
  color: ${(props) => props.$color};
`

const ChangeIcon = styled(FontAwesomeIcon)<{ $color: string }>`
  font-size: 1rem;
  line-height: 2.25rem;
  color: ${(props) => props.$color};
`
const TitrationHistoryGroup = () => {
  return (
    <colgroup data-testid="titrationHistoryGroup">
      <col style={{ width: '12.125%' }} />
      <col style={{ width: '17.5%' }} />
      <col style={{ width: '11.75%' }} />
      <col style={{ width: '26.25%' }} />
      <col style={{ width: '32.375%' }} />
    </colgroup>
  )
}
export const getChangeString = (
  changeAmount: number | null,
  t: TFunction<'translation', undefined>
) => {
  if (changeAmount === null) {
    return (
      <>
        <ChangeIcon icon={faCircleQuestion} $color={'var(--text-lighter)'} />
        <ChangeText $color={'var(--text-medium)'}>
          {t('Inconclusive')}
        </ChangeText>
      </>
    )
  } else if (changeAmount < 0) {
    return (
      <>
        <ChangeIcon icon={faArrowDownFromLine} $color={'var(--pink)'} />
        <ChangeText $color={'var(--text-primary)'}>
          {t('Subtract')} {Math.abs(changeAmount)} U
        </ChangeText>
      </>
    )
  } else if (changeAmount === 0) {
    return (
      <>
        <ChangeIcon icon={faArrowsToLine} $color={'var(--green)'} />
        <ChangeText $color={'var(--green)'}>{t('No change')}</ChangeText>
      </>
    )
  } else {
    return (
      <>
        <ChangeIcon icon={faArrowUpFromLine} $color={'var(--blue)'} />
        <ChangeText $color={'var(--text-primary)'}>
          {t('Add')} {changeAmount} U
        </ChangeText>
      </>
    )
  }
}

export const TitrationHistory = ({
  history,
  sorting,
  setSorting
}: {
  history: TitrationHistoryObject[] | null
  sorting: SortEnum
  setSorting: Dispatch<SetStateAction<SortEnum>>
}) => {
  const { user } = useSessionContext()
  const { t } = useTranslation()
  const columns = [
    { name: t('Date & time'), show: true },
    {
      name: t('Change from last dose'),
      show: user?.type !== UserType.Patient
    },
    { name: t('New suggested dose'), show: true },
    { name: t('Set and approved by'), show: true },
    { name: t('Context'), show: true }
  ]

  return (
    <TableWrapper>
      <ScrollTableComponent group={<TitrationHistoryGroup />}>
        <THead data-testid="titrationHistoryHeader">
          {columns.map(
            (key, idx) =>
              key.show && (
                <Th key={key.name}>
                  <HeaderWrapper>
                    <TText>{key.name}</TText>
                    {idx === 0 && (
                      <SortIconWrapper
                        data-testid="sortbutton"
                        onClick={() =>
                          setSorting(
                            sorting === SortEnum.Ascending
                              ? SortEnum.Descending
                              : SortEnum.Ascending
                          )
                        }
                      >
                        <SortIconUp
                          data-testid="sortUp"
                          icon={faSortUp}
                          $active={sorting === SortEnum.Ascending}
                        />
                        <SortIconDown
                          data-testid="sortDown"
                          icon={faSortDown}
                          $active={sorting === SortEnum.Descending}
                        />
                      </SortIconWrapper>
                    )}
                  </HeaderWrapper>
                </Th>
              )
          )}
        </THead>
        {history ? (
          history.length > 0 ? (
            history.map((e) => (
              <Tr
                key={'titrationHistoryEvent' + e.time}
                data-testid="titrationHistoryEventRow"
              >
                <Td>
                  <RowBoxWrapper>
                    <TText>{dayjs(e.time).format('DD/MM/YYYY')}</TText>
                    <TText style={{ color: 'var(--text-lighter)' }}>@</TText>
                    <TText>{dayjs(e.time).format('HH:mm')}</TText>
                  </RowBoxWrapper>
                </Td>
                {user?.type !== UserType.Patient && (
                  <Td>
                    <RowBoxWrapper>
                      {getChangeString(e.changeFromLastDose, t)}
                    </RowBoxWrapper>
                  </Td>
                )}
                <Td>
                  <RowBoxWrapper>
                    <TText>{e.dose} U</TText>
                  </RowBoxWrapper>
                </Td>
                <Td>
                  <RowBoxWrapper>
                    <TText>{e.user}</TText>
                  </RowBoxWrapper>
                </Td>
                <UploadContext
                  version={e.version}
                  phoneOs={null}
                  productType={e.context}
                />
              </Tr>
            ))
          ) : (
            <NoDataRow />
          )
        ) : (
          <ScrollTableLoading size={20} />
        )}
      </ScrollTableComponent>
    </TableWrapper>
  )
}
