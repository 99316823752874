import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { WarningInfo } from '../DownloadWarning/DownloadWarning'
import { Button } from '../OpenUploader/OpenUploader'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 37.5rem;
  gap: 2rem;
`
const Guide = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 1rem;
`
const Title = styled.span`
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 700;
  font-family: inter;
  text-align: center;
  white-space: pre-line;
`
const GoBackButton = styled(Button)`
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-bg);
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
const BackIcon = styled(FontAwesomeIcon)`
  color: var(--text-primary);
  font-size: 1rem;
`
const GoBackButtonText = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
`

interface DownloadFailedProps {
  reset: () => void
}
export const DownloadFailed = ({ reset }: DownloadFailedProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper data-testid="uploaderDownloadFailed">
      <Title>{t('The download process failed')}</Title>
      <Guide>
        <WarningInfo
          text={t(
            'Unfortunately the download process failed. Please go back and try again.'
          )}
        />
        <GoBackButton onClick={reset}>
          <Row>
            <BackIcon icon={faArrowLeft}></BackIcon>
            <GoBackButtonText>{t('Go back')}</GoBackButtonText>
          </Row>
        </GoBackButton>
      </Guide>
    </Wrapper>
  )
}
