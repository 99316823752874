import styled from 'styled-components'
import { useBlurContext } from '../../core/contexts/blur-context'

const Blur = styled.div<{ blur: boolean }>`
  position: fixed;
  width: 100vw;
  margin-top: 4.5rem;
  height: ${(props) => (props.blur ? '3.75rem' : '3.25rem')};
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.blur ? 'var(--white-color)' : 'var(--transparentWhite80-color)'};
  backdrop-filter: blur(0.25rem);
  z-index: 3;
`

export const BlurComponent = () => {
  const { blur } = useBlurContext()
  return <Blur data-testid="blur" blur={blur} />
}
