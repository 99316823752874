import { Dispatch, SetStateAction } from 'react'
import { TFunction } from 'react-i18next'
import { AddPatientToPopulation } from '../../../components/AddPatientToPopulation/AddPatientToPopulation'
import { CreatePatient } from '../../../components/CreatePatient/CreatePatient'
import { CreatePopulation } from '../../../components/CreatePopulation/CreatePopulation'
import { SortEnum } from '../../../components/ProviderPatients/ProviderPatients'
import { User, UserType } from '../../../core/entities/user.entity'
import {
  PatientSearchResponse,
  PopulationOverview,
  TableType
} from '../tables/interfaces'
import {
  PatientBody,
  PatientGroup,
  PatientHeader
} from '../tables/patientTable/patientTable'
import {
  PopulationBody,
  PopulationGroup,
  PopulationHeader
} from '../tables/populationTable/populationTable'

type GetTableDataProps =
  | {
      type: TableType.patient
      t: TFunction<'translation', undefined>
      error: Error | null
      user: User | null
      config: {
        data: PatientSearchResponse | null
        sorting: SortEnum
        setSorting: Dispatch<SetStateAction<SortEnum>>
        populationid: string | undefined
        populations: PopulationOverview[] | null
        refresh: () => void
      }
    }
  | {
      type: TableType.population
      t: TFunction<'translation', undefined>
      error: Error | null
      user: User | null
      config: {
        data: PopulationOverview[] | null
        refresh: () => void
        updateContext: () => void
      }
    }

export function getTableData({
  type,
  t,
  user,
  error,
  config
}: GetTableDataProps) {
  switch (type) {
    case TableType.patient: {
      const getPatientHeader = () => {
        if (config.populationid && config.populations) {
          if (user?.type === UserType.HCPAdmin)
            return (
              <AddPatientToPopulation
                populationid={config.populationid}
                populations={config.populations}
                refresh={config.refresh}
              />
            )
          else return <></>
        }
        return <CreatePatient refresh={config.refresh} />
      }
      return {
        title: t('All Patients'),
        header: getPatientHeader(),
        tableGroup: <PatientGroup />,
        tableHeader: (
          <PatientHeader
            sorting={config.sorting}
            setSorting={config.setSorting}
          />
        ),
        tableBody: (
          <PatientBody
            patients={config.data?.patients ?? null}
            populationId={config.populationid}
            refresh={config.refresh}
            error={error}
          />
        )
      }
    }
    case TableType.population: {
      return {
        title: t('Population Management'),
        header:
          user?.type === UserType.HCPAdmin ? (
            <CreatePopulation
              refresh={config.refresh}
              updateContext={config.updateContext}
            />
          ) : (
            <></>
          ),
        tableGroup: <PopulationGroup />,
        tableHeader: <PopulationHeader />,
        tableBody: <PopulationBody populations={config.data} error={error} />
      }
    }
  }
}
