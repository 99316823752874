import config from '../config'
import { AxiosRequest } from './axios-request'

interface NominaldesignProviderProps {
  cmiPatientId: string
  ignored: boolean
}

export async function ignorePatient({
  cmiPatientId,
  ignored
}: NominaldesignProviderProps) {
  await (ignored
    ? AxiosRequest.create().delete(
        `${config.API_URL}/cmi/patients/${cmiPatientId}/ignore`
      )
    : AxiosRequest.create().post(
        `${config.API_URL}/cmi/patients/${cmiPatientId}/ignore`
      ))
}
