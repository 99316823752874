import { useTranslation } from 'react-i18next'
import { formatSeconds } from '../../../../helpers/formatSeconds'
import { FlexRowWrapper } from '../../../FlexWrappers/FlexRowWrapper'
import { LegendComponent, LegendEntry } from '../../Shared/Legend/Legend'
import { TimeInActivityConfig } from '../TimeInActivity'

interface LegendProps {
  data: { exercise: number; sleep: number } | null
}
export const Legend = ({ data }: LegendProps) => {
  const { t } = useTranslation()
  const exerciseValue = data ? formatSeconds(data.exercise) : '--h --m'
  const sleepValue = data ? formatSeconds(data.sleep) : '--h --m'

  return (
    <LegendComponent>
      <FlexRowWrapper>
        <LegendEntry
          label={t('Exercise')}
          value={exerciseValue}
          color={TimeInActivityConfig.exercise.color}
        ></LegendEntry>
        <LegendEntry
          label={t('Sleep')}
          value={sleepValue}
          color={TimeInActivityConfig.sleep.color}
        ></LegendEntry>
      </FlexRowWrapper>
    </LegendComponent>
  )
}
