import config from '../config'
import { AxiosRequest } from './axios-request'

interface AbbottLibreviewProviderProps {
  name: string
  username: string
  password: string
  email?: string
  emailHost?: string
  emailPassword?: string
}

export async function abbottLibreviewProvider(
  data: AbbottLibreviewProviderProps
) {
  return await AxiosRequest.create().post(
    `${config.API_URL}/cmi/providers/abbottlibreviewwebproviders`,
    { ...data, description: '' }
  )
}
