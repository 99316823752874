import { Dispatch, SetStateAction } from 'react'
import {
  Bar,
  BarChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'
import styled from 'styled-components'
import { TrendsTooltipProps } from '../../../containers/Trends/Trends'
import { TrendsData } from '../../../hooks/GetPatientData'
import { NoDataComponent } from '../../NoData/NoData'
import { StateValues } from '../TrendsHelpers/TrendsHelpers'

export interface TrendsGraphProps {
  minStart: number
  minEnd: number
  median: number
  range50End: number
  range50Start: number
  range80End: number
  range80Start: number
  range100End: number
  range100Start: number
  isEmpty: boolean
}
interface Props {
  data: TrendsData | null
  state: Array<boolean> | null
  setTooltip: Dispatch<SetStateAction<TrendsTooltipProps | null>>
  loading: boolean
}
interface GraphObject {
  [key: string]: Array<number> | string
}
enum MedianRange {
  veryHigh = 'medianVeryHigh',
  high = 'medianHigh',
  inRange = 'medianInRange',
  low = 'medianLow',
  veryLow = 'medianVeryLow',
  null = 'null'
}
const ResponsiveWrapper = styled(ResponsiveContainer)`
  width: 100%;
  height: 100%;
`
const Container = styled.div`
  width: 100%;
  height: 100%;
`
const yDomainMax = 30

const MedianShape = (props: any) => {
  return (
    <>
      {props.value !== undefined && (
        <circle
          cy={props.y}
          cx={props.x + props.radius}
          r={props.radius}
          stroke={props.stroke}
          fill={props.fill}
          onMouseEnter={() =>
            props.setTooltip({
              index: props.index,
              value: props.value
            })
          }
          onMouseLeave={() => props.setTooltip(null)}
        ></circle>
      )}
    </>
  )
}
const CustomLine = (props: any) => {
  return (
    <line
      x1={props.x1 - 8.5}
      x2={props.x2 - 8.5}
      y1={props.y1}
      y2={props.y2}
      stroke="var(--text-lightest)"
    ></line>
  )
}

const CustomizedXAxisTick = (props: any) => {
  const getLineAndTextPositions = () => {
    if (props.payload.value === 0) {
      return { line: -6, text: -2 }
    }
    if (props.payload.value === 47) {
      return { line: 7, text: -30 }
    } else {
      return { line: 0, text: 2 }
    }
  }
  const positions = getLineAndTextPositions()
  return (
    <g
      transform={
        props.payload.value === 0 || props.payload.value === 47
          ? `translate(${props.x},${props.y})`
          : `translate(${props.x - 8.5},${props.y})`
      }
    >
      <line
        x1={positions.line}
        x2={positions.line}
        y1="-12"
        y2="10"
        stroke="var(--text-lightest)"
      ></line>
      <text
        x={positions.text}
        textAnchor="right"
        dy={3}
        fontFamily="inter"
        fontSize="0.75rem"
        fontWeight={600}
        fill="var(--text-lighter)"
      >
        {props.payload.value === 47 ? '24:00' : props.payload.value / 2 + ':00'}
      </text>
    </g>
  )
}
const getMedianStyle = (range: MedianRange) => {
  switch (range) {
    case MedianRange.veryHigh:
      return {
        fill: 'var(--veryHigh-color)',
        stroke: 'var(--element-bg)'
      }
    case MedianRange.high:
      return {
        fill: 'var(--high-color)',
        stroke: 'var(--element-bg)'
      }
    case MedianRange.inRange:
      return {
        fill: 'var(--inRange-color)',
        stroke: 'var(--element-bg)'
      }
    case MedianRange.low:
      return {
        fill: 'var(--low-color)',
        stroke: 'var(--element-bg)'
      }
    case MedianRange.veryLow:
      return {
        fill: 'var(--veryLow-color)',
        stroke: 'var(--element-bg)'
      }
    default:
      return { fill: 'rgba(0,0,0,0)', stroke: 'rgba(0,0,0,0)' }
  }
}
export const TrendsGraph = ({ data, state, setTooltip, loading }: Props) => {
  const dataForGraph: Array<GraphObject> = []
  if (data && data.slices && data.slices.length > 0) {
    for (let i = 0; i < data.slices.length; i++) {
      if (!data.slices[i].isEmpty) {
        const object = {
          name: i.toString(),
          grey: [data.slices[i].range100Start, data.slices[i].range100End],
          lightBlue: [data.slices[i].range80Start, data.slices[i].range80End],
          blue: [data.slices[i].range50Start, data.slices[i].range50End]
        }
        const getGraphDataKey = (value: number) => {
          if (value < 3.0) return 'medianVeryLow'
          if (value >= 3.0 && value < 3.9) return 'medianLow'
          if (value >= 3.9 && value <= 10.0) return 'medianInRange'
          if (value > 10.0 && value <= 13.9) {
            return 'medianHigh'
          }
          return 'medianVeryHigh'
        }

        data.slices[i].median === null
          ? Object.assign(object, { null: [0] })
          : Object.assign(object, {
              [getGraphDataKey(data.slices[i].median)]: [
                data.slices[i].median,
                data.slices[i].median
              ]
            })
        dataForGraph.push(object)
      } else {
        dataForGraph.push({
          name: i.toString(),
          noData: [0, i % 2 === 0 ? 0 : 30]
        })
      }
    }
  } else {
    for (let i = 0; i < 48; i++) {
      dataForGraph.push({
        name: i.toString(),
        noData: [0, i % 2 === 0 ? 0 : 30]
      })
    }
  }
  return (
    <Container>
      {!(data?.slices && data.slices.length > 0) && !loading && (
        <NoDataComponent style={{ transform: 'translateX(-50%' }} />
      )}
      <ResponsiveWrapper data-testid="TrendsData">
        <BarChart
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          data={dataForGraph}
          barCategoryGap={2}
          className={'trendsClass'}
        >
          <XAxis
            xAxisId={0}
            axisLine={false}
            orientation={'top'}
            ticks={[0, 6, 12, 18, 24, 30, 36, 42, 47]}
            tick={CustomizedXAxisTick}
            tickLine={false}
          />
          {new Array(9).fill(0).map((e_, idx) => (
            <XAxis key={idx} xAxisId={idx + 1} hide />
          ))}
          <YAxis
            tick={{
              fill: 'var(--text-lighter)',
              fontWeight: 500,
              fontSize: '0.875rem',
              fontFamily: 'inter'
            }}
            fontSize="12px"
            dataKey="y"
            name="mmol"
            interval={0}
            tickLine={{ stroke: 'var(--text-lightest)' }}
            ticks={[3.0, 3.9, 10, 13.9]}
            domain={[0, yDomainMax]}
            axisLine={false}
            width={5}
          />

          <ReferenceArea
            x1={0}
            x2={47}
            y1={0}
            y2={29.9}
            stroke={'var(--element-stroke)'}
            fill={'var(--element-bg)'}
            radius={4}
          />
          {new Array(7).fill(0).map((_e, idx) => (
            <ReferenceLine
              x={6 + idx * 6}
              key={'referenceline' + idx}
              stroke="var(--text-lightest)"
              strokeOpacity={1}
              shape={<CustomLine />}
            />
          ))}
          <ReferenceLine
            y={3.9}
            stroke="var(--text-lightest)"
            strokeOpacity={1}
          />
          <ReferenceLine
            y={10}
            stroke="var(--text-lightest)"
            strokeOpacity={1}
          />

          {state && state[StateValues.hundred] && (
            <>
              <Bar
                xAxisId={1}
                isAnimationActive={false}
                dataKey="grey"
                fill="var(--trends100)"
                radius={6}
                stroke="var(--text-lightest)"
                barSize={12}
              />
            </>
          )}
          {state && state[StateValues.eighty] && (
            <Bar
              xAxisId={2}
              isAnimationActive={false}
              dataKey="lightBlue"
              fill="var(--trends80)"
              radius={6}
              barSize={12}
            />
          )}
          {state && state[StateValues.fifty] && (
            <Bar
              xAxisId={3}
              isAnimationActive={false}
              dataKey="blue"
              fill="var(--trends50)"
              radius={6}
              barSize={12}
            />
          )}
          {state && state[StateValues.median] && (
            <>
              {Object.entries(MedianRange).map((key, idx) => {
                const style = getMedianStyle(key[1])
                return (
                  <Bar
                    xAxisId={4 + idx}
                    isAnimationActive={false}
                    dataKey={key[1]}
                    fill={style.fill}
                    stroke={style.stroke}
                    radius={6}
                    barSize={12}
                    shape={<MedianShape setTooltip={setTooltip} />}
                    key={key[1]}
                  />
                )
              })}
            </>
          )}
          <Bar
            xAxisId={9}
            isAnimationActive={false}
            dataKey="noData"
            fillOpacity={0}
            radius={6}
            barSize={12}
            strokeOpacity={0}
          />
        </BarChart>
      </ResponsiveWrapper>
    </Container>
  )
}
