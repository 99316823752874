import styled from 'styled-components'
import { FlexColumnWrapper } from '../../../FlexWrappers/FlexColumnWrapper'
import { SizeEnum, WidgetState } from '../Interfaces/Interfaces'

export const Wrapper = styled(FlexColumnWrapper)<{
  $size: SizeEnum
  $screenshotBorder?: boolean
}>`
  width: 18.25rem;
  gap: 0.375rem;
  padding: ${(props) =>
    props.$size === SizeEnum.Small ? '0rem' : '0.75rem 1rem 1rem'};
  background-color: ${(props) =>
    props.$size === SizeEnum.Small ? 'none' : 'var(--white-color)'};
  border: ${(props) =>
    props.$size === SizeEnum.Small
      ? 'none'
      : props.$screenshotBorder
      ? '0.0625rem solid var(--blue)'
      : '0.0625rem solid var(--text-lightest)'};
  border-radius: ${(props) =>
    props.$size === SizeEnum.Small ? '0rem' : '0.5rem'};
  position: relative;
`

export const getWidgetState = (state: WidgetState, data: any | null) => {
  if (state === WidgetState.Data) {
    if (data === null) return WidgetState.Empty
  }
  return state
}
