import { faSearch, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { SubmitHandler, UseFormRegister, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
const SearchFieldWrapper = styled.form<{ focusState: boolean }>`
  display: flex;
  width: 26.25rem;
  height 2.5rem;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: var(--element-bg-dark);
   border: ${(props) =>
     props.focusState
       ? '0.0625rem solid var(--brand-primary-color)'
       : '0.0625rem solid rgba(0,0,0,0)'};
  box-shadow: ${(props) =>
    props.focusState
      ? '0rem 0rem 0rem 0.25rem var(--brand-primary-16-color)'
      : 'var(--text-lightest)'};
`
const SearchField = styled.input`
  width: 18rem;
  border: none;
  background-color: var(--element-bg-dark);
  color: var(--text-primary);
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  &:focus {
    outline: none;
  }
`
const Label = styled.span`
  font-family: inter;
  color: var(--text-primary);
  font-weight: 600;
  font-size: 0.875rem;
`

const SearchLogo = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--text-primary);
  margin-left: 1.25rem;
`
const DeleteLogo = styled(FontAwesomeIcon)`
  font-size: 1rem;
  font-weight: 900;
  color: inherit;
`
const DeleteButtonWrapper = styled.button`
  border: none;
  background: none;
  margin: 0 auto;
  padding: 0;
  &:disabled {
    opacity: 0;
    cursor: auto;
  }
  width: 1.75rem;
  cursor: pointer;
  color: var(--text-lighter);
`
const SearchButton = styled.input`
  padding: 0.75rem;
  text-transform: uppercase;
  border-radius: 0.375rem;
  border: 0.0625rem solid var(--text-primary);
  background-color: var(--text-primary);
  color: var(--white-color);
  font-size: 0.75rem;
  font-family: inter;
  font-weight: 600;
  height: 2.25rem;
  width: 4.75rem;
  margin-right: 0.125rem;
  line-height: 0.6rem;
  cursor: pointer;
  &:disabled {
    border: 0.0625rem solid var(--element-bg-darkest);
    background-color: var(--element-bg-darkest);
    color: var(--white-color);
    cursor: default;
  }
`
export interface SearchProps {
  searchString: string
}

interface SearchFieldComponentProps {
  submit: any
  register: UseFormRegister<SearchProps>
  isDirty: boolean | undefined
  resetField: () => void
}
const SearchFieldComponent = ({
  submit,
  register,
  isDirty,
  resetField
}: SearchFieldComponentProps) => {
  const [focusState, setFocusState] = useState<boolean>(false)
  const { t } = useTranslation()
  const handleFocus = () => {
    setFocusState(true)
  }
  const handleBlur = () => {
    isDirty ? setFocusState(isDirty) : setFocusState(false)
  }
  useEffect(() => {
    if (!isDirty) {
      submit()
    }
  })
  return (
    <Wrapper>
      <Label>{t('Search for employee id or cpr')}</Label>
      <SearchFieldWrapper onSubmit={submit} focusState={focusState}>
        <SearchLogo icon={faSearch} />
        <SearchField
          {...register('searchString')}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="off"
          data-testid="SearchBarInput"
          required
        />
        <DeleteButtonWrapper
          type="reset"
          disabled={!isDirty}
          onClick={resetField}
          data-testid="clearButton"
        >
          <DeleteLogo icon={faTimesCircle} />
        </DeleteButtonWrapper>
        <SearchButton
          type="submit"
          disabled={!isDirty}
          data-testid="submitButton"
          value={t('search')}
        ></SearchButton>
      </SearchFieldWrapper>
    </Wrapper>
  )
}

interface AuditLogSearchProps {
  getSearchString: (searchString: string) => void
  searchString: string
}
export const AuditLogSearch = ({
  getSearchString,
  searchString
}: AuditLogSearchProps) => {
  const {
    register,
    resetField,
    formState: { isDirty },
    handleSubmit,
    getValues,
    setValue
  } = useForm<SearchProps>({
    mode: 'onChange',
    defaultValues: { searchString: '' }
  })

  const onSubmit: SubmitHandler<SearchProps> = (data) => onSearch(data)
  const onSearch = (data: SearchProps) => {
    getSearchString(data.searchString)
  }

  useEffect(() => {
    if (searchString) {
      setValue('searchString', searchString, { shouldDirty: true })
    }
  }, [searchString, setValue])

  return (
    <SearchFieldComponent
      submit={handleSubmit(onSubmit)}
      register={register}
      isDirty={isDirty}
      resetField={() => {
        resetField('searchString')
        getSearchString(getValues('searchString'))
      }}
    />
  )
}
