import { faCircleChevronDown as faCircleChevronDownRegular } from '@fortawesome/pro-regular-svg-icons'
import { faCircleChevronDown as faCircleChevronDownSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { usePatientLoadingContext } from '../../../core/contexts/patient-loading-context'
import { Patient } from '../../../core/entities/patient.entity'
import { SnackBar, SnackBarType } from '../../../libraries/Toast/SnackBar'
import { Modal, ModalComponent } from '../../Modal/Modal'
import { Spinner, spinnerSize } from '../../Spinner/Spinner'
import { ChoosePatientModal } from './ChoosePatientModal/ChoosePatientModal'

const Wrapper = styled.div<{ $hovered: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.125rem;
  box-sizing: border-box;
  height: 100%;
  border-right: 0.0625rem solid var(--text-lightest);
  position: relative;
  z-index: 10;
  min-width: 9rem;
  background-color: ${(props) =>
    props.$hovered ? 'var(--element-bg)' : 'none'};
`

const MRN = styled.span`
  color: var(--brand-primary-color);
  cursor: pointer;
  &:hover {
    color: var(--green);
  }
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.75rem;
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 1rem;
`

const Name = styled.span`
  font-family: inter;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
  line-height: 1.75rem;
`

const ChevronDown = styled(FontAwesomeIcon)`
  color: var(--brand-primary-color);
  font-size: 1rem;
  line-height: 0.75rem;
`

interface NameComponentProps {
  patient: Patient
  wards?: Array<Patient> | null
}

export const NameComponent = ({ patient, wards }: NameComponentProps) => {
  const { t } = useTranslation()
  const [choosePatient, setChoosePatient] = useState<boolean>(false)
  const [originalPatient, setOriginalPatient] = useState<Patient | null>(null)
  const [hovered, setHovered] = useState<boolean>(false)
  const { patientLoading } = usePatientLoadingContext()

  const [enableChoosePatientModal, setEnableChoosePatientModal] =
    useState<boolean>(false)

  useEffect(() => {
    if (
      patient.patientId === originalPatient?.patientId ||
      originalPatient === null
    ) {
      setOriginalPatient(patient)
    }
  }, [originalPatient, patient])

  useEffect(() => {
    if (
      wards &&
      wards.length > 0 &&
      !(wards[0].mrn === patient.mrn && wards.length === 1)
    ) {
      setEnableChoosePatientModal(true)
    } else {
      setEnableChoosePatientModal(false)
    }
  }, [wards, patient])

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(patient.mrn)
      SnackBar({
        type: SnackBarType.Success,
        message: t('CPR has been successfully copied to the clipboard!')
      })
    } catch (error) {
      SnackBar({
        type: SnackBarType.Success,
        message: t('An error occurred when copying CPR to clipboard')
      })
    }
  }

  const getIcon = () => {
    if (patientLoading) {
      return <Spinner spinnersize={spinnerSize.small}></Spinner>
    }
    if (enableChoosePatientModal) {
      return (
        <ChevronDown
          data-testid="choosePatientEnabled"
          icon={hovered ? faCircleChevronDownSolid : faCircleChevronDownRegular}
        />
      )
    }
  }

  const closeModal = () => {
    setChoosePatient(false)
    setHovered(false)
  }

  return (
    <>
      {enableChoosePatientModal && wards && choosePatient && (
        <Modal isOpen={choosePatient}>
          <ModalComponent
            closeFun={() => closeModal()}
            closetestid="closeChoosePatientModal"
            hideButton
          >
            <ChoosePatientModal
              wards={wards}
              originalPatient={originalPatient}
              currentlyViewedPatient={patient}
              closeFun={() => closeModal()}
            />
          </ModalComponent>
        </Modal>
      )}
      <Wrapper
        data-testid="copyWrapper"
        onClick={() => setChoosePatient(true)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        $hovered={hovered && enableChoosePatientModal}
      >
        <Row>
          <MRN
            onClick={(e) => {
              e.stopPropagation()
              handleCopyToClipboard()
            }}
            data-testid="copyButton"
          >
            {patient.mrn.substring(0, 6) + '-' + patient.mrn.substring(6, 11)}
          </MRN>
          {getIcon()}
        </Row>
        <Name data-testid="patientName">{patient.name}</Name>
      </Wrapper>
    </>
  )
}
