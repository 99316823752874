import { SizeEnum, WidgetState } from '../Shared/Interfaces/Interfaces'
import { Wrapper, getWidgetState } from '../Shared/Wrapper/Wrapper'
import { Bars } from './Bars/Bars'
import { Header } from './Header/Header'
import { Legend } from './Legend/Legend'

export interface TimeInAutomationWidgetData {
  automation: { percentage: number; value: number }
  manual: { percentage: number; value: number }
}
interface TimeInAutomationProps {
  size: SizeEnum
  state: WidgetState
  data: TimeInAutomationWidgetData | null
}

export const TimeInAutomationConfig = {
  automation: {
    color: 'var(--pink)'
  },
  manual: {
    color: 'var(--text-medium)'
  }
}
export const TimeInAutomation = ({
  size,
  state,
  data
}: TimeInAutomationProps) => {
  const actualState = getWidgetState(state, data)

  return (
    <Wrapper $size={size} data-testid="timeInAutomation">
      <Header size={size} state={actualState} />
      <Bars
        data={
          data
            ? {
                automation: data.automation.percentage,
                manual: data.manual.percentage
              }
            : null
        }
        state={actualState}
      />
      <Legend
        data={
          data
            ? { automation: data.automation.value, manual: data.manual.value }
            : null
        }
      />
    </Wrapper>
  )
}
