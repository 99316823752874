import { t } from 'i18next'
import styled from 'styled-components'
import { ModalComponent } from '../Modal/Modal'

const DeleteModalComponent = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  padding: 2rem;
  width: 31.25rem;
`
const DeleteModalTitle = styled.div`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 700;
`

const DeleteModalBody = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-medium);
`

const DeleteModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`
const DeleteModalButton = styled.div`
  height: 3rem;
  width: 100%;
  text-transform: uppercase;
  font-family: inter;
  font-size: 1rem;
  font-weight: 700;
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--destructive);
  border-radius: 0.5rem;
  &:hover {
    background-color: var(--destructive-darker);
  }
`
const DeleteModalCancelButton = styled(DeleteModalButton)`
  height: 2.875rem;
  border: 0.0625rem solid var(--text-lightest);
  color: var(--text-primary);
  background-color: var(--element-bg);
  &:hover {
    background-color: var(--element-bg-dark);
  }
`
interface DeleteModalProps {
  title: string
  body: string
  buttonText: string
  acceptfn: () => Promise<void>
  cancelfn: () => void
}

export const DeleteModal = ({
  title,
  body,
  buttonText,
  acceptfn,
  cancelfn
}: DeleteModalProps) => {
  return (
    <ModalComponent closeFun={cancelfn} closetestid={'CloseDeleteModal'}>
      <DeleteModalComponent data-testid="DeleteModalWrapper">
        <DeleteModalTitle>{title}</DeleteModalTitle>
        <DeleteModalBody>{body}</DeleteModalBody>
        <DeleteModalButtons>
          <DeleteModalButton data-testid="AcceptDeleteModal" onClick={acceptfn}>
            {buttonText}
          </DeleteModalButton>
          <DeleteModalCancelButton
            data-testid="CancelDeleteModal"
            onClick={cancelfn}
          >
            {t('Cancel')}
          </DeleteModalCancelButton>
        </DeleteModalButtons>
      </DeleteModalComponent>
    </ModalComponent>
  )
}
