import { faMoonStars } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--white-color);
  background-color: var(--blue);
  &:hover {
    background-color: var(--blue-darker);
  }
`

const ButtonText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: inherit;
`
const ButtonIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: inherit;
`
interface EventsHeaderProps {
  setHypoAlgoModal: Dispatch<SetStateAction<boolean>>
}
export const EventsHeader = ({ setHypoAlgoModal }: EventsHeaderProps) => {
  const { t } = useTranslation()

  return (
    <Header data-testid="eventLogHeader">
      <Title>{t('Nocturnal hypo predictions')}</Title>
      <ButtonWrapper>
        <Button
          onClick={() => setHypoAlgoModal(true)}
          data-testid="doNocturnalPrediction"
        >
          <ButtonIcon icon={faMoonStars} />
          <ButtonText>{t('Nocturnal hypo prediction')}</ButtonText>
        </Button>
      </ButtonWrapper>
    </Header>
  )
}
