import config from '../config'
import { AxiosRequest } from './axios-request'

interface deletePatientProviderProps {
  patientId: string | null
}

export async function acknowledgePatientProvider({
  patientId
}: deletePatientProviderProps) {
  const deleted = AxiosRequest.create().delete(
    `${config.API_URL}/pmi/${patientId}/acknowledge`
  )
  return (await deleted).data
}
