import {
  faDisplay,
  faLaptop,
  faMobileNotch
} from '@fortawesome/pro-regular-svg-icons'
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TText, Td } from '../../../components/Table/ScrollTableComponent'
import { PhoneOs, ProductType } from '../interfaces'
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  min-height: 2.25rem;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 400;
`

const Seperator = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  font-weight: 900;
  color: var(--text-lighter);
`

const Container = styled(Td)`
  max-width: 15rem;
  overflow: auto;
  background-color: inherit;
`

interface UploadContextProps {
  version: string
  phoneOs: PhoneOs | null
  productType: ProductType
}
export const UploadContext = ({
  version,
  phoneOs,
  productType
}: UploadContextProps) => {
  const { t } = useTranslation()

  const getConfig = () => {
    switch (productType) {
      case ProductType.Uploader:
        return { source: faLaptop, text: t('Home Uploader') }
      case ProductType.Portal:
        return { source: faDisplay, text: t('Line Portal') }
      case ProductType.AdaptMobileApp:
        return { source: faMobileNotch, text: t('Patient App') }
    }
  }
  const config = getConfig()
  return (
    <Container>
      <Wrapper>
        <Icon icon={config.source}></Icon>
        <TText>{config.text}</TText>
        <Seperator icon={faCircleSmall} />
        <TText>{version}</TText>
        {phoneOs !== null && (
          <>
            <Seperator icon={faCircleSmall}></Seperator>
            <TText>{phoneOs}</TText>
          </>
        )}
      </Wrapper>
    </Container>
  )
}
