import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProviderObject } from '../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { FormInfoSection } from '../../../FormInfoSection/FormInfoSection'
import { ProviderPatientsType } from '../../../ProviderPatients/ProviderPatients'
import { BodyText, FormValues } from '../../ProviderIntegrationModel'
import { getProviderTypeInfoText } from '../../ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'
import { InputComponent } from './InputComponent/InputComponent'

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

interface FormPageProps {
  register: UseFormRegister<FormValues>
  provider: ProviderObject | null
  handleChange: () => void
  currentProvider: ProviderPatientsType | null
}

interface getInputFieldsProps {
  currentProvider: ProviderPatientsType | null
  register: UseFormRegister<FormValues>
  provider: ProviderObject | null
  handleChange: () => void
}

const getInputFields = ({
  currentProvider,
  register,
  provider,
  handleChange
}: getInputFieldsProps) => {
  switch (currentProvider) {
    case ProviderPatientsType.dexcomapiprovider:
      return (
        <>
          <InputComponent
            label={'clientId'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'clientSecret'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
        </>
      )
    case ProviderPatientsType.fitbitapiprovider:
      return (
        <>
          <InputComponent
            label={'clientId'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'clientSecret'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
        </>
      )
    case ProviderPatientsType.medtroniccarelinkwebprovider:
      return (
        <>
          <InputComponent
            label={'emailAddress'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'emailHost'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'emailPassword'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
        </>
      )
    case ProviderPatientsType.abbottlibreviewwebprovider:
      return (
        <>
          <InputComponent
            label={'emailAddress'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'emailHost'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'emailPassword'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
        </>
      )
    case ProviderPatientsType.dexcomclaritywebprovider:
      return <></>
  }
}
export const FormPage = ({
  register,
  provider,
  handleChange,
  currentProvider
}: FormPageProps) => {
  const { t } = useTranslation()
  const getFormPageDescription = (type: ProviderPatientsType) => {
    switch (type) {
      case ProviderPatientsType.dexcomapiprovider:
        return (
          <TextSection>
            <BodyText>
              {t('setupAPI', {
                provider: getProviderTypeInfoText(currentProvider)
              })}
            </BodyText>
          </TextSection>
        )
      case ProviderPatientsType.medtroniccarelinkwebprovider:
        return (
          <TextSection>
            <BodyText>
              {t(
                'You need to use the credentials of an clinican account that is invited to your clinic and has access to the patients within. The account should only be used this integration.'
              )}
            </BodyText>
          </TextSection>
        )
      case ProviderPatientsType.abbottlibreviewwebprovider:
        return (
          <TextSection>
            <BodyText>
              {t(
                'You need to use the credentials of an clinican account that is invited to your clinic and has access to the patients within. The account should only be used this integration.'
              )}
            </BodyText>
          </TextSection>
        )
      case ProviderPatientsType.dexcomclaritywebprovider:
        return (
          <TextSection>
            <BodyText>
              {t(
                'You need to use the credentials of an clinican account that is invited to your clinic and has access to the patients within. The account should only be used this integration.'
              )}
            </BodyText>
          </TextSection>
        )
      case ProviderPatientsType.fitbitapiprovider:
        return (
          <TextSection>
            <BodyText>
              {t('setupAPI', {
                provider: getProviderTypeInfoText(currentProvider)
              })}
            </BodyText>
          </TextSection>
        )
    }
  }
  return (
    <>
      {currentProvider && getFormPageDescription(currentProvider)}
      <FormWrapper>
        <InputComponent
          label={'alias'}
          register={register}
          disabled={provider !== null}
          handleChange={handleChange}
        />
        <FormInfoSection>
          {t(
            'Choose an alias that is meaningful to the context of the account. The name will show on the integration list and allow you to distinguish integrations of the same type if there are more than one'
          )}
        </FormInfoSection>
        {currentProvider !== ProviderPatientsType.dexcomapiprovider &&
          currentProvider !== ProviderPatientsType.fitbitapiprovider && (
            <>
              <InputComponent
                label={'username'}
                register={register}
                disabled={provider !== null}
                handleChange={handleChange}
              />
              <InputComponent
                label={'password'}
                register={register}
                disabled={provider !== null}
                handleChange={handleChange}
              />
            </>
          )}
        {getInputFields({
          currentProvider,
          register,
          provider,
          handleChange
        })}
      </FormWrapper>
    </>
  )
}
