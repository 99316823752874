import config from '../config'
import { AxiosRequest } from './axios-request'

interface DexcomApiProviderProps {
  name: string
  clientId: string
  clientSecret: string
}

export async function dexcomApiProvider(data: DexcomApiProviderProps) {
  return await AxiosRequest.create().post(
    `${config.API_URL}/cmi/providers/dexcomapiproviders`,
    { ...data, description: '' }
  )
}
