import { createContext, useContext } from 'react'
import { SiteSettings } from '../entities/siteSettings.entity'

export const SiteSettingsContext = createContext<{
  siteSettings: SiteSettings | null
  siteSettingsError: boolean
  siteSettingsInitialized: boolean
}>({
  siteSettings: null,
  siteSettingsError: false,
  siteSettingsInitialized: false
})

export function useSiteSettingsContext() {
  return useContext(SiteSettingsContext)
}

export default SiteSettingsContext
