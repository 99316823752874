import { Dispatch, SetStateAction } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'
import { checkBoxType, checkDays } from '../TrendsButtons/TrendsButtons'

interface ButtonProps {
  index: number
  state: Array<boolean> | null
  setState: Dispatch<SetStateAction<Array<boolean>>>
}
interface ButtonCSSProps {
  active: boolean
  hover: boolean
}

export const Button = styled.button<ButtonCSSProps>`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
  background-color: ${(props) =>
    props.active ? 'var(--brand-primary-4-color)' : 'var(--white-color)'};
  color: ${(props) =>
    props.active ? 'var(--brand-primary-color)' : 'var(--text-primary)'};
  border: ${(props) =>
    props.active
      ? '0.0625rem solid var(--brand-primary-12-color)'
      : '0.0625rem solid var(--text-lightest)'};
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  height: 2rem;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`
const getButtonNames = (
  index: number,
  t: TFunction<'translation', undefined>
) => {
  if (index === 0) return t('Median')
  if (index === 1) return t('50%')
  if (index === 2) return t('80%')
  if (index === 3) return t('100%')
}
export const TrendsButtonComponent = ({
  index,
  state,
  setState
}: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      hover={state !== null}
      data-testid={`trendsbutton${state?.length === 7 ? index : index + 7}`}
      active={state ? state[index] : false}
      name={
        state?.length === 7
          ? dayjs(new Date())
              .isoWeekday(index + 1)
              .format('dd')
          : getButtonNames(index, t)
      }
      onClick={
        state !== null
          ? () => setState(checkDays(state, checkBoxType.single, index))
          : () => {
              return null
            }
      }
    >
      {state?.length === 7
        ? dayjs(new Date())
            .isoWeekday(index + 1)
            .format('dd')
            .replace('.', '')
        : getButtonNames(index, t)}
    </Button>
  )
}
