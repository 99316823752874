import { useEffect, useState } from 'react'
import { PeriodObject } from '../components/Header/DevicesAndPeriod/DevicesAndPeriod'
import { getPatientPages } from '../core/api/get-patient-pages'
import { PatientPagesObjectType } from '../core/contexts/patient-view-context'
import { restructurePatientPageArray } from '../helpers/restructurePatientPageArray'

export interface PatientViewsProps {
  patientId: string | undefined
  period: PeriodObject | null
}
export const usePatientViews = ({ patientId, period }: PatientViewsProps) => {
  const [patientViews, setPatientViews] =
    useState<PatientPagesObjectType | null>(null)
  const [patientViewsError, setPatientViewsError] = useState<Error | null>(null)
  const [patientViewsLoading, setPatientViewsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!patientId || !period) return
    const getPatientViews = async () => {
      try {
        const views = await getPatientPages({ patientId, period })
        setPatientViews(restructurePatientPageArray(views.pages))
      } catch (error) {
        setPatientViewsError(error as Error)
      } finally {
        setPatientViewsLoading(false)
      }
    }
    setPatientViewsLoading(true)
    setPatientViews(null)
    getPatientViews()
  }, [period, patientId])

  return {
    patientViews,
    patientViewsError,
    patientViewsLoading
  }
}
