import config from '../config'
import { AxiosRequest } from './axios-request'

export async function uploadData(
  patientId: string,
  deviceType: string,
  formData: FormData,
  strict?: boolean
) {
  const strictString = strict === undefined ? '&strict=false' : '&strict=true'
  await AxiosRequest.create({
    headers: {
      'content-type': 'multipart/form-data'
    }
  }).post(
    `${config.API_URL}/cloudfile/upload?patientId=${patientId}&cloudFileType=${deviceType}${strictString}`,
    formData
  )
}
