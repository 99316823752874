import { SortEnum } from '../../components/ProviderPatients/ProviderPatients'
import { Upload } from '../../components/Uploads/Uploads'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getClinicUploads(
  orderBy: SortEnum | undefined,
  size: number | undefined
): Promise<Array<Upload>> {
  const response = await AxiosRequest.create().get(
    `${config.API_URL}/clinics/uploads?order=${orderBy}&size=${size}`
  )
  return response.data as Array<Upload>
}
