import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { CSSProperties } from 'styled-components'

const Warning = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
`
const WarningWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.75rem;
`
const WarningIcon = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 1.125;
  color: var(--yellow);
`
const WarningText = styled.div`
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--yellow-darker);
`

interface WarningInfoProps {
  text: string
  styling?: CSSProperties
}
export const WarningInfo = ({ text, styling }: WarningInfoProps) => {
  return (
    <Warning data-testid="downloadFailedWarning" style={styling}>
      <WarningWrapper>
        <WarningIcon icon={faTriangleExclamation}></WarningIcon>
        <WarningText>{text}</WarningText>
      </WarningWrapper>
    </Warning>
  )
}
